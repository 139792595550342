// tslint:disable:ordered-imports
import "src/utilities/polyfills/polyfillStringReplaceAll";

import { defineCustomElements } from "@ionic/pwa-elements/loader";
import ReactDOM from "react-dom";
import initGlobalCss from "src/globalCss";
import { setupIonicReact } from "@ionic/react";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import patchCordovaJs from "./utilities/patchCordovaJs";

initGlobalCss();

patchCordovaJs();

setupIonicReact({
  // TODO: see if this improves in a future version of Ionic (current @ionic/react v5.8.1)
  scrollAssist: false,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Call the element loader after the platform has been bootstrapped
dontAwait(defineCustomElements(window));
