import {
  teamUserProfilesPath,
  userProfilesPath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import TeamUserProfileConverter from "@connectedliving/common/lib/firestore/TeamUserProfileConverter";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import UserProfileConverter from "@connectedliving/common/lib/firestore/UserProfileConverter";
import firebase from "firebase/compat/app";
import { useCallback, useMemo } from "react";
import combineLoadingStates from "src/utilities/combineLoadingStates";
import { createContainer } from "src/utilities/createContainer";
import { Loadable } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import { LoadingState } from "src/utilities/LoadingState";
import { FindUserProfileById } from "../FindUserProfileById";
import FirebaseAppContainer from "./FirebaseAppContainer";

export type TeamUserProfiles = {
  userProfiles: firebase.firestore.QueryDocumentSnapshot<UserProfile>[];
  teamUserProfiles: Loadable<firebase.firestore.QuerySnapshot<TeamUserProfile>>;
  loadingState: LoadingState;
  findUserProfileById: FindUserProfileById;
  idToTeamUserProfile: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<TeamUserProfile>
  >;
  onboardedTeamUserProfiles: firebase.firestore.QueryDocumentSnapshot<TeamUserProfile>[];
  onboardedUserProfiles: firebase.firestore.QueryDocumentSnapshot<UserProfile>[];
};

function useTeamUserProfiles(initialState?: {
  teamId: string;
}): TeamUserProfiles {
  const teamId = initialState?.teamId;
  const { firebaseApp } = FirebaseAppContainer.useContainer();

  const userProfiles = useFirestoreCollection<UserProfile>(
    teamId &&
      firebaseApp
        .firestore()
        .collection(userProfilesPath())
        .withConverter(UserProfileConverter)
        .where("teamIds", "array-contains", teamId),
  );
  const teamUserProfiles = useFirestoreCollection<TeamUserProfile>(
    teamId &&
      firebaseApp
        .firestore()
        .collection(teamUserProfilesPath({ teamId }))
        .withConverter(TeamUserProfileConverter),
  );

  const idToTeamUserProfile: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<TeamUserProfile>
  > = {};
  teamUserProfiles.data?.forEach((teamUserProfile) => {
    idToTeamUserProfile[teamUserProfile.id] = teamUserProfile;
  });

  const userProfilesDocs = useMemo(
    () => userProfiles.data?.docs || [],
    [userProfiles.data?.docs],
  );

  const findUserProfileById = useCallback(
    (userProfileId) =>
      userProfiles.data?.docs.find(({ id }) => id === userProfileId),
    [userProfiles.data?.docs],
  );

  const onboardedTeamUserProfiles = useMemo(
    () =>
      teamUserProfiles.data?.docs.filter(
        (document) => document.data().onboardingVersionCompleted >= 1,
      ) ?? [],
    [teamUserProfiles.data?.docs],
  );

  const onboardedTeamUserProfilesIds = onboardedTeamUserProfiles?.flatMap(
    ({ id }) => id,
  );
  const onboardedUserProfiles = useMemo(
    () =>
      userProfilesDocs.filter((userProfile) =>
        onboardedTeamUserProfilesIds?.includes(userProfile.id),
      ),
    [onboardedTeamUserProfilesIds, userProfilesDocs],
  );

  return {
    loadingState: combineLoadingStates(
      userProfiles.loadingState,
      teamUserProfiles.loadingState,
    ),
    userProfiles: userProfilesDocs,
    teamUserProfiles,
    findUserProfileById,
    idToTeamUserProfile,
    onboardedTeamUserProfiles,
    onboardedUserProfiles,
  };
}

const TeamUserProfilesContainer = createContainer(useTeamUserProfiles);

export default TeamUserProfilesContainer;
