/* Ionicons v6.0.2, Types */

import { ArrayElement } from "../utilities/ArrayElement";

const supportedChannelIcons = Object.freeze([
  "accessibility",
  "add",
  "addCircle",
  "airplane",
  "alarm",
  "albums",
  "alert",
  "alertCircle",
  "americanFootball",
  "analytics",
  "aperture",
  "apps",
  "archive",
  "arrowBack",
  "arrowBackCircle",
  "arrowDown",
  "arrowDownCircle",
  "arrowForward",
  "arrowForwardCircle",
  "arrowRedo",
  "arrowRedoCircle",
  "arrowUndo",
  "arrowUndoCircle",
  "arrowUp",
  "arrowUpCircle",
  "at",
  "atCircle",
  "attach",
  "backspace",
  "bag",
  "bagAdd",
  "bagCheck",
  "bagHandle",
  "bagRemove",
  "balloon",
  "ban",
  "bandage",
  "barChart",
  "barbell",
  "barcode",
  "baseball",
  "basket",
  "basketball",
  "batteryCharging",
  "batteryDead",
  "batteryFull",
  "batteryHalf",
  "beaker",
  "bed",
  "beer",
  "bicycle",
  "bluetooth",
  "boat",
  "body",
  "bonfire",
  "book",
  "bookmark",
  "bookmarks",
  "bowlingBall",
  "briefcase",
  "browsers",
  "brush",
  "bug",
  "build",
  "bulb",
  "bus",
  "business",
  "cafe",
  "calculator",
  "calendar",
  "calendarClear",
  "calendarNumber",
  "call",
  "camera",
  "cameraReverse",
  "car",
  "carSport",
  "card",
  "caretBack",
  "caretBackCircle",
  "caretDown",
  "caretDownCircle",
  "caretForward",
  "caretForwardCircle",
  "caretUp",
  "caretUpCircle",
  "cart",
  "cash",
  "cellular",
  "chatbox",
  "chatboxEllipses",
  "chatbubble",
  "chatbubbleEllipses",
  "chatbubbles",
  "checkbox",
  "checkmark",
  "checkmarkCircle",
  "checkmarkDone",
  "checkmarkDoneCircle",
  "chevronBack",
  "chevronBackCircle",
  "chevronDown",
  "chevronDownCircle",
  "chevronForward",
  "chevronForwardCircle",
  "chevronUp",
  "chevronUpCircle",
  "clipboard",
  "close",
  "closeCircle",
  "cloud",
  "cloudCircle",
  "cloudDone",
  "cloudDownload",
  "cloudOffline",
  "cloudUpload",
  "cloudy",
  "cloudyNight",
  "code",
  "codeDownload",
  "codeSlash",
  "codeWorking",
  "cog",
  "colorFill",
  "colorFilter",
  "colorPalette",
  "colorWand",
  "compass",
  "construct",
  "contract",
  "contrast",
  "copy",
  "create",
  "crop",
  "cube",
  "cut",
  "desktop",
  "diamond",
  "dice",
  "disc",
  "document",
  "documentAttach",
  "documentLock",
  "documentText",
  "documents",
  "download",
  "duplicate",
  "ear",
  "earth",
  "easel",
  "egg",
  "ellipse",
  "ellipsisHorizontal",
  "ellipsisHorizontalCircle",
  "ellipsisVertical",
  "ellipsisVerticalCircle",
  "enter",
  "exit",
  "expand",
  "extensionPuzzle",
  "eye",
  "eyeOff",
  "eyedrop",
  "fastFood",
  "female",
  "fileTray",
  "fileTrayFull",
  "fileTrayStacked",
  "film",
  "filter",
  "filterCircle",
  "fingerPrint",
  "fish",
  "fitness",
  "flag",
  "flame",
  "flash",
  "flashOff",
  "flashlight",
  "flask",
  "flower",
  "folder",
  "folderOpen",
  "football",
  "footsteps",
  "funnel",
  "gameController",
  "gift",
  "gitBranch",
  "gitCommit",
  "gitCompare",
  "gitMerge",
  "gitNetwork",
  "gitPullRequest",
  "glasses",
  "globe",
  "golf",
  "grid",
  "hammer",
  "handLeft",
  "handRight",
  "happy",
  "hardwareChip",
  "headset",
  "heart",
  "heartCircle",
  "heartDislike",
  "heartDislikeCircle",
  "heartHalf",
  "help",
  "helpBuoy",
  "helpCircle",
  "home",
  "hourglass",
  "iceCream",
  "idCard",
  "image",
  "images",
  "infinite",
  "information",
  "informationCircle",
  "invertMode",
  "journal",
  "key",
  "keypad",
  "language",
  "laptop",
  "layers",
  "leaf",
  "library",
  "link",
  "list",
  "listCircle",
  "locate",
  "location",
  "lockClosed",
  "lockOpen",
  "logIn",
  "logOut",
  "logoAlipay",
  "logoAmazon",
  "logoAmplify",
  "logoAndroid",
  "logoAngular",
  "logoApple",
  "logoAppleAppstore",
  "logoAppleAr",
  "logoBehance",
  "logoBitbucket",
  "logoBitcoin",
  "logoBuffer",
  "logoCapacitor",
  "logoChrome",
  "logoClosedCaptioning",
  "logoCodepen",
  "logoCss3",
  "logoDesignernews",
  "logoDeviantart",
  "logoDiscord",
  "logoDocker",
  "logoDribbble",
  "logoDropbox",
  "logoEdge",
  "logoElectron",
  "logoEuro",
  "logoFacebook",
  "logoFigma",
  "logoFirebase",
  "logoFirefox",
  "logoFlickr",
  "logoFoursquare",
  "logoGithub",
  "logoGitlab",
  "logoGoogle",
  "logoGooglePlaystore",
  "logoHackernews",
  "logoHtml5",
  "logoInstagram",
  "logoIonic",
  "logoIonitron",
  "logoJavascript",
  "logoLaravel",
  "logoLinkedin",
  "logoMarkdown",
  "logoMastodon",
  "logoMedium",
  "logoMicrosoft",
  "logoNoSmoking",
  "logoNodejs",
  "logoNpm",
  "logoOctocat",
  "logoPaypal",
  "logoPinterest",
  "logoPlaystation",
  "logoPwa",
  "logoPython",
  "logoReact",
  "logoReddit",
  "logoRss",
  "logoSass",
  "logoSkype",
  "logoSlack",
  "logoSnapchat",
  "logoSoundcloud",
  "logoStackoverflow",
  "logoSteam",
  "logoStencil",
  "logoTableau",
  "logoTiktok",
  "logoTumblr",
  "logoTux",
  "logoTwitch",
  "logoTwitter",
  "logoUsd",
  "logoVenmo",
  "logoVercel",
  "logoVimeo",
  "logoVk",
  "logoVue",
  "logoWebComponent",
  "logoWechat",
  "logoWhatsapp",
  "logoWindows",
  "logoWordpress",
  "logoXbox",
  "logoXing",
  "logoYahoo",
  "logoYen",
  "logoYoutube",
  "magnet",
  "mail",
  "mailOpen",
  "mailUnread",
  "male",
  "maleFemale",
  "man",
  "map",
  "medal",
  "medical",
  "medkit",
  "megaphone",
  "menu",
  "mic",
  "micCircle",
  "micOff",
  "micOffCircle",
  "moon",
  "move",
  "musicalNote",
  "musicalNotes",
  "navigate",
  "navigateCircle",
  "newspaper",
  "notifications",
  "notificationsCircle",
  "notificationsOff",
  "notificationsOffCircle",
  "nuclear",
  "nutrition",
  "open",
  "options",
  "paperPlane",
  "partlySunny",
  "pause",
  "pauseCircle",
  "paw",
  "pencil",
  "people",
  "peopleCircle",
  "person",
  "personAdd",
  "personCircle",
  "personRemove",
  "phoneLandscape",
  "phonePortrait",
  "pieChart",
  "pin",
  "pint",
  "pizza",
  "planet",
  "play",
  "playBack",
  "playBackCircle",
  "playCircle",
  "playForward",
  "playForwardCircle",
  "playSkipBack",
  "playSkipBackCircle",
  "playSkipForward",
  "playSkipForwardCircle",
  "podium",
  "power",
  "pricetag",
  "pricetags",
  "print",
  "prism",
  "pulse",
  "push",
  "qrCode",
  "radio",
  "radioButtonOff",
  "radioButtonOn",
  "rainy",
  "reader",
  "receipt",
  "recording",
  "refresh",
  "refreshCircle",
  "reload",
  "reloadCircle",
  "remove",
  "removeCircle",
  "reorderFour",
  "reorderThree",
  "reorderTwo",
  "repeat",
  "resize",
  "restaurant",
  "returnDownBack",
  "returnDownForward",
  "returnUpBack",
  "returnUpForward",
  "ribbon",
  "rocket",
  "rose",
  "sad",
  "save",
  "scale",
  "scan",
  "scanCircle",
  "school",
  "search",
  "searchCircle",
  "send",
  "server",
  "settings",
  "shapes",
  "share",
  "shareSocial",
  "shield",
  "shieldCheckmark",
  "shieldHalf",
  "shirt",
  "shuffle",
  "skull",
  "snow",
  "sparkles",
  "speedometer",
  "square",
  "star",
  "starHalf",
  "statsChart",
  "stop",
  "stopCircle",
  "stopwatch",
  "storefront",
  "subway",
  "sunny",
  "swapHorizontal",
  "swapVertical",
  "sync",
  "syncCircle",
  "tabletLandscape",
  "tabletPortrait",
  "telescope",
  "tennisball",
  "terminal",
  "text",
  "thermometer",
  "thumbsDown",
  "thumbsUp",
  "thunderstorm",
  "ticket",
  "time",
  "timer",
  "today",
  "toggle",
  "trailSign",
  "train",
  "transgender",
  "trash",
  "trashBin",
  "trendingDown",
  "trendingUp",
  "triangle",
  "trophy",
  "tv",
  "umbrella",
  "unlink",
  "videocam",
  "videocamOff",
  "volumeHigh",
  "volumeLow",
  "volumeMedium",
  "volumeMute",
  "volumeOff",
  "walk",
  "wallet",
  "warning",
  "watch",
  "water",
  "wifi",
  "wine",
  "woman",
] as const);

export const selectableChannelIcons: ArrayElement<
  typeof supportedChannelIcons
>[] = [
  "accessibility",
  "airplane",
  "alarm",
  "americanFootball",
  "aperture",
  "bagHandle",
  "balloon",
  "bandage",
  "barChart",
  "barbell",
  "baseball",
  "basketball",
  "bed",
  "beer",
  "bicycle",
  "boat",
  "bonfire",
  "book",
  "bookmarks",
  "bowlingBall",
  "briefcase",
  "brush",
  "bug",
  "build",
  "bulb",
  "bus",
  "business",
  "cafe",
  "calculator",
  "calendarNumber",
  "call",
  "camera",
  "car",
  "card",
  "cart",
  "cash",
  "chatbubbles",
  "checkbox",
  "codeSlash",
  "cog",
  "colorFill",
  "colorFilter",
  "colorPalette",
  "colorWand",
  "compass",
  "construct",
  "cube",
  "cut",
  "diamond",
  "dice",
  "disc",
  "documentText",
  "ear",
  "earth",
  "easel",
  "extensionPuzzle",
  "fastFood",
  "female",
  "film",
  "fish",
  "flash",
  "flashlight",
  "flask",
  "flower",
  "football",
  "footsteps",
  "gameController",
  "gift",
  "glasses",
  "golf",
  "hammer",
  "handRight",
  "happy",
  "headset",
  "heart",
  "help",
  "helpBuoy",
  "home",
  "hourglass",
  "iceCream",
  "image",
  "infinite",
  "information",
  "key",
  "keypad",
  "leaf",
  "library",
  "location",
  "magnet",
  "mail",
  "male",
  "maleFemale",
  "map",
  "medical",
  "megaphone",
  "moon",
  "musicalNotes",
  "newspaper",
  "nutrition",
  "partlySunny",
  "paw",
  "pizza",
  "planet",
  "print",
  "prism",
  "radio",
  "ribbon",
  "rocket",
  "rose",
  "sad",
  "scale",
  "school",
  "shapes",
  "shirt",
  "snow",
  "sparkles",
  "speedometer",
  "star",
  "storefront",
  "subway",
  "telescope",
  "tennisball",
  "thermometer",
  "ticket",
  "trailSign",
  "transgender",
  "trash",
  "trophy",
  "umbrella",
  "walk",
  "watch",
  "water",
  "wifi",
  "wine",
  "woman",
];

export type SupportedChannelIcon = ArrayElement<typeof supportedChannelIcons>;
export default supportedChannelIcons;
