import firebase from "firebase/compat/app";
import {
  isFirestoreAppTimestamp,
  makeTimestampValueFromFirestoreAppTimestamp,
} from "./app/firestoreAppTimestamp";
import { ChannelBackingStorage } from "./ChannelBackingStorage";
import {
  getBoolean,
  getList,
  getOptionalStrictDocumentData,
  getString,
  getStringLiteral,
  StrictDocumentData,
} from "./dataHelpers";
import { DirectMessageChannel } from "./DirectMessageChannel";
import { firestoreDataToMessage } from "./MessageConverter";

export function directMessageChannelToFirestoreData(
  directMessageChannel: DirectMessageChannel,
): DirectMessageChannel {
  return directMessageChannel;
}

export function firestoreDataToDirectMessageChannel(
  data: StrictDocumentData,
): DirectMessageChannel {
  const latestMessageData = getOptionalStrictDocumentData(data.latestMessage);
  return {
    teamId: getString(data.teamId),
    userId: getString(data.userId),
    otherUserId: getString(data.otherUserId),
    createdBy: getString(data.createdBy),
    streamChannelId: getString(data.streamChannelId),
    importedFromStream: getBoolean(data.importedFromStream),
    backingStorage: getStringLiteral(data.backingStorage, {
      permitted: [
        ChannelBackingStorage.Firestore,
        ChannelBackingStorage.Stream,
      ],
      fallback: ChannelBackingStorage.Stream,
    }),
    latestMessage:
      latestMessageData && firestoreDataToMessage(latestMessageData),
    messageTimestamps: getList(data.messageTimestamps, {
      element: isFirestoreAppTimestamp,
    }).map(makeTimestampValueFromFirestoreAppTimestamp),
  };
}

const DirectMessageChannelConverter: firebase.firestore.FirestoreDataConverter<DirectMessageChannel> =
  Object.freeze({
    toFirestore(directMessageChannel: DirectMessageChannel) {
      return directMessageChannelToFirestoreData(directMessageChannel);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): DirectMessageChannel {
      return firestoreDataToDirectMessageChannel(snapshot.data(options));
    },
  });

export default DirectMessageChannelConverter;
