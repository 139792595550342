import { Clipboard } from "@capacitor/clipboard";
import { deletedUserName } from "@connectedliving/common/lib/cloudFunctions/DeleteUser";
import { ChannelBackingStorage } from "@connectedliving/common/lib/firestore/ChannelBackingStorage";
import { ChannelPurpose } from "@connectedliving/common/lib/firestore/ChannelPurpose";
import { getUrl } from "@connectedliving/common/lib/firestore/dataHelpers";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import streamSystemUser from "@connectedliving/common/lib/stream/streamSystemUser";
import { TrackingChannelType } from "@connectedliving/common/lib/TrackingEvent";
import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import isErrorWithProps from "@connectedliving/common/lib/utilities/lang/isErrorWithProps";
import { viewUserProfilePageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import { useIonActionSheet, useIonToast } from "@ionic/react";
import firebase from "firebase/compat/app";
import { throttle } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import StreamChatContainer from "src/state/stream/StreamChatContainer";
import TranslationConfigContainer from "src/state/TranslationConfigContainer";
import {
  MessageContextValue,
  MessageSimple,
  MessageUIComponentProps,
  useMessageContext,
} from "stream-chat-react";
import { LongPressDetectEvents, useLongPress } from "use-long-press";

export const disableLinkTracking = "disable link tracking";
type DisableLinkTracking = typeof disableLinkTracking;

export type MessageRendererProps =
  MessageUIComponentProps<InternalStreamChatGenerics> & {
    authUser: firebase.User;
    teamId: string;
    linkTrackingContext:
      | {
          "channel type": Extract<TrackingChannelType, "direct message">;
          "channel id": string;
          "channel path": string;
          "channel purpose": null;
          "channel name": null;
          "is support dm channel": boolean;
          "is support user": boolean;
        }
      | {
          "channel type": Extract<TrackingChannelType, "team">;
          "channel id": string;
          "channel path": string;
          "channel purpose": ChannelPurpose | null;
          "channel name": string;
          "is support dm channel": false;
          "is support user": boolean;
        }
      | DisableLinkTracking;
    findUserProfileById: (
      userProfileId: string,
    ) => firebase.firestore.QueryDocumentSnapshot<UserProfile> | undefined;
  };
type MessageType = MessageContextValue<InternalStreamChatGenerics>["message"];

function applyTranslation(
  message: MessageType,
  preferredLanguage: string | null,
  showTranslation: boolean,
  authUserId: string,
): MessageType {
  const sentByCurrentUser = message.user?.id === authUserId;
  const alreadyInTargetLanguage =
    preferredLanguage &&
    message.detectedSourceLanguage &&
    preferredLanguage === message.detectedSourceLanguage;

  if (sentByCurrentUser || alreadyInTargetLanguage) {
    return { ...message, i18n: undefined };
  }

  if (preferredLanguage && showTranslation) {
    const googleTranslatedText = message.translations
      ? (message.translations as Record<string, string>)[preferredLanguage]
      : undefined;

    const translatedText = googleTranslatedText || message.text;

    return {
      ...message,
      i18n: undefined, // legacy from enabling translations with stream
      text: translatedText,
    };
  }
  return { ...message, i18n: undefined };
}

const unusedMouseEvent = blindCast<
  React.MouseEvent<HTMLElement, MouseEvent>,
  "StreamChat handleEdit() and handleDelete() require a MouseEvent, only to call preventDefault() on it"
>({
  preventDefault() {
    // empty method to prevent Unhandled Rejection
  },
});

const MessageRenderer: React.FC<MessageRendererProps> = (props) => {
  const history = useHistory();
  const messageContext =
    useMessageContext<InternalStreamChatGenerics>("MessageRenderer");
  const { streamChat } = StreamChatContainer.useContainer();
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const { authUser, teamId, findUserProfileById, linkTrackingContext } = props;

  const { showTranslation, preferredLanguage } =
    TranslationConfigContainer.useContainer();

  const message = applyTranslation(
    messageContext.message,
    preferredLanguage,
    showTranslation,
    authUser.uid,
  );
  const i18n = I18nContainer.useContainer();

  useEffect(() => {
    const onTouchMove = throttle(() => {
      setIsScrolling(true);
    }, 10);
    document.addEventListener("touchmove", onTouchMove);

    const onTouchEnd = () => {
      setIsScrolling(false);
    };
    document.addEventListener("touchend", onTouchEnd);

    return () => {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    };
  }, []);

  const [present, dismiss] = useIonActionSheet();

  const presentDeleteMessageConfirmation = useCallback(() => {
    dontAwait(
      present({
        header: i18n.t.MessageRenderer.cannotUndo,
        animated: true,
        keyboardClose: true,
        buttons: [
          {
            text: i18n.t.MessageRenderer.deleteMessage,
            role: "destructive",
            handler: () => messageContext.handleDelete(unusedMouseEvent),
          },
          {
            text: i18n.t.common.cancel,
            role: "cancel",
          },
        ],
      }),
    );
  }, [
    present,
    i18n.t.MessageRenderer.cannotUndo,
    i18n.t.MessageRenderer.deleteMessage,
    i18n.t.common.cancel,
    messageContext,
  ]);

  const [presentToast] = useIonToast();
  const presentMessageActions = useCallback(
    (event) => {
      if (isScrolling) return;

      // prevent long press when image gallery or other modal is open
      if (event.target.closest(".str-chat__modal")) {
        return;
      }

      const buttons = [];
      const copyAction = {
        text: i18n.t.MessageRenderer.copyText,
        handler: async () =>
          Clipboard.write({
            string: message.text || "",
          }),
      };

      const flagAction = {
        text: i18n.t.MessageRenderer.reportMessage,
        role: "destructive",
        handler: async () => {
          try {
            await streamChat.flagMessage(message.id);
          } catch (error) {
            if (!isErrorWithProps(error, ["code"])) throw error;
            const messageAlreadyFlagged = error.code === 4;
            if (!messageAlreadyFlagged) throw error;
          }
          dontAwait(
            presentToast({
              header: i18n.t.MessageRenderer.reportToast.header,
              message: i18n.t.MessageRenderer.reportToast.message,
              duration: 5000,
            }),
          );
        },
      };

      const editAction = {
        text: i18n.t.MessageRenderer.editMessage,
        handler: async () => messageContext.handleEdit(unusedMouseEvent),
      };

      const deleteAction = {
        text: i18n.t.MessageRenderer.deleteMessage,
        role: "destructive",
        handler: async () => {
          await dismiss();
          presentDeleteMessageConfirmation();
        },
      };

      const messageActions = messageContext.getMessageActions();
      if (messageActions.includes("flag")) buttons.push(flagAction);
      if (messageActions.includes("delete")) buttons.push(deleteAction);
      if (messageActions.includes("edit")) buttons.push(editAction);
      if (message.text) buttons.push(copyAction);

      if (buttons.length) {
        dontAwait(
          present({
            animated: true,
            keyboardClose: true,
            buttons: [
              ...buttons,
              {
                text: i18n.t.common.cancel,
                role: "cancel",
              },
            ],
          }),
        );
      }
    },
    [
      isScrolling,
      i18n.t.MessageRenderer.copyText,
      i18n.t.MessageRenderer.reportMessage,
      i18n.t.MessageRenderer.editMessage,
      i18n.t.MessageRenderer.deleteMessage,
      i18n.t.MessageRenderer.reportToast.header,
      i18n.t.MessageRenderer.reportToast.message,
      i18n.t.common.cancel,
      messageContext,
      message.text,
      message.id,
      presentToast,
      streamChat,
      dismiss,
      presentDeleteMessageConfirmation,
      present,
    ],
  );

  const bindLongPressEvents = useLongPress(presentMessageActions, {
    threshold: 500,
    captureEvent: true,
    detect: LongPressDetectEvents.BOTH,
  });

  const { track } = MixpanelClientContainer.useContainer();

  const onClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (linkTrackingContext === disableLinkTracking) return;

      if (event.target instanceof HTMLAnchorElement && event.target.href) {
        const { href } = event.target;
        const linkText = event.target.textContent;

        const { url, query, domain } = getUrl(href);

        dontAwait(
          track({
            eventName: "Message Link Clicked",

            "link text": linkText,
            "link url": url,
            "link query": query,
            "link domain": domain,

            "team id": teamId,

            "message id": message.id,
            "message sender id": message.user?.id || "",
            "message sender name": message.user?.name || "",
            "message text": message.text || "",
            "message created at":
              (message.created_at instanceof Date
                ? message.created_at.toISOString()
                : message.created_at) || "",
            "message purpose": message.purpose || null,

            "offer id": message.offer?.["offer id"] || null,
            "offer partner id": message.offer?.["offer partner id"] || null,
            "channel backing storage": ChannelBackingStorage.Stream,

            ...linkTrackingContext,
          }),
        );
      }
    },
    [linkTrackingContext, message, teamId, track],
  );

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      {...(messageContext.actionsEnabled ? { ...bindLongPressEvents } : null)}
      onClick={onClick}
    >
      <MessageSimple
        {...props}
        message={message}
        onUserClick={useCallback(() => {
          const { user } = message;
          if (!user) return;
          const userProfile = findUserProfileById(user.id);
          if (!userProfile) return;
          if (user.id === streamSystemUser) return;
          if (user.name === deletedUserName) return;

          history.push(
            viewUserProfilePageUrl({ teamId, userProfileId: user.id }),
          );
        }, [findUserProfileById, history, message, teamId])}
      />
    </div>
  );
};

export default MessageRenderer;
