import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  isPlatform,
  useIonToast,
} from "@ionic/react";
import { closeOutline, qrCodeOutline, shareOutline } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import InviteUrlsContainer from "src/state/InviteUrlsContainer";
import { MixpanelClient } from "src/state/mixpanel/MixpanelContainer";
import isShareCanceledError from "../utilities/capacitor/isShareCanceledError";
import commonCss from "./common.module.css";
import IonItemIcon from "./IonItemIcon";
import ShowQrCodeModal from "./ShowQrCodeModal";

export type ShareAppModalProps = React.ComponentProps<typeof IonModal> & {
  track: MixpanelClient["track"];
  dismissModal: () => void;
  teamId: string;
  screenName: string;
};

export type ModalStatus = "Modal Completed" | "Modal Aborted" | "Modal Opened";
export type ShareAppModalNames = "ShareAppModal" | "ShowQrModal";

const ShareAppModal: React.FC<ShareAppModalProps> = ({
  isOpen,
  track,
  dismissModal,
  teamId,
  screenName,
}) => {
  const i18n = I18nContainer.useContainer();
  const [isOpenQrModal, setIsOpenQrModal] = useState<boolean>(false);
  const [presentToast] = useIonToast();
  const [modalCompleted, setModalCompleted] = useState<boolean>(false);
  const {
    n2c: { copyLinkUrl, osShareUrl, qrCodeUrl },
  } = InviteUrlsContainer.useContainer();

  const onShareAppModalDismiss = useCallback(() => {
    dismissModal();
    track({
      eventName: modalCompleted ? "Modal Completed" : "Modal Aborted",
      "modal name": "ShareAppModal",
      "screen name": screenName,
      "team id": teamId,
    });
    setModalCompleted(false);
  }, [dismissModal, modalCompleted, screenName, teamId, track]);

  const onShowQrCodeModalClick = useCallback(() => {
    setModalCompleted(true);
    setIsOpenQrModal(true);
    dontAwait(
      track({
        eventName: "Modal Opened",
        "modal name": "ShowQrCodeModal",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
  }, [screenName, teamId, track]);

  const onShowQrCodeModalDismiss = useCallback(() => {
    setIsOpenQrModal(false);
  }, []);

  const onShareLinkClick = useCallback(async () => {
    setModalCompleted(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "OS Share App",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
    try {
      await Share.share({
        title: i18n.t.common.connectedLivingMessenger,
        dialogTitle: i18n.t.ShareAppModal.nativeShareModal.shareDialogTitle,
        text: i18n.t.ShareAppModal.nativeShareModal.shareText,
        url: osShareUrl,
      });
    } catch (value) {
      if (isShareCanceledError(value)) return;
      throw value;
    }
  }, [
    i18n.t.ShareAppModal.nativeShareModal,
    i18n.t.common.connectedLivingMessenger,
    screenName,
    teamId,
    track,
    osShareUrl,
  ]);

  const onCopyLinkClick = useCallback(async () => {
    await Clipboard.write({ url: copyLinkUrl });
    setModalCompleted(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "OS Share App",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
    dontAwait(
      presentToast({
        message: i18n.t.common.copiedToClipboard,
        color: "medium",
        duration: 5000,
      }),
    );
  }, [
    copyLinkUrl,
    i18n.t.common.copiedToClipboard,
    presentToast,
    screenName,
    teamId,
    track,
  ]);

  return (
    <>
      <IonModal
        isOpen={isOpen}
        canDismiss
        handle={false}
        onDidDismiss={onShareAppModalDismiss}
        breakpoints={[0, 0.45, 0.6, 1]}
        initialBreakpoint={0.45}
        className={commonCss.modalCard}
      >
        <IonItem lines="none" class="ion-margin-top">
          <h1>
            <strong>{i18n.t.ShareAppModal.title}</strong>
          </h1>
          <IonIcon
            className={commonCss.itemIconAvatar}
            color="medium"
            size="large"
            slot="end"
            icon={closeOutline}
            onClick={dismissModal}
          />
        </IonItem>
        <IonItem lines="full">
          <IonText color="medium">
            <p>{i18n.t.ShareAppModal.body}</p>
          </IonText>
        </IonItem>
        <IonItem button disabled={!qrCodeUrl} onClick={onShowQrCodeModalClick}>
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={qrCodeOutline}
          />
          {i18n.t.ShareAppModal.displayQrCode}
        </IonItem>
        <IonItem
          button
          detail={false}
          disabled={!osShareUrl || !copyLinkUrl}
          onClick={isPlatform("capacitor") ? onShareLinkClick : onCopyLinkClick}
          lines="none"
        >
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={shareOutline}
          />
          {isPlatform("capacitor")
            ? i18n.t.ShareButton.share
            : i18n.t.ShareButton.copyLink}
        </IonItem>
      </IonModal>
      <ShowQrCodeModal
        isOpen={isOpenQrModal}
        qrCodeUrl={qrCodeUrl || ""}
        dismissModal={onShowQrCodeModalDismiss}
        modalTitle={i18n.t.ShowQrCodeModal.appShare.title}
        modalBodyText={i18n.t.ShowQrCodeModal.appShare.body}
      />
    </>
  );
};

export default ShareAppModal;
