export default function firestoreConsoleDocumentUrl({
  projectId,
  documentPath,
}: {
  projectId: string;
  documentPath: string;
}): string {
  const escapedPath = encodeURIComponent(documentPath.replace(/\//g, "~2F"));
  return `https://console.firebase.google.com/u/0/project/${projectId}/firestore/data/${escapedPath}`;
}
