import makeEnvVarAccessors from "@connectedliving/common/lib/utilities/makeEnvironmentVariableAccessors";
import { MarketingSiteConfig } from "@connectedliving/common/lib/utilities/marketingSiteUrlBuilders";

const { requireEnvVar, getEnvVar, getEnvVarBoolean } = makeEnvVarAccessors({
  environmentNameVariable: "REACT_APP_CONNECTED_LIVING_ENVIRONMENT",
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function environment() {
  return {
    maxSupportedApiVersion: () => "1.0.0",
    minSupportedLocalNativeAppVersions: (): Record<string, string> => ({
      "native ios": "1.0.0",
      "native android": "1.0.0",
    }),
    requireEnvVar,
    getEnvVar,
    marketingSiteConfig: (): MarketingSiteConfig => ({
      baseUrl:
        getEnvVar("REACT_APP_MARKETING_SITE_URL", {
          requiredIn: ["production"],
        }) || "",
      supportEmail:
        getEnvVar("REACT_APP_SUPPORT_EMAIL", { requiredIn: ["production"] }) ||
        "",
    }),
    deployTarget: () =>
      getEnvVar("REACT_APP_DEPLOY_TARGET", {
        requiredIn: ["production"],
      }) || "(unknown)",
    releaseVersion: () =>
      getEnvVar("REACT_APP_RELEASE_VERSION", {
        requiredIn: ["production"],
      }) || "(unknown)",
    supportUserId: () => requireEnvVar("REACT_APP_SUPPORT_USER_ID"),
    waitingListFeatureFlag: () =>
      getEnvVarBoolean("REACT_APP_WAITING_LIST_FEATURE_ENABLED"),
    appStoreUrl: () =>
      getEnvVar("REACT_APP_APPLE_APP_STORE_URL", {
        requiredIn: ["production"],
      }),
    playStoreUrl: () =>
      getEnvVar("REACT_APP_GOOGLE_PLAY_STORE_URL", {
        requiredIn: ["production"],
      }),

    firebaseApiKey: () => requireEnvVar("REACT_APP_FIREBASE_API_KEY"),
    firebaseAuthDomain: () => requireEnvVar("REACT_APP_FIREBASE_AUTH_DOMAIN"),
    firebaseProjectId: () => requireEnvVar("REACT_APP_FIREBASE_PROJECT_ID"),
    firebaseStorageBucket: () =>
      requireEnvVar("REACT_APP_FIREBASE_STORAGE_BUCKET"),
    firebaseAppId: () => requireEnvVar("REACT_APP_FIREBASE_APP_ID"),
  };
}

export type WebAppEnvironment = ReturnType<typeof environment>;
