import { IonAvatar, IonIcon } from "@ionic/react";
import React from "react";
import css from "./IonItemIcon.module.css";

export type IonItemIconProps = {
  icon: string;
  slot?: string;
  color?: string;
  className?: string;
};

const IonItemIcon: React.FC<IonItemIconProps> = ({
  icon,
  slot,
  color = "primary",
  className,
}) => (
  <IonAvatar slot={slot} className={`${css.avatar} ${className}`}>
    <IonIcon icon={icon} color={color} className={css.icon} />
  </IonAvatar>
);

export default IonItemIcon;
