import React from "react";
import {
  DefaultTriggerProvider,
  useMessageInputContext,
} from "stream-chat-react";
import { CLUserItem } from "./CLUserItem";

const ReplaceStreamUserItemComponent: React.FC = ({ children }) => {
  const currentContextValue = useMessageInputContext();

  const { autocompleteTriggers } = currentContextValue;

  if (autocompleteTriggers?.["@"]) {
    autocompleteTriggers["@"].component = CLUserItem;
  }

  if (autocompleteTriggers) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delete (autocompleteTriggers as any)[":"];
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

const CLTriggerProvider: React.FC = ({ children }) => (
  <DefaultTriggerProvider>
    <ReplaceStreamUserItemComponent>{children}</ReplaceStreamUserItemComponent>
  </DefaultTriggerProvider>
);

export default CLTriggerProvider;
