import { ErrorState, isErrorState } from "../LoadingState";

export type InitialLoad<DataType> = {
  loadingState: "initialLoad";
  data: DataType | undefined;
};
export type Updating<DataType> = {
  loadingState: "updating";
  data: DataType;
};
export type Ready<DataType> = {
  loadingState: "ready";
  data: DataType;
};
export type NetworkUnavailable<DataType> = {
  loadingState: "networkUnavailable";
  data: DataType | undefined;
};
export type Errored<DataType> = {
  loadingState: ErrorState;
  data: DataType | undefined;
};
export type Loadable<DataType> =
  | InitialLoad<DataType>
  | Updating<DataType>
  | Errored<DataType>
  | Ready<DataType>
  | NetworkUnavailable<DataType>;

export type DataAvailable<DataType> = Updating<DataType> | Ready<DataType>;

export function isDataAvailable<T>(
  remoteData: Loadable<T>,
): remoteData is Updating<T> | Ready<T> {
  return (
    remoteData.loadingState === "ready" ||
    remoteData.loadingState === "updating"
  );
}

export function isInitialLoad<T>(
  remoteData: Loadable<T>,
): remoteData is InitialLoad<T> {
  return remoteData.loadingState === "initialLoad";
}

export function isNetworkUnavailable<T>(
  remoteData: Loadable<T>,
): remoteData is NetworkUnavailable<T> {
  return remoteData.loadingState === "networkUnavailable";
}

export function isErrored<T>(loadable: Loadable<T>): loadable is Errored<T> {
  return isErrorState(loadable.loadingState);
}
