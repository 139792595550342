import firebase from "firebase/compat/app";
import convertFields from "../utilities/firestore/convertFields";
import {
  convertTimestampValueToFirebaseAppTimestamp,
  getFirestoreAppTimestampValue,
} from "./app/firestoreAppTimestamp";
import { getString, getValue, StrictDocumentData } from "./dataHelpers";
import isSupportedCountryCode from "./isSupportedCountryCode";
import { TeamLocation } from "./TeamLocation";

function teamLocationToFirestoreData(
  teamLocation: TeamLocation,
): Record<string, unknown> {
  const result = convertFields(teamLocation, {
    googlePlaceIdRetrievedAt: convertTimestampValueToFirebaseAppTimestamp,
  });
  return result;
}

function firestoreDataToTeamLocation(data: StrictDocumentData): TeamLocation {
  return {
    streetNumber: getString(data.streetNumber),
    streetName: getString(data.streetName),
    city: getString(data.city),
    state: getString(data.state),
    postcode: getString(data.postcode),
    country: getValue(data.country, {
      typeCheck: isSupportedCountryCode,
      fallback: "de",
    }),
    googlePlaceId: getString(data.googlePlaceId),
    googlePlaceIdRetrievedAt: getFirestoreAppTimestampValue(
      data.googlePlaceIdRetrievedAt,
    ),
  };
}

const TeamLocationConverter: firebase.firestore.FirestoreDataConverter<TeamLocation> =
  Object.freeze({
    toFirestore(teamLocation: TeamLocation) {
      return teamLocationToFirestoreData(teamLocation);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TeamLocation {
      return firestoreDataToTeamLocation(snapshot.data(options));
    },
  });
export default TeamLocationConverter;
