import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import { IonImg } from "@ionic/react";
import constructImgixUrl from "./constructImgixUrl";
import FallbackProfileImage from "./FallbackProfileImage";
import css from "./ProfileImage.module.css";

type ProfileImageProps = {
  userProfile?: UserProfile;
};

const ProfileImage: React.FC<ProfileImageProps> = ({ userProfile }) => {
  const { imageUrl } = userProfile || {};
  const formattedName = userProfile ? formatFullName(userProfile) : "";

  if (!imageUrl) {
    return <FallbackProfileImage name={formattedName} />;
  }

  return (
    <IonImg
      className={css.profileImage}
      src={constructImgixUrl(imageUrl, { w: 400, h: 400 })}
    />
  );
};

export default ProfileImage;
