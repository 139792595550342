import { App } from "@capacitor/app";
import { BackButtonEvent } from "@ionic/core/components";
import { useIonRouter } from "@ionic/react";
import { useEffect } from "react";

export default function useAndroidHardwareBackButtonHandler(): void {
  const ionRouter = useIonRouter();

  useEffect(() => {
    function onIonBackButton(ev: BackButtonEvent) {
      ev.detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
          App.exitApp();
        }
      });
    }

    document.addEventListener(
      "ionBackButton",
      onIonBackButton as (ev: unknown) => void,
    );

    return () =>
      document.removeEventListener(
        "ionBackButton",
        onIonBackButton as (ev: unknown) => void,
      );
  }, [ionRouter]);
}
