import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import { FormSelectOption } from "src/common/form/FormSingleSelect";

export default function mapObjectToFormSelectOptions<
  ValueLabelDict extends Record<string | number, string>,
>(
  valueToLabel: ValueLabelDict,
): FormSelectOption<keyof ValueLabelDict & (string | number)>[] {
  return Object.entries(valueToLabel).map(([key, value]) => ({
    value: blindCast<
      keyof ValueLabelDict & (string | number),
      "This must be the same as we passed it in"
    >(key),
    label: value,
  }));
}
