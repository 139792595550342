import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { useIonActionSheet } from "@ionic/react";
import { useCallback } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import mapObjectToFormSelectOptions from "src/utilities/mapObjectToFormSelectOptions";
import sortFormSelectOptionsAlphabetically from "src/utilities/sortFormSelectOptionsAlphabetically";

const useLocaleSwitcherActionSheet = ({
  screenName,
}: {
  screenName: string;
}): (() => Promise<void>) => {
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const [presentActionSheet] = useIonActionSheet();
  const presentLocaleSwitcherActionSheet = useCallback(async () => {
    await presentActionSheet({
      buttons: [
        ...sortFormSelectOptionsAlphabetically(
          mapObjectToFormSelectOptions(i18n.t.common.locales),
        ).map(({ value, label }) => {
          const locale = blindCast<
            keyof typeof i18n.t.common.locales,
            "must be same type as the key it came from"
          >(value);
          return {
            text: label,
            role: i18n.currentLocale === value ? "selected" : undefined,
            handler: () => {
              dontAwait(
                track({
                  eventName: "Locale Selected",
                  "screen name": screenName,
                  "new locale": locale,
                }),
              );
              i18n.setCurrentLocale(locale);
            },
          };
        }),
        {
          text: i18n.t.common.cancel,
          role: "cancel",
        },
      ],
      header: i18n.t.OnboardingActionSheetButtons.localeSwitch,
    });
  }, [i18n, presentActionSheet, screenName, track]);

  return presentLocaleSwitcherActionSheet;
};

export default useLocaleSwitcherActionSheet;
