import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import {
  directMessageChannelPath,
  parseDirectMessageChannelPath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import {
  SupportDirectMessageChannelPageParams,
  supportDirectMessageChannelPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonRouterOutlet, IonSplitPane } from "@ionic/react";
import firebase from "firebase/compat/app";
import { useMemo } from "react";
import { matchPath, Redirect, Route } from "react-router";
import DraftMessagesContainer from "src/state/DraftMessagesContainer";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import LastReadTimestampsContainer from "src/state/team/LastReadTimestampsContainer";
import { isDataAvailable } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import useFirestoreDocument, {
  checkDocumentExistence,
} from "src/utilities/data/useFirestoreDocument";
import { countUnreadMessagesInFirestoreChannels } from "../state/stream/useOtherChannelUnreadCounts";
import NotFoundPage from "./NotFoundPage";
import { DirectMessageChannelWithContext } from "./SupportUserConversations/DirectMessageChannelWithContext";
import SupportUserConversationsPage from "./SupportUserConversations/SupportUserConversationsPage";
import SupportUserConversationsPageDetailsMenu from "./SupportUserConversations/SupportUserConversationsPageDetailsMenu";
import SupportUserConversationsPageMenu from "./SupportUserConversations/SupportUserConversationsPageMenu";
import SupportUserProfilesContainer from "./SupportUserProfilesContainer";

const BackofficePages: React.FC = () => {
  const { pathname } = window.location;
  const routeParams = matchPath<SupportDirectMessageChannelPageParams>(
    pathname,
    supportDirectMessageChannelPageUrl({
      teamId: ":teamId",
      otherUserId: ":otherUserId",
      supportUserId: ":supportUserId",
    }),
  )?.params;

  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  const { userProfileSnapshot } = LoggedInUserProfileContainer.useContainer();

  const { findUserProfileById, prefetchUserProfiles } =
    SupportUserProfilesContainer.useContainer();

  assertPresent(authUser, {
    because: "You need to be authenticated to reach this route",
  });
  assertPresent(userProfileSnapshot, {
    because: "App waits for user to log in",
  });

  const userProfile = checkDocumentExistence(userProfileSnapshot);
  if (!userProfile.exists)
    throw new Error(
      "It should be impossible to not have a userProfile document at this point",
    );

  const latestDirectMessageChannelsCollection = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collectionGroup("DirectMessageChannels")
      .where("userId", "==", authUser.uid)
      .orderBy("latestMessage.createdAt", "desc")
      .withConverter(DirectMessageChannelConverter),
  );

  if (latestDirectMessageChannelsCollection.data) {
    // NOTE: this is not in a useEffect() because it will be deduped internally
    prefetchUserProfiles(
      latestDirectMessageChannelsCollection.data.docs.map(
        (doc) => doc.data().otherUserId,
      ),
    );
  }

  const latestDirectMessageChannelsWithContext: DirectMessageChannelWithContext[] =
    useMemo(() => {
      if (!isDataAvailable(latestDirectMessageChannelsCollection)) return [];

      return latestDirectMessageChannelsCollection.data.docs.map(
        (directMessageChannel) => {
          const { teamId } = parseDirectMessageChannelPath(
            directMessageChannel.ref.path,
          );
          return {
            teamId,
            directMessageChannel,
            otherUserId: directMessageChannel.data().otherUserId,
            otherUserProfile: findUserProfileById(
              directMessageChannel.data().otherUserId,
            ),
          };
        },
      );
    }, [latestDirectMessageChannelsCollection, findUserProfileById]);

  const { getChannelLastReadTimestamp } =
    LastReadTimestampsContainer.useContainer();

  const otherChannelsUnreadCount =
    latestDirectMessageChannelsCollection.data &&
    countUnreadMessagesInFirestoreChannels({
      channels: latestDirectMessageChannelsCollection.data?.docs,
      getChannelLastReadTimestamp,
    });

  const activeDirectMessageChannelRef:
    | firebase.firestore.DocumentReference<DirectMessageChannel>
    | undefined =
    routeParams &&
    firebaseApp
      .firestore()
      .doc(
        directMessageChannelPath({
          teamId: routeParams.teamId,
          userId: authUser.uid,
          otherUserId: routeParams.otherUserId,
        }),
      )
      .withConverter(DirectMessageChannelConverter);

  const activeDirectMessageChannelDoc = useFirestoreDocument(
    activeDirectMessageChannelRef,
  );
  const activeDirectMessageChannelWithContext = useMemo(():
    | DirectMessageChannelWithContext
    | undefined => {
    if (
      activeDirectMessageChannelDoc.data &&
      activeDirectMessageChannelDoc.data.exists &&
      routeParams
    ) {
      return {
        teamId: routeParams.teamId,
        directMessageChannel: activeDirectMessageChannelDoc.data,
        otherUserId: routeParams.otherUserId,
        otherUserProfile: findUserProfileById(routeParams.otherUserId),
      };
    }
    return undefined;
  }, [activeDirectMessageChannelDoc.data, findUserProfileById, routeParams]);

  if (!userProfile.data().isEmployee) {
    return <Redirect to="/" />;
  }

  return (
    <DraftMessagesContainer.Provider>
      <IonSplitPane contentId="conversations">
        <SupportUserConversationsPageMenu
          {...{ activeDirectMessageChannelWithContext }}
          directMessageChannels={latestDirectMessageChannelsWithContext}
          otherChannelsUnreadCount={otherChannelsUnreadCount || 0}
          loadingState={latestDirectMessageChannelsCollection.loadingState}
          findUserProfileById={findUserProfileById}
        />

        <SupportUserConversationsPageDetailsMenu
          {...{ authUser, activeDirectMessageChannelWithContext }}
          loadingState="ready"
        />

        <IonRouterOutlet id="conversations">
          <Route
            exact
            path={supportDirectMessageChannelPageUrl({
              teamId: ":teamId",
              otherUserId: ":otherUserId",
              supportUserId: ":supportUserId",
            })}
          >
            <SupportUserConversationsPage
              findUserProfileById={findUserProfileById}
              channelLoadingState={activeDirectMessageChannelDoc.loadingState}
              {...{ activeDirectMessageChannelWithContext }}
              otherChannelsUnreadCount={otherChannelsUnreadCount || 0}
              userProfile={userProfile}
              authUser={authUser}
            />
          </Route>
          <Route component={NotFoundPage} />
        </IonRouterOutlet>
      </IonSplitPane>
    </DraftMessagesContainer.Provider>
  );
};

const BackofficePage: React.FC = () => (
  <LastReadTimestampsContainer.Provider>
    <SupportUserProfilesContainer.Provider>
      <BackofficePages />
    </SupportUserProfilesContainer.Provider>
  </LastReadTimestampsContainer.Provider>
);

export default BackofficePage;
