import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import {
  channelsListPageUrl,
  teamChannelMessagesPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import * as allIcons from "ionicons/icons";
import { sortBy } from "lodash";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import ChannelAvatarContent from "src/common/ChannelAvatarContent";
import useScreenTracking from "src/firebase/useScreenTracking";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import joinChannel from "src/state/firebase/joinChannel";
import I18nContainer from "src/state/i18n/I18nContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { dataAvailable } from "src/utilities/LoadingState";
import LastReadTimestampsContainer from "../../state/team/LastReadTimestampsContainer";
import { ChannelPreviewSkeleton } from "../list/ChannelsListSkeleton";
import css from "./DiscoverChannelsPage.module.css";

export type ChannelPreviewItemProps = {
  teamChannel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>;
  onJoinChannelClick: (
    channel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>,
  ) => Promise<unknown>;
};

const ChannelPreviewItem: React.FC<ChannelPreviewItemProps> = ({
  teamChannel,
  onJoinChannelClick,
}) => {
  const [operationInProgress, setOperationInProgress] = useState(false);
  const i18n = I18nContainer.useContainer();

  const onJoinButtonClick = useCallback(async () => {
    setOperationInProgress(true);
    await onJoinChannelClick(teamChannel);
    setOperationInProgress(false);
  }, [onJoinChannelClick, teamChannel]);

  return (
    <IonItem data-cy="ChannelPreview-item">
      <IonAvatar slot="start" className={css.iconAvatar}>
        <ChannelAvatarContent
          content={{
            type: "icon",
            icon: teamChannel.data().channelIcon,
            iconColor: teamChannel.data().channelIconColor,
          }}
        />
      </IonAvatar>

      <IonLabel>{teamChannel.data().name}</IonLabel>

      {operationInProgress ? (
        <IonSpinner />
      ) : (
        <IonButton
          slot="end"
          color="primary"
          onClick={onJoinButtonClick}
          data-cy="DiscoverChannelsPage-joinButton"
        >
          {i18n.t.common.join}
        </IonButton>
      )}
    </IonItem>
  );
};

export type DiscoverChannelsPageProps = {
  teamId: string;
};

const DiscoverChannelsPage: React.FC<DiscoverChannelsPageProps> = ({
  teamId,
}) => {
  const { authUser } = TeamContextContainer.useContainer();
  const { teamChannels, isUserMember, loadingState } =
    FirestoreChannelsContainer.useContainer();
  const i18n = I18nContainer.useContainer();

  useScreenTracking("DiscoverChannelsPage", teamId);

  const channels = sortBy(
    teamChannels.filter(({ id }) => !isUserMember(id)),
    (channel) => channel.data().name.toLocaleLowerCase(),
  );
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const history = useHistory();
  const { setChannelLastReadTimestamp } =
    LastReadTimestampsContainer.useContainer();

  const onJoinChannelClick = useCallback(
    async (
      teamChannel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>,
    ) => {
      await Promise.all([
        joinChannel(firebaseApp, teamId, teamChannel.id, authUser.uid),
        setChannelLastReadTimestamp(teamChannel.ref.path, new Date(Date.now())),
      ]);

      history.replace(
        teamChannelMessagesPageUrl({ teamId, teamChannelId: teamChannel.id }),
      );
    },
    [authUser.uid, firebaseApp, history, setChannelLastReadTimestamp, teamId],
  );

  return (
    <IonPage id="main-content" data-cy="DiscoverChannelsPage">
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              routerLink={channelsListPageUrl({
                teamId,
              })}
              routerDirection="back"
            >
              <IonIcon icon={allIcons.chevronBack} />
            </IonButton>
          </IonButtons>

          <IonTitle>{i18n.t.DiscoveryChannelsPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-bottom">
        {!dataAvailable(loadingState) ? (
          <IonList>
            <ChannelPreviewSkeleton />
            <ChannelPreviewSkeleton />
            <ChannelPreviewSkeleton />
            <ChannelPreviewSkeleton />
          </IonList>
        ) : (
          <IonList>
            {!channels.length ? (
              <IonItem lines="none" className="ion-padding-top">
                {i18n.t.DiscoveryChannelsPage.memberOfAll}
              </IonItem>
            ) : (
              channels.map((teamChannel) => (
                <ChannelPreviewItem
                  key={teamChannel.id}
                  {...{ teamChannel, onJoinChannelClick }}
                />
              ))
            )}
          </IonList>
        )}
      </IonContent>
    </IonPage>
  );
};

export default DiscoverChannelsPage;
