import { deletedUserName } from "@connectedliving/common/lib/cloudFunctions/DeleteUser";
import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import React, { useEffect, useState } from "react";
import constructImgixUrl from "src/utilities/constructImgixUrl";
import FallbackProfileImage from "src/utilities/FallbackProfileImage";
import type { UserResponse } from "stream-chat";
import { getWholeChar } from "stream-chat-react";
import css from "./CLAvatar.module.css";

const noop = () => undefined;

export type AvatarProps<StreamChatGenerics extends InternalStreamChatGenerics> =
  {
    /** Image URL or default is an image of the first initial of the name if there is one  */
    image?: string | null;
    /** Name of the image, used for title tag fallback */
    name?: string;
    /** click event handler */
    onClick?: (event: React.BaseSyntheticEvent) => void;
    /** mouseOver event handler */
    onMouseOver?: (event: React.BaseSyntheticEvent) => void;
    /** Shape of the avatar - circle, rounded or square
     * @default circle
     */
    shape?: "circle" | "rounded" | "square";
    /** Size in pixels
     * @default 32px
     */
    size?: number;
    /** The entire user object for the chat user displayed in the component */
    // eslint-disable-next-line react/no-unused-prop-types
    user?: UserResponse<StreamChatGenerics>;
  };

/**
 * A round avatar image with fallback to username's first letter
 */
export const CLAvatar = <StreamChatGenerics extends InternalStreamChatGenerics>(
  props: AvatarProps<StreamChatGenerics>,
): React.ReactElement | null => {
  const {
    name,
    onClick = noop,
    onMouseOver = noop,
    shape = "circle",
    size = 32,
  } = props;

  const { image } = props;

  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setError(false);
    setLoaded(false);
  }, [image]);

  const nameStr = name?.toString() || "";
  const initials = getWholeChar(nameStr, 0);
  const isDeleted = name === deletedUserName;

  const clickableClass = onClick !== noop && !isDeleted ? css.clickable : "";
  const cssVariables = { "--avatar-size": `${size}px` } as React.CSSProperties;

  return (
    <div
      role="button"
      className={`str-chat__avatar str-chat__avatar--${shape} ${clickableClass}`}
      data-testid="avatar"
      onClick={onClick}
      onMouseOver={onMouseOver}
      onFocus={onMouseOver}
      onKeyDown={onClick}
      tabIndex={0}
      style={{
        ...cssVariables,
        flexBasis: `var(--avatar-size)`,
        fontSize: `calc(var(--avatar-size) / 2)`,
        height: `var(--avatar-size)`,
        lineHeight: `var(--avatar-size)`,
        width: `var(--avatar-size)`,
      }}
      title={name}
    >
      {image && !error ? (
        <img
          alt={initials}
          className={`str-chat__avatar-image${
            loaded ? " str-chat__avatar-image--loaded" : ""
          }`}
          data-testid="avatar-img"
          onError={() => setError(true)}
          onLoad={() => setLoaded(true)}
          src={constructImgixUrl(image, { w: 400, h: 400 })}
          style={{
            flexBasis: `var(--avatar-size)`,
            height: `var(--avatar-size)`,
            objectFit: "cover",
            width: `var(--avatar-size)`,
          }}
        />
      ) : (
        <FallbackProfileImage name={name} />
      )}
    </div>
  );
};
