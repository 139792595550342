import assertExhausted from "@connectedliving/common/lib/utilities/lang/assertExhausted";
import { channelsListPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonPage } from "@ionic/react";
import { Redirect, useParams } from "react-router";
import useScreenTracking from "src/firebase/useScreenTracking";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import FirestoreChannelsContainer from "../../state/team/FirestoreChannelsContainer";
import { isErrorState } from "../../utilities/LoadingState";
import ChannelMessagePageSkeleton from "./ChannelMessagesPageSkeleton";
import FirestoreDirectMessageChannelMessagesPageContent from "./FirestoreDirectMessageChannelMessagesPageContent";
import StreamDirectMessageChannelMessagesPageContent from "./stream/StreamDirectMessageChannelMessagesPageContent";

type DirectMessageChannelMessagesPageProps = {
  teamId: string;
};

type DirectMessageChannelMessagesPageRouteParams = {
  otherUserId: string;
};

const DirectMessageChannelMessagesPage: React.FC<
  DirectMessageChannelMessagesPageProps
> = ({ teamId }) => {
  const { otherUserId } =
    useParams<DirectMessageChannelMessagesPageRouteParams>();
  const { getDirectMessageChannelByUserId, directMessageChannelsLoadingState } =
    FirestoreChannelsContainer.useContainer();
  const directMessageChannel = getDirectMessageChannelByUserId(otherUserId);

  useScreenTracking("DirectMessageChannelMessagesPage", teamId);

  function content() {
    if (directMessageChannelsLoadingState === "initialLoad") {
      return <ChannelMessagePageSkeleton />;
    }

    if (
      isErrorState(directMessageChannelsLoadingState) ||
      !directMessageChannel ||
      !directMessageChannel.exists
    ) {
      return <Redirect to={channelsListPageUrl({ teamId })} />;
    }

    const { backingStorage } = directMessageChannel.data()!;
    if (backingStorage === "stream") {
      return (
        <StreamDirectMessageChannelMessagesPageContent
          {...{ teamId, otherUserId }}
          directMessageChannelId={directMessageChannel.id}
          directMessageChannel={directMessageChannel.data()}
          currentDirectMessageChannelPath={directMessageChannel.ref.path}
        />
      );
    }

    if (backingStorage === "firestore") {
      return (
        <FirestoreDirectMessageChannelMessagesPageContent
          {...{ teamId, otherUserId }}
          directMessageChannel={directMessageChannel}
        />
      );
    }

    throw assertExhausted(backingStorage);
  }

  return (
    <IonPage
      id="main-content"
      data-cy="DirectMessageChannelMessagesPage"
      className="ion-page ion-justify-content-center ion-align-items-center"
    >
      <UserOnboardingModal />
      {content()}
    </IonPage>
  );
};

export default DirectMessageChannelMessagesPage;
