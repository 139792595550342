import {
  getString,
  getStringLiteral,
} from "@connectedliving/common/lib/firestore/dataHelpers";
import {
  StreamChannelType,
  streamChannelTypes,
} from "@connectedliving/common/lib/stream/StreamChannelType";
import { difference, get } from "lodash";

const payloadV1Keys = [
  "channelType",
  "teamId",
  "channelCid",
  "channelId",
  "userId",
  "senderId",
];
type StreamNewMessagePushNotificationPayloadV1 = {
  channelType: string;
  teamId: string;
  channelCid: string;
  channelId: string;

  userId: string;
  senderId: string;
};

const payloadV2Keys = ["channel_type", "cid", "channel_id"];
type StreamNewMessagePushNotificationPayloadV2 = {
  stream: {
    channel_type: string;
    cid: string;
    channel_id: string;
  };
};

function isV1Payload(
  payload: unknown,
): payload is StreamNewMessagePushNotificationPayloadV1 {
  if (typeof payload !== "object" || payload === null) return false;
  return difference(payloadV1Keys, Object.keys(payload)).length === 0;
}

function isV2Payload(
  payload: unknown,
): payload is StreamNewMessagePushNotificationPayloadV2 {
  if (typeof payload !== "object" || payload === null) return false;
  if (!("stream" in payload)) return false;
  const streamPayload = get(payload, "stream");
  return difference(payloadV2Keys, Object.keys(streamPayload)).length === 0;
}

export default function parseNewMessagePushNotificationPayload(
  payload: unknown,
): {
  channelId: string | undefined;
  channelType: StreamChannelType | undefined;
} {
  if (isV1Payload(payload)) {
    return {
      channelId: getString(payload.channelId),
      channelType: getStringLiteral<StreamChannelType>(payload.channelType, {
        permitted: streamChannelTypes,
        fallback: StreamChannelType.Team,
      }),
    };
  }

  if (isV2Payload(payload)) {
    return {
      channelId: getString(payload.stream.channel_id),
      channelType: getStringLiteral<StreamChannelType>(
        payload.stream.channel_type,
        {
          permitted: streamChannelTypes,
          fallback: StreamChannelType.Team,
        },
      ),
    };
  }

  return {
    channelId: undefined,
    channelType: undefined,
  };
}
