import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { ArrayElement } from "@connectedliving/common/lib/utilities/ArrayElement";
import { isHttpsError } from "@connectedliving/common/lib/utilities/firestore/isHttpsError";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  channelsListPageUrl,
  checkMyAddressPageUrl,
  getStartedPageUrl,
  teamCreatedSuccessPageUrl,
  teamCreatePageUrl,
  TeamCreatePageUrlParams,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonSpinner,
  IonText,
  isPlatform,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import { close, helpCircle } from "ionicons/icons";
import { noop } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import AuthPage from "src/auth/AuthPage";
import ErrorCard from "src/common/ErrorCard";
import FormSegmentSelect from "src/common/form/FormSegmentSelect";
import FormTextInput from "src/common/form/FormTextInput";
import MoreInfoModal from "src/common/MoreInfoModal";
import SearchAddressModal from "src/common/SearchAddressModal";
import useEmailActionSheet from "src/common/useEmailActionSheet";
import cloudFunctions from "src/firebase/cloudFunctions";
import ionicCss from "src/ionicCss.module.css";
import OnboardingPageLayout from "src/share/OnboardingPageLayout";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import { useFieldInputs } from "src/state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "src/state/formState/useFormState";
import GoogleMapsApiContainer from "src/state/GoogleMapsApiContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import formatTeamLocation from "src/utilities/formatTeamLocation";
import validateGeocodeResult from "src/utilities/googlePlaces/validateGeocodeResult";
import useIonicParamsForRoute from "src/utilities/ionic/useIonicParamsForRoute";
import { dataAvailable, isErrorState } from "src/utilities/LoadingState";
import validate from "src/utilities/validate/validate";
import { Navigation, Pagination } from "swiper";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { getGeocode } from "use-places-autocomplete";
import commonCss from "../common/common.module.css";
import css from "./TeamCreatePage.module.css";

export type TeamCreatePageFormValues = {
  numberOfApartments: number | null;
  teamLocation: TeamLocation | null;
};

type TeamCreatePageLocationState = {
  teamLocation: TeamLocation | null;
};

const numberOfApartmentsOptions = Object.freeze([
  { label: "1-10", value: 10, key: "1-10" },
  { label: "11-20", value: 20, key: "11-20" },
  { label: "21-40", value: 40, key: "21-40" },
  { label: "41-100", value: 100, key: "41-100" },
]);

const slides = [
  { name: "registerYourAddress", img: "location" },
  { name: "receiveInvitations", img: "invitation" },
  { name: "inviteYourNeighbors", img: "mailbox" },
] as const;

export type TeamCreationSlideProps = {
  index: number;
  slide: ArrayElement<typeof slides>;
};

const TeamCreationSlide: React.FC<TeamCreationSlideProps> = ({
  index,
  slide,
}) => {
  const swiper = useSwiper();
  const i18n = I18nContainer.useContainer();

  return (
    <IonCard>
      <IonCardHeader className={css.slideHeader}>
        <IonButton
          fill="clear"
          color="medium"
          size="small"
          data-cy={`TeamCreatePage-skipButton-${index}`}
          onClick={() => swiper.slideTo(3, 0)}
        >
          {i18n.t.TeamCreatePage.skip}
        </IonButton>
        <div className={css.illustrationContainer}>
          <img
            src={`/assets/illustrations/${slide.img}.png`}
            alt={i18n.t.TeamCreatePage.slides[slide.name].imgAlt}
          />
        </div>
      </IonCardHeader>
      <IonCardContent>
        <p>
          <strong>{i18n.t.TeamCreatePage.step(index + 1)}</strong>
        </p>
        <div className={`${css.cardTextContainer} ion-margin-vertical`}>
          <IonText color="aubergine-text">
            <h1>
              <strong>{i18n.t.TeamCreatePage.slides[slide.name].title}</strong>
            </h1>
          </IonText>
          <IonText color="dark">
            {i18n.t.TeamCreatePage.slides[slide.name].body}
          </IonText>
        </div>
        <div className="paginator" />
      </IonCardContent>
    </IonCard>
  );
};

const TeamCreatePage: React.FC = () => {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  const { userProfile } = LoggedInUserProfileContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const backUrl = !process.env.REACT_APP_RESTRICTED_WEB_BUILD
    ? getStartedPageUrl()
    : checkMyAddressPageUrl();
  const [presentToast, dismissToast] = useIonToast();
  const i18n = I18nContainer.useContainer();
  const [showModal, setShowModal] = useState<boolean>(false);
  const history = useHistory();

  const { loadGoogleMapsApi, loadingState: googleMapsApiLoadingState } =
    GoogleMapsApiContainer.useContainer();

  useEffect(() => {
    dontAwait(loadGoogleMapsApi());
  }, [loadGoogleMapsApi]);

  const screenName = "TeamCreatePage";
  const supportEmail = requireEnvVar("REACT_APP_SUPPORT_EMAIL");
  const { placeId: googlePlaceId } =
    useIonicParamsForRoute<TeamCreatePageUrlParams>(
      teamCreatePageUrl({ placeId: ":placeId" }),
    );
  const { state: locationState } = useLocation<TeamCreatePageLocationState>();

  const [teamLocation, setTeamLocation] = useState<TeamLocation | null>(null);

  useIonViewWillEnter(() => {
    if (
      locationState &&
      googlePlaceId === locationState.teamLocation?.googlePlaceId
    ) {
      setTeamLocation(locationState.teamLocation);
    } else {
      setTeamLocation(null);
    }
  }, [googlePlaceId, locationState]);

  const formState = useFormState<TeamCreatePageFormValues>({
    initial: {
      numberOfApartments: null,
      teamLocation,
    },
    validate: {
      fields: {
        numberOfApartments: (fieldState) => {
          const { value } = fieldState;
          if (value === null)
            return [{ message: i18n.t.TeamCreatePage.isRequired }];

          return validate.all([
            validate.number.isInteger(i18n.t.common.validations),
            validate.number.over({ min: 0 }, i18n.t.common.validations),
          ])({ value });
        },
        teamLocation: (fieldState) => {
          const { value } = fieldState;
          if (value === null)
            return [{ message: i18n.t.TeamCreatePage.isRequired }];
          return [];
        },
      },
    },
  });

  const fields = useFieldInputs(formState, useFieldStates(formState));

  useEffect(() => {
    if (
      !teamLocation &&
      googlePlaceId &&
      googleMapsApiLoadingState &&
      dataAvailable(googleMapsApiLoadingState)
    ) {
      getGeocode({ placeId: googlePlaceId })
        .then((geocoderResults) => {
          if (geocoderResults.length === 0) {
            dontAwait(
              presentToast({
                message:
                  i18n.t.CheckMyAddressResultsPage.errors.noGeocodeResults,
                duration: 5000,
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                  },
                ],
                color: "danger",
              }),
            );
            history.replace(checkMyAddressPageUrl());
          }
          if (geocoderResults.length > 1) {
            Sentry.captureException(
              "There was more than one geocodeResult for a placeId geocoding request",
            );
          }

          const geocoderResult = assertPresent.andReturn(geocoderResults[0], {
            because:
              "We handled the case that there is no result, hence there must be at least one",
          });
          const validatedGeocodeResult = validateGeocodeResult(geocoderResult);

          if (validatedGeocodeResult.validTeamLocation) {
            setTeamLocation(validatedGeocodeResult.validTeamLocation);
            fields.teamLocation.onChange(
              validatedGeocodeResult.validTeamLocation,
            );
          } else {
            dontAwait(
              presentToast({
                message:
                  i18n.t.CheckMyAddressResultsPage.errors.noGeocodeResults,
                duration: 5000,
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                  },
                ],
                color: "danger",
              }),
            );
            history.replace(checkMyAddressPageUrl());
          }
        })
        .catch((error) => {
          dontAwait(
            presentToast({
              message: i18n.t.CheckMyAddressResultsPage.errors.noGeocodeResults,
              duration: 5000,
              buttons: [
                {
                  icon: close,
                  role: "cancel",
                },
              ],
              color: "danger",
            }),
          );
          history.replace(checkMyAddressPageUrl());
          Sentry.captureException(
            `Something went wrong trying to fetch geoCode:${error}`,
          );
        });
    }
  }, [
    fields.teamLocation,
    googleMapsApiLoadingState,
    googlePlaceId,
    history,
    i18n.t.CheckMyAddressResultsPage.errors.noGeocodeResults,
    locationState,
    presentToast,
    teamLocation,
  ]);

  useEffect(() => {
    dontAwait(
      track({
        eventName: "Screen Viewed",
        "screen name": screenName,
        "team id": undefined,
      }),
    );
  }, [track]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const emailActionSheet = useEmailActionSheet(supportEmail);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const navigation = !process.env.REACT_APP_RESTRICTED_WEB_BUILD
    ? {
        prevEl: ".swiper-button-prev",
        nextEl: ".swiper-button-next",
      }
    : false;

  const { form } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        if (isSubmitting || !formValues.teamLocation) return;
        setIsSubmitting(true);
        try {
          const { teamId } = await cloudFunctions(firebaseApp).createTeam({
            teamLocation: formValues.teamLocation,
            team: {
              name: `${formValues.teamLocation.streetName} ${formValues.teamLocation.streetNumber}`,
              numberOfApartments: assertPresent.andReturn(
                formValues.numberOfApartments,
                { because: "validations require it to be present" },
              ),
            },
          });
          const redirectUrl = isPlatform("capacitor")
            ? channelsListPageUrl({ teamId })
            : teamCreatedSuccessPageUrl({ teamId });
          history.replace(redirectUrl);
        } catch (e) {
          if (isHttpsError(e)) {
            let { message } = e;
            if (/internal/.test(e.code)) {
              message = i18n.t.TeamCreatePage.errorMessage;
            }
            formState.setFormErrors([{ message }]);
            dontAwait(
              presentToast({
                message,
                duration: 5000,
                color: "danger",
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                    handler: () => dismissToast(),
                  },
                ],
              }),
            );
            setIsSubmitting(false);
            return;
          }
          throw e;
        }
      },
      [
        dismissToast,
        firebaseApp,
        formState,
        history,
        isSubmitting,
        presentToast,
        i18n.t.TeamCreatePage,
      ],
    ),
  });

  const onCarouselChange = useCallback(
    (index: number, name: string | undefined) => {
      dontAwait(
        track({
          eventName: "Carousel Slide Viewed",
          "slide index": index,
          "slide name": name,
          "screen name": screenName,
        }),
      );
    },
    [track],
  );

  if (!authUser) {
    return <AuthPage />;
  }

  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  const firstTeamId = userProfile.teamIds[0];

  let formSlideContent;

  if (
    googleMapsApiLoadingState === null ||
    googleMapsApiLoadingState === "initialLoad"
  ) {
    formSlideContent = (
      <div className={`${commonCss.flexCenter} ${commonCss.flexColumn}`}>
        <IonSpinner color="primary" />
      </div>
    );
  } else if (
    isErrorState(googleMapsApiLoadingState) ||
    googleMapsApiLoadingState === "networkUnavailable"
  ) {
    formSlideContent = (
      <ErrorCard
        errorMessage={i18n.t.SearchAddressModal.loadingError}
        onRetryClick={async () => {
          await loadGoogleMapsApi();
        }}
      />
    );
  } else {
    formSlideContent = (
      <>
        <SearchAddressModal
          {...i18n.t.TeamCreatePage.searchAddressModal}
          teamId={undefined}
          isOpen={showModal}
          screenName={screenName}
          onDidDismiss={(event) => {
            if (event.detail.data) {
              if (fields.teamLocation.value !== event.detail.data)
                fields.teamLocation.onChange(event.detail.data);
            }
            setShowModal(false);
          }}
        />
        <IonCard>
          <form
            {...form}
            className="ion-text-start ion-padding-top"
            autoComplete="on"
          >
            <FormTextInput
              {...fields.teamLocation}
              value={
                fields.teamLocation.value
                  ? formatTeamLocation(fields.teamLocation.value)
                  : ""
              }
              onChange={() => noop}
              label={i18n.t.TeamCreatePage.cardLabels.address}
              onClick={() => {
                if (locationState) return;
                setShowModal(true);
              }}
              data-cy="TeamCreatePage-searchAddressModal"
              inputMode="none"
              disabled={!!teamLocation}
            />
            <FormSegmentSelect
              {...fields.numberOfApartments}
              label={i18n.t.TeamCreatePage.cardLabels.numberOfApartments}
              helperText={i18n.t.TeamCreatePage.helperTexts.numberOfApartments}
              options={numberOfApartmentsOptions}
              data-cy="TeamCreatePage-numberOfApartments"
            />
            <IonButton
              type="submit"
              fill="solid"
              expand="block"
              className="ion-margin-horizontal ion-margin-top"
              data-cy="TeamCreatePage-submitButton"
              disabled={isSubmitting}
            >
              {isSubmitting ? <IonSpinner /> : i18n.t.TeamCreatePage.buttonName}
            </IonButton>
            <input type="submit" className={ionicCss.submitFormWorkaround} />
            <div className="ion-margin">
              <IonText color="medium">
                <em>{i18n.t.TeamCreatePage.additionalInformation}</em>
              </IonText>
            </div>
            <div className="ion-margin">
              <IonText color="medium">
                <em>
                  {i18n.t.TeamCreatePage.contactSupport(
                    <IonText
                      color="primary"
                      onClick={async () => emailActionSheet()}
                    >
                      {supportEmail}
                    </IonText>,
                  )}
                </em>
              </IonText>
            </div>
          </form>
        </IonCard>
      </>
    );
  }

  if (
    firstTeamId &&
    !isSubmitting &&
    process.env.REACT_APP_RESTRICTED_WEB_BUILD
  ) {
    return <Redirect to={teamCreatedSuccessPageUrl({ teamId: firstTeamId })} />;
  }

  return (
    <OnboardingPageLayout
      title={i18n.t.TeamCreatePage.title}
      iconProps={{ iconName: helpCircle, iconOnClickFn: () => setIsOpen(true) }}
      subtitle={i18n.t.TeamCreatePage.subtitle}
      backButtonUrl={backUrl}
      screenName={screenName}
    >
      <div className={css.navigationLayout}>
        <div
          className={`swiper-button-prev ${css.navArrow} ion-hide-md-down`}
        />
        <Swiper
          modules={[Pagination, Navigation]}
          autoHeight
          navigation={navigation}
          pagination={{
            el: ".paginator",
            type: "bullets",
            bulletActiveClass: css.activeBullet,
          }}
          onActiveIndexChange={({ activeIndex }) => {
            onCarouselChange(activeIndex, slides[activeIndex]?.name);
          }}
          onInit={() => onCarouselChange(0, slides[0].name)}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={slide.name} className={css.slideContainer}>
              <TeamCreationSlide index={index} slide={slide} />
            </SwiperSlide>
          ))}
          <SwiperSlide>{formSlideContent}</SwiperSlide>
        </Swiper>
        <div
          className={`swiper-button-next ${css.navArrow} ion-hide-md-down`}
        />
      </div>
      <MoreInfoModal
        isOpen={isOpen}
        breakpoints={[0, 0.85, 1]}
        initialBreakpoint={0.85}
        title={i18n.t.common.startNewCommunityInfoModal.title}
        body={i18n.t.common.startNewCommunityInfoModal.body(emailActionSheet)}
        closeModal={() => setIsOpen(false)}
      />
    </OnboardingPageLayout>
  );
};
export default TeamCreatePage;
