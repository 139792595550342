import { has } from "lodash";
import { Override } from "../lang/Override";

export default function convertFields<
  InputObject extends Record<string, unknown>,
  ConversionFns extends Partial<{
    [Key in keyof InputObject]: (value: Required<InputObject>[Key]) => unknown;
  }>,
>(
  object: InputObject,
  fns: ConversionFns,
): Override<
  InputObject,
  {
    [Key in keyof ConversionFns]: ConversionFns[Key] extends (
      ...args: any[]
    ) => any
      ? ReturnType<ConversionFns[Key]>
      : never;
  }
> {
  const result: Record<string, unknown> = { ...object };

  for (const [key, fn] of Object.entries(fns)) {
    if (has(object, key)) {
      result[key] = fn(object[key]);
    }
  }

  return result as any;
}
