import firebase from "firebase/compat/app";
import {
  firestoreDataToTeamUserProfile,
  TeamUserProfile,
  teamUserProfileToFirestoreData,
} from "./TeamUserProfile";

const TeamUserProfileConverter: firebase.firestore.FirestoreDataConverter<TeamUserProfile> =
  Object.freeze({
    toFirestore(teamUserProfile: TeamUserProfile) {
      return teamUserProfileToFirestoreData(teamUserProfile);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TeamUserProfile {
      return firestoreDataToTeamUserProfile(snapshot.data(options));
    },
  });
export default TeamUserProfileConverter;
