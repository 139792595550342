import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { formatStreetAddress } from "@connectedliving/common/lib/formatAddress";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonButton,
  IonCardSubtitle,
  IonCardTitle,
  IonText,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import sortTeamLocations from "src/utilities/sortTeamLocations";
import { useSwiper, useSwiperSlide } from "swiper/react";
import SafeAreaSpacer from "../utilities/SafeAreaSpacer";
import css from "./UserOnboardingInitialSlide.module.css";
import UserOnboardingSlideLayout from "./UserOnboardingSlideLayout";
import layoutCss from "./UserOnboardingSlideLayout.module.css";

type UserOnboardingInitialSlideProps = {
  teamLocations: firebase.firestore.QuerySnapshot<TeamLocation>;
};

const UserOnboardingInitialSlide: React.FC<UserOnboardingInitialSlideProps> = ({
  teamLocations,
}) => {
  const { track } = MixpanelClientContainer.useContainer();
  const { team, teamId } = TeamContextContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();

  const sortedTeamLocations = sortTeamLocations(
    teamLocations,
    i18n.currentLocale,
  );

  useEffect(() => {
    if (swiperSlide.isVisible) {
      dontAwait(
        track({
          eventName: "Screen Viewed",
          "team id": teamId,
          "screen name": "UserOnboardingInitialSlide",
        }),
      );
    }
  }, [swiperSlide.isVisible, teamId, track]);

  return (
    <UserOnboardingSlideLayout>
      <div
        className={`${layoutCss.content} ${css.content}`}
        data-cy="UserOnboardingInitialSlide"
      >
        <SafeAreaSpacer position="top" />

        <div className="ion-margin-vertical">
          <IonCardSubtitle color="dark">
            <strong>{i18n.t.UserOnboardingInitialSlide.welcome}</strong>
          </IonCardSubtitle>
          <IonCardTitle color="aubergine-text" className="ion-no-margin">
            <strong>{team.data().name}</strong>
          </IonCardTitle>
          <IonText color="dark">
            {i18n.t.UserOnboardingInitialSlide.mainText}
          </IonText>
        </div>

        <div className={css.illustrationWrapper}>
          <img
            className={css.houseImage}
            src="/assets/illustrations/partyHouse.png"
            alt={i18n.t.UserOnboardingInitialSlide.illustrationAltText}
          />
        </div>

        <ul className={css.list}>
          {sortedTeamLocations.length > 0 &&
            sortedTeamLocations.map((location) => (
              <li key={location.id}>
                <IonText color="medium">
                  {formatStreetAddress(location.data())}
                </IonText>
              </li>
            ))}
        </ul>
      </div>

      <div className={`${layoutCss.footer} ${layoutCss.buttons}`}>
        <IonButton
          color="aubergine-fill"
          expand="block"
          size="large"
          data-cy="UserOnboardingInitialSlide-getStarted"
          onClick={() => swiper.slideNext()}
        >
          {i18n.t.UserOnboardingInitialSlide.getStartedButton}
        </IonButton>
      </div>
    </UserOnboardingSlideLayout>
  );
};

export default UserOnboardingInitialSlide;
