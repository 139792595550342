import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { Team } from "@connectedliving/common/lib/firestore/Team";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import { supportDirectMessageChannelPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonBadge,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonMenu,
  IonSearchbar,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { ChannelPreviewSkeleton } from "src/channels/list/ChannelsListSkeleton";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import { dataAvailable, LoadingState } from "src/utilities/LoadingState";
import FirestoreChannelPreview from "../../channels/list/firestore/FirestoreChannelPreview";
import FirebaseAppContainer from "../../state/firebase/FirebaseAppContainer";
import { DirectMessageChannelWithContext } from "./DirectMessageChannelWithContext";

type SupportUserConversationsPageMenuProps = {
  directMessageChannels: DirectMessageChannelWithContext[];
  loadingState: LoadingState;
  otherChannelsUnreadCount: number;
  activeDirectMessageChannelWithContext:
    | DirectMessageChannelWithContext
    | undefined;
  findUserProfileById: (
    userProfileId: string,
  ) => firebase.firestore.QueryDocumentSnapshot<UserProfile> | undefined;
};

type SupportUserConversationMenuItemProps = {
  directMessageChannelItem: DirectMessageChannelWithContext;
  findUserProfileById: (
    userProfileId: string,
  ) => firebase.firestore.QueryDocumentSnapshot<UserProfile> | undefined;
  activeDirectMessageChannelWithContext:
    | DirectMessageChannelWithContext
    | undefined;
};

const SupportUserConversationMenuItem: React.FC<
  SupportUserConversationMenuItemProps
> = ({
  activeDirectMessageChannelWithContext,
  directMessageChannelItem,
  findUserProfileById,
}) => {
  const { authUser } = FirebaseAppContainer.useContainer();

  const { otherUserProfile } = directMessageChannelItem;
  const otherUserUserProfileData = otherUserProfile && otherUserProfile.data();
  const imageUrl = otherUserUserProfileData?.imageUrl;
  const formattedName = otherUserUserProfileData
    ? formatFullName(otherUserUserProfileData)
    : "";
  const isActiveConversation =
    directMessageChannelItem.directMessageChannel.ref.path ===
    activeDirectMessageChannelWithContext?.directMessageChannel.ref.path;

  return (
    <FirestoreChannelPreview
      channelAvatar={
        imageUrl
          ? { type: "image", imageUrl }
          : {
              type: "initialsAvatar",
              name: formattedName,
            }
      }
      channel={{
        type: FirestoreChannelType.DirectMessage,
        channel: directMessageChannelItem.directMessageChannel,
      }}
      routerLink={supportDirectMessageChannelPageUrl({
        teamId: directMessageChannelItem.teamId,
        supportUserId: authUser?.uid || "",
        otherUserId: directMessageChannelItem.otherUserId,
      })}
      routerDirection="none"
      active={isActiveConversation}
      {...{ findUserProfileById }}
    />
  );
};

type SearchEntry = {
  teamName: string;
  otherUserLastName: string;
  otherUserFirstName: string;
  userName: string;
  searchString: string;
  value: DirectMessageChannelWithContext;
};

function makeSearchEntries(
  directMessageChannels: DirectMessageChannelWithContext[],
  findTeamById: (
    teamId: string,
  ) => firebase.firestore.QueryDocumentSnapshot<Team> | undefined,
): SearchEntry[] {
  return directMessageChannels.map((value) => {
    const teamName = findTeamById(value.teamId)?.data().name || "";
    const otherUserFirstName = value.otherUserProfile?.data().firstName || "";
    const otherUserLastName = value.otherUserProfile?.data().lastName || "";

    return {
      teamName,
      otherUserFirstName,
      otherUserLastName,
      userName: `${otherUserFirstName} ${otherUserLastName}`,
      searchString: `${otherUserFirstName} ${otherUserLastName} ${teamName}`,
      value,
    };
  });
}

const SupportUserConversationsPageMenu: React.FC<
  SupportUserConversationsPageMenuProps
> = ({
  directMessageChannels,
  activeDirectMessageChannelWithContext,
  loadingState,
  otherChannelsUnreadCount,
  findUserProfileById,
}) => {
  const { findTeamById } = LoggedInUserProfileContainer.useContainer();
  const [fuse] = useState(
    () =>
      new Fuse(makeSearchEntries(directMessageChannels, findTeamById), {
        keys: ["searchString"],
        useExtendedSearch: true,
      }),
  );

  const i18n = I18nContainer.useContainer();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fuse.setCollection(makeSearchEntries(directMessageChannels, findTeamById));
  }, [directMessageChannels, findTeamById, fuse]);

  const filteredDirectMessageChannels =
    searchValue === ""
      ? directMessageChannels
      : fuse.search(searchValue).map(({ item }) => item.value);

  const itemContent = (index: number) => {
    const directMessageChannelItem =
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filteredDirectMessageChannels[index]!;

    return (
      <SupportUserConversationMenuItem
        key={directMessageChannelItem.directMessageChannel.ref.path}
        directMessageChannelItem={directMessageChannelItem}
        {...{ findUserProfileById, activeDirectMessageChannelWithContext }}
      />
    );
  };

  return (
    <IonMenu side="start" contentId="conversations" menuId="conversationsList">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{i18n.t.SupportUserConversationsPage.menu.title}</IonTitle>
          {otherChannelsUnreadCount > 0 && (
            <IonBadge slot="end" color="light" className="ion-margin-end">
              {otherChannelsUnreadCount}
            </IonBadge>
          )}
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={searchValue}
            onIonChange={({ detail: { value } }) => setSearchValue(value || "")}
            debounce={0}
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {!dataAvailable(loadingState) ? (
          <>
            <IonItem detail={false}>
              <IonLabel color="primary">
                {i18n.t.SupportUserConversationsPage.menu.loadingMessage}
              </IonLabel>
              <IonSpinner />
            </IonItem>
            <ChannelPreviewSkeleton />
            <ChannelPreviewSkeleton />
            <ChannelPreviewSkeleton />
          </>
        ) : (
          <Virtuoso
            totalCount={filteredDirectMessageChannels.length}
            itemContent={itemContent}
          />
        )}
      </IonContent>
    </IonMenu>
  );
};

export default SupportUserConversationsPageMenu;
