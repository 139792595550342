/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonIcon,
  IonItem,
  IonModal,
  IonText,
  isPlatform,
  useIonToast,
} from "@ionic/react";
import { closeOutline, copyOutline, shareOutline } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import isShareCanceledError from "../utilities/capacitor/isShareCanceledError";
import commonCss from "./common.module.css";
import IonItemIcon from "./IonItemIcon";
import css from "./ShowInviteCodeModal.module.css";

export type ShowInviteCodeModalProps = React.ComponentProps<typeof IonModal> & {
  screenName: string;
  teamId: string;
  inviteCode: string;
  teamName: string;
  dismissModal: () => void;
};

const ShowInviteCodeModal: React.FC<ShowInviteCodeModalProps> = ({
  inviteCode,
  teamName,
  screenName,
  teamId,
  dismissModal,
  isOpen,
}) => {
  const i18n = I18nContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const [presentToast] = useIonToast();
  const [modalCompleted, setModalCompleted] = useState<boolean>(false);

  const onShareCodeClick = useCallback(async () => {
    setModalCompleted(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "OS Share Invite Code",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
    try {
      await Share.share({
        title: i18n.t.common.connectedLivingMessenger,
        text: i18n.t.InviteNeighborModal.nativeShareModal.shareCodeText(
          teamName,
          inviteCode.toUpperCase(),
        ),
        dialogTitle:
          i18n.t.InviteNeighborModal.nativeShareModal.shareDialogTitle,
      });
    } catch (value) {
      if (isShareCanceledError(value)) return;
      throw value;
    }
  }, [
    track,
    screenName,
    teamId,
    i18n.t.common.connectedLivingMessenger,
    i18n.t.InviteNeighborModal.nativeShareModal,
    teamName,
    inviteCode,
  ]);

  const onInviteCodeClick = useCallback(async () => {
    setModalCompleted(true);
    await Clipboard.write({ string: inviteCode.toUpperCase() });
    dontAwait(
      presentToast({
        message: i18n.t.common.copiedToClipboard,
        color: "medium",
        duration: 1500,
      }),
    );
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "Copy Invite Code",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
  }, [
    inviteCode,
    presentToast,
    i18n.t.common.copiedToClipboard,
    track,
    screenName,
    teamId,
  ]);

  const onInviteCodeModalDismiss = useCallback(() => {
    dismissModal();
    dontAwait(
      track({
        eventName: modalCompleted ? "Modal Completed" : "Modal Aborted",
        "modal name": "ShowInviteCodeModal",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
    setModalCompleted(false);
  }, [dismissModal, modalCompleted, screenName, teamId, track]);

  return (
    <IonModal
      isOpen={isOpen}
      canDismiss
      handle={false}
      showBackdrop
      onDidDismiss={onInviteCodeModalDismiss}
      breakpoints={[0, 0.45, 0.6, 1]}
      initialBreakpoint={0.45}
      className={commonCss.modalCard}
    >
      <IonItem lines="none" class="ion-margin-top">
        <h1>
          <strong>{i18n.t.ShowInviteCodeModal.title}</strong>
        </h1>
        <IonIcon
          className={`${commonCss.itemIconAvatar} ${commonCss.closeIcon}`}
          color="medium"
          size="large"
          slot="end"
          icon={closeOutline}
          onClick={onInviteCodeModalDismiss}
        />
      </IonItem>
      <div
        className={`${css.inviteCode} ion-margin-horizontal`}
        onClick={onInviteCodeClick}
      >
        <IonText color="salmon-pink">
          <strong>{inviteCode}</strong>
        </IonText>
        <IonIcon size="large" icon={copyOutline} />
      </div>

      <IonItem lines={isPlatform("capacitor") ? "full" : "none"}>
        <IonText color="medium" className="ion-margin-bottom">
          {i18n.t.ShowInviteCodeModal.body}
        </IonText>
      </IonItem>
      {isPlatform("capacitor") && (
        <IonItem button detail={false} lines="none" onClick={onShareCodeClick}>
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={shareOutline}
          />
          {i18n.t.ShowInviteCodeModal.shareCode}
        </IonItem>
      )}
    </IonModal>
  );
};

export default ShowInviteCodeModal;
