import { TeamUserPreferences } from "@connectedliving/common/lib/firestore/TeamUserPreferences";
import firebase from "firebase/compat/app";

export default function muteChannel({
  teamUserPreferences,
  teamChannelId,
}: {
  teamUserPreferences: firebase.firestore.QueryDocumentSnapshot<TeamUserPreferences>;
  teamChannelId: string;
}): Promise<void> {
  return teamUserPreferences.ref.update({
    mutedTeamChannelIds:
      firebase.firestore.FieldValue.arrayUnion(teamChannelId),
  });
}
