import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import firebase from "firebase/compat/app";
import { I18n } from "src/state/i18n/I18nContainer";

const sortTeamLocations = (
  teamLocations: firebase.firestore.QuerySnapshot<TeamLocation>,
  currentLocale: I18n["currentLocale"],
): firebase.firestore.QueryDocumentSnapshot<TeamLocation>[] =>
  teamLocations.docs.sort(
    (location1, location2) =>
      location1
        .data()
        .country.localeCompare(location2.data().country, currentLocale) ||
      location1
        .data()
        .state.localeCompare(location2.data().state, currentLocale) ||
      location1
        .data()
        .city.localeCompare(location2.data().city, currentLocale) ||
      location1
        .data()
        .streetName.localeCompare(location2.data().streetName, currentLocale) ||
      location1
        .data()
        .streetNumber.localeCompare(
          location2.data().streetNumber,
          currentLocale,
          { numeric: true },
        ),
  );

export default sortTeamLocations;
