import { Mixpanel } from "mixpanel-browser";

function unimplemented() {
  throw Error("Unexpected call of unimplemented function");
}
function noop(...args: unknown[]) {
  const maybeCallback = args[args.length - 1];
  if (typeof maybeCallback === "function") maybeCallback(undefined);
}

export default function makeMixpanelClientNullObject(): Mixpanel {
  const client: Mixpanel = {
    identify: noop,
    track: noop,
    init: () => client,
    add_group: unimplemented,
    alias: noop,
    clear_opt_in_out_tracking: noop,
    disable: noop,
    get_config: unimplemented,
    get_distinct_id: noop,
    get_group: unimplemented as unknown as Mixpanel["get_group"],
    get_property: unimplemented,
    has_opted_in_tracking:
      unimplemented as unknown as Mixpanel["has_opted_in_tracking"],
    has_opted_out_tracking:
      unimplemented as unknown as Mixpanel["has_opted_out_tracking"],
    opt_in_tracking: noop,
    opt_out_tracking: noop,
    push: noop,
    register: noop,
    register_once: noop,
    remove_group: unimplemented,
    reset: noop,
    set_config: noop,
    set_group: unimplemented,
    time_event: noop,
    track_forms: noop,
    track_links: noop,
    track_with_groups: unimplemented,
    unregister: noop,
    people: {
      set: unimplemented,
      set_once: unimplemented,
      unset: unimplemented,
      increment: unimplemented,
      remove: unimplemented,
      append: unimplemented,
      union: unimplemented,
      track_charge: unimplemented,
      clear_charges: unimplemented,
      delete_user: noop,
    },
  };
  return client;
}
