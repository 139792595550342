import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { IonButton, IonText } from "@ionic/react";
import { useEffect } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import { useSwiper, useSwiperSlide } from "swiper/react";
import SafeAreaSpacer from "../utilities/SafeAreaSpacer";
import css from "./UserOnboardingDataPrivacySlide.module.css";
import UserOnboardingSlideLayout from "./UserOnboardingSlideLayout";
import layoutCss from "./UserOnboardingSlideLayout.module.css";

type UserOnboardingDataPrivacySlideParams = EmptyObject;

const UserOnboardingDataPrivacySlide: React.FC<
  UserOnboardingDataPrivacySlideParams
> = () => {
  const { track } = MixpanelClientContainer.useContainer();
  const { teamId } = TeamContextContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();

  useEffect(() => {
    if (swiperSlide.isVisible) {
      dontAwait(
        track({
          eventName: "Screen Viewed",
          "team id": teamId,
          "screen name": "UserOnboardingDataPrivacySlide",
        }),
      );
    }
  }, [swiperSlide.isVisible, teamId, track]);

  return (
    <UserOnboardingSlideLayout>
      <SafeAreaSpacer position="top" />
      <div
        data-cy="UserOnboardingDataPrivacySlide"
        className={`${layoutCss.media} ion-padding`}
      >
        <img
          src="/assets/illustrations/dataProtection.png"
          alt={i18n.t.UserOnboardingDataPrivacySlide.illustrationAltText}
        />
      </div>

      <div className={`${layoutCss.content} ${css.content}`}>
        <h2>
          <IonText color="aubergine-text">
            <strong>{i18n.t.UserOnboardingDataPrivacySlide.heading}</strong>
          </IonText>
        </h2>

        {i18n.t.UserOnboardingDataPrivacySlide.explanation()}
      </div>

      <div className={`${layoutCss.footer} ${layoutCss.buttons}`}>
        <IonButton
          color="aubergine-fill"
          fill="solid"
          expand="block"
          size="large"
          data-cy="UserOnboardingDataPrivacySlide-accept"
          onClick={() => swiper.slideNext()}
        >
          {i18n.t.common.ok}
        </IonButton>
      </div>
    </UserOnboardingSlideLayout>
  );
};

export default UserOnboardingDataPrivacySlide;
