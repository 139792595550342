import { Message } from "@connectedliving/common/lib/firestore/Message";
import { get } from "lodash";

export default function getTranslatedMessageBody(
  showTranslation: boolean,
  preferredLanguage: string | null,
  messageData: Message,
  loggedInUserId: string,
): string {
  const sentByCurrentUser = messageData.creatorId === loggedInUserId;

  if (sentByCurrentUser) return messageData.body;

  const translationEnabled = showTranslation && preferredLanguage !== null;
  if (!translationEnabled) return messageData.body;

  const translationInPreferredLanguage =
    messageData.translations &&
    get(messageData.translations, preferredLanguage);

  if (translationInPreferredLanguage) {
    return translationInPreferredLanguage;
  }

  return messageData.body;
}
