import convertTimestampValueToDate from "@connectedliving/common/lib/utilities/convertTimestampValueToDate";
import { FIRESTORE_RANDOM_ID_LENGTH } from "@connectedliving/common/lib/utilities/firebaseConstants";
import isFirestoreError from "@connectedliving/common/lib/utilities/firestore/isFirestoreError";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import uniqueGlobalId from "@connectedliving/common/lib/utilities/uniqueGlobalId";
import {
  IonButton,
  IonIcon,
  IonInput,
  IonItem,
  IonSpinner,
  useIonToast,
} from "@ionic/react";
import { close, send } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import cloudFunctions from "src/firebase/cloudFunctions";
import useIonStringInputBehavior from "../../../common/form/useIonStringInputBehavior";
import FirebaseAppContainer from "../../../state/firebase/FirebaseAppContainer";
import { useFieldInputs } from "../../../state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "../../../state/formState/useFormState";
import I18nContainer from "../../../state/i18n/I18nContainer";
import LastReadTimestampsContainer from "../../../state/team/LastReadTimestampsContainer";
import validate from "../../../utilities/validate/validate";
import css from "./FirestoreMessageComposer.module.css";

export type FirestoreMessageComposerProps = {
  channelPath: string;
  disabled: boolean;
};

function convertValueToDisplayString(value: string): string {
  return value;
}

function convertDisplayStringToValue(displayString: string): string {
  return displayString.trim();
}

const FirestoreMessageComposer: React.FC<FirestoreMessageComposerProps> = ({
  channelPath,
  disabled,
}) => {
  const { authUser, firebaseApp } = FirebaseAppContainer.useContainer();
  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const { setChannelLastReadTimestamp } =
    LastReadTimestampsContainer.useContainer();

  const i18n = I18nContainer.useContainer();
  const formState = useFormState<{
    body: string;
  }>({
    initial: {
      body: "",
    },
    validate: {
      fields: {
        body: validate.string.isPresent(i18n.t.common.validations),
      },
    },
  });
  const fields = useFieldInputs(formState, useFieldStates(formState));

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [presentToast, dismissToast] = useIonToast();

  const { form, submit } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
          const result = await cloudFunctions(firebaseApp).createMessage({
            channelPath,
            messageId: uniqueGlobalId({ length: FIRESTORE_RANDOM_ID_LENGTH }),
            message: {
              body: formValues.body,
            },
          });
          dontAwait(
            setChannelLastReadTimestamp(
              channelPath,
              convertTimestampValueToDate(result.message.createdAt),
            ),
          );

          fields.body.onChange("");
        } catch (e) {
          if (isFirestoreError(e)) {
            let { message: errorMessage } = e;
            if (e.code === "internal") {
              errorMessage = i18n.t.common.errorMessage;
            }
            formState.setFormErrors([{ message: errorMessage }]);
            dontAwait(
              presentToast({
                message: errorMessage,
                color: "danger",
                duration: 5000,
                position: "bottom",
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                    handler: () => dismissToast(),
                  },
                ],
              }),
            );
          }
          throw e;
        } finally {
          setIsSubmitting(false);
        }
      },
      [
        channelPath,
        dismissToast,
        fields.body,
        firebaseApp,
        formState,
        i18n.t.common.errorMessage,
        isSubmitting,
        presentToast,
        setChannelLastReadTimestamp,
      ],
    ),
  });

  const { currentDisplayString, onIonBlur, onIonInput } =
    useIonStringInputBehavior({
      valueProp: fields.body.value,
      onChange: fields.body.onChange,
      onBlur: fields.body.onBlur,
      convertDisplayStringToValue,
      convertValueToDisplayString,
    });

  return (
    <form {...form} className={css.form}>
      <IonItem lines="none">
        <IonInput
          {...{ onIonInput, onIonBlur }}
          value={currentDisplayString}
          disabled={isSubmitting || disabled}
          placeholder={i18n.t.MessageComposer.inputPlaceholder}
        />

        <IonButton
          slot="end"
          type="submit"
          onClick={submit}
          disabled={isSubmitting || disabled}
          fill="clear"
        >
          {isSubmitting ? (
            <IonSpinner />
          ) : (
            <IonIcon color="primary" icon={send} />
          )}
        </IonButton>
      </IonItem>
    </form>
  );
};

export default FirestoreMessageComposer;
