import hashString from "../utilities/crypto/hashString";

export default function streamDirectMessageChannelId(
  teamId: string,
  user1Id: string,
  user2Id: string,
): string {
  const participants = [user1Id, user2Id];
  return hashString(`${teamId}-${participants.sort().join("-")}`);
}
