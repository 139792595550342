export enum MessagePurpose {
  Offers = "offers",
  InitialDefaultChannelMessage = "initial default channel message",
  TeamJoinedNotification = "team joined notification",
  OfferStarterKit = "offer starter kit",
  OfferFreeStarterKit = "offer free starter kit",
  StarterKitSent = "starter kit sent",
  UnusedStarterKitReminder = "unused starter kit reminder",
  UnusedPrintAtHomePdfReminder = "unused print at home pdf reminder",
  PmfSurveyRequest = "pmf survey request",
  DeliverPrintAtHomePdf = "deliver print-at-home pdf",
  FeedbackRequest = "feedback request",
}

export const messagePurposeValues = Object.freeze(
  Object.values(MessagePurpose),
);
