function zip2(a1: readonly unknown[], arr2: readonly unknown[]): unknown[] {
  return a1.map((element, index) => [element, arr2[index]]);
}

export type FormatValueTagFunction = (
  stringLiterals: TemplateStringsArray,
  ...interpolatedValues: unknown[]
) => string;

export type FormatValueFunction = (value: unknown) => string;

export function makeFormatValueTagFunction(
  format: FormatValueFunction,
): FormatValueTagFunction {
  const templateTagFunction: FormatValueTagFunction = (
    stringLiterals,
    ...interpolatedValues
  ): string => {
    const formattedValues = interpolatedValues.map((value) => format(value));
    return zip2(stringLiterals, formattedValues).flat().join("");
  };

  return templateTagFunction;
}
