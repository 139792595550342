// Adapted from https://github.com/soldair/node-qrcode/blob/f08fd572d7cca92c8b9d71b24cebccf61663d4a6/lib/renderer/svg-tag.js
import { QRCode } from "qrcode";

function svgCmd(cmd: string, x: number, y?: number) {
  let str = cmd + x;
  if (typeof y !== "undefined") str += ` ${y}`;

  return str;
}

function renderQrCodePath(
  data: QRCode["modules"]["data"],
  size: number,
  margin: number,
) {
  let path = "";
  let moveBy = 0;
  let newRow = false;
  let lineLength = 0;

  for (let i = 0; i < data.length; i += 1) {
    const col = Math.floor(i % size);
    const row = Math.floor(i / size);

    if (!col && !newRow) newRow = true;

    if (data[i]) {
      lineLength += 1;

      if (!(i > 0 && col > 0 && data[i - 1])) {
        path += newRow
          ? svgCmd("M", col + margin, 0.5 + row + margin)
          : svgCmd("m", moveBy, 0);

        moveBy = 0;
        newRow = false;
      }

      if (!(col + 1 < size && data[i + 1])) {
        path += svgCmd("h", lineLength);
        lineLength = 0;
      }
    } else {
      moveBy += 1;
    }
  }

  return path;
}

export function renderSvgQrCodeTags(
  qrCodeObject: QRCode,
  color: string,
): {
  qrCodePathTag: string;
  size: number;
} {
  const qrCodeSize = qrCodeObject.modules.size;
  const { data } = qrCodeObject.modules;

  const qrCodePathTag = `<path stroke="${color}" d="${renderQrCodePath(
    data,
    qrCodeSize,
    0,
  )}" />`;

  return { qrCodePathTag, size: qrCodeSize };
}

export default function renderSvgQrCode(
  qrCodeObject: QRCode,
  options: { outputWidth?: number; color?: string } = {},
): string {
  const magenta = "#d06cb4";
  const color = options.color || magenta;
  const { qrCodePathTag, size } = renderSvgQrCodeTags(qrCodeObject, color);
  let { outputWidth } = options;

  if (!outputWidth) outputWidth = size;

  const scale = outputWidth / size;
  const viewBox = `viewBox="0 0 ${outputWidth} ${outputWidth}"`;
  return `
  <svg xmlns="http://www.w3.org/2000/svg" ${viewBox} shape-rendering="crispEdges">
    <g transform="scale(${scale})">
      ${qrCodePathTag}
    </g>
  </svg>`;
}
