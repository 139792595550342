import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import usePlacesAutocomplete, {
  ClearSuggestions,
  Init,
  SetValue,
  Status,
} from "use-places-autocomplete";

type UseGooglePlacesAutocompleteAndGeocodingReturn = {
  placesQuery: string;
  setValue: SetValue;
  clearSuggestions: ClearSuggestions;
  queryStatus: Status;
  queryResults: google.maps.places.AutocompletePrediction[];
  queryLoadingState: boolean;
  init: Init;
};

export default function useGooglePlacesAutocompleteAndGeocoding(): UseGooglePlacesAutocompleteAndGeocodingReturn {
  const {
    value,
    suggestions: { status, data, loading },
    setValue,
    clearSuggestions,
    init,
  } = usePlacesAutocomplete({
    cache: 24 * 60 * 60,
    debounce: 400,
    requestOptions: {
      componentRestrictions: { country: "de" },
      types: ["address"],
    },
    initOnMount: false,
  });

  useEffect(() => {
    if (!!status && !["OK", "ZERO_RESULTS"].includes(status)) {
      Sentry.captureException(status);
    }
  }, [status]);

  return {
    placesQuery: value,
    setValue,
    clearSuggestions,
    queryStatus: status,
    queryResults: data,
    queryLoadingState: loading,
    init,
  };
}
