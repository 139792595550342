/**
 * Used to assert at compile time that all possible values of a union have been exhausted.
 *
 * For example:
 *
 *   function numberOfLegs(animal: "dog" | "bird") : number {
 *     if (animal === "dog") return 4;
 *     if (animal === "bird") return 2;
 *   }
 *
 * The above will cause a compile-time error unless you return a number, but obviously there's no return value that
 * makes sense, because it should be impossible to reach and even then what's the "default" number of legs of an animal?
 *
 * You could do this:
 *
 *   function numberOfLegs(animal: "dog" | "bird") : number {
 *     if (animal === "dog") return 4;
 *     return 2;
 *   }
 *
 * This is correct, but what happens when you add a new animal type?
 *
 *   function numberOfLegs(animal: "dog" | "bird" | "spider") : number {
 *     if (animal === "dog") return 4;
 *     return 2;
 *   }
 *
 * This will continue to compile, even though you don't have correct handling for one case.
 *
 * This is where `exhausted()` is useful:
 *
 *   function numberOfLegs(animal: "dog" | "bird" | "spider") : number {
 *     if (animal === "dog") return 4;
 *     if (animal === "bird") return 2;
 *     throw exhausted(animal);
 *   }
 *
 * This asserts at compile-time that `value` can never have a value (it's of type `never`), so when you add the "spider"
 * union value the compiler will immediately raise an error.
 *
 * It also throws an error at run-time, in case we reach this code path via some uncompiled JavaScript (like library
 * code).
 */
export default function exhausted(value: never): Error {
  throw new Error(`Unhandled discriminated union member ${value}`);
}
