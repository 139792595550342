import { LanguageCode } from "@connectedliving/common/lib/firestore/supportedLanguages";
import { LocaleTag } from "src/state/i18n/I18nContainer";

const localeToTranslationLanguage: Record<LocaleTag, LanguageCode> =
  Object.freeze({
    "en-de": "en",
    "en-gb": "en",
    "en-us": "en",
    de: "de",
  });

export default localeToTranslationLanguage;
