import assertExhausted from "@connectedliving/common/lib/utilities/lang/assertExhausted";
import { channelsListPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonPage } from "@ionic/react";
import React from "react";
import { Redirect, useParams } from "react-router";
import useScreenTracking from "src/firebase/useScreenTracking";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { isErrorState } from "../../utilities/LoadingState";
import ChannelMessagePageSkeleton from "./ChannelMessagesPageSkeleton";
import FirestoreTeamChannelMessagesPageContent from "./FirestoreTeamChannelMessagesPageContent";
import StreamTeamChannelMessagesContent from "./stream/StreamTeamChannelMessagesContent";

type TeamChannelMessagesPageProps = {
  teamId: string;
};

type TeamChannelMessagesPageRouteParams = {
  teamChannelId: string;
};

const TeamChannelMessagesPage: React.FC<TeamChannelMessagesPageProps> = ({
  teamId,
}) => {
  const { teamChannelId } = useParams<TeamChannelMessagesPageRouteParams>();
  const { getTeamChannelById, teamChannelsLoadingState } =
    FirestoreChannelsContainer.useContainer();
  const teamChannel = getTeamChannelById(teamChannelId);

  useScreenTracking("TeamChannelMessagesPage", teamId);

  function content() {
    if (teamChannelsLoadingState === "initialLoad") {
      return <ChannelMessagePageSkeleton />;
    }

    if (
      isErrorState(teamChannelsLoadingState) ||
      !teamChannel ||
      !teamChannel.exists
    ) {
      return <Redirect to={channelsListPageUrl({ teamId })} />;
    }

    const { backingStorage } = teamChannel.data();
    if (backingStorage === "stream") {
      return <StreamTeamChannelMessagesContent {...{ teamId, teamChannel }} />;
    }

    if (backingStorage === "firestore") {
      return (
        <FirestoreTeamChannelMessagesPageContent {...{ teamId, teamChannel }} />
      );
    }

    throw assertExhausted(backingStorage);
  }

  return (
    <IonPage
      id="main-content"
      data-cy="TeamChannelMessagesPage"
      className="ion-page ion-justify-content-center ion-align-items-center"
    >
      <UserOnboardingModal />
      {content()}
    </IonPage>
  );
};

export default TeamChannelMessagesPage;
