export const buildingNamesById = Object.freeze(
  new Map([
    ["front-house", "Vorderhaus"],
    ["back-house", "Hinterhaus"],
    ["rear-house", "Quergebäude"],
    ["garden-house2", "Gartenhaus"],
    ["garden-house", "Remise"],
    ["house-wing", "Seitenflügel"],
    ["house-wing-left", "Seitenflügel Links"],
    ["house-wing-right", "Seitenflügel Rechts"],
    ["side-house", "Seitenhaus"],
    ["side-house-left", "Seitenhaus Links"],
    ["side-house-right", "Seitenhaus Rechts"],
    ["first-hof", "1. Hof"],
    ["second-hof", "2. Hof"],
    ["third-hof", "3. Hof"],
    ["fourth-hof", "4. Hof"],
    ["fifth-hof", "5. Hof"],
    ["sixth-hof", "6. Hof"],
  ]),
);

export function buildingName(id: string): string {
  return buildingNamesById.get(id) || "";
}
