import I18nContainer from "../../../state/i18n/I18nContainer";
import { ChannelHistoryDayBoundaryItem as ChannelHistoryTimeBoundaryItem } from "./ChannelHistoryItem";
import css from "./FirestoreMessageListTimeBoundaryItem.module.css";

export type FirestoreMessageListTimeBoundaryItemProps = {
  channelHistoryDayBoundaryItem: ChannelHistoryTimeBoundaryItem;
};

const FirestoreMessageListTimeBoundaryItem: React.FC<
  FirestoreMessageListTimeBoundaryItemProps
> = ({ channelHistoryDayBoundaryItem }) => {
  const i18n = I18nContainer.useContainer();

  return (
    <div className="ion-text-center ion-padding">
      <div className={css.timeBoundaryContainer}>
        <small>
          <strong>
            {i18n.format.formatDate(channelHistoryDayBoundaryItem.date)}
          </strong>
        </small>
      </div>
    </div>
  );
};

export default FirestoreMessageListTimeBoundaryItem;
