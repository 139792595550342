import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import updateFirestoreDocument from "@connectedliving/common/lib/utilities/firestore/updateFirestoreDocument";
import { MixpanelClient } from "src/state/mixpanel/MixpanelContainer";
import updatedObjectKeys from "src/utilities/data/updatedObjectKeys";
import { ExistingDocument } from "src/utilities/data/useFirestoreDocument";

export default function updateUserProfileAndTeamProfile({
  userProfile,
  teamUserProfile,
  teamId,
  track,
}: {
  userProfile?: {
    updatedUserProfile: Partial<UserProfile>;
    originalUserProfile: ExistingDocument<UserProfile>;
  };
  teamUserProfile?: {
    updatedTeamUserProfile: Partial<TeamUserProfile>;
    originalTeamUserProfile: ExistingDocument<TeamUserProfile>;
  };
  track: MixpanelClient["track"];
  teamId: string;
}): Promise<unknown> {
  const pendingOperations: Promise<unknown>[] = [];

  if (userProfile) {
    const { updatedUserProfile, originalUserProfile } = userProfile;
    const originalUserProfileData = originalUserProfile.data();
    const userProfileFieldsUpdated = updatedObjectKeys<UserProfile>(
      originalUserProfileData,
      updatedUserProfile,
    );

    if (userProfileFieldsUpdated.length > 0) {
      pendingOperations.push(
        updateFirestoreDocument(originalUserProfile, updatedUserProfile),
        track({
          eventName: "User Profile Updated",
          "team id": teamId,
          "user fields updated": userProfileFieldsUpdated,
        }),
      );
    }
  }

  if (teamUserProfile) {
    const { updatedTeamUserProfile, originalTeamUserProfile } = teamUserProfile;
    const originalTeamUserProfileData = originalTeamUserProfile.data();
    const TeamUserProfileFieldsUpdated = updatedObjectKeys<TeamUserProfile>(
      originalTeamUserProfileData,
      updatedTeamUserProfile,
    );

    if (TeamUserProfileFieldsUpdated.length > 0) {
      pendingOperations.push(
        updateFirestoreDocument(
          originalTeamUserProfile,
          updatedTeamUserProfile,
        ),
        track({
          eventName: "Team User Profile Updated",
          "team id": teamId,
          "user fields updated": TeamUserProfileFieldsUpdated,
        }),
      );
    }
  }

  return Promise.all(pendingOperations);
}
