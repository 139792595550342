import { teamLocationsPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import { formatGermanAddress } from "@connectedliving/common/lib/formatAddress";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import firebase from "firebase/compat/app";
import { MixpanelClient } from "src/state/mixpanel/MixpanelContainer";

export default async function createTeamLocation({
  teamLocation,
  firebaseApp,
  track,
  teamId,
}: {
  teamLocation: TeamLocation;
  track: MixpanelClient["track"];
  firebaseApp: firebase.app.App;
  teamId: string;
}): Promise<unknown> {
  const { streetName, streetNumber, city, state, postcode, country } =
    teamLocation;
  const createdTeamLocation = await firebaseApp
    .firestore()
    .collection(teamLocationsPath({ teamId }))
    .withConverter(TeamLocationConverter)
    .add(teamLocation as TeamLocation);

  dontAwait(
    track({
      eventName: "Team Location Added",
      "team id": teamId,
      "team location id": createdTeamLocation.id,
      "street name": streetName,
      "street number": streetNumber,
      city,
      state,
      postcode,
      country,
      address: formatGermanAddress(teamLocation),
    }),
  );

  return createdTeamLocation;
}
