import {
  getStrictDocumentData,
  getString,
  StrictDocumentData,
} from "./dataHelpers";
import { firestoreDataToDimensions } from "./Dimensions";

export type Thumbnail = {
  url: string;
  dimensions: {
    width: number;
    height: number;
  };
};

export function firestoreDataToThumbnail(data: StrictDocumentData): Thumbnail {
  return {
    url: getString(data.url),
    dimensions: firestoreDataToDimensions(
      getStrictDocumentData(data.dimensions),
    ),
  };
}
