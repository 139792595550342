import { SupportedChannelIcon } from "@connectedliving/common/lib/firestore/supportedChannelIcons";
import { ChannelIconColor } from "@connectedliving/common/lib/firestore/TeamChannel";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import castAs from "@connectedliving/common/lib/utilities/lang/castAs";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import uniqueGlobalId from "@connectedliving/common/lib/utilities/uniqueGlobalId";
import {
  channelsListPageUrl,
  teamChannelMessagesPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { OverlayEventDetail } from "@ionic/core";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import { sortBy } from "lodash";
import {
  ComponentProps,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import ChannelAvatarContent from "src/common/ChannelAvatarContent";
import useScreenTracking from "src/firebase/useScreenTracking";
import createTeamChannel from "src/state/firebase/createTeamChannel";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import environment from "src/utilities/environment";
import { dataAvailable, LoadingState } from "src/utilities/LoadingState";
import ProfileImage from "src/utilities/ProfileImage";
import SelectColoredIconModal, {
  SelectColoredIconModalName,
} from "../../common/SelectColoredIconModal";
import MixpanelClientContainer from "../../state/mixpanel/MixpanelContainer";
import TeamContextContainer from "../../state/TeamContextContainer";
import css from "./NewTeamChannelPage.module.css";
import WhoCanSeeThis from "./WhoCanSeeThis";

const channelNameCharacterLimit = 25;

type NewTeamChannelPageProps = {
  teamId: string;
};

type SelectedUsers = Record<string, boolean>;

const NewTeamChannelPage: React.FC<NewTeamChannelPageProps> = ({ teamId }) => {
  const supportUserId = environment().supportUserId();
  const history = useHistory();
  const { teamChannels } = FirestoreChannelsContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { team } = TeamContextContainer.useContainer();

  const { authUser, firebaseApp } = FirebaseAppContainer.useContainer();
  const { userProfile: currentUserProfile } =
    LoggedInUserProfileContainer.useContainer();
  const { onboardedUserProfiles, loadingState: userProfilesLoadingState } =
    TeamUserProfilesContainer.useContainer();
  const [selectedUsers, setSelectedUsers] = useState<SelectedUsers>({});
  const [channelName, setChannelName] = useState<string | undefined | null>();
  const [channelIcon, setChannelIcon] = useState<{
    icon: SupportedChannelIcon;
    color: ChannelIconColor;
  }>({ icon: "chatbubbles", color: "sky-blue" });
  const [invalidInputMessage, setInvalidInputMessage] = useState<
    string | undefined
  >();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const loadingState: LoadingState =
    userProfilesLoadingState === "ready" ? "ready" : "initialLoad";
  const i18n = I18nContainer.useContainer();

  const formDisabled =
    !dataAvailable(loadingState) ||
    !channelName ||
    !!invalidInputMessage ||
    isSaving;

  assertPresent(authUser, { because: "<App> waits for user to log in" });
  assertPresent(currentUserProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  const screenName = "NewTeamChannelPage";

  useScreenTracking(screenName, teamId);

  useEffect(() => {
    function validateChannelName() {
      if (!channelName) {
        setInvalidInputMessage(undefined);
        return;
      }
      if (channelName.length > channelNameCharacterLimit) {
        setInvalidInputMessage(i18n.t.NewTeamChannelPage.tooLong);
        return;
      }
      setInvalidInputMessage(undefined);
    }
    validateChannelName();
  }, [channelName, i18n.t.NewTeamChannelPage]);

  const pageRef = useRef();
  const [present, dismiss] = useIonModal(
    SelectColoredIconModal,
    castAs<ComponentProps<typeof SelectColoredIconModal>>({
      icon: channelIcon,
      onSelect: (icon) => {
        setChannelIcon(icon);
        dismiss(icon, "confirm");
      },
    }),
  );

  const onEditChannelIconClick = useCallback(() => {
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Edit Channel Icon",
      }),
    );

    present({
      onWillPresent: () => {
        dontAwait(
          track({
            eventName: "Modal Opened",
            "screen name": screenName,
            "team id": teamId,
            "modal name": SelectColoredIconModalName,
          }),
        );
      },
      canDismiss: true,
      presentingElement: pageRef.current,
      onWillDismiss: (ev: CustomEvent<OverlayEventDetail>) => {
        if (ev.detail.role === "confirm") {
          dontAwait(
            track({
              eventName: "Modal Completed",
              "screen name": screenName,
              "team id": teamId,
              "modal name": SelectColoredIconModalName,
            }),
          );
        }
        if (ev.detail.role === "dismiss") {
          dontAwait(
            track({
              eventName: "Modal Aborted",
              "screen name": screenName,
              "team id": teamId,
              "modal name": SelectColoredIconModalName,
            }),
          );
        }
      },
    });
  }, [present, teamId, track]);

  const onFormSubmit = async () => {
    if (formDisabled || !channelName) return;

    setIsSaving(true);

    const matchingChannel = teamChannels.find(
      (channel) => channel.data().name === channelName,
    );

    if (matchingChannel) {
      setInvalidInputMessage(i18n.t.NewTeamChannelPage.alreadyExists);
      setIsSaving(false);
      return;
    }

    const usersToAddToChannel = [
      authUser.uid,
      ...Object.keys(selectedUsers).filter((userId) => selectedUsers[userId]),
    ];

    const channelId = uniqueGlobalId();

    await createTeamChannel(
      firebaseApp,
      authUser,
      {
        teamId,
        teamChannelId: channelId,
        name: channelName.trim(),
        channelIcon: channelIcon.icon,
        channelIconColor: channelIcon.color,
        channelBackingStorage: team.data().channelBackingStorage,
      },
      usersToAddToChannel,
    );

    history.replace(
      teamChannelMessagesPageUrl({
        teamId,
        teamChannelId: channelId,
      }),
    );
  };

  const filteredMembers = onboardedUserProfiles.filter(
    (userProfile) =>
      userProfile.id !== authUser.uid && userProfile.id !== supportUserId,
  );

  const filteredAndSortedMembers = sortBy(filteredMembers, (userProfile) =>
    formatFullName(userProfile.data()),
  );

  function selectAllMembers() {
    const newSelectedUsersState: Record<string, boolean> = {};
    filteredAndSortedMembers.forEach((userProfile) => {
      newSelectedUsersState[userProfile.id] = true;
    });
    setSelectedUsers(newSelectedUsersState);
  }

  function deselectAllMembers() {
    setSelectedUsers({});
  }

  const countUsersSelected = Object.values(selectedUsers).filter(
    (v) => v,
  ).length;
  const indeterminate =
    countUsersSelected > 0 &&
    countUsersSelected < filteredAndSortedMembers.length;

  return (
    <IonPage
      ref={pageRef}
      data-cy="NewTeamChannelPage"
      className={css.container}
    >
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              data-cy="NewTeamChannelPage-BackButton"
              text=""
              defaultHref={channelsListPageUrl({ teamId })}
            />
          </IonButtons>
          <IonTitle>{i18n.t.NewTeamChannelPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dontAwait(onFormSubmit());
          }}
        >
          <IonListHeader>{i18n.t.NewTeamChannelPage.name}</IonListHeader>

          <div className={css.channelPreviewContainer}>
            <IonAvatar
              className={`${css.channelIconAvatar} ${css.channelPreviewIcon}`}
              onClick={() => onEditChannelIconClick()}
            >
              <ChannelAvatarContent
                content={{
                  type: "icon",
                  icon: channelIcon.icon,
                  iconColor: channelIcon.color,
                }}
              />
            </IonAvatar>
            <IonItem
              className={css.channelPreviewItem}
              data-cy="ChannelsNewForm-input-channelName"
            >
              <IonInput
                autofocus
                placeholder={i18n.t.NewTeamChannelPage.purpose}
                value={channelName}
                onIonChange={(e) => setChannelName(e.detail.value?.trim())}
                maxlength={channelNameCharacterLimit}
              />
            </IonItem>
          </div>

          {invalidInputMessage && (
            <div className="ion-padding-top ion-padding-start">
              <IonNote color="danger">{invalidInputMessage}</IonNote>
            </div>
          )}

          <WhoCanSeeThis channelType={StreamChannelType.Team} />
          <IonList>
            <IonListHeader className={css.checkboxHeader}>
              <IonLabel>{i18n.t.common.members}</IonLabel>
              <IonCheckbox
                checked={countUsersSelected === filteredAndSortedMembers.length}
                indeterminate={indeterminate}
                onClick={(event) => {
                  if (event.currentTarget.checked) {
                    if (indeterminate) {
                      deselectAllMembers();
                    } else {
                      selectAllMembers();
                    }
                  } else {
                    deselectAllMembers();
                  }
                }}
              />
            </IonListHeader>

            <IonItem>
              <IonAvatar slot="start">
                <ProfileImage userProfile={currentUserProfile} />
              </IonAvatar>
              <IonLabel>{i18n.t.common.you}</IonLabel>
              <IonCheckbox checked disabled />
            </IonItem>
            {filteredAndSortedMembers.map((userProfile) => (
              <IonItem
                data-cy="NewTeamChannelPage-memberItem"
                key={userProfile.id}
              >
                <IonAvatar slot="start">
                  <ProfileImage userProfile={userProfile.data()} />
                </IonAvatar>
                <IonLabel>{formatFullName(userProfile.data())}</IonLabel>
                <IonCheckbox
                  checked={!!selectedUsers[userProfile.id]}
                  onClick={(event) => {
                    const newValue = event.currentTarget.checked;
                    setSelectedUsers((prevState) => ({
                      ...prevState,
                      [userProfile.id]: newValue,
                    }));
                  }}
                />
              </IonItem>
            ))}
          </IonList>
        </form>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton
            data-cy="NewTeamChannelPage-submit"
            disabled={formDisabled}
            expand="block"
            onClick={onFormSubmit}
          >
            {isSaving ? (
              <IonSpinner />
            ) : (
              i18n.t.NewTeamChannelPage.createChannel
            )}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default NewTeamChannelPage;
