import { teamPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamConverter from "@connectedliving/common/lib/firestore/TeamConverter";
import { ArrayElement } from "@connectedliving/common/lib/utilities/ArrayElement";
import { isHttpsError } from "@connectedliving/common/lib/utilities/firestore/isHttpsError";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  getStartedPageUrl,
  leaveTeamPageUrl,
  userSettingsPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonContent,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import * as Sentry from "@sentry/browser";
import { close } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import { Redirect, useHistory } from "react-router";
import commonCss from "src/common/common.module.css";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import useIonicParamsForRoute from "src/utilities/ionic/useIonicParamsForRoute";
import { FormOptionalRadioGroup } from "../common/form/FormRadioGroup";
import { useFieldInputs } from "../state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "../state/formState/useFormState";
import { isDataAvailable } from "../utilities/data/Loadable";
import useFirestoreDocument from "../utilities/data/useFirestoreDocument";
import mapObjectToFormSelectOptions from "../utilities/mapObjectToFormSelectOptions";
import validate from "../utilities/validate/validate";

const TeamLeavePage: React.FC = () => {
  const { teamId } = useIonicParamsForRoute(
    leaveTeamPageUrl({ teamId: ":teamId" }),
  );
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const team = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(teamPath({ teamId }))
      .withConverter(TeamConverter),
  );
  const { authUser, cloudFunctions } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const [isLeaving, setIsLeaving] = useState<boolean>(false);
  const history = useHistory();
  const [presentToast] = useIonToast();

  const reasonOptions = mapObjectToFormSelectOptions(
    i18n.t.TeamLeavePage.inputs.reason.options,
  );
  const formState = useFormState({
    initial: {
      reason: null as ArrayElement<typeof reasonOptions>["value"] | null,
    },
    validate: {
      fields: {
        reason: (fieldState) =>
          validate.string.isPresent(i18n.t.common.validations)({
            value: fieldState.value || "",
          }),
      },
    },
  });
  const fields = useFieldInputs(formState, useFieldStates(formState));
  const { form } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        if (isLeaving) return;
        setIsLeaving(true);

        if (!authUser) {
          throw new Error("You must be authenticated to leave a team");
        }

        try {
          await cloudFunctions.removeUserFromTeam({
            userId: authUser.uid,
            teamId,
          });

          dontAwait(
            track({
              eventName: "Team Left",
              "team id": teamId,
              reason: assertPresent.andReturn(formValues.reason, {
                because: "validations prevent it being null",
              }),
            }),
          );

          history.replace(getStartedPageUrl());
        } catch (error) {
          if (isHttpsError(error)) {
            dontAwait(
              presentToast({
                color: "danger",
                duration: 5000,
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                  },
                ],
                message: i18n.t.common.httpsErrorMessage(error),
              }),
            );

            Sentry.captureException(error);
          }

          setIsLeaving(false);
        }
      },
      [
        authUser,
        cloudFunctions,
        history,
        i18n.t.common,
        isLeaving,
        presentToast,
        teamId,
        track,
      ],
    ),
  });

  return (
    <IonPage id="main-content">
      {isDataAvailable(team) && team.data.exists === false && (
        <Redirect to="/" />
      )}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={userSettingsPageUrl({ teamId })}
              text=""
            />
          </IonButtons>
          <IonTitle>{i18n.t.TeamLeavePage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={commonCss.contentContainer}>
        <IonCard className={commonCss.card}>
          <FormOptionalRadioGroup
            options={reasonOptions}
            label={i18n.t.TeamLeavePage.inputs.reason.label(
              team.data?.data()?.name || "---",
            )}
            disabled={isLeaving}
            {...fields.reason}
          />

          <IonCardContent>
            <IonButton
              expand="block"
              color="danger"
              fill="solid"
              disabled={isLeaving}
              onClick={() => form.onSubmit()}
            >
              {isLeaving ? <IonSpinner /> : i18n.t.TeamLeavePage.submit}
            </IonButton>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default TeamLeavePage;
