/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { sanitizeUrl } from "@braintree/sanitize-url";
import React, { useCallback, useState } from "react";
import { ModalComponent as ModalWrapper } from "stream-chat-react";
import { useChannelMessagesPageContext } from "./ChannelMessagesPageContext";

export type ImageProps = {
  /** The text fallback for the image */
  fallback?: string;
  /** The full size image url */
  image_url?: string;
  /** The thumb url */
  thumb_url?: string;
};

/**
 * A simple component that displays an image.
 */
export const CLImage: React.FC<ImageProps> = (props) => {
  const [modalIsOpen, internalSetModalIsOpen] = useState(false);

  //----------------------------------------------------------------------------
  // CONNECTED LIVING MODIFICATIONS
  //----------------------------------------------------------------------------
  const { setAttachmentModalOpen } = useChannelMessagesPageContext();
  const setModalIsOpen = useCallback(
    (value: boolean) => {
      internalSetModalIsOpen(value);
      setAttachmentModalOpen(value);
    },
    [setAttachmentModalOpen],
  );
  // END MODIFICATIONS ---------------------------------------------------------

  const { fallback, image_url, thumb_url } = props;
  const imageSrc = sanitizeUrl(image_url || thumb_url);
  const formattedArray = [
    {
      original: imageSrc,
      originalAlt: "User uploaded content",
      source: imageSrc,
    },
  ];

  const toggleModal = () => setModalIsOpen(!modalIsOpen);

  return (
    <>
      <img
        alt={fallback}
        className="str-chat__message-attachment--img"
        data-testid="image-test"
        onClick={toggleModal}
        onKeyPress={toggleModal}
        src={imageSrc}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
      />

      <ModalWrapper
        images={formattedArray}
        index={0}
        modalIsOpen={modalIsOpen}
        toggleModal={toggleModal}
      />
    </>
  );
};
