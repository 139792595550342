import firebase from "firebase/compat/app";
import {
  firestoreDataToTeamUserPreferences,
  TeamUserPreferences,
  teamUserPreferencesToFirestoreData,
} from "./TeamUserPreferences";

const TeamUserPreferencesConverter: firebase.firestore.FirestoreDataConverter<TeamUserPreferences> =
  Object.freeze({
    toFirestore(teamUserPreferences: TeamUserPreferences) {
      return teamUserPreferencesToFirestoreData(teamUserPreferences);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TeamUserPreferences {
      return firestoreDataToTeamUserPreferences(snapshot.data(options));
    },
  });
export default TeamUserPreferencesConverter;
