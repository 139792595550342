import { isArray, isDate, isUndefined, omitBy } from "lodash";
import { StrictDocumentData } from "../../firestore/dataHelpers";
import { isTimestampValue } from "../../firestore/TimestampValue";
import convertTimestampValueToDate from "../convertTimestampValueToDate";

type MixpanelDataType =
  | boolean
  | number
  | string
  | Date
  | undefined
  | MixpanelDataType[];

export function assertMixpanelDataType(
  value: unknown,
): asserts value is MixpanelDataType {
  if (
    ["boolean", "number", "string", "undefined"].includes(typeof value) ||
    isDate(value) ||
    isArray(value) ||
    value === null
  ) {
    return;
  }
  throw new Error(
    `${JSON.stringify(value)} is of a type not supported by Mixpanel`,
  );
}

export function prepareMixpanelData(
  originalData: StrictDocumentData,
): StrictDocumentData {
  const result = { ...originalData };

  for (const [prop, value] of Object.entries(originalData)) {
    if (isTimestampValue(value)) {
      result[prop] = convertTimestampValueToDate(value).toISOString();
    }
    assertMixpanelDataType(result[prop]);
  }

  return omitBy(result, isUndefined);
}
