import React from "react";
// Custom Connected Living code ------------------------------------------------
import { CLAvatar } from "./CLAvatar";
//------------------------------------------------------------------------------

export type UserItemProps = {
  /** The user */
  entity: {
    /** The parts of the Name property of the entity (or id if no name) that can be matched to the user input value.
     * Default is bold for matches, but can be overwritten in css.
     * */
    itemNameParts: { match: string; parts: string[] };
    /** Id of the user */
    id?: string;
    /** Image of the user */
    image?: string;
    /** Name of the user */
    name?: string;
  };
};

/**
 * UserItem - Component rendered in commands menu
 */
const UnMemoizedUserItem: React.FC<UserItemProps> = (props) => {
  const { entity } = props;

  const hasEntity = Object.keys(entity).length;
  const itemParts = entity?.itemNameParts;

  const renderName = () => {
    if (!hasEntity) return null;

    return (
      hasEntity &&
      itemParts.parts.map((part, i) => (
        <>
          {part[0] === " " && <>&nbsp;</>}
          {part.toLowerCase() === itemParts.match.toLowerCase() ? (
            // eslint-disable-next-line react/no-array-index-key
            <span className="str-chat__emoji-item--highlight" key={`part-${i}`}>
              {part}
            </span>
          ) : (
            <span
              className="str-chat__emoji-item--part"
              // eslint-disable-next-line react/no-array-index-key
              key={`part-${i}`}
            >
              {part}
            </span>
          )}
          {part[part.length - 1] === " " && part[0] !== " " && <>&nbsp;</>}
        </>
      ))
    );
  };

  return (
    <div className="str-chat__user-item">
      <CLAvatar image={entity.image} name={entity.name} size={20} />
      <span className="str-chat__user-item--name">{renderName()}</span>
    </div>
  );
};

export const CLUserItem = React.memo(
  UnMemoizedUserItem,
) as typeof UnMemoizedUserItem;
