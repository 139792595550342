import { lastReadTimestampsPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { LastReadTimestamps } from "@connectedliving/common/lib/firestore/LastReadTimestamps";
import LastReadTimestampsConverter from "@connectedliving/common/lib/firestore/LastReadTimestampsConverter";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import firebase from "firebase/compat/app";
import { get } from "lodash";
import { useCallback } from "react";
import { createContainer } from "src/utilities/createContainer";
import { LoadingState } from "src/utilities/LoadingState";
import useFirestoreDocument from "../../utilities/data/useFirestoreDocument";
import FirebaseAppContainer from "../firebase/FirebaseAppContainer";

export type LastReadTimestampsValue = {
  lastReadTimestamps:
    | firebase.firestore.DocumentSnapshot<LastReadTimestamps>
    | undefined;
  loadingState: LoadingState;
  getChannelLastReadTimestamp: (channelPath: string) => Date | undefined;
  setChannelLastReadTimestamp: (
    channelPath: string,
    latestMessageCreatedAt: Date,
  ) => Promise<void>;
};

export function useLastReadTimestamps(): LastReadTimestampsValue {
  const { authUser, firebaseApp } = FirebaseAppContainer.useContainer();

  assertPresent(authUser, {
    because: "must wait for AuthUser before initializing provider",
  });

  const lastReadTimestampsDocPath = lastReadTimestampsPath({
    userId: authUser.uid,
  });
  const lastReadTimestampsDoc = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(lastReadTimestampsDocPath)
      .withConverter(LastReadTimestampsConverter),
  );

  const getChannelLastReadTimestamp = useCallback(
    (channelPath: string) =>
      get(lastReadTimestampsDoc?.data?.data(), channelPath),
    [lastReadTimestampsDoc?.data],
  );

  const setChannelLastReadTimestamp = useCallback(
    (channelPath: string, latestMessageCreatedAt: Date) =>
      firebaseApp
        .firestore()
        .doc(lastReadTimestampsDocPath)
        .withConverter(LastReadTimestampsConverter)
        .set({ [channelPath]: latestMessageCreatedAt }, { merge: true }),
    [firebaseApp, lastReadTimestampsDocPath],
  );

  return {
    lastReadTimestamps: lastReadTimestampsDoc.data,
    loadingState: lastReadTimestampsDoc.loadingState,
    getChannelLastReadTimestamp,
    setChannelLastReadTimestamp,
  };
}

const LastReadTimestampsContainer = createContainer(useLastReadTimestamps);
export default LastReadTimestampsContainer;
