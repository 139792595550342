import {
  IonAvatar,
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
} from "@ionic/react";
import I18nContainer from "../../state/i18n/I18nContainer";

export const ChannelPreviewSkeleton: React.FC = () => (
  <IonItem>
    <IonAvatar slot="start">
      <IonSkeletonText animated />
    </IonAvatar>
    <IonLabel>
      <IonSkeletonText animated />
    </IonLabel>
  </IonItem>
);

const ChannelsListSkeleton: React.FC = () => {
  const i18n = I18nContainer.useContainer();
  return (
    <>
      <IonList>
        <IonListHeader>
          <IonLabel>{i18n.t.ChannelsListPage.channels}</IonLabel>
        </IonListHeader>
        <ChannelPreviewSkeleton />
        <ChannelPreviewSkeleton />
        <ChannelPreviewSkeleton />
        <ChannelPreviewSkeleton />
      </IonList>

      <IonButton expand="block" fill="clear">
        <IonSkeletonText animated />
      </IonButton>

      <IonList>
        <IonListHeader>
          <IonLabel>{i18n.t.ChannelsListPage.directMessages}</IonLabel>
        </IonListHeader>
        <ChannelPreviewSkeleton />
        <ChannelPreviewSkeleton />
      </IonList>
    </>
  );
};

export default ChannelsListSkeleton;
