import { BuildingPart } from "@connectedliving/common/lib/firestore/BuildingPart";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import makeEmptyArray from "@connectedliving/common/lib/utilities/makeEmptyArray";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonSpinner,
  IonText,
  isPlatform,
  useIonToast,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import firebase from "firebase/compat/app";
import { close } from "ionicons/icons";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import FormOptionalTextInput from "src/common/form/FormOptionalTextInput";
import FormSingleSelect, {
  FormSelectOption,
} from "src/common/form/FormSingleSelect";
import { useFieldInputs } from "src/state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "src/state/formState/useFormState";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import updateUserProfileAndTeamProfile from "src/userProfiles/updateUserAndTeamUserProfiles";
import updatedObjectKeys from "src/utilities/data/updatedObjectKeys";
import formatTeamLocation from "src/utilities/formatTeamLocation";
import SafeAreaSpacer from "src/utilities/SafeAreaSpacer";
import sortTeamLocations from "src/utilities/sortTeamLocations";
import validate from "src/utilities/validate/validate";
import { useSwiper, useSwiperSlide } from "swiper/react";
import css from "./UserOnboardingLocationSlide.module.css";
import UserOnboardingSlideLayout from "./UserOnboardingSlideLayout";
import layoutCss from "./UserOnboardingSlideLayout.module.css";

type UserOnboardingLocationSlideParams = {
  teamLocations: firebase.firestore.QuerySnapshot<TeamLocation>;
  teamBuildingParts: BuildingPart[];
};

type UserOnboardingLocationSlideFormValues = Omit<
  TeamUserProfile,
  "onboardingVersionCompleted" | "visibleSuggestionBanners"
>;
const UserOnboardingLocationSlide: React.FC<
  UserOnboardingLocationSlideParams
> = ({ teamLocations, teamBuildingParts }) => {
  const { teamId, teamUserProfile } = TeamContextContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const [presentToast, dismissToast] = useIonToast();
  const i18n = I18nContainer.useContainer();
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  const sortedTeamLocations = sortTeamLocations(
    teamLocations,
    i18n.currentLocale,
  );
  useEffect(() => {
    if (swiperSlide.isVisible) {
      dontAwait(
        track({
          eventName: "Screen Viewed",
          "team id": teamId,
          "screen name": "UserOnboardingLocationSlide",
        }),
      );
    }
  }, [swiperSlide.isVisible, teamId, track]);
  const { floor, building, extraLocationData } = teamUserProfile.data();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const formState = useFormState<UserOnboardingLocationSlideFormValues>({
    initial: {
      teamLocationId: sortedTeamLocations[0] ? sortedTeamLocations[0].id : null,
      floor,
      building,
      extraLocationData,
    },
    validate: {
      fields: {
        teamLocationId: (fieldState) => {
          const { value } = fieldState;
          if (value)
            return validate.string.isPresent(i18n.t.common.validations)({
              value,
            });
          return [];
        },
      },
    },
  });

  const fields = useFieldInputs(formState, useFieldStates(formState));
  const { form } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
          await updateUserProfileAndTeamProfile({
            teamUserProfile: {
              originalTeamUserProfile: teamUserProfile,
              updatedTeamUserProfile: { ...formValues },
            },
            teamId,
            track,
          });
          setIsSubmitting(false);
          swiper.slideNext();
        } catch (error) {
          setIsSubmitting(false);
          const message = `${i18n.t.common.genericError} ${get(
            error,
            "message",
          )}`;
          Sentry.captureException(error);
          dontAwait(
            presentToast({
              message,
              color: "danger",
              duration: 5000,
              position: "bottom",
              buttons: [
                {
                  icon: close,
                  role: "cancel",
                  handler: () => dismissToast(),
                },
              ],
            }),
          );
        }
      },
      [
        dismissToast,
        i18n.t.common.genericError,
        isSubmitting,
        presentToast,
        swiper,
        teamId,
        teamUserProfile,
        track,
      ],
    ),
  });

  function formHasChanges() {
    const updatedFields = updatedObjectKeys(teamUserProfile.data(), {
      building: fields.building.value,
      teamLocationId: fields.teamLocationId.value,
      floor: fields.floor.value,
      extraLocationData: fields.extraLocationData.value,
    });

    return updatedFields.length > 0;
  }

  const availableBuildingPartOptions: FormSelectOption<BuildingPart>[] =
    teamBuildingParts.map((buildingPart) => ({
      value: buildingPart,
      label: i18n.t.common.buildingParts[buildingPart],
    }));

  let buttonDisplay;
  if (isSubmitting) {
    buttonDisplay = <IonSpinner />;
  } else if (formHasChanges()) {
    buttonDisplay = i18n.t.common.next;
  } else buttonDisplay = i18n.t.common.skip;

  return (
    <form {...form} className={layoutCss.form}>
      <UserOnboardingSlideLayout>
        <SafeAreaSpacer position="top" />
        <div
          data-cy="UserOnboardingLocationSlide"
          className={`ion-padding-horizontal ${layoutCss.media}`}
        >
          <img
            className={css.illustration}
            src={`/assets/illustrations/${i18n.t.UserOnboardingLocationSlide.illustrationFileName}`}
            alt={i18n.t.UserOnboardingLocationSlide.illustrationAlt}
          />
        </div>
        <div className={layoutCss.content}>
          <h2>
            <IonText color="aubergine-text">
              <strong>{i18n.t.UserOnboardingLocationSlide.headline}</strong>
            </IonText>
          </h2>
          <p>{i18n.t.UserOnboardingLocationSlide.explanation}</p>
          {sortedTeamLocations[0] && sortedTeamLocations.length === 1 && (
            <IonItem
              className="ion-no-padding"
              lines={isPlatform("ios") ? undefined : "full"}
            >
              <IonLabel className="ion-padding-start">
                {formatTeamLocation(sortedTeamLocations[0].data())}
              </IonLabel>
            </IonItem>
          )}
          {sortedTeamLocations.length > 1 && (
            <FormSingleSelect
              {...fields.teamLocationId}
              label={i18n.t.UserOnboardingLocationSlide.teamLocationLabel}
              interface="action-sheet"
              options={sortedTeamLocations.map((teamLocation) => ({
                value: teamLocation.id,
                label: `${teamLocation.data().streetName} ${
                  teamLocation.data().streetNumber
                }`,
              }))}
              buttonLabels={i18n.t.common}
            />
          )}
          <FormSingleSelect
            {...fields.floor}
            label={i18n.t.UserOnboardingLocationSlide.floorLabel}
            data-cy="UserOnboardingLocationSlide-select"
            options={makeEmptyArray(32).map((_, i) => {
              const floorNumber = i - 1;
              return {
                value: floorNumber,
                label: i18n.t.common.floorName(floorNumber),
              };
            })}
            buttonLabels={i18n.t.common}
          />
          {availableBuildingPartOptions.length > 0 && (
            <FormSingleSelect
              {...fields.building}
              label={i18n.t.UserOnboardingLocationSlide.buildingLabel}
              data-cy="UserOnboardingLocationSlide-select"
              options={availableBuildingPartOptions}
              buttonLabels={i18n.t.common}
            />
          )}
          <FormOptionalTextInput
            {...fields.extraLocationData}
            autoCapitalize="sentences"
            data-cy="UserOnboardingLocationSlide-extraLocationData"
            label={i18n.t.UserOnboardingLocationSlide.extraLocationDataLabel}
          />
        </div>
        <div className={`${layoutCss.footer} ${layoutCss.buttons}`}>
          <IonButton
            color="aubergine-text"
            size="large"
            fill="clear"
            data-cy="UserOnboardingLocationSlide-goBack"
            onClick={() => swiper.slidePrev()}
          >
            {i18n.t.common.back}
          </IonButton>

          <IonButton
            type="submit"
            color={formHasChanges() ? "aubergine-fill" : "aubergine-text"}
            fill={formHasChanges() ? "solid" : "clear"}
            size="large"
            data-cy="UserOnboardingLocationSlide-submit"
            disabled={isSubmitting}
          >
            {buttonDisplay}
          </IonButton>
        </div>
      </UserOnboardingSlideLayout>
    </form>
  );
};

export default UserOnboardingLocationSlide;
