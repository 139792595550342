import firebase from "firebase/compat/app";
import { ChannelBackingStorage } from "../firestore/ChannelBackingStorage";
import { DirectMessageChannel } from "../firestore/DirectMessageChannel";
import { TeamChannel } from "../firestore/TeamChannel";

export default function isStreamBackedChannel(
  channel:
    | firebase.firestore.QueryDocumentSnapshot<TeamChannel>
    | firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>,
): boolean {
  return channel.data().backingStorage === ChannelBackingStorage.Stream;
}
