import { BuildingPart } from "./BuildingPart";
import {
  getDictionary,
  getNumber,
  getOptionalNumber,
  getOptionalString,
  getOptionalValue,
  isBoolean,
  isPermittedValue,
  StrictDocumentData,
} from "./dataHelpers";
import isBuildingPart from "./isBuildingPart";
import {
  suggestionBannerNames,
  VisibleSuggestionBanners,
} from "./VisibleSuggestionBanners";

export type TeamUserProfile = Readonly<{
  onboardingVersionCompleted: number;
  floor: number | null;
  building: BuildingPart | null;
  extraLocationData: string | null;
  teamLocationId: string | null;
  visibleSuggestionBanners: VisibleSuggestionBanners;
}>;

export function teamUserProfileToFirestoreData(
  teamUserProfile: TeamUserProfile,
): TeamUserProfile {
  return teamUserProfile;
}

export function firestoreDataToTeamUserProfile(
  data: StrictDocumentData,
): TeamUserProfile {
  return {
    onboardingVersionCompleted: getNumber(data.onboardingVersionCompleted),
    floor: getOptionalNumber(data.floor),
    building: getOptionalValue(data.building, {
      typeCheck: isBuildingPart,
      fallback: null,
    }),
    extraLocationData: getOptionalString(data.extraLocationData),
    teamLocationId: getOptionalString(data.teamLocationId),
    visibleSuggestionBanners: getDictionary(data.visibleSuggestionBanners, {
      isKey: isPermittedValue(suggestionBannerNames),
      isValue: isBoolean,
      fallback: { "Grow your community": false },
    }),
  };
}
