export const floorNameById = new Map<number, string>([
  [-1, "1.UG"],
  [0, "EG"],
]);

const maxFloor = 30;
for (let i = 1; i <= maxFloor; i += 1) {
  floorNameById.set(i, `${i}.OG`);
}

Object.freeze(floorNameById);

export function floorName(id: number): string {
  return floorNameById.get(id) || "";
}
