import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getOptionalValue, getValue } from "../dataHelpers";
import {
  serverTimestamp,
  SpecificTimestampValue,
  TimestampValue,
} from "../TimestampValue";

export function isFirestoreAppTimestamp(
  value: unknown,
): value is firebase.firestore.Timestamp {
  return value instanceof firebase.firestore.Timestamp;
}

export function getFirestoreAppTimestampValue(
  value: unknown,
  { fallback }: { fallback: TimestampValue } = {
    fallback: {
      type: "timestamp",
      nanoseconds: 0,
      seconds: 0,
    },
  },
): TimestampValue {
  const appTimestamp = getValue(value, {
    typeCheck: isFirestoreAppTimestamp,
    fallback: undefined,
  });

  if (appTimestamp === undefined) return fallback;

  return {
    type: "timestamp",
    nanoseconds: appTimestamp.nanoseconds,
    seconds: appTimestamp.seconds,
  };
}

export function getOptionalFirestoreAppTimestampValue(
  value: unknown,
  { fallback }: { fallback: TimestampValue | null } = {
    fallback: null,
  },
): TimestampValue | null {
  const appTimestamp = getOptionalValue(value, {
    typeCheck: isFirestoreAppTimestamp,
    fallback: undefined,
  });

  if (appTimestamp === undefined) return fallback;
  if (appTimestamp === null) return null;

  return {
    type: "timestamp",
    nanoseconds: appTimestamp.nanoseconds,
    seconds: appTimestamp.seconds,
  };
}

export function convertTimestampValueToFirebaseAppTimestamp(
  value: TimestampValue,
): firebase.firestore.Timestamp | firebase.firestore.FieldValue {
  if (value !== "serverTimestamp" && value.type !== "timestamp") {
    throw new Error(`Received a value which is not a TimestampValue: ${value}
This can happen if the value has already been converted to its Firestore representation and the conversion process is applied a second time`);
  }

  if (value === serverTimestamp) {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  return new firebase.firestore.Timestamp(value.seconds, value.nanoseconds);
}

export function makeTimestampValueFromFirestoreAppTimestamp(
  timestamp: firebase.firestore.Timestamp,
): SpecificTimestampValue {
  return {
    type: "timestamp",
    seconds: timestamp.seconds,
    nanoseconds: timestamp.nanoseconds,
  };
}
