import { isSet } from "@connectedliving/common/lib/firestore/dataHelpers";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import {
  directMessageChannelPath,
  teamLocationsPath,
  teamUserProfilePath,
  userProfilePath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import TeamUserProfileConverter from "@connectedliving/common/lib/firestore/TeamUserProfileConverter";
import UserProfileConverter from "@connectedliving/common/lib/firestore/UserProfileConverter";
import { buildingName } from "@connectedliving/common/lib/utilities/buildingName";
import { floorName } from "@connectedliving/common/lib/utilities/floorName";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  channelsListPageUrl,
  directMessageChannelMessagesPageUrl,
  editMyUserProfilePageUrl,
  ViewUserProfilePageUrlParams,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { chatbubbleOutline, createOutline } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router";
import commonCss from "src/common/common.module.css";
import IonItemIcon from "src/common/IonItemIcon";
import useBlockUserButtons from "src/common/useBlockUserButtons";
import useReportUserButton from "src/common/useReportUserButton";
import UserIsBlockedBanner from "src/common/UserIsBlockedBanner";
import useScreenTracking from "src/firebase/useScreenTracking";
import createDirectMessageChannel from "src/state/firebase/createDirectMessageChannel";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TeamUserPreferencesContainer from "src/state/team/TeamUserPreferencesContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { isDataAvailable, isErrored } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import useFirestoreDocument, {
  isNotFound,
} from "src/utilities/data/useFirestoreDocument";
import { languageName } from "src/utilities/languageName";
import ProfileImage from "src/utilities/ProfileImage";

export type ViewUserProfilePageProps = {
  teamId: string;
};

const ViewUserProfilePage: React.FC<ViewUserProfilePageProps> = () => {
  const { teamId, team } = TeamContextContainer.useContainer();
  const { userProfileId } = useParams<ViewUserProfilePageUrlParams>();
  const { authUser, firebaseApp } = FirebaseAppContainer.useContainer();
  const history = useHistory();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { directMessageChannels } = FirestoreChannelsContainer.useContainer();
  const { isUserBlocked } = TeamUserPreferencesContainer.useContainer();
  const i18n = I18nContainer.useContainer();

  const userProfile = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(userProfilePath({ userId: userProfileId }))
      .withConverter(UserProfileConverter),
  );
  const teamUserProfile = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(teamUserProfilePath({ teamId, userId: userProfileId }))
      .withConverter(TeamUserProfileConverter),
  );
  const teamLocations = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(teamLocationsPath({ teamId }))
      .withConverter(TeamLocationConverter),
  );

  const onSendDirectMessageClick = useCallback(async () => {
    setIsRedirecting(true);
    assertPresent(authUser, { because: "<App> waits for user to log in" });

    const existingDirectMessageChannel = directMessageChannels.find(
      (channel) => channel.id === userProfileId,
    );
    if (!existingDirectMessageChannel) {
      await createDirectMessageChannel({
        firebaseApp,
        teamId,
        userId: authUser.uid,
        otherUserId: userProfileId,
        backingStorage: team.data().channelBackingStorage,
      });

      await firebaseApp
        .firestore()
        .doc(
          directMessageChannelPath({
            teamId,
            userId: authUser.uid,
            otherUserId: userProfileId,
          }),
        )
        .withConverter(DirectMessageChannelConverter)
        .get();
    }

    history.replace(
      directMessageChannelMessagesPageUrl({
        teamId,
        authUserId: authUser.uid,
        otherUserId: userProfileId,
      }),
    );
    setIsRedirecting(false);
  }, [
    authUser,
    directMessageChannels,
    firebaseApp,
    history,
    team,
    teamId,
    userProfileId,
  ]);

  const isSupportUser =
    userProfileId === requireEnvVar("REACT_APP_SUPPORT_USER_ID");

  const userProfileData = userProfile.data?.data();

  const formattedName = userProfileData ? formatFullName(userProfileData) : "";

  const screenName = "ViewUserProfilePage";
  useScreenTracking(screenName, teamId);

  const blockUserButtons = useBlockUserButtons({
    targetUserId: userProfileId,
    screenName,
    targetUserName: formattedName,
    teamId,
  });

  const reportUserButton = useReportUserButton({
    targetUserId: userProfileId,
    screenName,
    targetUserName: formattedName,
    teamId,
  });

  if (
    (isDataAvailable(userProfile) && isNotFound(userProfile.data)) ||
    isErrored(userProfile)
  ) {
    return <Redirect to={channelsListPageUrl({ teamId })} push={false} />;
  }

  const userIsSelf = userProfileId === authUser?.uid;

  const teamUserProfileData = teamUserProfile.data?.data();

  const languageDisplayString =
    userProfileData && isSet(userProfileData?.language)
      ? languageName(userProfileData.language)
      : i18n.t.ViewUserProfilePage.noLanguageSelected;

  const teamLocation =
    teamUserProfileData &&
    isSet(teamUserProfileData.teamLocationId) &&
    isDataAvailable(teamLocations) &&
    teamLocations.data.docs.find(
      ({ id }) => id === teamUserProfileData.teamLocationId,
    );

  const buildingDisplayString =
    teamUserProfileData &&
    isSet(teamUserProfileData.building) &&
    buildingName(teamUserProfileData.building);

  const floorDisplayString =
    teamUserProfileData &&
    isSet(teamUserProfileData.floor) &&
    floorName(teamUserProfileData.floor);

  const extraLocationDataDisplayString =
    teamUserProfileData &&
    isSet(teamUserProfileData.extraLocationData) &&
    teamUserProfileData.extraLocationData;

  function renderUserLocationDetails() {
    return (
      <>
        {floorDisplayString && (
          <IonCard className={commonCss.card}>
            <IonItem>
              <IonLabel>
                <p>{i18n.t.ViewUserProfilePage.labels.floor}</p>
                <div data-cy="ViewUserProfilePage-floor-value">
                  {floorDisplayString}
                </div>
              </IonLabel>
            </IonItem>
          </IonCard>
        )}

        {buildingDisplayString && (
          <IonCard className={commonCss.card}>
            <IonItem>
              <IonLabel>
                <p>{i18n.t.ViewUserProfilePage.labels.building}</p>
                <div>{buildingDisplayString}</div>
              </IonLabel>
            </IonItem>
          </IonCard>
        )}

        {extraLocationDataDisplayString && (
          <IonCard className={commonCss.card}>
            <IonItem>
              <IonLabel>
                <p>{i18n.t.ViewUserProfilePage.labels.extraLocationData}</p>
                <div data-cy="ViewUserProfilePage-extraLocationData-value">
                  {extraLocationDataDisplayString}
                </div>
              </IonLabel>
            </IonItem>
          </IonCard>
        )}

        {teamLocation && (
          <IonCard className={commonCss.card}>
            <IonItem>
              <IonLabel>
                <p>{i18n.t.ViewUserProfilePage.labels.address}</p>

                <div data-cy="ViewUserProfilePage-address-value">
                  {i18n.t.TeamAdminPage.location(teamLocation.data())}
                </div>
              </IonLabel>
            </IonItem>
          </IonCard>
        )}
      </>
    );
  }

  function supportUserContent() {
    return (
      <IonItem>
        <p>
          <em>{i18n.t.ViewUserProfilePage.messageVisibilityNotification()}</em>
        </p>
      </IonItem>
    );
  }

  return (
    <IonPage data-cy="ViewProfilePage">
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={channelsListPageUrl({ teamId })}
              data-cy="ViewUserProfileBage-BackButton"
            />
          </IonButtons>
          <IonTitle>{i18n.t.ViewUserProfilePage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={commonCss.contentContainer}>
        <div className={`ion-padding-top ${commonCss.centerContent}`}>
          <IonAvatar className={commonCss.large}>
            <ProfileImage userProfile={userProfileData} />
          </IonAvatar>
          <h1>
            <strong>
              {isDataAvailable(userProfile) ? (
                <span data-cy="ViewUserProfilePage-name-value">
                  {formattedName}
                </span>
              ) : (
                <IonSkeletonText
                  animated
                  style={{ display: "inline-block", width: 100 }}
                />
              )}
            </strong>
          </h1>
        </div>

        <IonCard className={commonCss.card}>
          {userIsSelf ? (
            <IonItem
              detail={false}
              routerDirection="forward"
              data-cy="ViewUserProfilePage-EditProfilePageButton"
              routerLink={editMyUserProfilePageUrl({ teamId })}
            >
              <IonItemIcon
                className={commonCss.itemIconAvatar}
                slot="start"
                color="primary"
                icon={createOutline}
              />
              <IonText color="primary">
                {i18n.t.ViewUserProfilePage.edit}
              </IonText>
            </IonItem>
          ) : (
            !isUserBlocked(userProfileId) && (
              <IonItem
                disabled={userIsSelf || isRedirecting}
                button
                detail={false}
                onClick={onSendDirectMessageClick}
                data-cy="ViewUserProfilePage-SendDirectMessageButton"
              >
                <IonItemIcon
                  className={commonCss.itemIconAvatar}
                  color="primary"
                  slot="start"
                  icon={chatbubbleOutline}
                />
                <IonText color="primary">
                  {i18n.t.ViewUserProfilePage.sendDirectMessage}
                </IonText>
              </IonItem>
            )
          )}
        </IonCard>

        {isSupportUser && (
          <IonCard className={commonCss.card}>{supportUserContent()}</IonCard>
        )}

        {!isSupportUser && (
          <>
            {isUserBlocked(userProfileId) && (
              <UserIsBlockedBanner
                {...{ teamId }}
                blockedUserId={userProfileId}
              />
            )}

            {renderUserLocationDetails()}

            <IonCard className={commonCss.card}>
              <IonItem>
                <IonLabel>
                  <p>{i18n.t.ViewUserProfilePage.labels.preferredLanguage}</p>
                  {isDataAvailable(userProfile) ? (
                    languageDisplayString
                  ) : (
                    <IonSkeletonText
                      animated
                      style={{ display: "inline-block", width: 100 }}
                    />
                  )}
                </IonLabel>
              </IonItem>
            </IonCard>
          </>
        )}

        <IonCard className={commonCss.card}>
          {blockUserButtons}
          {reportUserButton}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default ViewUserProfilePage;
