import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { directMessageChannelMessagesPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import MessageConverter from "@connectedliving/common/lib/firestore/MessageConverter";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import {
  ActiveUserTrackingProps,
  ChannelTrackingProps,
} from "@connectedliving/common/lib/TrackingEvent";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import assertExhausted from "@connectedliving/common/lib/utilities/lang/assertExhausted";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import { ChannelTrackingContext } from "@connectedliving/common/lib/utilities/tracking/channelTrackingProps";
import {
  directMessageChannelDetailPageUrl,
  viewUserProfilePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonText,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { warningOutline } from "ionicons/icons";
import React, { useMemo } from "react";
import ChannelsMessagesHeader from "src/channels/messages/ChannelsMessagesHeader";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import TranslationConfigContainer from "src/state/TranslationConfigContainer";
import UserIsBlockedBanner from "../../common/UserIsBlockedBanner";
import TeamUserProfilesContainer from "../../state/firebase/TeamUserProfilesContainer";
import I18nContainer from "../../state/i18n/I18nContainer";
import TeamUserPreferencesContainer from "../../state/team/TeamUserPreferencesContainer";
import { isDataAvailable } from "../../utilities/data/Loadable";
import useFirestoreCollection from "../../utilities/data/useFirestoreCollection";
import environment from "../../utilities/environment";
import { isErrorState } from "../../utilities/LoadingState";
import SafeAreaSpacer from "../../utilities/SafeAreaSpacer";
import FirestoreMessageComposer from "./firestore/FirestoreMessageComposer";
import FirestoreMessageList from "./firestore/FirestoreMessageList";
import FirestoreMessageListErrorScreen from "./firestore/FirestoreMessageListErrorScreen";
import FirestoreMessageListLoadingScreen from "./firestore/FirestoreMessageListLoadingScreen";
import useChannelScreenBehavior from "./firestore/useChannelScreenBehavior";

export type FirestoreDirectMessageChannelMessagesPageContentProps = {
  directMessageChannel: firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>;
  teamId: string;
  otherUserId: string;
};

const FirestoreDirectMessageChannelMessagesPageContent: React.FC<
  FirestoreDirectMessageChannelMessagesPageContentProps
> = ({ directMessageChannel, teamId, otherUserId }) => {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const i18n = I18nContainer.useContainer();

  const { findUserProfileById, loadingState: teamUserProfilesLoadingState } =
    TeamUserProfilesContainer.useContainer();
  const { showTranslation, setShowTranslation } =
    TranslationConfigContainer.useContainer();

  const channelDetailPageUrl = directMessageChannelDetailPageUrl({
    teamId,
    authUserId: authUser.uid,
    otherUserId,
  });

  const messagesPath = directMessageChannelMessagesPath({
    teamId,
    userId: authUser.uid,
    otherUserId,
  });

  const otherUserProfile = findUserProfileById(otherUserId);

  const messagesCollectionRef = firebaseApp
    .firestore()
    .collection(messagesPath)
    .withConverter(MessageConverter);
  const messagesCollection = useFirestoreCollection(
    messagesCollectionRef.orderBy("createdAt", "asc"),
  );

  const channelPath = directMessageChannel.ref.path;
  const directMessageChannelData = directMessageChannel.data();
  const { latestMessage } = directMessageChannelData;

  useChannelScreenBehavior({ channelPath, latestMessage });

  const { teamUserPreferences } = TeamUserPreferencesContainer.useContainer();
  const userIsBlocked = teamUserPreferences.data
    ?.data()
    ?.blockedUserIds.includes(otherUserId);

  const imageUrl = otherUserProfile?.data().imageUrl;
  const formattedName = otherUserProfile?.data()
    ? formatFullName(otherUserProfile?.data())
    : "";

  const channelTrackingContext: ChannelTrackingContext = useMemo(
    () => ({
      type: FirestoreChannelType.DirectMessage,
      id: directMessageChannel.ref.id,
      path: directMessageChannel.ref.path,
      data: directMessageChannelData,
      userIds: [authUser.uid, otherUserId],
    }),
    [
      authUser.uid,
      directMessageChannel.ref.id,
      directMessageChannel.ref.path,
      directMessageChannelData,
      otherUserId,
    ],
  );

  const messageListTrackingContext = useMemo(
    (): ChannelTrackingProps & ActiveUserTrackingProps => ({
      "team id": directMessageChannelData.teamId,
      "channel backing storage": directMessageChannelData.backingStorage,
      "channel type": "direct message",
      "channel id": directMessageChannel.id,
      "channel path": directMessageChannel.ref.path,
      "channel name": null,
      "channel purpose": null,
      "is support dm channel": [
        directMessageChannelData.userId,
        directMessageChannelData.otherUserId,
      ].includes(environment().supportUserId()),
      "is support user": authUser?.uid === environment().supportUserId(),
    }),
    [
      authUser?.uid,
      directMessageChannel.id,
      directMessageChannel.ref.path,
      directMessageChannelData.backingStorage,
      directMessageChannelData.otherUserId,
      directMessageChannelData.teamId,
      directMessageChannelData.userId,
    ],
  );

  function content() {
    if (userIsBlocked) {
      return (
        <UserIsBlockedBanner {...{ teamId }} blockedUserId={otherUserId} />
      );
    }

    if (messagesCollection.loadingState === "initialLoad") {
      return <FirestoreMessageListLoadingScreen />;
    }

    if (messagesCollection.loadingState === "networkUnavailable") {
      return (
        <FirestoreMessageListErrorScreen>
          <IonText className="ion-text-center">
            <IonIcon icon={warningOutline} size="large" />
            <br />
            {i18n.t.common.cantReachNetwork}
          </IonText>
        </FirestoreMessageListErrorScreen>
      );
    }

    if (isErrorState(messagesCollection.loadingState)) {
      return (
        <FirestoreMessageListErrorScreen>
          <IonText className="ion-text-center">
            <IonIcon icon={warningOutline} size="large" />
            <br />
            {String(messagesCollection.loadingState.error)}
          </IonText>
        </FirestoreMessageListErrorScreen>
      );
    }

    if (
      messagesCollection.loadingState === "updating" ||
      messagesCollection.loadingState === "ready"
    ) {
      return (
        <FirestoreMessageList
          channelType={FirestoreChannelType.DirectMessage}
          trackingContext={messageListTrackingContext}
          {...{
            teamId,
            messagesCollection: messagesCollection.data,
            findUserProfileById,
          }}
        />
      );
    }

    throw assertExhausted(messagesCollection.loadingState);
  }

  return (
    <>
      <IonHeader>
        <IonHeader>
          <ChannelsMessagesHeader
            channelPath={directMessageChannel.ref.path}
            channelType={StreamChannelType.DirectMessage}
            channelAvatar={
              imageUrl
                ? { type: "image", imageUrl }
                : {
                    type: "initialsAvatar",
                    name: formattedName,
                  }
            }
            title={formattedName}
            onShowTranslationChange={setShowTranslation}
            loadingState={teamUserProfilesLoadingState}
            titleRouterLink={viewUserProfilePageUrl({
              teamId,
              userProfileId: otherUserId,
            })}
            {...{
              teamId,
              showTranslation,
              channelDetailPageUrl,
              channelTrackingContext,
            }}
          />
        </IonHeader>
      </IonHeader>
      <IonContent forceOverscroll={false} scrollY={false}>
        {content()}
      </IonContent>
      <IonFooter>
        <FirestoreMessageComposer
          disabled={!isDataAvailable(messagesCollection)}
          {...{ channelPath }}
        />
        <SafeAreaSpacer position="bottom" />
      </IonFooter>
    </>
  );
};

export default FirestoreDirectMessageChannelMessagesPageContent;
