/* eslint-disable prefer-destructuring */
export function localeTagsMatch(tagA: string, tagB: string): boolean {
  const lowercaseA = tagA.toLocaleLowerCase();
  const lowercaseB = tagB.toLocaleLowerCase();
  return lowercaseA.startsWith(lowercaseB) || lowercaseB.startsWith(lowercaseA);
}

export function selectPreferredLocale<Locale extends { tag: string }>(
  preferredTags: readonly string[],
  availableLocales: readonly Locale[],
): Locale {
  const fallbackLocale = availableLocales[0];
  if (!fallbackLocale) throw new Error("No locales available");

  let selectedLocale;
  for (const preferredTag of preferredTags) {
    const matchingLocales = availableLocales.filter(({ tag }) =>
      localeTagsMatch(preferredTag, tag),
    );

    if (matchingLocales.length > 0) {
      selectedLocale = matchingLocales.sort((a, b) => {
        if (preferredTag === a.tag) {
          return -1;
        }
        if (preferredTag === b.tag) {
          return 1;
        }
        return b.tag.length - a.tag.length;
      })[0];
      break;
    }
  }

  return selectedLocale || fallbackLocale;
}
