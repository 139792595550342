import { isHttpsError } from "@connectedliving/common/lib/utilities/firestore/isHttpsError";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import { IonItem, IonText, useIonAlert, useIonToast } from "@ionic/react";
import { alertOutline } from "ionicons/icons";
import React, { useCallback } from "react";
import commonCss from "src/common/common.module.css";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import IonItemIcon from "./IonItemIcon";

type UseReportUserButton = React.ReactNode;

export default function useReportUserButton({
  screenName,
  teamId,
  targetUserId,
  targetUserName,
}: {
  screenName: string;
  targetUserId: string;
  targetUserName: string;
  teamId: string;
}): UseReportUserButton {
  const { authUser } = TeamContextContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { cloudFunctions } = FirebaseAppContainer.useContainer();
  const [presentAlert] = useIonAlert();
  const [presentToast] = useIonToast();
  const i18n = I18nContainer.useContainer();

  const onReportUserClick = useCallback(
    async ({ content }) => {
      try {
        await cloudFunctions.reportUser({
          targetUserId,
          teamId,
          content,
        });
      } catch (e) {
        dontAwait(
          presentToast({
            message: `${i18n.t.ReportUserButton.reportUserToast.error} (${
              isHttpsError(e) && e.code
            })`,
            duration: 5000,
          }),
        );
        throw e;
      }
      dontAwait(
        presentToast({
          message:
            i18n.t.ReportUserButton.reportUserToast.success(targetUserName),
          duration: 5000,
        }),
      );
    },
    [
      targetUserId,
      targetUserName,
      cloudFunctions,
      teamId,
      presentToast,
      i18n.t.ReportUserButton.reportUserToast,
    ],
  );

  const targetUserIsSelf = targetUserId === authUser.uid;
  const targetUserIsSupportUser =
    targetUserId === requireEnvVar("REACT_APP_SUPPORT_USER_ID");
  const carReportTargetUser = !targetUserIsSupportUser && !targetUserIsSelf;

  if (!carReportTargetUser) {
    return null;
  }

  return (
    <IonItem
      button
      detail={false}
      data-cy="ReportUserButton"
      onClick={() => {
        dontAwait(
          track({
            eventName: "Button Clicked",
            "screen name": screenName,
            "team id": teamId,
            "button name": "Report User",
          }),
        );
        dontAwait(
          presentAlert({
            header: i18n.t.ReportUserButton.alert.header(targetUserName),
            message: i18n.t.ReportUserButton.alert.message,
            buttons: [
              i18n.t.common.cancel,
              {
                text: i18n.t.common.confirm,
                handler: (data) => onReportUserClick(data),
              },
            ],
            inputs: [
              {
                name: "content",
                type: "textarea",
                placeholder: i18n.t.ReportUserButton.alert.inputPlaceholder,
              },
            ],
          }),
        );
      }}
    >
      <IonItemIcon
        className={commonCss.itemIconAvatar}
        color="primary"
        slot="start"
        icon={alertOutline}
      />
      <IonText color="primary">{i18n.t.ReportUserButton.buttonText}</IonText>
    </IonItem>
  );
}
