import { getOptionalNumber } from "@connectedliving/common/lib/firestore/dataHelpers";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import { IonSearchbar, IonText } from "@ionic/react";
import firebase from "firebase/compat/app";
import { compact } from "lodash";
import React, { useState } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import GroupedUserProfileList from "./GroupedUserProfileList";

function matchesSearchQuery(
  name: string,
  floor: number | null,
  searchQuery: string,
): boolean {
  return (
    name.toLocaleLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
    (floor !== null && floor.toString() === searchQuery)
  );
}

export type SearchableUserProfileListProps = {
  teamId: string;
  screenName: string;
  userProfiles: firebase.firestore.QueryDocumentSnapshot<UserProfile>[];
  idToTeamUserProfile: Record<
    string,
    firebase.firestore.QueryDocumentSnapshot<TeamUserProfile>
  >;
  teamLocations: firebase.firestore.QueryDocumentSnapshot<TeamLocation>[];
};

const SearchableUserProfileList: React.FC<SearchableUserProfileListProps> = ({
  userProfiles,
  teamId,
  screenName,
  teamLocations,
  idToTeamUserProfile,
}) => {
  const [searchText, setSearchText] = useState("");
  const supportUserId = requireEnvVar("REACT_APP_SUPPORT_USER_ID");

  const filteredUsers = userProfiles
    .filter((userProfile) => userProfile.id !== supportUserId)
    .filter((userProfile) => {
      const userProfileData = userProfile.data();
      const teamUserProfile = idToTeamUserProfile[userProfile.id];
      const floor = getOptionalNumber(teamUserProfile?.data()?.floor);

      return matchesSearchQuery(
        formatFullName(userProfileData),
        floor,
        searchText,
      );
    });
  const filteredTeamUserProfiles = compact(
    filteredUsers.map(({ id }) => idToTeamUserProfile[id]),
  );
  const i18n = I18nContainer.useContainer();

  return (
    <>
      <IonSearchbar
        value={searchText}
        onIonChange={(e) => setSearchText(e.detail.value || "")}
        debounce={0}
        placeholder={i18n.t.common.search}
      />

      {userProfiles.length === 0 ? (
        <div className="ion-margins ion-text-center">
          <p>
            <IonText color="medium">
              {i18n.t.SearchableUserProfileList.noResults}
            </IonText>
          </p>
        </div>
      ) : (
        <GroupedUserProfileList
          userProfiles={filteredUsers}
          teamUserProfiles={filteredTeamUserProfiles}
          {...{ screenName, teamId, teamLocations }}
        />
      )}
    </>
  );
};

export default SearchableUserProfileList;
