import { IonButton, IonCard, IonCardContent, IonIcon } from "@ionic/react";
import { reloadOutline } from "ionicons/icons";
import I18nContainer from "src/state/i18n/I18nContainer";
import css from "./ErrorCard.module.css";

type ErrorCardProps = {
  errorMessage: string;
  onRetryClick: React.MouseEventHandler<HTMLIonButtonElement>;
};

const ErrorCard: React.FC<ErrorCardProps> = ({
  errorMessage,
  onRetryClick,
}) => {
  const i18n = I18nContainer.useContainer();
  return (
    <IonCard>
      <IonCardContent className={css.card}>
        {errorMessage}
        <IonButton className="ion-align-self-center" onClick={onRetryClick}>
          <IonIcon slot="start" icon={reloadOutline} color="white" />
          {i18n.t.common.retry}
        </IonButton>
      </IonCardContent>
    </IonCard>
  );
};

export default ErrorCard;
