import {
  IonCardContent,
  IonCardHeader,
  IonContent,
  IonIcon,
  IonModal,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import css from "./MoreInfoModal.module.css";

export type MoreInfoModalProps = React.ComponentProps<typeof IonModal> & {
  closeModal: () => void;
  title?: string;
  body?: string | JSX.Element;
};

const MoreInfoModal: React.FC<MoreInfoModalProps> = ({
  isOpen,
  closeModal,
  title,
  body,
  ...restProps
}) => (
  <IonModal isOpen={isOpen} onDidDismiss={() => closeModal()} {...restProps}>
    <IonContent>
      <IonCardHeader className={css.modalHeader}>
        <h1>
          <strong>{title}</strong>
        </h1>
        <IonIcon
          icon={closeOutline}
          size="large"
          color="medium"
          onClick={() => closeModal()}
        />
      </IonCardHeader>
      <IonCardContent className={css.cardContent}>{body}</IonCardContent>
    </IonContent>
  </IonModal>
);

export default MoreInfoModal;
