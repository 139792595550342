import { teamPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamConverter from "@connectedliving/common/lib/firestore/TeamConverter";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  teamCreatedSuccessPageUrl,
  TeamCreatedSuccessPageUrlParams,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonImg,
  IonSpinner,
  IonText,
} from "@ionic/react";
import React, { useEffect } from "react";
import AppStoreButtons from "src/common/AppStoreButtons";
import commonCss from "src/common/common.module.css";
import OnboardingPageLayout from "src/share/OnboardingPageLayout";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import useFirestoreDocument from "src/utilities/data/useFirestoreDocument";
import useIonicParamsForRoute from "../utilities/ionic/useIonicParamsForRoute";
import css from "./TeamCreatedSuccessPage.module.css";

const TeamCreatedSuccessPage: React.FC = () => {
  const { teamId } = useIonicParamsForRoute<TeamCreatedSuccessPageUrlParams>(
    teamCreatedSuccessPageUrl({ teamId: ":teamId" }),
  );
  const { track } = MixpanelClientContainer.useContainer();
  const { firebaseApp } = FirebaseAppContainer.useContainer();

  const { loadingState, data: team } = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(teamPath({ teamId }))
      .withConverter(TeamConverter),
  );
  const teamData = team?.data();
  const screenName = "TeamCreatedSuccessPage";
  useEffect(() => {
    dontAwait(
      track({
        eventName: "Screen Viewed",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
  }, [teamId, track]);

  const i18n = I18nContainer.useContainer();
  if (loadingState !== "ready")
    return (
      <div
        className={`${commonCss.fullscreenContainer} ${commonCss.flexColumn}`}
      >
        <IonSpinner />
      </div>
    );

  return (
    <OnboardingPageLayout screenName={screenName}>
      <IonCard data-cy={screenName} className="ion-light-mode">
        <IonImg
          className={`${css.houseIllustration} `}
          src="/assets/illustrations/partyHouse.png"
          alt={i18n.t.TeamCreatedSuccessPage.illustrationAltText}
        />

        <IonCardContent>
          {teamData && (
            <>
              <IonCardSubtitle color="dark">
                <strong>{i18n.t.TeamCreatedSuccessPage.welcome}</strong>
              </IonCardSubtitle>
              <IonCardTitle
                className="ion-margin-bottom"
                color="aubergine-text"
              >
                <strong>{teamData.name}</strong>
              </IonCardTitle>
              <IonText color="dark">
                {i18n.t.TeamCreatedSuccessPage.communityIsReady}
              </IonText>
            </>
          )}
          <p className="ion-text-start">
            <AppStoreButtons {...{ screenName, teamId }} />
          </p>
        </IonCardContent>
      </IonCard>
    </OnboardingPageLayout>
  );
};

export default TeamCreatedSuccessPage;
