import {
  getBoolean,
  getList,
  getOptionalString,
  getOptionalStringLiteral,
  getOptionalValue,
  isString,
  StrictDocumentData,
} from "./dataHelpers";
import isUtmParams from "./isUtmParams";
import { LanguageCode, languages } from "./supportedLanguages";
import { UtmParams } from "./UtmParams";

export type UserProfile = Readonly<{
  firstName: string | null;
  lastName: string | null;
  language: LanguageCode | null;
  teamIds: string[];
  imageUrl: string | null;
  streamUserExists: boolean;
  isEmployee: boolean;
  utmParams: UtmParams | null;
  referrerUserId: string | null;
  referrerTeamId: string | null;
  createdTeamIds: string[];
}>;

export function userProfileToFirestoreData(
  userProfile: UserProfile,
): UserProfile {
  return userProfile;
}

export function firestoreDataToUserProfile(
  data: StrictDocumentData,
): UserProfile {
  return {
    firstName: getOptionalString(data.firstName),
    lastName: getOptionalString(data.lastName),
    imageUrl: getOptionalString(data.imageUrl),
    language: getOptionalStringLiteral(data.language, {
      permitted: languages,
    }),
    teamIds: getList(data.teamIds, { element: isString }),
    streamUserExists: getBoolean(data.streamUserExists, { fallback: true }),
    isEmployee: getBoolean(data.isEmployee, { fallback: false }),
    utmParams: getOptionalValue(data.utmParams, {
      typeCheck: isUtmParams,
      fallback: null,
    }),
    referrerUserId: getOptionalString(data.referrerUserId),
    referrerTeamId: getOptionalString(data.referrerTeamId),
    createdTeamIds: getList(data.createdTeamIds, { element: isString }),
  };
}
