import { serverTimestamp, TimestampValue } from "../firestore/TimestampValue";
import nowValueThatCanBeStubbedInTests from "./lang/nowValueThatCanBeStubbedInTests";

export default function convertTimestampValueToDate(
  timestampValue: TimestampValue,
): Date {
  if (
    timestampValue !== "serverTimestamp" &&
    timestampValue.type !== "timestamp"
  ) {
    throw new Error(`Received a value which is not a TimestampValue: ${timestampValue}
This can happen if the value has already been converted to its Firestore representation and the conversion process is applied a second time`);
  }

  if (timestampValue === serverTimestamp)
    return nowValueThatCanBeStubbedInTests();

  const dateValueInMilliseconds =
    timestampValue.seconds * 1000 + timestampValue.nanoseconds / 1000000;
  return new Date(dateValueInMilliseconds);
}
