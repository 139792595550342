import firebase from "firebase/compat/app";
import {
  firestoreDataToUserProfile,
  UserProfile,
  userProfileToFirestoreData,
} from "./UserProfile";

const UserProfileConverter: firebase.firestore.FirestoreDataConverter<UserProfile> =
  Object.freeze({
    toFirestore(userProfile: UserProfile) {
      return userProfileToFirestoreData(userProfile);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): UserProfile {
      return firestoreDataToUserProfile(snapshot.data(options));
    },
  });
export default UserProfileConverter;
