import { ArrayElement } from "../utilities/ArrayElement";
import { ChannelBackingStorage } from "./ChannelBackingStorage";
import { ChannelPurpose } from "./ChannelPurpose";
import { Message } from "./Message";
import supportedChannelIcons from "./supportedChannelIcons";
import { SpecificTimestampValue } from "./TimestampValue";

export const channelIcons = supportedChannelIcons;
export type ChannelIcon = ArrayElement<typeof channelIcons>;

export const channelIconColors = [
  "mango",
  "tangerine",
  "salmon-pink",
  "magenta",
  "sky-blue",
  "kiwi",
] as const;
export type ChannelIconColor = ArrayElement<typeof channelIconColors>;

export type TeamChannel = {
  teamId: string;
  name: string;
  createdBy: string;
  streamChannelId: string;
  channelIcon: ChannelIcon;
  channelIconColor: ChannelIconColor;
  purpose: ChannelPurpose | null;
  autoAddNewTeamMembers: boolean;
  backingStorage: ChannelBackingStorage;
  latestMessage: Message | null;
  messageTimestamps: SpecificTimestampValue[];
};
