import queryString from "query-string";

export type MarketingSiteConfig = {
  baseUrl: string;
  supportEmail: string;
};

export type UtmParams = {
  utm_medium?: string;
  utm_source?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  utm_item?: string;
};

export function marketingSiteRootUrl(
  { baseUrl }: MarketingSiteConfig,
  queryParams: UtmParams & {
    referrerUserId?: string;
    referrerTeamId?: string;
  } = {},
): string {
  return queryString.stringifyUrl({
    url: baseUrl,
    query: queryParams,
  });
}

export function marketingSiteGetStartedUrl(
  { baseUrl }: MarketingSiteConfig,
  queryParams: UtmParams & {
    referrerUserId?: string;
    referrerTeamId?: string;
  } = {},
): string {
  return queryString.stringifyUrl({
    url: `${baseUrl}/get-started`,
    query: queryParams,
  });
}

export function marketingSiteJoinUrl(
  { baseUrl }: MarketingSiteConfig,
  queryParams: UtmParams & {
    inviteCode: string;
    referrerUserId?: string;
    referrerTeamId?: string;
    teamName: string;
    teamId: string;
  },
): string {
  return queryString.stringifyUrl({
    url: `${baseUrl}/join`,
    query: queryParams,
  });
}

export function marketingSitePrivacyPolicyUrl(
  { baseUrl }: MarketingSiteConfig,
  queryParams: UtmParams = {},
): string {
  return queryString.stringifyUrl({
    url: `${baseUrl}/privacy-policy`,
    query: queryParams,
  });
}

export function marketingSiteTermsAndConditionsUrl(
  { baseUrl }: MarketingSiteConfig,
  queryParams: UtmParams = {},
): string {
  return queryString.stringifyUrl({
    url: `${baseUrl}/terms-and-conditions`,
    query: queryParams,
  });
}
