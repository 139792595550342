import { sortBy } from "lodash";
import { FormSelectOption } from "src/common/form/FormSingleSelect";

export default function sortFormSelectOptionsAlphabetically<T>(
  formSelectOptions: FormSelectOption<T>[],
): FormSelectOption<T>[] {
  return sortBy(
    formSelectOptions,
    (formSelectOption) => formSelectOption.label,
  );
}
