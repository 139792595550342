import { useEffect } from "react";
import { StreamChannelWithLoadingState } from "src/state/stream/StreamChannelsCacheContainer";
import useForceUpdate from "src/utilities/react/useForceUpdate";
import { EventTypes } from "stream-chat";

export default function useStreamEventsThatAffectSortOrder(
  streamChannels: StreamChannelWithLoadingState[],
): void {
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    const eventsThatAffectSortOrder: EventTypes[] = [
      "message.new",
      "message.read",
    ];
    streamChannels.forEach((streamChannel) => {
      eventsThatAffectSortOrder.forEach((event) =>
        streamChannel.value.on(event, forceUpdate),
      );
    });

    return () => {
      streamChannels.forEach((streamChannel) => {
        eventsThatAffectSortOrder.forEach((event) =>
          streamChannel.value.off(event, forceUpdate),
        );
      });
    };
  }, [forceUpdate, streamChannels]);
}
