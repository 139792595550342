import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonAvatar,
  IonButton,
  IonCardSubtitle,
  IonCardTitle,
  IonSpinner,
  IonText,
  useIonToast,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import { close } from "ionicons/icons";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import updateUserProfileAndTeamProfile from "src/userProfiles/updateUserAndTeamUserProfiles";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import ProfileImage from "src/utilities/ProfileImage";
import { useSwiperSlide } from "swiper/react";
import SafeAreaSpacer from "../utilities/SafeAreaSpacer";
import css from "./UserOnboardingFinalSlide.module.css";
import UserOnboardingSlideLayout from "./UserOnboardingSlideLayout";
import layoutCss from "./UserOnboardingSlideLayout.module.css";

type UserOnboardingFinalSlideProps = EmptyObject;

const UserOnboardingFinalSlide: React.FC<
  UserOnboardingFinalSlideProps
> = () => {
  const { track } = MixpanelClientContainer.useContainer();
  const { team, teamId, userProfile, teamUserProfile } =
    TeamContextContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const swiperSlide = useSwiperSlide();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [presentToast, dismissToast] = useIonToast();

  const currentOnboardingVersion = 2;

  const onDoneClick = useCallback(async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    try {
      await updateUserProfileAndTeamProfile({
        teamUserProfile: {
          originalTeamUserProfile: teamUserProfile,
          updatedTeamUserProfile: {
            onboardingVersionCompleted: currentOnboardingVersion,
          },
        },
        track,
        teamId,
      });
    } catch (error) {
      setIsSubmitting(false);
      const message = `${i18n.t.common.genericError} ${get(error, "message")}`;
      Sentry.captureException(error);
      dontAwait(
        presentToast({
          message,
          color: "danger",
          duration: 5000,
          position: "bottom",
          buttons: [
            {
              icon: close,
              role: "cancel",
              handler: () => dismissToast(),
            },
          ],
        }),
      );
    }
  }, [
    dismissToast,
    i18n.t.common.genericError,
    isSubmitting,
    presentToast,
    teamId,
    teamUserProfile,
    track,
  ]);

  useEffect(() => {
    if (swiperSlide.isVisible) {
      dontAwait(
        track({
          eventName: "Screen Viewed",
          "team id": teamId,
          "screen name": "UserOnboardingFinalSlide",
        }),
      );
    }
  }, [swiperSlide.isVisible, teamId, track]);

  return (
    <UserOnboardingSlideLayout>
      <SafeAreaSpacer position="top" />
      <div
        className={`${layoutCss.content} ${css.content}`}
        data-cy="UserOnboardingFinalSlide"
      >
        <div className="ion-margin-vertical">
          <IonCardSubtitle>
            <strong>{i18n.t.UserOnboardingFinalSlide.welcome}</strong>
          </IonCardSubtitle>
          <IonCardTitle className="ion-no-margin" color="aubergine-text">
            <strong>{team.data().name}</strong>
          </IonCardTitle>
          <IonText color="dark">
            {i18n.t.UserOnboardingFinalSlide.headerSubtext}
          </IonText>
        </div>

        <div className={`${css.illustrationWrapper} ion-text-center`}>
          <IonAvatar className={css.userProfileAvatar}>
            <ProfileImage userProfile={userProfile.data()} />
          </IonAvatar>
          <img
            className={css.houseImage}
            src="/assets/illustrations/house.png"
            alt={i18n.t.UserOnboardingFinalSlide.illustrationAltText}
          />
        </div>

        <h2 className="ion-padding-top ion-text-center">
          {i18n.t.UserOnboardingFinalSlide.welcomeText(
            userProfile.data().firstName,
          )}
        </h2>
      </div>

      <div className={`${layoutCss.footer} ${layoutCss.buttons}`}>
        <IonButton
          expand="block"
          size="large"
          data-cy="UserOnboardingFinalSlide-done"
          color="aubergine-fill"
          onClick={onDoneClick}
        >
          {isSubmitting ? (
            <IonSpinner />
          ) : (
            i18n.t.UserOnboardingFinalSlide.buttonText
          )}
        </IonButton>
      </div>
    </UserOnboardingSlideLayout>
  );
};

export default UserOnboardingFinalSlide;
