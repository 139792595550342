import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonItem,
  IonModal,
  IonSpinner,
  IonText,
  isPlatform,
} from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import { useState } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import SafeAreaSpacer from "src/utilities/SafeAreaSpacer";
import commonCss from "./common.module.css";
import css from "./HowToInviteNeighborsModal.module.css";
import InfoCard, { InfoCardText } from "./InfoCard";

export type InviteNeighborsOptionCardProps = {
  name:
    | "printAtHome"
    | "shareInviteLink"
    | "requestStarterKit"
    | "shareInviteCode"
    | "shareQrCode";
  onClick: () => void;
  isLoading?: boolean;
  index: number;
};

const InviteNeighborsOptionCard: React.FC<InviteNeighborsOptionCardProps> = ({
  name,
  onClick: clickHandler,
  isLoading,
  index,
}) => {
  const i18n = I18nContainer.useContainer();
  const hasTipBanner = i18n.t.HowToInviteNeighborsModal.options[name].tipBanner;
  return (
    <InfoCard
      color="aubergine-fill"
      onClick={clickHandler}
      className={`${css.optionCard} ion-margin-horizontal`}
      cardTitle={
        <>
          <div>
            <IonCardSubtitle className={css.superTitle}>
              {i18n.t.HowToInviteNeighborsModal.superTitle(index)}
            </IonCardSubtitle>
            <IonCardTitle>
              <strong>
                {i18n.t.HowToInviteNeighborsModal.options[name].title}
              </strong>
            </IonCardTitle>
          </div>
          {hasTipBanner && (
            <div className={css.tipBanner}>
              <IonIcon icon={hasTipBanner.icon} />
              <IonText>
                <strong>{hasTipBanner.text}</strong>
              </IonText>
            </div>
          )}
        </>
      }
    >
      <InfoCardText>
        {isLoading ? (
          <div className={commonCss.flexCenter}>
            <IonSpinner />
          </div>
        ) : (
          i18n.t.HowToInviteNeighborsModal.options[name].body
        )}
      </InfoCardText>
    </InfoCard>
  );
};

export type HowToInviteNeighborsModalProps = React.ComponentProps<
  typeof IonModal
> & {
  screenName: string;
  teamId: string;
  dismissModal: () => void;
  onShareInviteCodeClick: () => void;
  onShareLinkClick: () => void;
  onCopyLinkClick: () => void;
  onRequestStarterKitClick: () => void;
  onPrintAtHomeClick: () => void;
  onShareQrCodeClick: () => void;
};

const HowToInviteNeighborsModal: React.FC<HowToInviteNeighborsModalProps> = ({
  isOpen,
  screenName,
  teamId,
  dismissModal,
  onShareInviteCodeClick,
  onShareLinkClick,
  onCopyLinkClick,
  onRequestStarterKitClick,
  onPrintAtHomeClick,
  onShareQrCodeClick,
}) => {
  const i18n = I18nContainer.useContainer();
  const [modalCompleted, setModalCompleted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { track } = MixpanelClientContainer.useContainer();

  const onModalDismiss = () => {
    dismissModal();
    dontAwait(
      track({
        eventName: modalCompleted ? "Modal Completed" : "Modal Aborted",
        "modal name": "HowToInviteNeighborsModal",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
    setModalCompleted(false);
  };

  return (
    <IonModal
      isOpen={isOpen}
      canDismiss
      handle={false}
      onWillDismiss={onModalDismiss}
      breakpoints={[0, 1]}
      initialBreakpoint={1}
      className={commonCss.modalCard}
    >
      <IonContent className="ion-padding-bottom">
        <IonItem lines="none" class="ion-margin-top">
          <h1>
            <strong>{i18n.t.HowToInviteNeighborsModal.title}</strong>
          </h1>
          <IonIcon
            className={`${commonCss.itemIconAvatar} ${commonCss.closeIcon}`}
            color="medium"
            size="large"
            slot="end"
            icon={closeOutline}
            onClick={onModalDismiss}
          />
        </IonItem>
        <IonItem lines="none">
          <IonText color="dark">
            {i18n.t.HowToInviteNeighborsModal.body()}
          </IonText>
        </IonItem>
        <InviteNeighborsOptionCard
          name="printAtHome"
          onClick={() => {
            setIsLoading(true);
            setModalCompleted(true);
            onPrintAtHomeClick();
          }}
          isLoading={isLoading}
          index={1}
        />
        <InviteNeighborsOptionCard
          name="requestStarterKit"
          onClick={() => {
            setModalCompleted(true);
            onRequestStarterKitClick();
          }}
          index={2}
        />
        <InviteNeighborsOptionCard
          name="shareInviteLink"
          onClick={() => {
            setModalCompleted(true);
            if (isPlatform("capacitor")) {
              onShareLinkClick();
            } else onCopyLinkClick();
          }}
          index={3}
        />
        <InviteNeighborsOptionCard
          name="shareInviteCode"
          onClick={() => {
            setModalCompleted(true);
            onShareInviteCodeClick();
          }}
          index={4}
        />
        <InviteNeighborsOptionCard
          name="shareQrCode"
          onClick={() => {
            setModalCompleted(true);
            onShareQrCodeClick();
          }}
          index={5}
        />
        <SafeAreaSpacer position="bottom" />
      </IonContent>
    </IonModal>
  );
};

export default HowToInviteNeighborsModal;
