import { get } from "lodash";
import isErrorWithProps from "../utilities/lang/isErrorWithProps";
import { streamRateLimitExceeded } from "./streamApiConstants";

export default async function respectRateLimit<T>(
  performRequest: () => Promise<T>,
): Promise<T> {
  try {
    return await performRequest();
  } catch (error) {
    if (!isErrorWithProps(error, ["response"])) throw error;

    const status: unknown = get(error.response, "status");

    if (status === streamRateLimitExceeded) {
      const rateLimitHeader: unknown = get(
        error.response,
        "headers.x-ratelimit-reset",
      );

      let blockedUntilTimestamp;
      if (typeof rateLimitHeader === "string") {
        blockedUntilTimestamp = parseInt(rateLimitHeader, 10);
      } else if (typeof rateLimitHeader === "number") {
        blockedUntilTimestamp = rateLimitHeader;
      } else {
        throw error;
      }

      const timeUntilUnblocked = blockedUntilTimestamp * 1000 - Date.now();

      await new Promise((resolve) => {
        setTimeout(resolve, timeUntilUnblocked);
      });
      return respectRateLimit(performRequest);
    }
    throw error;
  }
}
