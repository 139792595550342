import { isSet } from "@connectedliving/common/lib/firestore/dataHelpers";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import { ChannelTrackingContext } from "@connectedliving/common/lib/utilities/tracking/channelTrackingProps";
import { channelsListPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonIcon,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import * as allIcons from "ionicons/icons";
import { useCallback } from "react";
import { useHistory } from "react-router";
import ChannelAvatarContent, {
  ChannelAvatarContentProps,
} from "src/common/ChannelAvatarContent";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import useUnreadCountInOtherChannels from "src/state/stream/useOtherChannelUnreadCounts";
import TeamContextContainer from "src/state/TeamContextContainer";
import environment from "src/utilities/environment";
import { dataAvailable, LoadingState } from "src/utilities/LoadingState";
import css from "./ChannelsMessagesHeader.module.css";
import MessageTranslationButton from "./MessageTranslationButton";

type ChannelsMessagesHeaderProps = {
  channelType: StreamChannelType;
  channelPath: string;
  channelAvatar: ChannelAvatarContentProps["content"];
  title: string;
  teamId: string;
  showTranslation: boolean;
  onShowTranslationChange: (value: boolean) => void;
  loadingState: LoadingState;
  channelDetailPageUrl: string;
  titleRouterLink?: string;
  channelTrackingContext: ChannelTrackingContext;
};

const ChannelsMessagesHeader: React.FC<ChannelsMessagesHeaderProps> = ({
  channelType,
  channelPath,
  channelAvatar,
  teamId,
  showTranslation,
  onShowTranslationChange,
  loadingState,
  title,
  channelDetailPageUrl,
  titleRouterLink,
  channelTrackingContext,
}) => {
  const { unreadCountInOtherChannels, loadingState: unreadCountLoadingState } =
    useUnreadCountInOtherChannels(channelType, channelPath);
  const history = useHistory();
  const { userProfile, userProfileDocumentRef } =
    LoggedInUserProfileContainer.useContainer();
  const { team } = TeamContextContainer.useContainer();

  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });
  assertPresent(userProfileDocumentRef, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  const backButtonText =
    dataAvailable(unreadCountLoadingState) && unreadCountInOtherChannels > 0
      ? unreadCountInOtherChannels.toString()
      : "";

  const onTitleClick = useCallback(() => {
    if (titleRouterLink) {
      history.push(titleRouterLink);
    }
  }, [titleRouterLink, history]);

  const isSupportUser =
    userProfileDocumentRef.id === environment().supportUserId();

  const channelTitle = isSupportUser ? (
    <>
      {title} - <strong>{team.data().name}</strong>
    </>
  ) : (
    title
  );

  return (
    <IonToolbar data-cy="ChannelsMessagesHeader">
      <IonButtons slot="start">
        <IonBackButton
          defaultHref={channelsListPageUrl({ teamId })}
          data-cy="ChannelsMessagesPage-BackButton"
          text={backButtonText}
        />
      </IonButtons>
      <IonTitle
        className={[
          titleRouterLink && css.clickable,
          "cy-ChannelMessagesHeader-title",
        ].join(" ")}
        onClick={onTitleClick}
      >
        {dataAvailable(loadingState) ? (
          <div className={css.channelTitleWrapper}>
            <IonAvatar
              className={`${css.channelAvatar} ${
                channelAvatar.type === "icon" ? css.noIconOverflow : ""
              }`}
            >
              <ChannelAvatarContent content={channelAvatar} />
            </IonAvatar>
            <div className={css.channelTitle}>{channelTitle}</div>
          </div>
        ) : (
          <IonSkeletonText
            animated
            style={{ display: "inline-block", width: 100 }}
          />
        )}
      </IonTitle>

      <IonButtons slot="end">
        {isSet(userProfile.language) && (
          <MessageTranslationButton
            onShowTranslationChange={onShowTranslationChange}
            showTranslation={showTranslation}
            {...{ channelTrackingContext }}
            userProfile={userProfile}
            teamId={teamId}
          />
        )}
        <IonButton
          data-cy="ChannelsMessagesHeader-infoButton"
          routerLink={channelDetailPageUrl}
          routerDirection="forward"
        >
          <IonIcon icon={allIcons.informationCircleOutline} />
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default ChannelsMessagesHeader;
