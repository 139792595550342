import { ChannelIconColor } from "@connectedliving/common/lib/firestore/TeamChannel";
import { IonIcon, IonImg } from "@ionic/react";
import classnames from "classnames";
import * as allIcons from "ionicons/icons";
import React from "react";
import FallbackProfileImage, {
  FallBackProfileImageProps,
} from "src/utilities/FallbackProfileImage";
import constructImgixUrl from "../utilities/constructImgixUrl";
import css from "./ChannelAvatarContent.module.css";

export type ChannelAvatarContentProps = {
  content:
    | {
        type: "icon";
        icon: keyof typeof allIcons | null;
        iconColor: ChannelIconColor;
      }
    | { type: "image"; imageUrl: string }
    | ({ type: "initialsAvatar" } & FallBackProfileImageProps);
};

const ChannelAvatarContent: React.FC<ChannelAvatarContentProps> = ({
  content,
}) => {
  switch (content.type) {
    case "image":
      return (
        <IonImg
          src={constructImgixUrl(content.imageUrl, { w: 400, h: 400 })}
          alt="Channel Avatar"
          className={css.fill}
        />
      );
    case "icon":
      return (
        <div className={classnames(css.fill, `ion-color-${content.iconColor}`)}>
          {content.icon && (
            <IonIcon icon={allIcons[content.icon]} className={css.avatarIcon} />
          )}
        </div>
      );
    default:
      return <FallbackProfileImage name={content.name} />;
  }
};

export default ChannelAvatarContent;
