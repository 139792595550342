import firebase from "firebase/compat/app";
import { mapValues } from "lodash";
import { isFirestoreAppTimestamp } from "./app/firestoreAppTimestamp";
import { getOptionalValue } from "./dataHelpers";
import { LastReadTimestamps } from "./LastReadTimestamps";

type LastReadTimestampsFirestore = Readonly<{
  [channelId: string]: firebase.firestore.Timestamp;
}>;

const LastReadTimestampsConverter: firebase.firestore.FirestoreDataConverter<LastReadTimestamps> =
  Object.freeze({
    toFirestore(lastReadTimestamps: LastReadTimestamps) {
      return mapValues(lastReadTimestamps, (value) =>
        value ? firebase.firestore.Timestamp.fromDate(value) : null,
      );
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot<LastReadTimestampsFirestore>,
      options: firebase.firestore.SnapshotOptions,
    ): LastReadTimestamps {
      return mapValues(snapshot.data(options), (value: unknown) => {
        const timestamp = getOptionalValue(value, {
          typeCheck: isFirestoreAppTimestamp,
          fallback: null,
        });
        return timestamp?.toDate();
      });
    },
  });

export default LastReadTimestampsConverter;
