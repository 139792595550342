import { FindTeamsWithPlaceIdMatch } from "@connectedliving/common/lib/cloudFunctions/FindTeamsWithPlaceId";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  channelsListPageUrl,
  checkMyAddressPageUrl,
  checkMyAddressResultsPageUrl,
  JoinTeamPageParams,
  joinTeamPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonIcon,
  IonPage,
  IonRow,
  IonSkeletonText,
  IonText,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import { chevronBack, close } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router";
import commonCss from "src/common/common.module.css";
import CommunityResultCardContent from "src/common/CommunityResultCardContent";
import ErrorCard from "src/common/ErrorCard";
import InfoCard, { InfoCardButton, InfoCardText } from "src/common/InfoCard";
import InviteCodeFormCard from "src/common/InviteCodeFormCard";
import LocaleSwitcher from "src/common/LocaleSwitcher";
import OnboardingActionSheetButton from "src/common/OnboardingActionSheetButton";
import SkeletonCard from "src/common/SkeletonCard";
import useEmailActionSheet from "src/common/useEmailActionSheet";
import useScreenTracking from "src/firebase/useScreenTracking";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import useIonicParamsForRoute from "src/utilities/ionic/useIonicParamsForRoute";
import SafeAreaSpacer from "src/utilities/SafeAreaSpacer";

type JoinTeamPageLocationState = { teamDetails: FindTeamsWithPlaceIdMatch };

const JoinTeamPage: React.FC = () => {
  const { teamId, placeId } = useIonicParamsForRoute<JoinTeamPageParams>(
    joinTeamPageUrl({ teamId: ":teamId", placeId: ":placeId" }),
  );
  const { authUser, cloudFunctions } = FirebaseAppContainer.useContainer();
  const screenName = "JoinTeamPage";
  const i18n = I18nContainer.useContainer();
  const supportEmail = requireEnvVar("REACT_APP_SUPPORT_EMAIL");
  const emailActionSheet = useEmailActionSheet(supportEmail);
  const { userProfile } = LoggedInUserProfileContainer.useContainer();

  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  const firstTeamId = userProfile.teamIds[0];

  const history = useHistory();

  const { state } = useLocation<JoinTeamPageLocationState | undefined>();

  const [teamDetails, setTeamDetails] = useState(state?.teamDetails);
  const [loadingFailed, setLoadingFailed] = useState(false);

  const [presentToast] = useIonToast();

  useEffect(() => {
    async function findMatchingTeam(googlePlaceIdToSearchFor: string) {
      const matchingTeams = await cloudFunctions.findTeamsWithPlaceId({
        googlePlaceId: googlePlaceIdToSearchFor,
      });
      return matchingTeams;
    }
    if (!teamDetails && !state) {
      findMatchingTeam(placeId)
        .then((results) => {
          const teamData = results.find((result) => result.id === teamId);
          if (!teamData) {
            Sentry.captureException(
              "Couldn't find a matching team in the results for supplied placeId and teamId",
            );
            dontAwait(
              presentToast({
                message: i18n.t.JoinTeamPage.errors.faultyUrlParams,
                color: "danger",
                duration: 5000,
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                  },
                ],
              }),
            );
            history.replace(checkMyAddressPageUrl());
          } else {
            setTeamDetails(teamData);
          }
        })
        .catch((error) => {
          Sentry.captureException(
            `Something went wrong fetching data on JoinTeamPage: ${error}`,
          );
          setLoadingFailed(true);
        });
    }
  }, [
    cloudFunctions,
    history,
    i18n.t.JoinTeamPage.errors.faultyUrlParams,
    placeId,
    presentToast,
    state,
    teamDetails,
    teamId,
  ]);

  useScreenTracking(screenName, teamId);

  let content;

  if (firstTeamId) {
    content = (
      <Redirect
        to={channelsListPageUrl({
          teamId: firstTeamId,
        })}
      />
    );
  } else if (!teamDetails && loadingFailed) {
    content = (
      <ErrorCard
        errorMessage={i18n.t.JoinTeamPage.errors.potentiallyOffline}
        onRetryClick={() => {
          window.location.reload();
        }}
      />
    );
  } else if (!teamDetails) {
    content = (
      <>
        <IonCardHeader>
          <IonCardSubtitle color="aubergine-text">
            <IonSkeletonText animated />
          </IonCardSubtitle>
          <IonCardTitle color="aubergine-text">
            <IonSkeletonText animated />
          </IonCardTitle>
        </IonCardHeader>
        <SkeletonCard />
        <SkeletonCard />
      </>
    );
  } else {
    content = (
      <>
        <IonCardHeader>
          <IonCardSubtitle color="aubergine-text">
            {i18n.t.JoinTeamPage.subtitle}
          </IonCardSubtitle>
          <IonCardTitle color="aubergine-text">{teamDetails.name}</IonCardTitle>
        </IonCardHeader>

        <CommunityResultCardContent
          createdAtMillisecondsTimestamp={
            teamDetails.createdAtMillisecondsTimestamp
          }
          numberOfMembers={teamDetails.numberOfMembers}
          numberOfApartments={teamDetails.numberOfApartments}
        >
          <IonRow>
            <IonCol>
              <p>
                <IonText color="aubergine-text">
                  {i18n.t.JoinTeamPage.paragraph}
                </IonText>
              </p>
            </IonCol>
          </IonRow>
        </CommunityResultCardContent>

        <InviteCodeFormCard title={i18n.t.JoinTeamPage.inviteCodeTitle} />

        <InfoCard
          cardTitle={
            <IonCardTitle>{i18n.t.JoinTeamPage.getHelpCardTitle}</IonCardTitle>
          }
        >
          <InfoCardText>{i18n.t.JoinTeamPage.getHelpCardText}</InfoCardText>
          <InfoCardButton
            data-cy={`${screenName}-getHelp-button`}
            onClick={() => emailActionSheet()}
          >
            {i18n.t.common.contactSupport}
          </InfoCardButton>
        </InfoCard>
      </>
    );
  }

  return (
    <IonPage id="main-content" className="ion-light-mode" data-cy={screenName}>
      <IonContent color="beige">
        <SafeAreaSpacer position="top" />
        <IonToolbar color="beige">
          <IonButtons slot="start">
            <IonButton
              onClick={(e) => {
                e.preventDefault();
                history.replace(checkMyAddressResultsPageUrl({ placeId }));
              }}
              color="dark"
            >
              <IonIcon slot="start" icon={chevronBack} />
              <span>{i18n.t.common.back}</span>
            </IonButton>
            <IonBackButton color="black" />
          </IonButtons>
          <IonButtons slot="primary">
            <LocaleSwitcher color="dark" screenName={screenName} />
            {authUser && (
              <OnboardingActionSheetButton
                authUser={authUser}
                screenName={screenName}
                color="dark"
              />
            )}
          </IonButtons>
        </IonToolbar>
        <div className={commonCss.onboardingContentContainer}>
          <div className={commonCss.limitMaxWidth}>{content}</div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default JoinTeamPage;
