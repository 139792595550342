import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  ActionSheetButton,
  useIonActionSheet,
  useIonAlert,
  useIonModal,
} from "@ionic/react";
import { useCallback, useMemo, useState } from "react";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import FullscreenSpinner from "./FullscreenSpinner";

const useDeleteAccountActionSheet = ({
  screenName,
  currentTeamId,
}: {
  screenName: string;
  currentTeamId?: string;
}): (() => Promise<void>) => {
  const [presentActionSheet, dismissActionSheet] = useIonActionSheet();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [presentSpinnerModal, dismissSpinnerModal] =
    useIonModal(FullscreenSpinner);
  const { authUser, cloudFunctions, logOut } =
    FirebaseAppContainer.useContainer();
  const { userProfile } = LoggedInUserProfileContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const onDeletionConfirmationClick = useCallback(
    async (deletionReason: string) => {
      if (isDeleting) return;
      setIsDeleting(true);
      if (!authUser) {
        setIsDeleting(false);
        throw new Error("Tried calling delete without being authenticated");
      }
      const userId = authUser.uid;
      const formattedName = userProfile
        ? formatFullName(userProfile)
        : "(Not completed user onboarding)";

      if (
        userProfile?.isEmployee ||
        userId === requireEnvVar("REACT_APP_SUPPORT_USER_ID")
      ) {
        setIsDeleting(false);
        throw new Error("You can't delete an employee account like this");
      }

      try {
        presentSpinnerModal({
          backdropDismiss: false,
          canDismiss: false,
        });
        await cloudFunctions.deleteUser({ userId }).then(async () => {
          await track({
            eventName: "User Account Deleted",
            "screen name": screenName,
            "initiator id": userId,
            "initiator name": formattedName,
            "user id": userId,
            "team id": currentTeamId,
            $user_id: userId,
            reason: deletionReason,
          });
          logOut();
        });
      } catch (error) {
        setIsDeleting(false);
        dismissSpinnerModal();
      }
    },
    [
      authUser,
      cloudFunctions,
      currentTeamId,
      dismissSpinnerModal,
      isDeleting,
      logOut,
      presentSpinnerModal,
      screenName,
      track,
      userProfile,
    ],
  );

  const presentConfirmationAlert = useCallback(
    async (
      deletionReason: keyof typeof i18n.t.DeleteAccountActionSheet.reasons,
    ) => {
      await presentAlert({
        header: i18n.t.DeleteAccountActionSheet.confirmationAlert.header,
        subHeader: i18n.t.DeleteAccountActionSheet.confirmationAlert.subHeader,
        buttons: [
          {
            text: i18n.t.common.cancel,
            role: "cancel",
            handler: dismissAlert,
          },
          {
            text: i18n.t.DeleteAccountActionSheet.confirmationAlert
              .confirmDeletionButton,
            role: "destructive",
            handler: async () => {
              await onDeletionConfirmationClick(deletionReason);
            },
          },
        ],
        backdropDismiss: false,
      });
    },
    [dismissAlert, i18n, onDeletionConfirmationClick, presentAlert],
  );

  const reasonButtons: ActionSheetButton[] = useMemo(
    () =>
      Object.entries(i18n.t.DeleteAccountActionSheet.reasons).map(
        ([key, value]) => ({
          text: value,
          handler: async () => {
            await presentConfirmationAlert(
              blindCast<
                keyof typeof i18n.t.DeleteAccountActionSheet.reasons,
                "we map the entries of the object, so the key value must be one of the keys"
              >(key),
            );
          },
        }),
      ),
    [i18n, presentConfirmationAlert],
  );

  const presentDeleteAccountActionSheet = useCallback(async () => {
    await presentActionSheet({
      buttons: [
        ...reasonButtons,
        {
          text: i18n.t.common.cancel,
          role: "cancel",
          handler: dismissActionSheet,
        },
      ],
      header: i18n.t.DeleteAccountActionSheet.header,
      backdropDismiss: true,
    });
  }, [
    dismissActionSheet,
    i18n.t.DeleteAccountActionSheet.header,
    i18n.t.common.cancel,
    presentActionSheet,
    reasonButtons,
  ]);

  return presentDeleteAccountActionSheet;
};

export default useDeleteAccountActionSheet;
