import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { isPlatform, useIonToast } from "@ionic/react";
import * as Sentry from "@sentry/react";
import React, { useCallback } from "react";
import { MessageInput, MessageInputProps } from "stream-chat-react";
import { DraftMessage } from "../../state/DraftMessagesContainer";
import I18nContainer from "../../state/i18n/I18nContainer";
import { EMPTY } from "../../utilities/createContainer";

export type CLMessageInputContextValue = {
  onChange(message: DraftMessage): void;
  draftMessage: DraftMessage | undefined;
};
const CLMessageInputContext = React.createContext<
  CLMessageInputContextValue | typeof EMPTY
>(EMPTY);

const CLMessageInputContextProvider: React.FC<CLMessageInputContextValue> = ({
  children,
  ...props
}) => (
  <CLMessageInputContext.Provider value={props}>
    {children}
  </CLMessageInputContext.Provider>
);

export function useCLMessageInputContext(): CLMessageInputContextValue {
  const value = React.useContext(CLMessageInputContext);
  if (value === EMPTY) {
    throw new Error("Component must be wrapped with <Container.Provider>");
  }
  return value;
}

export type CLMessageInputProps = {
  onChange(message: DraftMessage): void;
  draftMessage: DraftMessage | undefined;
};

const CLMessageInput: React.FC<CLMessageInputProps> = ({
  onChange,
  draftMessage,
}) => {
  const i18n = I18nContainer.useContainer();
  const [presentToast] = useIonToast();

  // Possibly we no longer need this since Stream displays its own error now
  const onMessageInputError = useCallback<
    NonNullable<MessageInputProps["errorHandler"]>
  >(
    (error, type, file) => {
      if (type === "upload-image") {
        dontAwait(
          presentToast({
            message: i18n.t.CLMessageInputSmall.couldNotUpload,
            duration: 5000,
            position: "top",
            color: "danger",
          }),
        );
      }
      Sentry.captureException(error, { extra: { file, type } });
    },
    [i18n.t.CLMessageInputSmall.couldNotUpload, presentToast],
  );

  return (
    <CLMessageInputContextProvider {...{ onChange, draftMessage }}>
      <MessageInput
        errorHandler={onMessageInputError}
        keycodeSubmitKeys={isPlatform("capacitor") ? [] : undefined}
        grow
      />
    </CLMessageInputContextProvider>
  );
};

export default CLMessageInput;
