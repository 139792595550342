import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { useIonViewDidEnter } from "@ionic/react";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";

export default function useScreenTracking(
  screenName: string,
  teamId: string | undefined,
): void {
  const { authUser } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();

  useIonViewDidEnter(() => {
    if (!authUser) {
      return;
    }

    dontAwait(
      track({
        eventName: "Screen Viewed",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
  });
}
