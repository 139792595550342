import {
  CreateMessageRequestBody,
  CreateMessageResponseBody,
} from "@connectedliving/common/lib/cloudFunctions/CreateMessageEndpoint";
import {
  CreateTeamRequestBody,
  CreateTeamResponseBody,
} from "@connectedliving/common/lib/cloudFunctions/CreateTeam";
import {
  DeleteUserRequest,
  DeleteUserResponse,
} from "@connectedliving/common/lib/cloudFunctions/DeleteUser";
import {
  FindTeamsWithPlaceIdRequestBody,
  FindTeamsWithPlaceIdResponseBody,
} from "@connectedliving/common/lib/cloudFunctions/FindTeamsWithPlaceId";
import {
  GeneratePrintAtHomeKitRequest,
  GeneratePrintAtHomeKitResponse,
} from "@connectedliving/common/lib/cloudFunctions/GeneratePrintAtHomeKit";
import { GetStreamUserTokenResponse } from "@connectedliving/common/lib/cloudFunctions/GetStreamUserToken";
import {
  JoinTeamRequest,
  JoinTeamResponse,
} from "@connectedliving/common/lib/cloudFunctions/JoinTeam";
import {
  RemoveUserFromTeamRequestBody,
  RemoveUserFromTeamResponse,
} from "@connectedliving/common/lib/cloudFunctions/RemoveUserFromTeam";
import { ReportUserRequestBody } from "@connectedliving/common/lib/cloudFunctions/ReportUser";
import { TrackEventRequest } from "@connectedliving/common/lib/cloudFunctions/TrackEvent";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import firebase from "firebase/compat/app";

const region = requireEnvVar("REACT_APP_FIREBASE_CLOUD_FUNCTIONS_REGION");

async function data<T = unknown>(
  result: Promise<firebase.functions.HttpsCallableResult>,
): Promise<T> {
  return (await result).data;
}

export type CloudFunctions = {
  getStreamUserToken(): Promise<GetStreamUserTokenResponse>;

  joinTeam(request: JoinTeamRequest): Promise<JoinTeamResponse>;

  reportUser(request: ReportUserRequestBody): Promise<void>;

  trackEvent(request: TrackEventRequest): Promise<void>;

  createTeam(request: CreateTeamRequestBody): Promise<CreateTeamResponseBody>;
  createMessage(
    request: CreateMessageRequestBody,
  ): Promise<CreateMessageResponseBody>;

  findTeamsWithPlaceId(
    request: FindTeamsWithPlaceIdRequestBody,
  ): Promise<FindTeamsWithPlaceIdResponseBody>;

  deleteUser(request: DeleteUserRequest): Promise<DeleteUserResponse>;

  removeUserFromTeam(
    request: RemoveUserFromTeamRequestBody,
  ): Promise<RemoveUserFromTeamResponse>;

  generatePrintAtHomeKit(
    request: GeneratePrintAtHomeKitRequest,
  ): Promise<GeneratePrintAtHomeKitResponse>;
};

function cloudFunctions(firebaseApp: firebase.app.App): CloudFunctions {
  return {
    getStreamUserToken() {
      return data(
        firebaseApp.functions(region).httpsCallable("getStreamUserToken")(),
      );
    },

    joinTeam(request: JoinTeamRequest) {
      return data(
        firebaseApp.functions(region).httpsCallable("joinTeam")(request),
      );
    },

    reportUser(request: ReportUserRequestBody) {
      return data(
        firebaseApp.functions(region).httpsCallable("reportUser")(request),
      );
    },

    trackEvent(request: TrackEventRequest) {
      return data(
        firebaseApp.functions(region).httpsCallable("trackEvent")(request),
      );
    },

    createTeam(request) {
      return data(
        firebaseApp.functions(region).httpsCallable("createTeam")(request),
      );
    },

    createMessage(request) {
      return data(
        firebaseApp.functions(region).httpsCallable("createMessage")(request),
      );
    },

    findTeamsWithPlaceId(request) {
      return data(
        firebaseApp.functions(region).httpsCallable("findTeamsWithPlaceId")(
          request,
        ),
      );
    },

    deleteUser(request) {
      return data(
        firebaseApp.functions(region).httpsCallable("deleteUser")(request),
      );
    },

    removeUserFromTeam(request) {
      return data(
        firebaseApp.functions(region).httpsCallable("removeUserFromTeam")(
          request,
        ),
      );
    },

    generatePrintAtHomeKit(request) {
      return data(
        firebaseApp.functions(region).httpsCallable("generatePrintAtHomeKit")(
          request,
        ),
      );
    },
  };
}

export default cloudFunctions;
