import VersionsConverter from "@connectedliving/common/lib/firestore/VersionsConverter";
import semverCompare from "@connectedliving/common/lib/utilities/semverCompare";
import { isPlatform } from "@ionic/core";
import {
  IonApp,
  IonButtons,
  IonCardTitle,
  IonContent,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import commonCss from "src/common/common.module.css";
import InfoCard, { InfoCardButton, InfoCardText } from "../common/InfoCard";
import LocaleSwitcher from "../common/LocaleSwitcher";
import css from "../share/OnboardingPageLayout.module.css";
import FirebaseAppContainer from "../state/firebase/FirebaseAppContainer";
import I18nContainer from "../state/i18n/I18nContainer";
import getNativeAppVersion, {
  NativeAppVersionData,
} from "../utilities/capacitor/getNativeAppVersion";
import useFirestoreDocument from "../utilities/data/useFirestoreDocument";
import environment from "../utilities/environment";

function isVersionSupported(
  currentVersion: string,
  {
    min: minimumSupportedVersion = "",
    max: maximumSupportedVersion = "",
  }: { min?: string | undefined; max?: string | undefined },
): boolean {
  if (!currentVersion) return true;

  if (
    minimumSupportedVersion &&
    semverCompare(currentVersion, minimumSupportedVersion) < 0
  )
    return false;
  if (
    maximumSupportedVersion &&
    semverCompare(currentVersion, maximumSupportedVersion) > 0
  )
    return false;

  return true;
}

const UpdateRequiredPage: React.FC = () => {
  const i18n = I18nContainer.useContainer();
  const appListingUrl = isPlatform("ios")
    ? environment().appStoreUrl()
    : environment().playStoreUrl();

  return (
    <IonApp>
      <IonPage>
        <IonContent className={commonCss.starburstBackground}>
          <div
            className={classNames(
              commonCss.flexColumn,
              commonCss.fullscreenContainer,
            )}
          >
            <IonToolbar className={css.toolbar}>
              <IonButtons slot="primary">
                <LocaleSwitcher screenName="UpdateRequiredPage" />
              </IonButtons>
            </IonToolbar>

            <div style={{ flexGrow: 1 }} />

            <InfoCard
              cardTitle={
                <IonCardTitle>{i18n.t.RequireUpdatedClient.title}</IonCardTitle>
              }
            >
              <InfoCardText>
                {i18n.t.RequireUpdatedClient.paragraph}
              </InfoCardText>

              <InfoCardButton href={appListingUrl} routerDirection="forward">
                {i18n.t.RequireUpdatedClient.updateButton}
              </InfoCardButton>
            </InfoCard>

            <div style={{ flexGrow: 1 }} />
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
};

export type RequireUpdatedClientProps = {
  children: ReactElement<any, any> | null;
};

const RequireUpdatedClient: React.FC<RequireUpdatedClientProps> = ({
  children,
}) => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const versionsDoc = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc("/Metadata/Versions")
      .withConverter(VersionsConverter),
  );
  const [nativeAppVersionData, setNativeAppVersionData] =
    useState<NativeAppVersionData | null>(null);
  useEffect(() => {
    getNativeAppVersion().then(setNativeAppVersionData);
  }, []);

  const versionsData = versionsDoc.data?.data();

  if (nativeAppVersionData) {
    const nativeAppIdentifier = `native ${nativeAppVersionData.platform}`;
    const currentNativeAppVersionNumber = nativeAppVersionData.version;

    const minSupportedLocalNativeAppVersion =
      environment().minSupportedLocalNativeAppVersions()[nativeAppIdentifier];
    if (
      !isVersionSupported(currentNativeAppVersionNumber, {
        min: minSupportedLocalNativeAppVersion,
      })
    ) {
      return <UpdateRequiredPage />;
    }

    const minimumSupportedGlobalNativeAppVersion =
      nativeAppVersionData &&
      versionsData?.minimumSupportedClientVersions[nativeAppIdentifier];
    if (
      minimumSupportedGlobalNativeAppVersion &&
      !isVersionSupported(currentNativeAppVersionNumber, {
        min: minimumSupportedGlobalNativeAppVersion,
      })
    ) {
      return <UpdateRequiredPage />;
    }
  }

  if (
    versionsData?.apiVersion !== undefined &&
    !isVersionSupported(versionsData.apiVersion, {
      max: environment().maxSupportedApiVersion(),
    })
  ) {
    return <UpdateRequiredPage />;
  }

  return children;
};

export default RequireUpdatedClient;
