import { useCallback, useState } from "react";
import { createContainer } from "src/utilities/createContainer";
import { StreamMessage } from "stream-chat-react";

export type DraftMessages = {
  setDraftMessage(channelPath: string, message: DraftMessage): void;
  getDraftMessage(channelPath: string): StreamMessage;
};

export type DraftMessage = Pick<
  StreamMessage,
  "attachments" | "mentioned_users" | "text"
>;

export function useDraftMessages(): DraftMessages {
  const [channelPathToStreamMessage, setChannelPathToStreamMessage] = useState<
    Record<string, DraftMessage>
  >({});

  const setDraftMessage = useCallback(
    (channelPath: string, draftMessage: DraftMessage) => {
      setChannelPathToStreamMessage((currentValue) => ({
        ...currentValue,
        [channelPath]: draftMessage,
      }));
    },
    [],
  );

  const getDraftMessage = useCallback(
    (channelPath: string) => {
      const draftMessage = channelPathToStreamMessage[channelPath] || {
        text: "",
        attachments: [],
        mentioned_users: [],
      };
      return { id: "", ...draftMessage };
    },
    [channelPathToStreamMessage],
  );

  return { setDraftMessage, getDraftMessage };
}

const DraftMessagesContainer = createContainer(useDraftMessages);
export default DraftMessagesContainer;
