import { FindTeamsWithPlaceIdResponseBody } from "@connectedliving/common/lib/cloudFunctions/FindTeamsWithPlaceId";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { formatGermanAddress } from "@connectedliving/common/lib/formatAddress";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  checkMyAddressResultsPageUrl,
  closedBetaPageUrl,
  getStartedPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonModal,
  IonRow,
  IonSpinner,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import { noop } from "lodash";
import React, { useState } from "react";
import { useHistory } from "react-router";
import AppStoreButtons from "src/common/AppStoreButtons";
import FormTextInput from "src/common/form/FormTextInput";
import InfoCard, { InfoCardButton, InfoCardText } from "src/common/InfoCard";
import SearchAddressModal from "src/common/SearchAddressModal";
import useScreenTracking from "src/firebase/useScreenTracking";
import OnboardingPageLayout from "src/share/OnboardingPageLayout";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import formatTeamLocation from "src/utilities/formatTeamLocation";

export type CheckMyAddressFormValues = {
  teamLocation: TeamLocation | null;
};

const CheckMyAddressPage: React.FC = () => {
  const i18n = I18nContainer.useContainer();
  const screenName = "CheckMyAddressPage";
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");

  useIonViewWillEnter(() => {
    setInputValue("");
  });

  const { cloudFunctions } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const history = useHistory();
  const [presentToast] = useIonToast();

  useScreenTracking(screenName, undefined);

  const searchAddressModalDismissHandler: React.ComponentProps<
    typeof IonModal
  >["onDidDismiss"] = async (event) => {
    let results: FindTeamsWithPlaceIdResponseBody = [];
    setShowModal(false);
    if (event.detail.data) {
      setInputValue(formatTeamLocation(event.detail.data));
      setIsSubmitting(true);
      try {
        results = await cloudFunctions.findTeamsWithPlaceId({
          googlePlaceId: event.detail.data.googlePlaceId,
        });
        setIsSubmitting(false);
        const {
          city,
          country,
          postcode,
          state,
          streetName,
          streetNumber,
          googlePlaceId,
        } = event.detail.data;
        dontAwait(
          track({
            eventName: "Search Teams by Location",
            "screen name": screenName,
            "results count": results.length,
            "query string": formatGermanAddress(event.detail.data) || "",
            "query city": city || "",
            "query country": country || "",
            "query postcode": postcode || "",
            "query state": state || "",
            "query streetName": streetName || "",
            "query streetNumber": streetNumber || "",
            "google place id": googlePlaceId || "",
          }),
        );
        history.push(
          checkMyAddressResultsPageUrl({
            placeId: event.detail.data.googlePlaceId,
          }),
          { teamLocation: event.detail.data, results },
        );
      } catch (error) {
        setIsSubmitting(false);
        await presentToast({
          color: "danger",
          message: i18n.t.common.errorMessage,
          duration: 500,
        });
      }
    }
  };
  return (
    <OnboardingPageLayout
      backButtonUrl={
        !process.env.REACT_APP_RESTRICTED_WEB_BUILD
          ? getStartedPageUrl()
          : undefined
      }
      screenName={screenName}
      title={i18n.t.CheckMyAddressPage.title}
      subtitle={i18n.t.CheckMyAddressPage.subtitle}
    >
      <SearchAddressModal
        screenName={screenName}
        placeholder={i18n.t.CheckMyAddressPage.cards.address.placeholder}
        submitText={i18n.t.CheckMyAddressPage.cards.address.modalSubmit}
        teamId={undefined}
        isOpen={showModal}
        onDidDismiss={searchAddressModalDismissHandler}
      />
      <IonCard
        onClick={() => setShowModal(true)}
        data-cy="CheckMyAddressPage-searchAddressModal"
      >
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <FormTextInput
                  insetItem={false}
                  className="ion-no-padding"
                  name="address"
                  id="address"
                  onChange={noop}
                  onBlur={noop}
                  errors={[]}
                  value={inputValue}
                  label={i18n.t.CheckMyAddressPage.cards.address.placeholder}
                  disabled={isSubmitting}
                  inputMode="none"
                />
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-end">
              <IonCol className="ion-align-self-end" size="auto">
                <IonButton
                  fill="solid"
                  data-cy="CheckMyAddressPage-submitButton"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <IonSpinner />
                  ) : (
                    i18n.t.CheckMyAddressPage.cards.address.button
                  )}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>

      <InfoCard
        cardTitle={
          <IonCardTitle>
            {i18n.t.CheckMyAddressPage.cards.inviteCode.title}
          </IonCardTitle>
        }
      >
        {process.env.REACT_APP_RESTRICTED_WEB_BUILD ? (
          <>
            <InfoCardText>
              {i18n.t.CheckMyAddressPage.cards.inviteCode.text}
            </InfoCardText>
            <AppStoreButtons
              color="aubergine-fill"
              screenName={screenName}
              className="ion-color-aubergine-fill"
              teamId={undefined}
            />
          </>
        ) : (
          <>
            <InfoCardText>
              {i18n.t.GetStartedPage.inviteCodeCard.paragraph}
            </InfoCardText>
            <InfoCardButton
              routerLink={closedBetaPageUrl()}
              routerDirection="forward"
            >
              {i18n.t.GetStartedPage.inviteCodeCard.button}
            </InfoCardButton>
          </>
        )}
      </InfoCard>
    </OnboardingPageLayout>
  );
};

export default CheckMyAddressPage;
