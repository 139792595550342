import { ArrayElement } from "../utilities/ArrayElement";

export const buildingParts = [
  "front-house",
  "back-house",
  "rear-house",
  "garden-house2",
  "garden-house",
  "house-wing",
  "house-wing-left",
  "house-wing-right",
  "side-house",
  "side-house-left",
  "side-house-right",
  "first-hof",
  "second-hof",
  "third-hof",
  "fourth-hof",
  "fifth-hof",
  "sixth-hof",
] as const;

export type BuildingPart = ArrayElement<typeof buildingParts>;
