import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { Message } from "@connectedliving/common/lib/firestore/Message";
import {
  ActiveUserTrackingProps,
  ChannelTrackingProps,
} from "@connectedliving/common/lib/TrackingEvent";
import classNames from "classnames";
import firebase from "firebase/compat/app";
import React, { useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import commonCss from "../../../common/common.module.css";
import { FindUserProfileById } from "../../../state/FindUserProfileById";
import { calculateChannelHistory } from "./ChannelHistoryItem";
import FirestoreMessageListItem from "./FirestoreMessageListItem";
import FirestoreMessageListTimeBoundaryItem from "./FirestoreMessageListTimeBoundaryItem";

export type FirestoreMessageListProps = {
  teamId: string;
  messagesCollection: firebase.firestore.QuerySnapshot<Message>;
  channelType: FirestoreChannelType;
  findUserProfileById: FindUserProfileById;
  trackingContext: ActiveUserTrackingProps & ChannelTrackingProps;
};

const FirestoreMessageList: React.FC<FirestoreMessageListProps> = ({
  teamId,
  messagesCollection,
  channelType,
  findUserProfileById,
  trackingContext,
}) => {
  const history = useMemo(
    () => calculateChannelHistory(messagesCollection.docs),
    [messagesCollection.docs],
  );

  const hideAllAvatars = channelType === FirestoreChannelType.DirectMessage;

  return (
    <Virtuoso
      className={classNames(
        commonCss.contentContainer,
        "ion-content-scroll-host ",
      )}
      style={{ height: "100%", backgroundColor: "var(--ion-background-color)" }}
      data={history}
      initialTopMostItemIndex={history.length}
      computeItemKey={(_index, item) => item.id}
      // eslint-disable-next-line react/no-unstable-nested-components
      itemContent={(_index, channelHistoryItem) =>
        channelHistoryItem.type === "message" ? (
          <FirestoreMessageListItem
            key={channelHistoryItem.id}
            message={channelHistoryItem.message}
            hideAllAvatars={hideAllAvatars}
            hideAvatar={hideAllAvatars || !channelHistoryItem.showAvatar}
            spaceBefore={channelHistoryItem.showAvatar}
            showTimestamp={channelHistoryItem.showTimestamp}
            {...{ teamId, findUserProfileById, trackingContext }}
          />
        ) : (
          <FirestoreMessageListTimeBoundaryItem
            channelHistoryDayBoundaryItem={channelHistoryItem}
          />
        )
      }
      followOutput="auto"
    />
  );
};

export default FirestoreMessageList;
