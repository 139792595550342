import {
  starterKitOptions,
  StarterKitSize,
} from "@connectedliving/common/lib/domain/StarterKitOptions";
import { getUrl } from "@connectedliving/common/lib/firestore/dataHelpers";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonButton,
  IonChip,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonModal,
} from "@ionic/react";
import classnames from "classnames";
import { closeOutline } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import starterKitLargeImage from "src/assets/images/starterKit-large.png";
import starterKitMediumImage from "src/assets/images/starterKit-medium.png";
import starterKitSmallImage from "src/assets/images/starterKit-small.png";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import SafeAreaSpacer from "../utilities/SafeAreaSpacer";
import commonCss from "./common.module.css";
import css from "./OrderStarterKitModal.module.css";

function initialStarterKitSize(numberOfApartments: number): StarterKitSize {
  let size: StarterKitSize = "medium";
  if (numberOfApartments < 20) size = "small";
  if (numberOfApartments > 40) size = "large";
  return size;
}

const starterKitAssets: Record<StarterKitSize, string> = {
  small: starterKitSmallImage,
  medium: starterKitMediumImage,
  large: starterKitLargeImage,
};

export type OrderStarterKitModalProps = React.ComponentProps<
  typeof IonModal
> & {
  screenName: string;
  dismissModal: () => void;
};

const OrderStarterKitModal: React.FC<OrderStarterKitModalProps> = ({
  screenName,
  dismissModal,
  isOpen,
}) => {
  const { t } = I18nContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { team, teamId } = TeamContextContainer.useContainer();
  const { numberOfApartments } = team.data();
  const [modalCompleted, setModalCompleted] = useState<boolean>(false);
  const [starterKitSize, setStarterKitSize] = useState<StarterKitSize>(
    initialStarterKitSize(numberOfApartments),
  );

  const { paypalUrl, numberOfInvitations, numberOfPosters, cost, shipping } =
    starterKitOptions[starterKitSize];

  const onPayClick = useCallback(async () => {
    const { url, domain, query } = getUrl(paypalUrl);

    setModalCompleted(true);

    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "Request Starter Kit",
        "team id": teamId,
        "screen name": screenName,
      }),
    );

    dontAwait(
      track({
        eventName: "External Link Opened",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Request Starter Kit",
        "destination name": "Paypal",
        "destination url": paypalUrl,

        "link text": t.OrderStarterKit.buttonText,
        "link url": url,
        "link domain": domain,
        "link query": query,
      }),
    );
    window.open(paypalUrl);
  }, [track, teamId, screenName, paypalUrl, t.OrderStarterKit.buttonText]);

  const onModalDismiss = useCallback(() => {
    dismissModal();
    dontAwait(
      track({
        eventName: modalCompleted ? "Modal Completed" : "Modal Aborted",
        "modal name": "OrderStarterKitModal",
        "screen name": screenName,
        "team id": teamId,
      }),
    );
    setModalCompleted(false);
  }, [dismissModal, modalCompleted, screenName, teamId, track]);

  return (
    <IonModal
      isOpen={isOpen}
      canDismiss
      handle={false}
      onWillDismiss={onModalDismiss}
      breakpoints={[0, 0.8, 1]}
      initialBreakpoint={0.8}
      className={commonCss.modalCard}
      showBackdrop
    >
      <IonItem lines="none" class="ion-margin-top">
        <h1>
          <strong>{t.OrderStarterKit.title}</strong>
        </h1>
        <IonIcon
          className={`${commonCss.itemIconAvatar} ${commonCss.closeIcon}`}
          color="medium"
          size="large"
          slot="end"
          icon={closeOutline}
          onClick={onModalDismiss}
        />
      </IonItem>
      <IonItem lines="none">
        <p>{t.OrderStarterKit.description}</p>
      </IonItem>
      <IonItem lines="none">
        <IonImg
          src={starterKitAssets[starterKitSize]}
          slot="start"
          className={css.starterKitImage}
        />
        <IonLabel>
          <h2>
            <strong>{t.OrderStarterKit.sizeLabel}</strong>
          </h2>
          <div className="ion-margin-bottom">
            {Object.values(starterKitOptions).map(({ size }) => (
              <IonChip
                key={size}
                className={classnames(css.chip, {
                  [css.selected!]: starterKitSize === size,
                })}
                onClick={() => setStarterKitSize(size)}
              >
                {t.OrderStarterKit.sizeOptions[size]}
              </IonChip>
            ))}
          </div>

          <h2>
            <strong>{t.OrderStarterKit.includesLabel}</strong>
          </h2>
          <ul className={css.orderedList}>
            <li>{t.OrderStarterKit.invitations(numberOfInvitations)}</li>
            <li>{t.OrderStarterKit.postersWithTape(numberOfPosters)}</li>
          </ul>

          <h2>
            <strong>{t.OrderStarterKit.priceLabel}</strong>
          </h2>
          <ul className={css.orderedList}>
            <li>{t.OrderStarterKit.price(cost)}</li>
            <li>{t.OrderStarterKit.shippingCost(shipping)}</li>
          </ul>
        </IonLabel>
      </IonItem>
      <IonButton
        className={classnames(css.button, "ion-margin-top")}
        fill="solid"
        expand="full"
        color="aubergine-fill"
        onClick={onPayClick}
        size="default"
      >
        {t.OrderStarterKit.buttonText}
      </IonButton>
      <SafeAreaSpacer position="bottom" />
    </IonModal>
  );
};

export default OrderStarterKitModal;
