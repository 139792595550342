import { SplashScreen } from "@capacitor/splash-screen";
import { FirstAppOpenEvent } from "@connectedliving/common/lib/TrackingEvent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  backofficePageUrl,
  channelsListPageUrl,
  checkMyAddressPageUrl,
  checkMyAddressResultsPageUrl,
  closedBetaPageUrl,
  getStartedPageUrl,
  joinTeamPageUrl,
  leaveTeamPageUrl,
  loginPageUrl,
  teamCreatedSuccessPageUrl,
  teamCreatePageUrl,
  teamUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonApp, IonRouterOutlet, IonSpinner, isPlatform } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import ClosedBetaPage from "src/closedBeta/ClosedBetaPage";
import commonCss from "src/common/common.module.css";
import FirebaseAppContainer, {
  useInitializedFirebaseApp,
} from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import SentryContainer from "src/state/SentryContainer";
import StreamChatContainer from "src/state/stream/StreamChatContainer";
import RequireUpdatedClient from "./app/RequireUpdatedClient";
import AuthPage from "./auth/AuthPage";
import ValuePropositionPage from "./auth/ValuePropositionPage";
import BackofficePage from "./backoffice/BackofficePage";
import FullscreenSpinner from "./common/FullscreenSpinner";
import CheckMyAddressPage from "./getStarted/CheckMyAddressPage";
import CheckMyAddressResultsPage from "./getStarted/CheckMyAddressResultsPage";
import GetStartedPage from "./getStarted/GetStartedPage";
import useAndroidHardwareBackButtonHandler from "./state/android/useAndroidHardwareBackButtonHandler";
import ClientInstanceContainer from "./state/ClientInstanceContainer";
import GoogleMapsApiContainer from "./state/GoogleMapsApiContainer";
import I18nContainer from "./state/i18n/I18nContainer";
import InitialUrlSearchParamsContainer from "./state/InitialUrlSearchParamsContainer";
import MixpanelClientContainer from "./state/mixpanel/MixpanelContainer";
import PushNotificationsContainer from "./state/PushNotificationsContainer";
import TranslationConfigContainer from "./state/TranslationConfigContainer";
import JoinTeamPage from "./teams/JoinTeamPage";
import TeamCreatedSuccessPage from "./teams/TeamCreatedSuccessPage";
import TeamCreatePage from "./teams/TeamCreatePage";
import TeamLeavePage from "./teams/TeamLeavePage";
import TeamPage from "./teams/TeamPage";
import environment from "./utilities/environment";
import RestrictedWebRoutes from "./web/RestrictedWebRoutes";

// eslint-disable-next-line no-console
console.info(
  "Connected Living environment:",
  process.env.REACT_APP_CONNECTED_LIVING_ENVIRONMENT,
);

const App: React.FC = () => {
  const { authUser } = FirebaseAppContainer.useContainer();
  const {
    userProfile: loggedInUserProfile,
    userProfileSnapshot: loggedInUserProfileSnapshot,
    userProfileLoadingState,
  } = LoggedInUserProfileContainer.useContainer();

  useAndroidHardwareBackButtonHandler();

  const { mixpanelClient } = MixpanelClientContainer.useContainer();
  const [trackingEventSent, setTrackingEventSent] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    dontAwait(SplashScreen.hide());
  }, []);

  const appJustOpened = history.length < 3;
  if (!trackingEventSent && appJustOpened) {
    const trackingEvent: FirstAppOpenEvent = {
      eventName: "$app_open",
    };
    const { eventName } = trackingEvent;
    mixpanelClient.track(eventName);
    setTrackingEventSent(true);
  }

  if (!authUser) {
    if (process.env.REACT_APP_RESTRICTED_WEB_BUILD) {
      return (
        <IonRouterOutlet>
          <Route
            path={checkMyAddressResultsPageUrl({ placeId: ":placeId" })}
            exact
            component={CheckMyAddressResultsPage}
          />
          <Route
            path={teamCreatePageUrl({ placeId: ":placeId" })}
            component={TeamCreatePage}
          />
          <Route path={loginPageUrl()} component={AuthPage} />
          <Route
            path={checkMyAddressPageUrl()}
            exact
            component={CheckMyAddressPage}
          />
          <Route render={() => <Redirect to={checkMyAddressPageUrl()} />} />
        </IonRouterOutlet>
      );
    }
    if (!isPlatform("capacitor")) {
      return <AuthPage />;
    }
    return (
      <Switch>
        <Route path={loginPageUrl()} component={AuthPage} />
        <Route component={ValuePropositionPage} />
      </Switch>
    );
  }

  if (
    userProfileLoadingState === "initialLoad" ||
    !loggedInUserProfile ||
    !loggedInUserProfileSnapshot
  ) {
    return (
      <div
        className={`${commonCss.fullscreenContainer} ${commonCss.flexColumn}`}
      >
        <IonSpinner color="primary" />
      </div>
    );
  }

  if (
    process.env.REACT_APP_RESTRICTED_WEB_BUILD &&
    !loggedInUserProfile.isEmployee
  ) {
    return <RestrictedWebRoutes />;
  }

  //----------------------------------------------------------------------------
  // WARNING: HERE BE DRAGONS! 🐉
  //
  // Read more here: https://www.notion.so/Ionic-Routing-fcc4e15010164081876a434912bf8f7f
  //
  // Ionic's router does a lot of heavy lifting for us to manage transitions
  // between pages, configure the back button to magicaly know where to take the
  // user, keep views alive after the user navigates away so they can be quickly
  // restored, etc.
  //
  // But to enable all that, there are some hoops that need jumping-through.
  //
  // WHAT YOU NEED TO KNOW RIGHT NOW:
  //   1. <IonRouterOutlet> implements page transitions; it must be present for
  //      page transitions to function
  //   2. <IonRouterOutlet> will render ALL child <Route>s whose paths match!
  //      Not just the first one!
  //   3. The direct children of <IonRouterOutlet> MUST ONLY be <Route>s. If you
  //      use anything else, page transitions will stop working
  //   4. A final <Redirect> has special behavior applied which is supposed to
  //      enable implementing handling "not found" behavior, but it's not
  //      documented except in this comment:
  //      https://github.com/ionic-team/ionic-framework/issues/20105#issuecomment-577863692
  //   5. If a <Redirect> route is present in a group of routes and it matches,
  //      it will strip the `routeParams` from the React Router context. So be
  //      very cautious using Ionic's "not found" route handler.
  //   6. Ionic does not support <Route>s being conditionally added to/removed
  //      from the UI tree
  //   7. Generally, always use routes of the form:
  //      <Route path={myPageUrl()} component={MyPage} />
  //      The other render methods have unexpected quirks.
  //
  // TL;DR unless you're very sure of yourself, don't touch these routes. Or, if
  // you get weird behavior, use the above list as a starting point for
  // debugging. And avoid the React Router hooks as much as possible.
  //
  // 💀
  //----------------------------------------------------------------------------

  return (
    <Switch>
      {/* THIS MUST REMAIN A <Switch> - NOT AN <IonRouterOutlet> - FOR THE
          "NOT FOUND" REDIRECT TO FUNCTION */}
      <Route path={closedBetaPageUrl()} component={ClosedBetaPage} />
      <Route
        path={teamCreatePageUrl({ placeId: ":placeId" })}
        exact
        component={TeamCreatePage}
      />
      <Route path={getStartedPageUrl()} component={GetStartedPage} />
      <Route
        path={teamCreatedSuccessPageUrl({ teamId: ":teamId" })}
        component={TeamCreatedSuccessPage}
      />
      <Route
        path={checkMyAddressResultsPageUrl({ placeId: ":placeId" })}
        exact
        component={CheckMyAddressResultsPage}
      />
      <Route
        path={joinTeamPageUrl({ teamId: ":teamId", placeId: ":placeId" })}
        exact
        component={JoinTeamPage}
      />
      <Route
        path={checkMyAddressPageUrl()}
        exact
        component={CheckMyAddressPage}
      />
      <Route
        path={leaveTeamPageUrl({ teamId: ":teamId" })}
        exact
        component={TeamLeavePage}
      />
      <Route path={teamUrl({ teamId: ":teamId" })} component={TeamPage} />
      <Route path={backofficePageUrl()} component={BackofficePage} />
      <Route
        render={() => {
          const teamId = loggedInUserProfile.teamIds[0];
          if (teamId) {
            return (
              <Redirect
                to={channelsListPageUrl({
                  teamId,
                })}
              />
            );
          }
          return <Redirect to={getStartedPageUrl()} />;
        }}
      />
    </Switch>
  );
};

const AppWithContainers: React.FC = () => {
  const firebaseApp = useInitializedFirebaseApp();
  const [history] = useState(() => createBrowserHistory());

  useEffect(() => {
    if (process.env.REACT_APP_CONNECTED_LIVING_ENVIRONMENT === "production") {
      Sentry.init({
        dsn: "https://beef18cd38444cfa9cad3f3cdb944991@o586674.ingest.sentry.io/5738328",
        integrations: [
          new Integrations.BrowserTracing({
            routingInstrumentation:
              Sentry.reactRouterV5Instrumentation(history),
          }),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.25,

        release: environment().releaseVersion(),

        // Source: https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
        ignoreErrors: [
          // Random plugins/extensions
          "top.GLOBALS",
          // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
          "originalCreateNotification",
          "canvas.contentDocument",
          "MyApp_RemoveAllHighlights",
          "http://tt.epicplay.com",
          "Can't find variable: ZiteReader",
          "jigsaw is not defined",
          "ComboSearch is not defined",
          "http://loading.retry.widdit.com/",
          "atomicFindClose",
          // Facebook borked
          "fb_xd_fragment",
          // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
          // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
          "bmi_SafeAddOnload",
          "EBCallBackMessageReceived",
          // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
          "conduitPage",
          // Generic error code from errors outside the security sandbox
          // You can delete this if using raven.js > 1.0, which ignores these automatically.
          "Script error.",
          // Avast extension error
          "_avast_submit",
          // Silence known error: https://sentry.io/organizations/thunderlabs-tech/issues/2368184021
          "Non-Error promise rejection captured with value: Request timed out. The device maybe offline.",
        ],
        denyUrls: [
          // Google Adsense
          /pagead\/js/i,
          // Facebook flakiness
          /graph\.facebook\.com/i,
          // Facebook blocked
          /connect\.facebook\.net\/en_US\/all\.js/i,
          // Woopra flakiness
          /eatdifferent\.com\.woopra-ns\.com/i,
          /static\.woopra\.com\/js\/woopra\.js/i,
          // Chrome extensions
          /extensions\//i,
          /^chrome:\/\//i,
          // Other plugins
          /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
          /webappstoolbarba\.texthelp\.com\//i,
          /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        ],
      });
    }
  }, [history]);

  return (
    <IonReactRouter {...{ history }}>
      <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
        <I18nContainer.Provider>
          <InitialUrlSearchParamsContainer.Provider>
            <MixpanelClientContainer.Provider>
              {!firebaseApp ? (
                <FullscreenSpinner />
              ) : (
                <FirebaseAppContainer.Provider initialState={{ firebaseApp }}>
                  <RequireUpdatedClient>
                    <ClientInstanceContainer.Provider>
                      <LoggedInUserProfileContainer.Provider>
                        <SentryContainer.Provider />

                        <TranslationConfigContainer.Provider>
                          <PushNotificationsContainer.Provider>
                            <StreamChatContainer.Provider>
                              <GoogleMapsApiContainer.Provider>
                                <IonApp>
                                  <App />
                                  {process.env.NODE_ENV !== "production" &&
                                    process.env
                                      .REACT_APP_CONNECTED_LIVING_ENVIRONMENT ===
                                      "production" && (
                                      <div
                                        style={{
                                          display: "fixed",
                                          zIndex: 1,
                                          background: "red",
                                          color: "white",
                                          textAlign: "center",
                                        }}
                                      >
                                        PRODUCTION
                                      </div>
                                    )}
                                </IonApp>
                              </GoogleMapsApiContainer.Provider>
                            </StreamChatContainer.Provider>
                          </PushNotificationsContainer.Provider>
                        </TranslationConfigContainer.Provider>
                      </LoggedInUserProfileContainer.Provider>
                    </ClientInstanceContainer.Provider>
                  </RequireUpdatedClient>
                </FirebaseAppContainer.Provider>
              )}
            </MixpanelClientContainer.Provider>
          </InitialUrlSearchParamsContainer.Provider>
        </I18nContainer.Provider>
      </Sentry.ErrorBoundary>
    </IonReactRouter>
  );
};

export default AppWithContainers;
