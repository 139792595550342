import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import * as Sentry from "@sentry/browser";
import { useEffect } from "react";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import getNativeAppVersion from "../utilities/capacitor/getNativeAppVersion";
import { createContainer } from "../utilities/createContainer";
import environment from "../utilities/environment";
import FirebaseAppContainer from "./firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "./firebase/LoggedInUserProfileContainer";

export function useSentry(): EmptyObject {
  const { authUser } = FirebaseAppContainer.useContainer();
  const { userProfile } = LoggedInUserProfileContainer.useContainer();

  useEffect(() => {
    if (!authUser || !userProfile) return;
    Sentry.setUser({
      id: authUser.uid,
      username: formatFullName(userProfile) || undefined,
      ip_address: "{{auto}}",
      teams: userProfile.teamIds.join(", "),
      isEmployee: userProfile.isEmployee,
    });

    getNativeAppVersion().then((appVersion) => {
      if (!appVersion) return;
      Sentry.setContext("app", {
        app_version: appVersion.version,
        app_build: appVersion.build,
        app_name: appVersion.name,
        app_identifier: appVersion.id,
        app_tag: appVersion.tag,
        deploy_target: environment().deployTarget(),
      });
    });
  }, [authUser, userProfile]);
  return {};
}

const SentryContainer = createContainer(useSentry);
export default SentryContainer;
