import { Dispatch, SetStateAction, useState } from "react";

/**
 * A hook which maintains a piece of state but if it's not present, returns a given default value.
 *
 * This is useful in situations where the `initialValue` isn't available when `useState()` is called,
 * e.g. when tracking the state of a form input but the object being edited isn't loaded yet.
 */
export default function useStateWithDefault<T>(
  initialValue: T,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] {
  const [result, setResult] = useState(initialValue);

  if (result === undefined && defaultValue !== undefined)
    setResult(defaultValue);

  return [result, setResult];
}
