import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import {
  directMessageChannelsPath,
  teamChannelsPath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import TeamChannelConverter from "@connectedliving/common/lib/firestore/TeamChannelConverter";
import TeamChannelMembershipConverter from "@connectedliving/common/lib/firestore/TeamChannelMembershipConverter";
import firebase from "firebase/compat/app";
import { compact } from "lodash";
import { useCallback, useEffect, useMemo } from "react";
import combineLoadingStates from "src/utilities/combineLoadingStates";
import { createContainer } from "src/utilities/createContainer";
import { isDataAvailable } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import { LoadingState } from "src/utilities/LoadingState";
import FirebaseAppContainer from "../firebase/FirebaseAppContainer";
import StreamChannelsCacheContainer from "../stream/StreamChannelsCacheContainer";
import TeamContextContainer from "../TeamContextContainer";

export type FirestoreChannels = {
  teamChannels: firebase.firestore.QueryDocumentSnapshot<TeamChannel>[];
  joinedTeamChannels: firebase.firestore.QueryDocumentSnapshot<TeamChannel>[];
  teamChannelsLoadingState: LoadingState;
  directMessageChannels: firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>[];
  directMessageChannelsLoadingState: LoadingState;
  loadingState: LoadingState;
  isUserMember: (firestoreChannelId: string) => boolean;
  getTeamChannelById: (
    firestoreChannelId: string,
  ) => firebase.firestore.QueryDocumentSnapshot<TeamChannel> | undefined;
  getDirectMessageChannelByUserId: (
    otherUserId: string,
  ) =>
    | firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>
    | undefined;
};

export function useFirestoreChannels(): FirestoreChannels {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { teamId, authUser } = TeamContextContainer.useContainer();
  const { loadStreamChannels } = StreamChannelsCacheContainer.useContainer();

  const teamChannelsCollection = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(teamChannelsPath({ teamId }))
      .withConverter(TeamChannelConverter),
  );
  const teamChannelMembershipsCollection = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collectionGroup("TeamChannelMemberships")
      .where("userId", "==", authUser.uid)
      .where("teamId", "==", teamId)
      .withConverter(TeamChannelMembershipConverter),
  );

  const directMessageChannelsCollection = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(directMessageChannelsPath({ teamId, userId: authUser.uid }))
      .withConverter(DirectMessageChannelConverter),
  );

  const teamChannels = useMemo(
    () =>
      isDataAvailable(teamChannelsCollection)
        ? teamChannelsCollection.data.docs
        : [],
    [teamChannelsCollection],
  );
  const teamChannelMemberships = useMemo(
    () =>
      isDataAvailable(teamChannelMembershipsCollection)
        ? teamChannelMembershipsCollection.data.docs
        : [],
    [teamChannelMembershipsCollection],
  );
  const directMessageChannels = useMemo(
    () =>
      isDataAvailable(directMessageChannelsCollection)
        ? directMessageChannelsCollection.data.docs
        : [],
    [directMessageChannelsCollection],
  );

  const getTeamChannelById = useCallback(
    (firestoreChannelId: string) =>
      teamChannels.find(({ id }) => id === firestoreChannelId),
    [teamChannels],
  );
  const getDirectMessageChannelByUserId = useCallback(
    (otherUserId: string) =>
      directMessageChannels.find(
        (doc) => doc.data().otherUserId === otherUserId,
      ),
    [directMessageChannels],
  );
  const isUserMember = useCallback(
    (firestoreChannelId: string): boolean => {
      const result = teamChannelMemberships.find(
        (doc) => doc.data().teamChannelId === firestoreChannelId,
      );
      return result !== undefined;
    },
    [teamChannelMemberships],
  );

  const joinedTeamChannels = compact(
    teamChannelMemberships.map((membership) =>
      teamChannels.find(
        (teamChannel) => teamChannel.id === membership.data().teamChannelId,
      ),
    ),
  );

  const loadingState = combineLoadingStates(
    teamChannelsCollection.loadingState,
    teamChannelMembershipsCollection.loadingState,
    directMessageChannelsCollection.loadingState,
  );

  useEffect(() => {
    loadStreamChannels(
      joinedTeamChannels.filter(
        (channel) => channel.data().backingStorage === "stream",
      ),
      directMessageChannels.filter(
        (channel) => channel.data().backingStorage === "stream",
      ),
    );
  }, [
    directMessageChannels,
    joinedTeamChannels,
    loadStreamChannels,
    loadingState,
  ]);

  return {
    teamChannels,
    teamChannelsLoadingState: teamChannelsCollection.loadingState,
    joinedTeamChannels,
    directMessageChannels,
    directMessageChannelsLoadingState:
      directMessageChannelsCollection.loadingState,
    loadingState,
    getTeamChannelById,
    isUserMember,
    getDirectMessageChannelByUserId,
  };
}

const FirestoreChannelsContainer = createContainer(useFirestoreChannels);
export default FirestoreChannelsContainer;
