import { mapValues } from "lodash";

type ObjectKey = string | number | symbol;

type IntlFormatFunctions<
  DateFormat extends ObjectKey,
  NumberFormat extends ObjectKey,
> = {
  formatDate(value: Date, format?: DateFormat): string;
  formatNumber(value: number, format?: NumberFormat): string;
};

export type IntlFormatFunctionsConfig = {
  date: Record<string, Partial<Intl.DateTimeFormatOptions>> & {
    default: Partial<Intl.DateTimeFormatOptions>;
  };
  number: Record<string, Partial<Intl.NumberFormatOptions>> & {
    default: Partial<Intl.NumberFormatOptions>;
  };
};

function configToIntlObjects<Config extends IntlFormatFunctionsConfig>(
  localeTag: string,
  config: Config,
): {
  date: { [key in keyof Config["date"]]: Intl.DateTimeFormat };
  number: { [key in keyof Config["number"]]: Intl.NumberFormat };
} {
  return {
    date: mapValues(
      config.date,
      (options) => new Intl.DateTimeFormat(localeTag, options),
    ) as { [key in keyof Config["date"]]: Intl.DateTimeFormat },

    number: mapValues(
      config.number,
      (options) => new Intl.NumberFormat(localeTag, options),
    ) as { [key in keyof Config["number"]]: Intl.NumberFormat },
  };
}

export function intlFormattersConfig<Config extends IntlFormatFunctionsConfig>(
  config: Config,
): Config {
  return config;
}

export function intlFormatters<Config extends IntlFormatFunctionsConfig>(
  localeTag: Intl.UnicodeBCP47LocaleIdentifier,
  config: Config,
): IntlFormatFunctions<keyof Config["date"], keyof Config["number"]> {
  const intlObjects = configToIntlObjects(localeTag, config);

  return {
    formatDate(value, format: keyof Config["date"] = "default") {
      return intlObjects.date[format].format(value);
    },
    formatNumber(value, format: keyof Config["number"] = "default") {
      return intlObjects.number[format].format(value);
    },
  };
}

export function intlFormatValueFunction(
  intlFormatFunctions: IntlFormatFunctions<ObjectKey, ObjectKey>,
): (value: unknown) => string | undefined {
  return (value) => {
    if (value instanceof Date) return intlFormatFunctions.formatDate(value);

    if (typeof value === "number")
      return intlFormatFunctions.formatNumber(value);

    return undefined;
  };
}
