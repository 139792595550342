import { teamUserPreferencesDocPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamUserPreferences } from "@connectedliving/common/lib/firestore/TeamUserPreferences";
import TeamUserPreferencesConverter from "@connectedliving/common/lib/firestore/TeamUserPreferencesConverter";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import { useCallback } from "react";
import { createContainer } from "src/utilities/createContainer";
import useFirestoreDocument, {
  FirestoreDocument,
} from "src/utilities/data/useFirestoreDocument";
import FirebaseAppContainer from "../firebase/FirebaseAppContainer";
import TeamContextContainer from "../TeamContextContainer";

export type TeamUserPreferencesValue = {
  teamUserPreferences: FirestoreDocument<TeamUserPreferences>;
  isUserBlocked(userId: string): boolean | undefined;
};

export function useTeamUserPreferences(initialState?: {
  teamId: string;
}): TeamUserPreferencesValue {
  assertPresent(initialState, { because: "must be provided" });
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { authUser } = TeamContextContainer.useContainer();

  const teamUserPreferences = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(
        teamUserPreferencesDocPath({
          teamId: initialState.teamId,
          userId: authUser.uid,
        }),
      )
      .withConverter(TeamUserPreferencesConverter),
  );

  const isUserBlocked = useCallback(
    (userId: string) => {
      const userIsBlocked = Boolean(
        teamUserPreferences.data?.data()?.blockedUserIds.includes(userId),
      );
      return userIsBlocked;
    },
    [teamUserPreferences.data],
  );

  return { teamUserPreferences, isUserBlocked };
}

const TeamUserPreferencesContainer = createContainer(useTeamUserPreferences);
export default TeamUserPreferencesContainer;
