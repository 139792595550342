/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Clipboard } from "@capacitor/clipboard";
import { Share } from "@capacitor/share";
import { getStringLiteral } from "@connectedliving/common/lib/firestore/dataHelpers";
import { languages } from "@connectedliving/common/lib/firestore/supportedLanguages";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import { directMessageChannelMessagesPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import { isPlatform } from "@ionic/core";
import {
  IonIcon,
  IonItem,
  IonModal,
  IonSpinner,
  IonText,
  useIonToast,
} from "@ionic/react";
import {
  close,
  closeOutline,
  downloadOutline,
  eyeOutline,
  giftOutline,
  helpCircleOutline,
  qrCodeOutline,
  shareOutline,
} from "ionicons/icons";
import { get, isError } from "lodash";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import InviteUrlsContainer from "src/state/InviteUrlsContainer";
import { MixpanelClient } from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import isShareCanceledError from "../utilities/capacitor/isShareCanceledError";
import commonCss from "./common.module.css";
import HowToInviteNeighborsModal from "./HowToInviteNeighborsModal";
import css from "./InviteNeighborModal.module.css";
import IonItemIcon from "./IonItemIcon";
import OrderStarterKitModal from "./OrderStarterKitModal";
import ShowInviteCodeModal from "./ShowInviteCodeModal";
import ShowQrCodeModal from "./ShowQrCodeModal";

export type InviteNeighborModalProps = React.ComponentProps<typeof IonModal> & {
  dismissInviteNeighborModal: () => void;
  inviteCode: string;
  track: MixpanelClient["track"];
  teamId: string;
  screenName: string;
  teamName: string;
};

const InviteNeighborModal: React.FC<InviteNeighborModalProps> = ({
  isOpen,
  dismissInviteNeighborModal,
  inviteCode,
  track,
  teamId,
  screenName,
  teamName,
}) => {
  const i18n = I18nContainer.useContainer();
  const {
    n2n: { copyLinkUrl, qrCodeUrl, osShareUrl },
  } = InviteUrlsContainer.useContainer();
  const [isOpenShowInviteCodeModal, setIsOpenShowInviteCodeModal] =
    useState<boolean>(false);
  const [isOpenShowQrCodeModal, setIsOpenShowQrCodeModal] =
    useState<boolean>(false);
  const [isOpenHowToInviteNeighborsModal, setIsOpenHowToInviteNeighborsModal] =
    useState<boolean>(false);
  const [isOpenOrderStarterKitModal, setIsOpenOrderStarterKitModal] =
    useState<boolean>(false);
  const [modalCompleted, setModalCompleted] = useState<boolean>(false);
  const [isLoadingPrintAtHome, setIsLoadingPrintAtHome] = useState(false);

  const { cloudFunctions } = FirebaseAppContainer.useContainer();
  const { authUser } = TeamContextContainer.useContainer();
  const supportUserId = requireEnvVar("REACT_APP_SUPPORT_USER_ID");
  const history = useHistory();

  const onInviteNeighborModalDismiss = useCallback(() => {
    dismissInviteNeighborModal();
    dontAwait(
      track({
        eventName: modalCompleted ? "Modal Completed" : "Modal Aborted",
        "modal name": "InviteNeighborModal",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
    setModalCompleted(false);
  }, [dismissInviteNeighborModal, modalCompleted, screenName, teamId, track]);

  const [presentToast] = useIonToast();

  const onShareLinkClick = useCallback(async () => {
    setModalCompleted(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "OS Share Invite Neighbor",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
    try {
      await Share.share({
        title: i18n.t.common.connectedLivingMessenger,
        text: i18n.t.InviteNeighborModal.nativeShareModal.shareText(teamName),
        dialogTitle:
          i18n.t.InviteNeighborModal.nativeShareModal.shareDialogTitle,
        url: osShareUrl,
      });
    } catch (value) {
      if (isShareCanceledError(value)) return;
      throw value;
    }
  }, [
    track,
    teamId,
    screenName,
    i18n.t.common.connectedLivingMessenger,
    i18n.t.InviteNeighborModal.nativeShareModal,
    teamName,
    osShareUrl,
  ]);

  const onCopyLinkClick = useCallback(async () => {
    setModalCompleted(true);
    await Clipboard.write({ url: copyLinkUrl });
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "Copy Invite Neighbor link",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
    dontAwait(
      presentToast({
        message: i18n.t.common.copiedToClipboard,
        color: "medium",
        duration: 1500,
      }),
    );
  }, [
    copyLinkUrl,
    track,
    teamId,
    screenName,
    presentToast,
    i18n.t.common.copiedToClipboard,
  ]);

  const onShowQrCodeModalClick = useCallback(() => {
    setModalCompleted(true);
    setIsOpenShowQrCodeModal(true);
    dontAwait(
      track({
        eventName: "Modal Opened",
        "modal name": "ShowQrCodeModal",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
  }, [screenName, teamId, track]);

  const onShowQrCodeModalDismiss = useCallback(() => {
    setIsOpenShowQrCodeModal(false);
  }, []);

  const onShowInviteCodeModalClick = useCallback(() => {
    setModalCompleted(true);
    setIsOpenShowInviteCodeModal(true);
    dontAwait(
      track({
        eventName: "Modal Opened",
        "modal name": "ShowInviteCodeModal",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
  }, [screenName, teamId, track]);

  const onShowInviteCodeModalDismiss = useCallback(
    () => setIsOpenShowInviteCodeModal(false),
    [],
  );

  const onHowToInviteNeighborsModalClick = useCallback(() => {
    setModalCompleted(true);
    dontAwait(
      track({
        eventName: "Modal Opened",
        "modal name": "HowToInviteNeighborsModal",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
    setIsOpenHowToInviteNeighborsModal(true);
  }, [screenName, teamId, track]);

  const onHowToInviteNeighborsModalDismiss = useCallback(
    () => setIsOpenHowToInviteNeighborsModal(false),
    [],
  );

  const openSupportUserChat = useCallback(async () => {
    if (isOpenHowToInviteNeighborsModal) {
      onHowToInviteNeighborsModalDismiss();
    }
    dismissInviteNeighborModal();

    history.replace(
      directMessageChannelMessagesPageUrl({
        teamId,
        authUserId: authUser.uid,
        otherUserId: supportUserId,
      }),
    );
  }, [
    authUser.uid,
    dismissInviteNeighborModal,
    history,
    isOpenHowToInviteNeighborsModal,
    onHowToInviteNeighborsModalDismiss,
    supportUserId,
    teamId,
  ]);

  const onRequestStarterKitClick = useCallback(async () => {
    setModalCompleted(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "Open Starter Kit Modal",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
    setIsOpenOrderStarterKitModal(true);
  }, [track, teamId, screenName]);

  const onPrintAtHomeClick = useCallback(async () => {
    setIsLoadingPrintAtHome(true);
    setModalCompleted(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "button name": "Print at home",
        "team id": teamId,
        "screen name": screenName,
      }),
    );
    try {
      await cloudFunctions.generatePrintAtHomeKit({
        locale: getStringLiteral(i18n.currentLocale.slice(0, 2), {
          permitted: languages,
          fallback: "en",
        }),
        teamId,
      });
      await openSupportUserChat();
    } catch (e) {
      if (!isError(e)) throw e;
      presentToast({
        message: `Error: ${e.message} (${e.name}, ${get(e, "code")})`,
        color: "danger",
        duration: 5000,
        buttons: [
          {
            icon: close,
            role: "cancel",
          },
        ],
      });
    } finally {
      setIsLoadingPrintAtHome(false);
    }
  }, [
    cloudFunctions,
    i18n.currentLocale,
    openSupportUserChat,
    presentToast,
    screenName,
    teamId,
    track,
  ]);

  return (
    <>
      <IonModal
        isOpen={isOpen}
        canDismiss
        handle={false}
        onWillDismiss={onInviteNeighborModalDismiss}
        breakpoints={[0, 0.8, 1]}
        initialBreakpoint={0.8}
        className={commonCss.modalCard}
      >
        <IonItem lines="none" class="ion-margin-top">
          <h1>
            <strong>{i18n.t.InviteNeighborModal.title}</strong>
          </h1>
          <IonIcon
            className={`${commonCss.itemIconAvatar} ${commonCss.closeIcon}`}
            color="medium"
            size="large"
            slot="end"
            icon={closeOutline}
            onClick={onInviteNeighborModalDismiss}
          />
        </IonItem>
        <IonItem lines="full">
          <IonText color="medium">
            <p>{i18n.t.InviteNeighborModal.body}</p>
          </IonText>
        </IonItem>
        <IonItem button onClick={onHowToInviteNeighborsModalClick}>
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={helpCircleOutline}
          />
          {i18n.t.InviteNeighborModal.howToInvite}
        </IonItem>
        <IonItem button onClick={onShowInviteCodeModalClick}>
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={eyeOutline}
          />
          {i18n.t.InviteNeighborModal.displayInviteCode}
        </IonItem>
        <IonItem button disabled={!qrCodeUrl} onClick={onShowQrCodeModalClick}>
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={qrCodeOutline}
          />
          {i18n.t.InviteNeighborModal.displayQrCode}
        </IonItem>
        <IonItem
          button
          detail={!isPlatform("capacitor") && false}
          disabled={!copyLinkUrl || !osShareUrl}
          onClick={isPlatform("capacitor") ? onShareLinkClick : onCopyLinkClick}
        >
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={shareOutline}
          />
          {isPlatform("capacitor")
            ? i18n.t.ShareButton.invite.share
            : i18n.t.ShareButton.invite.copy}
        </IonItem>
        <IonItem button onClick={onRequestStarterKitClick}>
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={giftOutline}
          />
          {i18n.t.InviteNeighborModal.requestStarterKit.buttonLabel}
        </IonItem>
        <IonItem
          button
          onClick={onPrintAtHomeClick}
          disabled={isLoadingPrintAtHome}
        >
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="none"
            slot="start"
            icon={downloadOutline}
          />
          {isLoadingPrintAtHome ? (
            <IonSpinner className={css.spinner} />
          ) : (
            i18n.t.InviteNeighborModal.printAtHome.buttonLabel
          )}
        </IonItem>
      </IonModal>
      <ShowInviteCodeModal
        teamId={teamId}
        screenName={screenName}
        inviteCode={inviteCode}
        teamName={teamName}
        isOpen={isOpenShowInviteCodeModal}
        dismissModal={onShowInviteCodeModalDismiss}
      />
      <ShowQrCodeModal
        isOpen={isOpenShowQrCodeModal}
        qrCodeUrl={qrCodeUrl || ""}
        dismissModal={onShowQrCodeModalDismiss}
        modalTitle={i18n.t.ShowQrCodeModal.teamInvite.title}
        modalBodyText={i18n.t.ShowQrCodeModal.teamInvite.body}
      />
      <HowToInviteNeighborsModal
        isOpen={isOpenHowToInviteNeighborsModal}
        screenName={screenName}
        teamId={teamId}
        dismissModal={onHowToInviteNeighborsModalDismiss}
        onShareInviteCodeClick={onShowInviteCodeModalClick}
        onShareLinkClick={onShareLinkClick}
        onCopyLinkClick={onCopyLinkClick}
        onRequestStarterKitClick={onRequestStarterKitClick}
        onShareQrCodeClick={onShowQrCodeModalClick}
        onPrintAtHomeClick={onPrintAtHomeClick}
      />
      <OrderStarterKitModal
        isOpen={isOpenOrderStarterKitModal}
        screenName={screenName}
        dismissModal={() => setIsOpenOrderStarterKitModal(false)}
      />
    </>
  );
};

export default InviteNeighborModal;
