import { FirestoreChannelType } from "../firestore/FirestoreChannelType";
import { TimestampValue } from "../firestore/TimestampValue";
import convertTimestampValueToDate from "../utilities/convertTimestampValueToDate";

export default function countUnreadMessages({
  channelLastReadTimestamp,
  messageTimestamps,
  channelType,
}: {
  channelLastReadTimestamp: Date | undefined;
  messageTimestamps: TimestampValue[];
  channelType: FirestoreChannelType;
}): number {
  const effectiveLastReadTimestamp =
    channelLastReadTimestamp ||
    (channelType === FirestoreChannelType.DirectMessage
      ? new Date(0)
      : undefined);

  if (!effectiveLastReadTimestamp) return 0;

  const lastReadTimestampNumber = effectiveLastReadTimestamp.valueOf();

  return messageTimestamps.filter((value) => {
    return (
      convertTimestampValueToDate(value).valueOf() > lastReadTimestampNumber
    );
  }).length;
}
