import { IonIcon, IonItem, IonModal, IonText } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import React from "react";
import QrCode from "src/share/QrCode";
import commonCss from "./common.module.css";

export type ShowQrCodeModalProps = React.ComponentProps<typeof IonModal> & {
  qrCodeUrl: string;
  dismissModal: () => void;
  modalTitle: string;
  modalBodyText: string;
};

const ShowQrCodeModal: React.FC<ShowQrCodeModalProps> = ({
  isOpen,
  qrCodeUrl,
  dismissModal,
  modalTitle,
  modalBodyText,
}) => (
  <IonModal
    isOpen={isOpen}
    canDismiss
    handle={false}
    showBackdrop
    onDidDismiss={dismissModal}
    breakpoints={[0, 0.8, 1]}
    initialBreakpoint={0.8}
    className={commonCss.modalCard}
  >
    <IonItem className="ion-margin-top" lines="none">
      <IonIcon
        className={`${commonCss.itemIconAvatar} ${commonCss.closeIcon}`}
        color="medium"
        size="large"
        slot="end"
        icon={closeOutline}
        onClick={dismissModal}
      />
    </IonItem>
    <div className="ion-margin-horizontal ion-padding-horizontal">
      <QrCode url={qrCodeUrl} />
    </div>
    <IonItem lines="none">
      <h1>
        <strong>{modalTitle}</strong>
      </h1>
    </IonItem>
    <IonItem lines="none">
      <IonText color="medium">{modalBodyText}</IonText>
    </IonItem>
  </IonModal>
);

export default ShowQrCodeModal;
