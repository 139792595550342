import enUs from "src/state/i18n/locales/en-us";
import {
  intlFormatters,
  intlFormattersConfig,
  intlFormatValueFunction,
} from "src/utilities/i18n/formatters/intlFormatters";
import formatEmpty from "../../utilities/i18n/formatters/formatEmpty";

const intlFormats = intlFormattersConfig({
  date: {
    default: {},
    monthAndYear: { month: "long", year: "numeric" },
    timeOfDay: { hour: "2-digit", minute: "2-digit" },
    hourAndMinute: { timeStyle: "short" },
  },
  number: {
    default: {},
    euroCurrency: {
      style: "currency",
      currency: "EUR",
    },
  },
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function defaultFormatters(
  localeTag: Intl.UnicodeBCP47LocaleIdentifier,
) {
  const formatFunctions = intlFormatters(localeTag, intlFormats);
  const intlFormatValue = intlFormatValueFunction(formatFunctions);

  return {
    ...formatFunctions,
    formatValue(value: unknown) {
      return intlFormatValue(value) ?? formatEmpty(value) ?? String(value);
    },
  };
}

export type LocaleTranslations = typeof enUs.translations;
export type LocaleFormatters = typeof enUs.formatters;
