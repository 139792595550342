import { teamLocationsPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import makeEmptyArray from "@connectedliving/common/lib/utilities/makeEmptyArray";
import {
  channelsListPageUrl,
  userSettingsPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonListHeader,
  IonNote,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar,
  isPlatform,
  useIonToast,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import commonCss from "src/common/common.module.css";
import ImagePicker from "src/common/ImagePicker";
import useScreenTracking from "src/firebase/useScreenTracking";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer, { I18n } from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { isDataAvailable } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import formatTeamLocation from "src/utilities/formatTeamLocation";
import ProfileImage from "src/utilities/ProfileImage";
import sortTeamLocations from "src/utilities/sortTeamLocations";
import useStateWithDefault from "src/utilities/useStateWithDefault";
import updateUserProfileAndTeamProfile from "../updateUserAndTeamUserProfiles";
import uploadProfileImage from "../uploadProfileImage";

export function teamLocationDropdownElements(
  teamLocations: firebase.firestore.QuerySnapshot<TeamLocation>,
  currentLocale: I18n["currentLocale"],
): React.ReactElement[] {
  const sortedTeamLocations = sortTeamLocations(teamLocations, currentLocale);

  return sortedTeamLocations.map((teamLocation) => (
    <IonSelectOption key={teamLocation.id} value={teamLocation.id}>
      {teamLocation.data().streetName} {teamLocation.data().streetNumber}
    </IonSelectOption>
  ));
}

type EditMyUserProfilePageProps = {
  teamId: string;
};

const EditMyUserProfilePage: React.FC<EditMyUserProfilePageProps> = ({
  teamId,
}) => {
  const i18n = I18nContainer.useContainer();

  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { authUser, userProfile, teamUserProfile, team } =
    TeamContextContainer.useContainer();

  const teamLocations = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(teamLocationsPath({ teamId }))
      .withConverter(TeamLocationConverter),
  );

  const [invalidInputMessage, setInvalidInputMessage] = useState<
    string | undefined
  >();
  const [firstName, setFirstName] = useState(userProfile.data().firstName);
  const [lastName, setLastName] = useState(userProfile.data().lastName);
  const [teamLocationId, setTeamLocationId] = useStateWithDefault(
    teamUserProfile.data().teamLocationId,
    teamUserProfile.data().teamLocationId,
  );
  const [floor, setFloor] = useStateWithDefault(
    teamUserProfile.data().floor,
    teamUserProfile.data().floor,
  );
  const [building, setBuilding] = useStateWithDefault(
    teamUserProfile.data().building,
    teamUserProfile.data().building,
  );
  const [extraLocationData, setExtraLocationData] = useStateWithDefault(
    teamUserProfile.data().extraLocationData,
    teamUserProfile.data().extraLocationData,
  );

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [formSubmitError, setFormSubmitError] = useState<string | undefined>();
  const screenName = "EditMyUserProfilePage";
  useScreenTracking(screenName, teamId);

  const formDisabled =
    isSaving || !firstName || !lastName || !!invalidInputMessage;

  const [presentToast] = useIonToast();
  const history = useHistory();

  const onFilePicked = useCallback(
    (file) =>
      uploadProfileImage(file, firebaseApp, authUser, userProfile.ref, track),
    [firebaseApp, authUser, userProfile.ref, track],
  );

  const onFormSubmit = async () => {
    if (formDisabled) {
      return;
    }

    assertPresent(teamUserProfile.data, {
      because: "can't submit the form otherwise",
    });
    assertPresent(teamLocations.data, {
      because: "can't submit the form otherwise",
    });

    setIsSaving(true);
    try {
      await updateUserProfileAndTeamProfile({
        userProfile: {
          updatedUserProfile: {
            firstName: firstName?.trim(),
            lastName: lastName?.trim(),
          },
          originalUserProfile: userProfile,
        },
        teamUserProfile: {
          updatedTeamUserProfile: {
            floor,
            building,
            extraLocationData: extraLocationData?.trim() ?? null,
            teamLocationId:
              teamLocations.data.docs.length === 1 && teamLocations.data.docs[0]
                ? teamLocations.data.docs[0].id
                : teamLocationId,
          },

          originalTeamUserProfile: teamUserProfile,
        },
        track,
        teamId,
      });
      setIsSaving(false);
      dontAwait(
        presentToast({
          color: "success",
          message: i18n.t.EditMyUserProfilePage.profileSaved,
          duration: 3000,
        }),
      );
    } catch (error) {
      setFormSubmitError(
        `${i18n.t.common.genericError} ${get(error, "message")}`,
      );
      setIsSaving(false);
      throw error;
    }

    history.replace(userSettingsPageUrl({ teamId }));
  };
  useEffect(() => {
    function validateName() {
      if (!firstName || !lastName) {
        setInvalidInputMessage(undefined);
        return;
      }
      if (firstName.includes("@") || lastName.includes("@")) {
        setInvalidInputMessage(
          i18n.t.EditMyUserProfilePage.atSignInvalidNameInput,
        );
        return;
      }
      setInvalidInputMessage(undefined);
    }
    validateName();
  }, [firstName, lastName, i18n.t.EditMyUserProfilePage]);

  if (!isDataAvailable(teamLocations)) {
    return (
      <IonPage data-cy="EditMyProfilePage">
        <div className={commonCss.fullscreenContainer}>
          <IonSpinner color="primary" />
        </div>
      </IonPage>
    );
  }

  const { buildingParts: enabledBuildingParts } = team.data();

  return (
    <IonPage data-cy="EditMyProfilePage">
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={channelsListPageUrl({ teamId })}
            />
          </IonButtons>
          <IonTitle>{i18n.t.EditMyUserProfilePage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <div className={`ion-padding-top ${commonCss.centerContent}`}>
          <IonAvatar className={commonCss.large}>
            <ProfileImage userProfile={userProfile.data()} />
          </IonAvatar>
          <ImagePicker
            text={
              userProfile.data().imageUrl
                ? i18n.t.EditMyUserProfilePage.changeImage
                : i18n.t.EditMyUserProfilePage.addImage
            }
            onFilePicked={onFilePicked}
            screenName={screenName}
            teamId={teamId}
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            dontAwait(onFormSubmit());
          }}
          autoComplete="on"
        >
          {formSubmitError && (
            <IonItem lines="none">
              <IonText color="danger">{formSubmitError}</IonText>
            </IonItem>
          )}
          <IonListHeader>
            {i18n.t.EditMyUserProfilePage.headers.name}
          </IonListHeader>
          <IonItem>
            <IonInput
              placeholder={i18n.t.EditMyUserProfilePage.placeHolders.firstName}
              autoCapitalize="words"
              data-cy="EditMyProfilePage-input-firstName"
              value={firstName}
              onIonChange={(e) => setFirstName(e.detail.value || "")}
              autocomplete="given-name"
            />
          </IonItem>
          <IonItem>
            <IonInput
              placeholder={i18n.t.EditMyUserProfilePage.placeHolders.lastName}
              autoCapitalize="words"
              data-cy="EditMyProfilePage-input-lastName"
              value={lastName}
              onIonChange={(e) => setLastName(e.detail.value || "")}
              autocomplete="family-name"
            />
          </IonItem>
          {invalidInputMessage && (
            <div className="ion-padding-top ion-padding-start">
              <IonNote color="danger">{invalidInputMessage}</IonNote>
            </div>
          )}
          <IonListHeader>
            {" "}
            {i18n.t.EditMyUserProfilePage.headers.location}
          </IonListHeader>
          {teamLocations.data.size === 1 && teamLocations.data.docs[0] && (
            <IonItem lines={isPlatform("ios") ? undefined : "full"}>
              <IonLabel>
                {formatTeamLocation(teamLocations.data.docs[0].data())}
              </IonLabel>
            </IonItem>
          )}

          {teamLocations.data.size > 1 && (
            <IonItem>
              <IonSelect
                interface="action-sheet"
                value={teamLocationId}
                cancelText={i18n.t.common.cancel}
                okText={i18n.t.common.ok}
                placeholder={i18n.t.EditMyUserProfilePage.placeHolders.address}
                onIonChange={(e) => setTeamLocationId(e.detail.value)}
                data-cy="EditMyProfilePage-select-Address"
              >
                {teamLocationDropdownElements(
                  teamLocations.data,
                  i18n.currentLocale,
                )}
              </IonSelect>
            </IonItem>
          )}
          <IonItem>
            <IonSelect
              value={floor}
              placeholder={i18n.t.EditMyUserProfilePage.placeHolders.floor}
              onIonChange={(e) => setFloor(e.detail.value)}
              cancelText={i18n.t.common.cancel}
              okText={i18n.t.common.ok}
              data-cy="EditMyProfilePage-select-Floor"
            >
              {makeEmptyArray(32).map((_, i) => {
                const floorNumber = i - 1;
                return (
                  <IonSelectOption key={floorNumber} value={floorNumber}>
                    {i18n.t.common.floorName(floorNumber)}
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          </IonItem>
          {enabledBuildingParts.length > 0 && (
            <IonItem>
              <IonSelect
                value={building}
                placeholder={i18n.t.EditMyUserProfilePage.placeHolders.building}
                onIonChange={(e) => setBuilding(e.detail.value)}
                cancelText={i18n.t.common.cancel}
                okText={i18n.t.common.ok}
                data-cy="EditMyProfilePage-select-Building"
                selectedText={
                  building ? i18n.t.common.buildingParts[building] : null
                }
              >
                {enabledBuildingParts.map((buildingPart) => (
                  <IonSelectOption key={buildingPart} value={buildingPart}>
                    {i18n.t.common.buildingParts[buildingPart]}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>
          )}
          <IonItem>
            <IonInput
              placeholder={
                i18n.t.EditMyUserProfilePage.placeHolders.apartmentNumber
              }
              autoCapitalize="sentences"
              data-cy="EditMyProfilePage-input-extraLocationData"
              value={extraLocationData}
              id="extraLocationData"
              onIonChange={(e) => setExtraLocationData(e.detail.value || "")}
            />
          </IonItem>
        </form>
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonButton
            disabled={formDisabled}
            expand="block"
            onClick={onFormSubmit}
            data-cy="EditMyProfilePage-submit"
          >
            {isSaving ? <IonSpinner /> : i18n.t.common.save}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default EditMyUserProfilePage;
