export const serverTimestamp = "serverTimestamp";

/**
 * Represents a Firebase Timestamp (see firebase.firestore.Timestamp).
 *
 * In order to create a Firebase Timestamp you have to import its constructor or
 * the FieldValue class. These runtime values are specific to either the
 * Firebase JavaScript SDK (package 'firebase') or the Firebase Admin SDK
 * (package 'firebase-admin').
 *
 * We want to share the serialization functions between our backend, admin code
 * and our frontend code, so to make it easier we define a library-agnostic type
 * which doesn't import the runtime Firebase values but allows easy
 * transformation to either the backend Timestamp or JavaScript Date.
 */
export type TimestampValue = typeof serverTimestamp | SpecificTimestampValue;
export type SpecificTimestampValue = {
  type: "timestamp";
  nanoseconds: number;
  seconds: number;
};

export function isTimestampValue(value: unknown): value is TimestampValue {
  return (
    value === serverTimestamp ||
    (typeof value === "object" &&
      value !== null &&
      (value as Record<string, unknown>).type === "timestamp")
  );
}

export function assertSpecificTimestampValue(
  value: TimestampValue,
): asserts value is SpecificTimestampValue {
  if (value === "serverTimestamp")
    throw new Error("Specific TimestampValue required");
}

export function makeTimestampValue({
  nanoseconds,
  seconds,
}: {
  nanoseconds: number;
  seconds: number;
}): TimestampValue {
  return {
    type: "timestamp",
    nanoseconds,
    seconds,
  };
}

export function makeTimestampValueFromDate(date: Date): TimestampValue {
  const millisecondsSinceEpoch = date.valueOf();
  const secondsSinceEpoch = millisecondsSinceEpoch / 1000;
  const wholeSecondsSinceEpoch = Math.floor(secondsSinceEpoch);

  const millisecondsFraction =
    millisecondsSinceEpoch - wholeSecondsSinceEpoch * 1000;
  const nanosecondsFraction = millisecondsFraction * 1000000;

  return {
    type: "timestamp",
    nanoseconds: nanosecondsFraction,
    seconds: wholeSecondsSinceEpoch,
  };
}

export function compareTimestampValues(
  value1: TimestampValue,
  value2: TimestampValue,
): number {
  if (value1 === "serverTimestamp" && value2 === "serverTimestamp") return 0;

  if (value1 === "serverTimestamp" || value2 === "serverTimestamp") {
    throw new Error(
      "Can't compare server timestamps since their value is not known",
    );
  }

  return (
    value1.seconds - value2.seconds || value1.nanoseconds - value2.nanoseconds
  );
}
