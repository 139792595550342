import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { ChannelTrackingContext } from "@connectedliving/common/lib/utilities/tracking/channelTrackingProps";
import {
  channelsListPageUrl,
  teamChannelDetailPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonContent, IonHeader, useIonViewDidEnter } from "@ionic/react";
import firebase from "firebase/compat/app";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect } from "react-router";
import ChannelsMessagesHeader from "src/channels/messages/ChannelsMessagesHeader";
import StreamChannelSkeleton from "src/channels/messages/StreamChannelSkeleton";
import DraftMessagesContainer, {
  DraftMessage,
} from "src/state/DraftMessagesContainer";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import StreamChannelsCacheContainer from "src/state/stream/StreamChannelsCacheContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TranslationConfigContainer from "src/state/TranslationConfigContainer";
import combineLoadingStates from "src/utilities/combineLoadingStates";
import { dataAvailable, isErrorState } from "src/utilities/LoadingState";
import {
  AutocompleteMinimalData,
  Channel,
  SuggestionListProps,
  SuggestionUser,
} from "stream-chat-react";
import { List } from "stream-chat-react/dist/components/AutoCompleteTextarea/List";
import environment from "../../../utilities/environment";
import ChannelMessagesPageContextProvider from "../ChannelMessagesPageContext";
import { CLAttachment } from "../CLAttachment";
import { CLAvatar } from "../CLAvatar";
import CLMessageInput from "../CLMessageInput";
import CLMessageInputFlat from "../CLMessageInputFlat";
import CLTriggerProvider from "../CLTriggerProvider";
import MessageRenderer from "../MessageRenderer";
import StreamMessageList from "../StreamMessageList";

const FilterMentionedUsers: React.FC<
  SuggestionListProps<InternalStreamChatGenerics>
> = (props) => {
  const { onboardedTeamUserProfiles } =
    TeamUserProfilesContainer.useContainer();
  const onboardedTeamUserProfilesIds = onboardedTeamUserProfiles.map(
    (doc) => doc.id,
  );
  const { value } = props;
  let { values } = props;
  if (value === "@") {
    const userSuggestionValues = blindCast<
      (AutocompleteMinimalData & SuggestionUser<InternalStreamChatGenerics>)[],
      "value of '@' indicates values will contain user suggestions"
    >(values);
    values = userSuggestionValues.filter((user) =>
      onboardedTeamUserProfilesIds.includes(user.id),
    );
  }
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <List {...props} values={values} />;
};

export type StreamTeamChannelMessagesPageProps = {
  teamId: string;
  teamChannel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>;
};

const StreamTeamChannelMessagesContent: React.FC<
  StreamTeamChannelMessagesPageProps
> = ({ teamId, teamChannel }) => {
  const { authUser } = FirebaseAppContainer.useContainer();
  const { getStreamChannel, loadStreamChannels } =
    StreamChannelsCacheContainer.useContainer();
  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const { findUserProfileById } = TeamUserProfilesContainer.useContainer();

  const { value: streamChannel, loadingState: channelLoadingState } =
    getStreamChannel(StreamChannelType.Team, teamChannel.id);

  const { showTranslation, setShowTranslation } =
    TranslationConfigContainer.useContainer();

  const { isUserMember, loadingState: firestoreChannelsLoadingState } =
    FirestoreChannelsContainer.useContainer();

  useEffect(() => {
    if (
      dataAvailable(firestoreChannelsLoadingState) &&
      !isUserMember(teamChannel.id)
    ) {
      loadStreamChannels([teamChannel], []);
    }
  }, [
    firestoreChannelsLoadingState,
    isUserMember,
    loadStreamChannels,
    teamChannel,
  ]);

  const loadingState = combineLoadingStates(
    channelLoadingState,
    firestoreChannelsLoadingState,
  );

  const { setDraftMessage, getDraftMessage } =
    DraftMessagesContainer.useContainer();
  const draftMessage = getDraftMessage(teamChannel.ref.path);

  const onMessageInputChange = useCallback(
    (newDraftMessage: DraftMessage) => {
      setDraftMessage(teamChannel.ref.path, newDraftMessage);
    },
    [setDraftMessage, teamChannel.ref.path],
  );
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);

  const channelDetailPageUrl = teamChannelDetailPageUrl({
    teamId,
    teamChannelId: teamChannel.id,
  });

  const { markChannelRead } = StreamChannelsCacheContainer.useContainer();

  const markRead = useCallback(() => {
    dontAwait(markChannelRead(StreamChannelType.Team, teamChannel.id));
  }, [markChannelRead, teamChannel.id]);

  useIonViewDidEnter(() => {
    if (channelLoadingState === "ready") {
      markRead();
    }
  }, [channelLoadingState, markRead]);

  const teamChannelData = teamChannel.data();
  const channelTrackingContext: ChannelTrackingContext = useMemo(
    () => ({
      type: FirestoreChannelType.Team,
      id: teamChannel.ref.id,
      path: teamChannel.ref.path,
      data: teamChannelData,
    }),
    [teamChannel.ref.id, teamChannel.ref.path, teamChannelData],
  );

  return (
    <ChannelMessagesPageContextProvider {...{ setAttachmentModalOpen }}>
      {isErrorState(channelLoadingState) && (
        <Redirect to={channelsListPageUrl({ teamId })} />
      )}
      {!attachmentModalOpen && (
        <IonHeader>
          <ChannelsMessagesHeader
            {...{
              teamId,
              showTranslation,
              channelDetailPageUrl,
              channelTrackingContext,
            }}
            channelPath={teamChannel.ref.path}
            onShowTranslationChange={setShowTranslation}
            channelType={StreamChannelType.Team}
            channelAvatar={{
              type: "icon",
              icon: teamChannelData.channelIcon,
              iconColor: teamChannelData.channelIconColor,
            }}
            loadingState={firestoreChannelsLoadingState}
            title={teamChannelData.name || ""}
            titleRouterLink={channelDetailPageUrl}
          />
        </IonHeader>
      )}
      <IonContent forceOverscroll={false}>
        {isErrorState(loadingState) && (
          <Redirect to={channelsListPageUrl({ teamId })} />
        )}
        {dataAvailable(loadingState) && streamChannel ? (
          <Channel<InternalStreamChatGenerics>
            channel={streamChannel}
            EmptyPlaceholder={<StreamChannelSkeleton />}
            doMarkReadRequest={markRead}
            Avatar={CLAvatar}
            // eslint-disable-next-line react/no-unstable-nested-components
            Message={(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <MessageRenderer
                {...props}
                authUser={authUser}
                teamId={teamId}
                findUserProfileById={findUserProfileById}
                linkTrackingContext={{
                  "channel type": "team",
                  "channel id": teamChannel.id,
                  "channel path": teamChannel.ref.path,
                  "channel purpose": teamChannel.data().purpose,
                  "channel name": teamChannel.data().name,
                  "is support dm channel": false,
                  "is support user":
                    authUser.uid === environment().supportUserId(),
                }}
              />
            )}
            Input={CLMessageInputFlat}
            TriggerProvider={CLTriggerProvider}
            AutocompleteSuggestionList={FilterMentionedUsers}
            Attachment={CLAttachment}
          >
            <StreamMessageList {...{ teamId, streamChannel }} />

            <div style={{ display: attachmentModalOpen ? "none" : "block" }}>
              <CLMessageInput
                onChange={onMessageInputChange}
                draftMessage={draftMessage}
              />
            </div>
          </Channel>
        ) : (
          <StreamChannelSkeleton />
        )}
      </IonContent>
    </ChannelMessagesPageContextProvider>
  );
};

export default StreamTeamChannelMessagesContent;
