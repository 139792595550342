import { ArrayElement } from "../utilities/ArrayElement";

export const channelPurposeValues = Object.freeze([
  "general",
  "free-stuff",
  "food-sharing",
  "borrowing-lending",
  "offers",
] as const);

export type ChannelPurpose = ArrayElement<typeof channelPurposeValues>;
