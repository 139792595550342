import firebase from "firebase/compat/app";
import convertFields from "../utilities/firestore/convertFields";
import {
  convertTimestampValueToFirebaseAppTimestamp,
  getFirestoreAppTimestampValue,
} from "./app/firestoreAppTimestamp";
import {
  ClientInstance,
  firestoreDataToPushConfig,
  PushStatus,
} from "./ClientInstance";
import {
  getBoolean,
  getStrictDocumentData,
  getString,
  getStringLiteral,
  StrictDocumentData,
} from "./dataHelpers";
import { languages } from "./supportedLanguages";
import { supportedLocaleValues } from "./SupportedLocale";

export function firestoreDataToClientInstance(
  data: StrictDocumentData,
): ClientInstance {
  const pushConfig = firestoreDataToPushConfig(
    getStrictDocumentData(data.pushConfig, {
      fallback: {
        pushStatus: PushStatus.initial,
        fcmToken: null,
        pushNetwork: null,
        pushDisabledReason: null,
        pushLatestError: null,
      },
    }),
  );
  const translationConfigData = getStrictDocumentData(data.translationConfig, {
    fallback: { enabled: false },
  });
  const translationConfig = getBoolean(translationConfigData.enabled)
    ? {
        enabled: true,
        targetLanguage: getStringLiteral(translationConfigData.targetLanguage, {
          permitted: languages,
          fallback: "en",
        }),
      }
    : ({ enabled: false } as const);

  return {
    userId: getString(data.userId),
    locale: getStringLiteral(data.locale, {
      permitted: supportedLocaleValues,
      fallback: "en-us",
    }),
    pushConfig: firestoreDataToPushConfig(pushConfig),
    translationConfig,
    createdAt: getFirestoreAppTimestampValue(data.createdAt),
    updatedAt: getFirestoreAppTimestampValue(data.updatedAt),
  };
}

export function clientInstanceToFirestoreData(
  clientInstance: Partial<ClientInstance>,
): StrictDocumentData {
  const result = convertFields(clientInstance, {
    createdAt: convertTimestampValueToFirebaseAppTimestamp,
    updatedAt: (value) =>
      value && convertTimestampValueToFirebaseAppTimestamp(value),
  });
  return result;
}

const ClientInstanceConverter: firebase.firestore.FirestoreDataConverter<ClientInstance> =
  Object.freeze({
    toFirestore(clientInstance: ClientInstance) {
      return clientInstanceToFirestoreData(clientInstance);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): ClientInstance {
      return firestoreDataToClientInstance(snapshot.data(options));
    },
  });

export default ClientInstanceConverter;
