/* eslint-disable no-param-reassign */
import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import { lastReadTimestampsPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import { InternalStreamChannel } from "@connectedliving/common/lib/stream/InternalStreamChannel";
import { InternalStreamChat } from "@connectedliving/common/lib/stream/InternalStreamChat";
import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import { InternalStreamUser } from "@connectedliving/common/lib/stream/InternalStreamUser";
import loadAllChannels from "@connectedliving/common/lib/stream/loadAllChannels";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import streamDirectMessageChannelId from "@connectedliving/common/lib/stream/streamDirectMessageChannelId";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import firebase from "firebase/compat/app";
import { get, isError } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { createContainer } from "src/utilities/createContainer";
import { LoadingState } from "src/utilities/LoadingState";
import { UserResponse } from "stream-chat";
import FirebaseAppContainer from "../firebase/FirebaseAppContainer";
import LastReadTimestampsContainer from "../team/LastReadTimestampsContainer";
import StreamChatContainer from "./StreamChatContainer";

export type StreamChannelsCache = {
  getStreamChannel(
    channelType: StreamChannelType,
    channelId: string,
  ): StreamChannelWithLoadingState;
  loadStreamChannels(
    teamChannels: firebase.firestore.QueryDocumentSnapshot<TeamChannel>[],
    directMessageChannels: firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>[],
  ): void;
  loadingState: LoadingState;
  markChannelRead: (
    channelType: StreamChannelType,
    channelId: string,
    lastReadTimestamp?: Date,
  ) => Promise<void>;
};

export type StreamChannelWithLoadingState = {
  value: InternalStreamChannel;
  loadingState: LoadingState;
};

async function fetchStreamChannels(
  streamChat: InternalStreamChat,
  channelCids: string[],
  onChannelsLoad: (channels: InternalStreamChannel[]) => void,
  onChannelsError: (channels: InternalStreamChannel[], error: Error) => void,
  {
    retries = 10,
    wait = 500,
  }: {
    retries?: number;
    wait?: number;
  } = { retries: 10, wait: 500 },
): Promise<void> {
  if (channelCids.length === 0) return undefined;

  let response: InternalStreamChannel[];

  try {
    response = await loadAllChannels(streamChat, {
      cid: { $in: channelCids },
    });
  } catch (error) {
    if (!isError(error)) throw error;
    if (retries === 0) {
      onChannelsError(
        channelCids.map((cid) => {
          const [channelType, channelId] = cid.split(":") as [string, string];
          return streamChat.channel(channelType, channelId);
        }),
        error,
      );

      return undefined;
    }

    response = [];
  }

  onChannelsLoad(response);

  const cidsNotLoaded = channelCids.filter(
    (cid) => !response.find((channel) => channel.cid === cid),
  );

  if (retries === 0) {
    const notFoundError = new Error(`Channel not found`);

    onChannelsError(
      cidsNotLoaded.map((cid) => {
        const [channelType, channelId] = cid.split(":") as [string, string];
        return streamChat.channel(channelType, channelId);
      }),
      notFoundError,
    );

    return undefined;
  }

  await new Promise((resolve) => {
    setTimeout(resolve, wait);
  });

  return fetchStreamChannels(
    streamChat,
    cidsNotLoaded,
    onChannelsLoad,
    onChannelsError,
    { retries: retries - 1, wait },
  );
}

function overrideStreamChannelLastReadTimestamp(
  streamChannel: InternalStreamChannel,
  authUserId: string,
  currentStreamUser: InternalStreamUser,
  lastReadTimestamp: Date,
): void {
  const readState = streamChannel.state.read[authUserId];
  if (!readState) {
    const user = blindCast<
      UserResponse<InternalStreamChatGenerics>,
      "user response came from Stream, it's guaranteed to be the correct type"
    >(currentStreamUser);

    streamChannel.state.read[authUserId] = {
      last_read: lastReadTimestamp,
      user,
      unread_messages: 0,
    };
  } else {
    readState.last_read = lastReadTimestamp;
  }

  streamChannel.state.unreadCount =
    streamChannel.countUnread(lastReadTimestamp);
}

type Cid = string;

export function useStreamChannelsCache(): StreamChannelsCache {
  const { authUser, firebaseApp } = FirebaseAppContainer.useContainer();

  assertPresent(authUser, { because: "App waits for it to load" });

  const { streamChat, streamReady } = StreamChatContainer.useContainer();

  const streamChatLoadingState: LoadingState = streamReady
    ? "ready"
    : "initialLoad";

  const [cidToStreamChannel, setCidToStreamChannel] = useState<
    Readonly<Record<Cid, StreamChannelWithLoadingState>>
  >({});

  const { lastReadTimestamps: lastReadTimestampsDoc } =
    LastReadTimestampsContainer.useContainer();

  const onChannelsLoad = useCallback(
    (channels: InternalStreamChannel[]) => {
      setCidToStreamChannel((currentCacheValue) => {
        const newCacheValue = { ...currentCacheValue };

        channels.forEach((streamChannel) => {
          newCacheValue[streamChannel.cid] = {
            value: streamChannel,
            loadingState: "ready",
          };

          const lastReadTimestamp = get(
            lastReadTimestampsDoc?.data(),
            streamChannel.cid,
          );
          if (!lastReadTimestamp) return;

          overrideStreamChannelLastReadTimestamp(
            streamChannel,
            authUser.uid,
            assertPresent.andReturn(streamChat.user, {
              because: "must be logged in",
            }),
            lastReadTimestamp,
          );
        });

        return newCacheValue;
      });
    },
    [authUser.uid, lastReadTimestampsDoc, streamChat.user],
  );

  const onChannelsError = useCallback(
    (channels: InternalStreamChannel[], error: Error) => {
      setCidToStreamChannel((currentCacheValue) => {
        const newCacheValue = { ...currentCacheValue };

        channels.forEach((streamChannel) => {
          newCacheValue[streamChannel.cid] = {
            value: streamChannel,
            loadingState: { error },
          };
        });

        return newCacheValue;
      });
    },
    [],
  );

  const loadStreamChannels = useCallback(
    (
      teamChannels: firebase.firestore.QueryDocumentSnapshot<TeamChannel>[],
      directMessageChannels: firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>[],
    ) => {
      const teamChannelsNotInCache = teamChannels
        ? teamChannels.filter(
            (teamChannel) =>
              cidToStreamChannel[`team:${teamChannel.id}`] === undefined,
          )
        : [];

      const directMessageChannelIds = directMessageChannels
        ? directMessageChannels.map((directMessageChannel) => {
            const regexMatch =
              directMessageChannel.ref.path.match(/^Teams\/([^/]+)\//);
            assertPresent(regexMatch, {
              because: "direct message channel path must match regex",
            });
            const teamId = regexMatch[1];
            assertPresent(teamId, {
              because: "regex has at least 1 matching group",
            });

            return (
              directMessageChannel.data().streamChannelId ||
              streamDirectMessageChannelId(
                teamId,
                authUser.uid,
                directMessageChannel.data().otherUserId,
              )
            );
          })
        : [];

      const directMessageChannelIdsNotInCache = directMessageChannelIds.filter(
        (id) => cidToStreamChannel[`messaging:${id}`] === undefined,
      );

      if (
        teamChannelsNotInCache.length === 0 &&
        directMessageChannelIdsNotInCache.length === 0
      )
        return;

      setCidToStreamChannel((currentCacheValue) => {
        const newCacheValue = { ...currentCacheValue };

        teamChannelsNotInCache.forEach((teamChannel) => {
          const streamChannel = streamChat.channel("team", teamChannel.id);
          newCacheValue[streamChannel.cid] = {
            loadingState: "initialLoad",
            value: streamChannel,
          };
        });

        directMessageChannelIdsNotInCache.forEach((channelId) => {
          const streamChannel = streamChat.channel("messaging", channelId);
          newCacheValue[streamChannel.cid] = {
            loadingState: "initialLoad",
            value: streamChannel,
          };
        });

        return newCacheValue;
      });

      const cidsToLoad = [
        ...teamChannelsNotInCache.map(
          (teamChannel) => `team:${teamChannel.id}`,
        ),
        ...directMessageChannelIdsNotInCache.map(
          (channelId) => `messaging:${channelId}`,
        ),
      ];

      dontAwait(
        fetchStreamChannels(
          streamChat,
          cidsToLoad,
          onChannelsLoad,
          onChannelsError,
        ),
      );
    },
    [
      streamChat,
      onChannelsLoad,
      onChannelsError,
      cidToStreamChannel,
      authUser.uid,
    ],
  );

  // Update Stream channels when Firestore last read timestamps change or cache is updated
  useEffect(() => {
    const lastReadTimestamps = lastReadTimestampsDoc?.data();
    if (!lastReadTimestamps) return;

    for (const [cid, lastReadTimestamp] of Object.entries(lastReadTimestamps)) {
      const channelWithLoadingState = cidToStreamChannel[cid];
      if (!channelWithLoadingState || !lastReadTimestamp) continue;

      assertPresent(streamChat.user, {
        because: "user must already be logged in",
      });

      overrideStreamChannelLastReadTimestamp(
        channelWithLoadingState.value,
        authUser.uid,
        streamChat.user,
        lastReadTimestamp,
      );
    }
  }, [
    authUser.uid,
    lastReadTimestampsDoc,
    cidToStreamChannel,
    streamChat.user,
  ]);

  useEffect(() => {
    function onConnectionRecovered() {
      // NOTE: it's EXTREMELY important this is executed using `setTimeout()`. When the connection is reestablished,
      // Stream will call our event handler BEFORE processing its internal updates, and the updates will overwrite our
      // timestamps.
      setTimeout(() => {
        Object.values(cidToStreamChannel).forEach(
          ({ value: streamChannel }) => {
            const lastReadTimestamp = get(
              lastReadTimestampsDoc?.data(),
              streamChannel.cid,
            );
            if (!lastReadTimestamp) return;
            assertPresent(authUser, {
              because: "container is only present after login",
            });

            overrideStreamChannelLastReadTimestamp(
              streamChannel,
              authUser.uid,
              assertPresent.andReturn(streamChat.user, {
                because: "container is only present after login",
              }),
              lastReadTimestamp,
            );
          },
        );
      });
    }

    streamChat.on("connection.recovered", onConnectionRecovered);

    return () => streamChat.off("connection.recovered", onConnectionRecovered);
  }, [authUser, cidToStreamChannel, lastReadTimestampsDoc, streamChat]);

  const getStreamChannel = useCallback(
    (
      channelType: StreamChannelType,
      channelId: string,
    ): StreamChannelWithLoadingState => {
      if (!streamReady) throw new Error("Wait for Stream to initialize");

      const cid = `${channelType}:${channelId}`;

      const cachedResult = cidToStreamChannel[cid];
      if (cachedResult) return cachedResult;

      const streamChannel = streamChat.channel(channelType, channelId);

      const result: StreamChannelWithLoadingState = {
        value: streamChannel,
        loadingState: "initialLoad",
      };

      return result;
    },
    [cidToStreamChannel, streamChat, streamReady],
  );

  const markChannelRead: (
    channelType: StreamChannelType,
    channelId: string,
    lastReadTimestamp?: Date,
  ) => Promise<void> = useCallback(
    async (channelType, channelId, lastReadTimestamp = new Date()) => {
      const streamChannel = getStreamChannel(channelType, channelId);

      await Promise.all([
        streamChannel.value.markRead(),
        firebaseApp
          .firestore()
          .doc(lastReadTimestampsPath({ userId: authUser.uid }))
          .set(
            {
              [streamChannel.value.cid]: lastReadTimestamp,
            },
            { merge: true },
          ),
      ]);

      overrideStreamChannelLastReadTimestamp(
        streamChannel.value,
        authUser.uid,
        assertPresent.andReturn(streamChat.user, {
          because: "user must be logged in",
        }),
        lastReadTimestamp,
      );
    },
    [authUser.uid, firebaseApp, getStreamChannel, streamChat.user],
  );

  return {
    loadingState: streamChatLoadingState,
    getStreamChannel,
    loadStreamChannels,
    markChannelRead,
  };
}

const StreamChannelsCacheContainer = createContainer(useStreamChannelsCache);
export default StreamChannelsCacheContainer;
