import makeEnvironmentVariableAccessors, {
  EnvironmentVariableAccessors,
} from "@connectedliving/common/lib/utilities/makeEnvironmentVariableAccessors";

const environmentAccessors = makeEnvironmentVariableAccessors({
  environmentNameVariable: "REACT_APP_CONNECTED_LIVING_ENVIRONMENT",
});

export default function reactEnvironment(): EnvironmentVariableAccessors {
  return environmentAccessors;
}
