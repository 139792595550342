import dayjsDeLocale from "dayjs/locale/de";
import dayjsEnLocale from "dayjs/locale/en";
import { deTranslations, enTranslations } from "stream-chat-react";
import { LocaleTag } from "../i18n/I18nContainer";

type StreamRegisterTranslationParams = {
  language: LocaleTag;
  translation: typeof enTranslations;
  customDayjsLocale?: Partial<ILocale>;
};

const defaultEnglishDayjsLocale = {
  ...dayjsEnLocale,
  formats: {
    LTS: "h:mm:ss A",
    LT: "h:mm A",
    L: "MM/DD/YYYY",
    LL: "MMMM D, YYYY",
    LLL: "MMMM D, YYYY h:mm A",
    LLLL: "dddd, MMMM D, YYYY h:mm A",
  },
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
  calendar: {
    lastDay: "[Yesterday at] LT",
    sameDay: "[Today at] LT",
    nextDay: "[Tomorrow at] LT",
    nextWeek: "dddd [at] LT",
    lastWeek: "[Last] dddd [at] LT",
    sameElse: "L",
  },
  ordinal: (n: number) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`;
  },
};

const StreamTranslations: readonly StreamRegisterTranslationParams[] =
  Object.freeze([
    {
      language: "en-us",
      translation: enTranslations,
      customDayjsLocale: defaultEnglishDayjsLocale,
    },
    {
      language: "en-gb",
      translation: enTranslations,
      customDayjsLocale: {
        ...defaultEnglishDayjsLocale,
        formats: {
          LT: "HH:mm",
          LTS: "HH:mm:ss",
          L: "DD/MM/YYYY",
          LL: "D MMMM YYYY",
          LLL: "D MMMM YYYY HH:mm",
          LLLL: "dddd, D MMMM YYYY HH:mm",
        },
      },
    },
    {
      language: "en-de",
      translation: enTranslations,
      customDayjsLocale: {
        ...defaultEnglishDayjsLocale,
        formats: {
          LTS: "HH:mm:ss",
          LT: "HH:mm",
          L: "DD.MM.YYYY",
          LL: "D. MMMM YYYY",
          LLL: "D. MMMM YYYY HH:mm",
          LLLL: "dddd, D. MMMM YYYY HH:mm",
        },
      },
    },
    {
      language: "de",
      translation: deTranslations,
      customDayjsLocale: {
        ...dayjsDeLocale,
        calendar: {
          lastDay: "[gestern um] LT",
          lastWeek: "[Letzten] dddd [um] LT",
          nextDay: "[morgen um] LT",
          nextWeek: "dddd [um] LT",
          sameDay: "[heute um] LT",
          sameElse: "L",
        },
      },
    },
  ]);

export default StreamTranslations;
