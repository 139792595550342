import firebase from "firebase/compat/app";

export type ErrorState = { error: Error };
/**
 * Represents the different states data is in while being fetched and refreshed from the network.
 *
 * It's not enough simply to record whether we have the data or not, since it could be loaded from cache (and stale),
 * completely unavailable (when the app loads the first time), or it may be present but the network is down so it can't
 * be refreshed or updated.
 */
export type LoadingState =
  | "initialLoad"
  | "networkUnavailable"
  | "ready"
  | ErrorState
  | "updating";

export function dataAvailable(loadingState: LoadingState): boolean {
  return loadingState === "ready" || loadingState === "updating";
}

export function isErrorState(
  loadingState: LoadingState,
): loadingState is ErrorState {
  return typeof loadingState === "object";
}

export function loadFailedOrNotFound(
  result:
    | firebase.firestore.DocumentSnapshot
    | firebase.firestore.QueryDocumentSnapshot
    | undefined,
  loadingState: LoadingState,
): boolean {
  if (isErrorState(loadingState) || loadingState === "networkUnavailable")
    return true;
  if (loadingState === "ready" && (!result || !result.exists)) return true;
  return false;
}
