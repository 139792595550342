import {
  IonButtons,
  IonContent,
  IonHeader,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import css from "./ChannelsMessagesHeader.module.css";
import StreamChannelSkeleton from "./StreamChannelSkeleton";

const ChannelMessagesHeaderSkeleton: React.FC = () => (
  <IonHeader>
    <IonToolbar data-cy="ChannelMessagesHeader-Preview">
      <IonButtons slot="start" />

      <IonTitle className={css.channelTitle}>
        <IonSkeletonText
          animated
          style={{ display: "inline-block", width: "100%" }}
        />
      </IonTitle>
    </IonToolbar>
  </IonHeader>
);

const ChannelMessagePageSkeleton: React.FC = () => (
  <>
    <ChannelMessagesHeaderSkeleton />
    <IonContent>
      <StreamChannelSkeleton />
    </IonContent>
  </>
);

export default ChannelMessagePageSkeleton;
