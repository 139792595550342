import firebase from "firebase/compat/app";
import blindCast from "../lang/blindCast";
import isObjectInstance from "../lang/isObjectInstance";

// https://firebase.google.com/docs/reference/node/firebase.functions#functionserrorcode
const functionsErrorCodes: firebase.functions.FunctionsErrorCode[] = [
  "ok",
  "cancelled",
  "unknown",
  "invalid-argument",
  "deadline-exceeded",
  "not-found",
  "already-exists",
  "permission-denied",
  "resource-exhausted",
  "failed-precondition",
  "aborted",
  "out-of-range",
  "unimplemented",
  "internal",
  "unavailable",
  "data-loss",
  "unauthenticated",
];

const errorCodeRegExp = new RegExp(functionsErrorCodes.join("|"));

export const isErrorCode = (errorObject: { code: string }) =>
  errorCodeRegExp.test(errorObject.code);

export function isHttpsError(
  value: unknown,
): value is firebase.functions.HttpsError {
  return (
    isObjectInstance(value) &&
    "code" in value &&
    "details" in value &&
    isErrorCode(blindCast(value))
  );
}
