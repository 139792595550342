import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import React, { useEffect } from "react";
import { FileUploadButton, ImageDropzone } from "react-file-utils";
import { Message } from "stream-chat";
import {
  ChatAutoComplete,
  EmojiPicker,
  FileUploadIconFlat as DefaultFileUploadIcon,
  QuotedMessagePreview as DefaultQuotedMessagePreview,
  SendButton as DefaultSendButton,
  Tooltip,
  UploadsPreview,
  useChannelStateContext,
  useComponentContext,
  useMessageInputContext,
  useTranslationContext,
} from "stream-chat-react";
import { useCLMessageInputContext } from "./CLMessageInput";

const CLMessageInputFlat = <
  StreamChatGenerics extends InternalStreamChatGenerics,
>(): React.ReactElement | null => {
  const { acceptedFiles, multipleUploads, quotedMessage } =
    useChannelStateContext<StreamChatGenerics>();
  const { t } = useTranslationContext("CLMessageInputSmall");

  const {
    cooldownRemaining,
    handleSubmit,
    isUploadEnabled,
    maxFilesLeft,
    uploadNewFiles,
    text,
    mentioned_users: mentionedUsers,
    attachments,
    insertText,
    textareaRef,
  } = useMessageInputContext<StreamChatGenerics>("CLMessageInputSmall");

  const {
    FileUploadIcon = DefaultFileUploadIcon,
    QuotedMessagePreview = DefaultQuotedMessagePreview,
    SendButton = DefaultSendButton,
  } = useComponentContext<StreamChatGenerics>("CLMessageInputSmall");

  const { onChange, draftMessage } = useCLMessageInputContext();

  useEffect(() => {
    function setInitialTextFromDraftMessage() {
      if (draftMessage?.text) {
        insertText(draftMessage.text);
      }
    }

    setInitialTextFromDraftMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange({
      text,
      mentioned_users: mentionedUsers,
      attachments,
    });
  }, [attachments, mentionedUsers, onChange, text]);
  useEffect(() => {
    const selectAutocompleteItem = (event: MouseEvent) => {
      const target = event.target as Element;
      const listItemButtonSelector = ".rta__entity";
      if (target.closest(listItemButtonSelector)) {
        // prevent safari broswer behavior for <button class="rta__entity" onClick={onClickHandler}>
        // so that onClick handler is called
        event.preventDefault();
      }
    };
    document.addEventListener("mousedown", selectAutocompleteItem);

    return () => {
      document.removeEventListener("mousedown", selectAutocompleteItem);
    };
  }, []);

  return (
    <div
      className={`str-chat__input-flat ${
        SendButton ? "str-chat__input-flat--send-button-active" : null
      } ${
        quotedMessage && !quotedMessage.parent_id
          ? "str-chat__input-flat-quoted"
          : null
      }`}
    >
      <ImageDropzone
        accept={acceptedFiles}
        disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
        handleFiles={uploadNewFiles}
        maxNumberOfFiles={maxFilesLeft}
        multiple={multipleUploads}
      >
        {quotedMessage && !quotedMessage.parent_id && (
          <QuotedMessagePreview quotedMessage={quotedMessage} />
        )}
        <div className="str-chat__input-flat-wrapper">
          <div className="str-chat__input-flat--textarea-wrapper">
            {isUploadEnabled && <UploadsPreview />}
            <EmojiPicker />
            <ChatAutoComplete />
            {isUploadEnabled && !cooldownRemaining && (
              <div
                className="str-chat__fileupload-wrapper"
                data-testid="fileinput"
              >
                <Tooltip>
                  {maxFilesLeft
                    ? t("Attach files")
                    : t("You've reached the maximum number of files")}
                </Tooltip>
                <FileUploadButton
                  accepts={acceptedFiles}
                  disabled={maxFilesLeft === 0}
                  handleFiles={uploadNewFiles}
                  multiple={multipleUploads}
                >
                  <span className="str-chat__input-flat-fileupload">
                    <FileUploadIcon />
                  </span>
                </FileUploadButton>
              </div>
            )}
          </div>
          {!cooldownRemaining && (
            <SendButton
              sendMessage={(
                event: React.BaseSyntheticEvent,
                customMessageData?:
                  | Partial<Message<StreamChatGenerics>>
                  | undefined,
              ) => {
                handleSubmit(event, customMessageData);
                textareaRef.current?.focus();
              }}
            />
          )}
        </div>
      </ImageDropzone>
    </div>
  );
};

export default CLMessageInputFlat;
