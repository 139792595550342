import { Clipboard } from "@capacitor/clipboard";
import { ChannelBackingStorage } from "@connectedliving/common/lib/firestore/ChannelBackingStorage";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import { directMessageChannelPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  marketingSitePrivacyPolicyUrl,
  marketingSiteTermsAndConditionsUrl,
} from "@connectedliving/common/lib/utilities/marketingSiteUrlBuilders";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  backofficePageUrl,
  channelsListPageUrl,
  directMessageChannelMessagesPageUrl,
  editMyUserProfilePageUrl,
  languageSettingsPageUrl,
  leaveTeamPageUrl,
  pushNotificationSettingsPageUrl,
  teamAdminPageUrl,
  viewUserProfilePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { isPlatform } from "@ionic/core";
import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import {
  bookOutline,
  businessOutline,
  closeOutline,
  code,
  createOutline,
  eyeOutline,
  helpCircleOutline,
  homeOutline,
  languageOutline,
  logOutOutline,
  notificationsOutline,
  personAddOutline,
  shareOutline,
  trashOutline,
} from "ionicons/icons";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import InviteNeighborModal from "src/common/InviteNeighborModal";
import IonItemIcon from "src/common/IonItemIcon";
import ShareAppModal from "src/common/ShareAppModal";
import useDeleteAccountActionSheet from "src/common/useDeleteAccountActionSheet";
import useScreenTracking from "src/firebase/useScreenTracking";
import createDirectMessageChannel from "src/state/firebase/createDirectMessageChannel";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import ProfileImage from "src/utilities/ProfileImage";
import commonCss from "../../common/common.module.css";
import LoggedInUserProfileContainer from "../../state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "../../state/i18n/I18nContainer";
import environment from "../../utilities/environment";
import TeamSwitcher from "./TeamSwitcher";
import css from "./UserSettingsPage.module.css";

type UserSettingsPageProps = {
  teamId: string;
};

const UserSettingsPage: React.FC<UserSettingsPageProps> = ({ teamId }) => {
  const history = useHistory();
  const { firebaseApp, logOut } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { authUser, userProfile, team } = TeamContextContainer.useContainer();
  const { teams } = LoggedInUserProfileContainer.useContainer();
  const [presentToast] = useIonToast();
  const i18n = I18nContainer.useContainer();
  const { directMessageChannels } = FirestoreChannelsContainer.useContainer();
  const [presentAlert] = useIonAlert();
  const [isOpenShareAppModal, setIsOpenShareAppModal] =
    useState<boolean>(false);
  const [isOpenInviteNeighborModal, setIsOpenInviteNeighborModal] =
    useState<boolean>(false);
  const { inviteCode, name } = team.data();

  const formattedReleaseVersion = environment().releaseVersion()?.slice(0, 7);
  const onAppVersionClick = async () => {
    await Clipboard.write({ string: formattedReleaseVersion });
    dontAwait(
      presentToast({
        message: i18n.t.common.copiedToClipboard,
        color: "medium",
        duration: 5000,
      }),
    );
  };

  const { isEmployee } = userProfile.data();

  const screenName = "UserSettingsPage";
  useScreenTracking(screenName, teamId);

  const onInviteNeighborButtonClick = useCallback(() => {
    setIsOpenInviteNeighborModal(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Invite Neighbor",
      }),
    );
    dontAwait(
      track({
        eventName: "Modal Opened",
        "screen name": screenName,
        "team id": teamId,
        "modal name": "InviteNeighborModal",
      }),
    );
  }, [track, teamId]);

  const onShareAppButtonClick = useCallback(() => {
    setIsOpenShareAppModal(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Share App",
      }),
    );
    dontAwait(
      track({
        eventName: "Modal Opened",
        "screen name": screenName,
        "team id": teamId,
        "modal name": "ShareAppModal",
      }),
    );
  }, [track, teamId]);

  const supportUserId = requireEnvVar("REACT_APP_SUPPORT_USER_ID");

  const onContactUsClick = useCallback(async () => {
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Contact Us",
      }),
    );
    const existingDirectMessageChannel = directMessageChannels.find(
      (channel) => channel.id === supportUserId,
    );
    if (!existingDirectMessageChannel) {
      await createDirectMessageChannel({
        firebaseApp,
        teamId,
        userId: authUser.uid,
        otherUserId: supportUserId,
        backingStorage: ChannelBackingStorage.Firestore,
      });

      await firebaseApp
        .firestore()
        .doc(
          directMessageChannelPath({
            teamId,
            userId: authUser.uid,
            otherUserId: supportUserId,
          }),
        )
        .withConverter(DirectMessageChannelConverter)
        .get();
    }

    history.replace(
      directMessageChannelMessagesPageUrl({
        teamId,
        authUserId: authUser.uid,
        otherUserId: supportUserId,
      }),
    );
  }, [
    track,
    teamId,
    directMessageChannels,
    history,
    authUser.uid,
    supportUserId,
    firebaseApp,
  ]);

  const onCancelClick = useCallback(() => {
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Cancel Contact Us",
      }),
    );
  }, [teamId, track]);

  const presentDeleteAccountActionSheet = useDeleteAccountActionSheet({
    screenName,
    currentTeamId: teamId,
  });

  return (
    <IonPage id="main-content" data-cy="UserSettingsPage">
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={channelsListPageUrl({ teamId })}
              data-cy="UserSettingsPage-BackButton"
            />
          </IonButtons>
          <IonTitle>{i18n.t.UserSettingsPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={commonCss.contentContainer}>
        <IonCard className={commonCss.card}>
          <IonItem
            data-cy="UserSettingsPage-ViewProfilePageButton"
            routerLink={viewUserProfilePageUrl({
              teamId,
              userProfileId: authUser.uid,
            })}
          >
            <IonAvatar slot="start" className={css.userProfileAvatar}>
              <ProfileImage userProfile={userProfile.data()} />
            </IonAvatar>
            <IonLabel>
              <h2>
                <strong>{formatFullName(userProfile.data())}</strong>
              </h2>
              <p>{authUser.email}</p>
            </IonLabel>
          </IonItem>
        </IonCard>

        <IonCard className={`ion-margin-bottom ${commonCss.card}`}>
          <IonItem
            data-cy="UserSettingsPage-EditProfilePageButton"
            routerLink={editMyUserProfilePageUrl({ teamId })}
            routerDirection="forward"
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={createOutline}
            />
            {i18n.t.UserSettingsPage.editProfile}
          </IonItem>

          <IonItem
            data-cy="UserSettingsPage-LanguagePageButton"
            routerLink={languageSettingsPageUrl({ teamId })}
            routerDirection="forward"
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={languageOutline}
            />
            {i18n.t.UserSettingsPage.language}
          </IonItem>

          {isPlatform("capacitor") && (
            <IonItem
              routerLink={pushNotificationSettingsPageUrl({ teamId })}
              routerDirection="forward"
            >
              <IonItemIcon
                className={commonCss.itemIconAvatar}
                color="none"
                slot="start"
                icon={notificationsOutline}
              />
              {i18n.t.UserSettingsPage.push}
            </IonItem>
          )}

          <IonItem
            data-cy="UserSettingsPage-TeamAdminPageButton"
            routerLink={teamAdminPageUrl({ teamId })}
            routerDirection="forward"
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={homeOutline}
            />
            {i18n.t.UserSettingsPage.yourCommunity}
          </IonItem>

          <IonItem onClick={onInviteNeighborButtonClick} button>
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={personAddOutline}
            />
            {i18n.t.common.inviteNeighbor}
          </IonItem>

          <IonItem onClick={onShareAppButtonClick} button>
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={shareOutline}
            />
            {i18n.t.UserSettingsPage.shareApp}
          </IonItem>

          <IonItem
            button
            data-cy="UserSettingsPage-ContactUsButton"
            routerDirection="forward"
            onClick={() =>
              presentAlert({
                header: i18n.t.UserSettingsPage.contactUs.alertHeader,
                subHeader: i18n.t.UserSettingsPage.contactUs.alertSubheader,
                message: i18n.t.UserSettingsPage.contactUs.alertMessage,
                buttons: [
                  {
                    text: i18n.t.UserSettingsPage.contactUs.alertCancel,
                    handler: () => onCancelClick(),
                  },
                  {
                    text: i18n.t.UserSettingsPage.contactUs.alertConfirm,
                    handler: () => onContactUsClick(),
                  },
                ],
              })
            }
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={helpCircleOutline}
            />
            {i18n.t.UserSettingsPage.contactUs.button}
          </IonItem>

          {isEmployee && (
            <IonItem
              button
              data-cy="UserSettingsPage-adminPage"
              routerDirection="forward"
              onClick={() => history.push(backofficePageUrl())}
            >
              <IonItemIcon
                className={commonCss.itemIconAvatar}
                color="none"
                slot="start"
                icon={businessOutline}
              />
              {i18n.t.UserSettingsPage.contactUs.supportUserInterface}
            </IonItem>
          )}
        </IonCard>

        {teams.length > 1 && (
          <>
            <IonLabel className="ion-padding-horizontal">
              {i18n.t.UserSettingsPage.myCommunities}
            </IonLabel>
            <TeamSwitcher screenName={screenName} />
          </>
        )}

        <IonCard className={commonCss.card}>
          <IonItem
            href={marketingSitePrivacyPolicyUrl(
              environment().marketingSiteConfig(),
            )}
            target="_blank"
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={eyeOutline}
            />
            {i18n.t.UserSettingsPage.privacyPolicy}
          </IonItem>

          <IonItem
            href={marketingSiteTermsAndConditionsUrl(
              environment().marketingSiteConfig(),
            )}
            target="_blank"
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={bookOutline}
            />
            {i18n.t.UserSettingsPage.termsOfUse}
          </IonItem>

          <IonItem onClick={onAppVersionClick}>
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={code}
            />
            {i18n.t.UserSettingsPage.appVersion}
            <IonText slot="end" color="medium">
              {formattedReleaseVersion}
            </IonText>
          </IonItem>
        </IonCard>

        <IonCard className={commonCss.card}>
          <IonItem button onClick={logOut} detail={false}>
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="primary"
              slot="start"
              icon={logOutOutline}
            />
            <IonText color="primary">{i18n.t.UserSettingsPage.logOut}</IonText>
          </IonItem>
        </IonCard>

        <IonCard className={commonCss.card}>
          <IonItem
            button
            detail={false}
            routerLink={leaveTeamPageUrl({ teamId })}
            routerDirection="forward"
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="danger"
              slot="start"
              icon={closeOutline}
            />
            <IonText color="danger">
              {i18n.t.UserSettingsPage.leaveCommunity}
            </IonText>
          </IonItem>
          <IonItem
            button
            detail={false}
            onClick={async () => {
              await presentDeleteAccountActionSheet();
            }}
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="danger"
              slot="start"
              icon={trashOutline}
            />
            <IonText color="danger">
              {i18n.t.UserSettingsPage.deleteAccount}
            </IonText>
          </IonItem>
        </IonCard>
      </IonContent>
      <InviteNeighborModal
        isOpen={isOpenInviteNeighborModal}
        dismissInviteNeighborModal={() => setIsOpenInviteNeighborModal(false)}
        inviteCode={inviteCode}
        track={track}
        teamId={teamId}
        screenName={screenName}
        teamName={name}
      />
      <ShareAppModal
        isOpen={isOpenShareAppModal}
        dismissModal={() => setIsOpenShareAppModal(false)}
        track={track}
        teamId={teamId}
        screenName={screenName}
      />
    </IonPage>
  );
};

export default UserSettingsPage;
