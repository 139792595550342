import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { userSettingsPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { get } from "lodash";
import { useCallback } from "react";
import FormSingleSelect from "src/common/form/FormSingleSelect";
import useScreenTracking from "src/firebase/useScreenTracking";
import { useFieldInputs } from "src/state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "src/state/formState/useFormState";
import I18nContainer, { LocaleTag } from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { UserOnboardingTranslationLanguageSlideFormValues } from "src/userOnboarding/UserOnboardingTranslationLanguageSlide";
import localeToTranslationLanguage from "src/utilities/i18n/localeToTranslationLanguage";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import mapObjectToFormSelectOptions from "src/utilities/mapObjectToFormSelectOptions";
import sortFormSelectOptionsAlphabetically from "src/utilities/sortFormSelectOptionsAlphabetically";
import commonCss from "../../common/common.module.css";
import updateUserProfileAndTeamProfile from "../updateUserAndTeamUserProfiles";

type LanguageSettingsPageProps = EmptyObject;

type CurrentLocaleFormValues = {
  locale: LocaleTag;
};

const LanguageSettingsPage: React.FC<LanguageSettingsPageProps> = () => {
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const { teamId, userProfile } = TeamContextContainer.useContainer();

  const screenName = "LanguageSettingsPage";
  useScreenTracking(screenName, teamId);

  const [presentToast, dismissToast] = useIonToast();
  const localeFormState = useFormState<CurrentLocaleFormValues>({
    initial: {
      locale: i18n.currentLocale,
    },
  });

  const localeFormFields = useFieldInputs(
    localeFormState,
    useFieldStates(localeFormState),
  );

  const { form: localeForm, submit: submitLocaleForm } = useForm(
    localeFormState,
    {
      onSubmit: useCallback(
        async (formValues) => {
          try {
            dontAwait(
              track({
                eventName: "Locale Selected",
                "screen name": screenName,
                "new locale": formValues.locale,
              }),
            );
            i18n.setCurrentLocale(formValues.locale);
            dontAwait(
              presentToast({
                message: i18n.t.LanguageSettingsPage.updatedLocale,
                color: "success",
                position: "bottom",
                duration: 3000,
              }),
            );
          } catch (error) {
            const message = `${i18n.t.common.genericError} ${get(
              error,
              "message",
            )}`;
            dontAwait(
              presentToast({
                message,
                color: "danger",
                duration: 5000,
                position: "bottom",
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                    handler: () => dismissToast(),
                  },
                ],
              }),
            );
          }
        },
        [dismissToast, i18n, presentToast, track],
      ),
    },
  );

  const messageTranslationFormState =
    useFormState<UserOnboardingTranslationLanguageSlideFormValues>({
      initial: {
        language: userProfile.data().language,
      },
    });

  const messageTranslationFormFields = useFieldInputs(
    messageTranslationFormState,
    useFieldStates(messageTranslationFormState),
  );

  const { form: messageTranslationForm, submit: submitMessageTranslationForm } =
    useForm(messageTranslationFormState, {
      onSubmit: useCallback(
        async (formValues) => {
          try {
            await updateUserProfileAndTeamProfile({
              userProfile: {
                updatedUserProfile: formValues,
                originalUserProfile: userProfile,
              },
              teamId,
              track,
            });
            dontAwait(
              presentToast({
                message: i18n.t.LanguageSettingsPage.successfulUpdate,
                color: "success",
                position: "bottom",
                duration: 3000,
              }),
            );
          } catch (error) {
            const message = `${i18n.t.common.genericError} ${get(
              error,
              "message",
            )}`;
            dontAwait(
              presentToast({
                message,
                color: "danger",
                duration: 5000,
                position: "bottom",
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                    handler: () => dismissToast(),
                  },
                ],
              }),
            );
          }
        },
        [
          dismissToast,
          i18n.t.LanguageSettingsPage.successfulUpdate,
          i18n.t.common.genericError,
          presentToast,
          teamId,
          track,
          userProfile,
        ],
      ),
    });
  return (
    <IonPage>
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={userSettingsPageUrl({ teamId })}
            />
          </IonButtons>
          <IonTitle>{i18n.t.LanguageSettingsPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={commonCss.contentContainer}>
        <IonCard className={commonCss.card}>
          <>
            <form {...localeForm}>
              <FormSingleSelect
                {...localeFormFields.locale}
                data-cy="LanguageSettingsPage-changeLocale"
                label={i18n.t.LanguageSettingsPage.localeLabel}
                options={sortFormSelectOptionsAlphabetically(
                  mapObjectToFormSelectOptions<Record<LocaleTag, string>>(
                    i18n.t.common.locales,
                  ),
                )}
                onChange={(e) => {
                  if (e !== localeFormFields.locale.value) {
                    localeFormFields.locale.onChange(e);
                    submitLocaleForm();
                  }
                }}
                buttonLabels={i18n.t.common}
              />
            </form>
            <div className="ion-margin">
              <IonText color="medium">
                <em>{i18n.t.LanguageSettingsPage.localeText}</em>
              </IonText>
            </div>
          </>
        </IonCard>
        <IonCard className={commonCss.card}>
          <IonItem
            lines={
              !messageTranslationFormFields.language.value ? "none" : "full"
            }
          >
            <IonToggle
              data-cy="UserOnboardingTranslationLanguageSlide-toggle"
              checked={!!messageTranslationFormFields.language.value}
              onIonChange={(e) => {
                if (e.detail.checked) {
                  messageTranslationFormFields.language.onChange(
                    userProfile.data().language ??
                      localeToTranslationLanguage[i18n.currentLocale],
                  );
                } else messageTranslationFormFields.language.onChange(null);
                submitMessageTranslationForm();
              }}
            />
            <IonLabel className="ion-margin">
              {i18n.t.LanguageSettingsPage.toggletext(
                !!messageTranslationFormFields.language.value,
              )}
            </IonLabel>
          </IonItem>

          {messageTranslationFormFields.language.value ? (
            <>
              <form {...messageTranslationForm}>
                <FormSingleSelect
                  data-cy="UserOnboardingTranslationLanguageSlide-select"
                  {...messageTranslationFormFields.language}
                  label={
                    i18n.t.UserOnboardingTranslationLanguageSlide.selectLabel
                  }
                  options={mapObjectToFormSelectOptions(i18n.t.common.language)}
                  onChange={(e) => {
                    if (e !== messageTranslationFormFields.language.value) {
                      messageTranslationFormFields.language.onChange(e);
                      submitMessageTranslationForm();
                    }
                  }}
                  buttonLabels={i18n.t.common}
                />
              </form>
              <div className="ion-margin">
                <IonText color="medium">
                  <em>
                    {i18n.t.LanguageSettingsPage.enabledText(
                      i18n.t.common.language[
                        messageTranslationFormFields.language.value
                      ],
                    )}
                  </em>
                </IonText>
              </div>
            </>
          ) : (
            <div className="ion-margin">
              <IonText color="medium">
                <em>{i18n.t.LanguageSettingsPage.deactivatedText}</em>
              </IonText>
            </div>
          )}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default LanguageSettingsPage;
