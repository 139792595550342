import ImgixClient from "@imgix/js-core";
import reactEnvironment from "./reactEnvironment";

type ImgixConfig =
  | {
      imgixEnabled: true;
      imgixDomain: string;
      storageBucket: string;
    }
  | { imgixEnabled: false };

export function getImgixConfig(): ImgixConfig {
  const imgixEnabled = reactEnvironment().getEnvVarBoolean(
    "REACT_APP_IMGIX_ENABLED",
    {
      requiredIn: ["production"],
    },
  );

  if (imgixEnabled) {
    return {
      imgixEnabled,
      imgixDomain: reactEnvironment().requireEnvVar("REACT_APP_IMGIX_DOMAIN"),
      storageBucket: reactEnvironment().requireEnvVar(
        "REACT_APP_FIREBASE_STORAGE_BUCKET",
      ),
    };
  }

  return {
    imgixEnabled: false,
  };
}

type ImgixOptions = {
  w: number;
  h: number;
};

export default function constructImgixUrl(
  imageUrl: string,
  imgixOptions: ImgixOptions,
  imgixConfig: ImgixConfig = getImgixConfig(),
): string {
  if (!imgixConfig.imgixEnabled) {
    return imageUrl;
  }

  const imgixClient = new ImgixClient({
    domain: imgixConfig.imgixDomain,
  });

  const url = new URL(imageUrl);
  const pathName = url.pathname;

  const storageBucketPrefix = `/v0/b/${imgixConfig.storageBucket}/o/`;

  const imageNotHostedOnBucket =
    pathName.slice(0, storageBucketPrefix.length) !== storageBucketPrefix;

  if (imageNotHostedOnBucket) {
    return imageUrl;
  }

  const imagePath = decodeURIComponent(
    pathName.slice(storageBucketPrefix.length),
  );

  return imgixClient.buildURL(imagePath, imgixOptions);
}
