import React, { useMemo } from "react";
import { EMPTY } from "src/utilities/createContainer";

export type ChannelMessagesPageContextValue = {
  setAttachmentModalOpen(value: boolean): void;
};

const ChannelMessagesPageContext = React.createContext<
  ChannelMessagesPageContextValue | typeof EMPTY
>(EMPTY);

export function useChannelMessagesPageContext(): ChannelMessagesPageContextValue {
  const value = React.useContext(ChannelMessagesPageContext);
  if (value === EMPTY) {
    throw new Error(
      "Component must be wrapped with <ChannelMessagesPageContextProvider>",
    );
  }
  return value;
}

const ChannelMessagesPageContextProvider: React.FC<
  ChannelMessagesPageContextValue
> = ({ children, ...context }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => context, Object.values(context));

  return (
    <ChannelMessagesPageContext.Provider value={contextValue}>
      {children}
    </ChannelMessagesPageContext.Provider>
  );
};

export default ChannelMessagesPageContextProvider;
