import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import firebase from "firebase/compat/app";

export type TeamUserProfileGroup = {
  floor: TeamUserProfile["floor"];
  building: TeamUserProfile["building"];
  teamLocationId: TeamUserProfile["teamLocationId"];
  teamUserProfiles: firebase.firestore.QueryDocumentSnapshot<TeamUserProfile>[];
};

/**
 * Hashing function for a TeamUserProfile. Must return a string which is the
 * same for any TeamUserProfile that should belong to the same group.
 */
function groupKey(teamUserProfile: TeamUserProfile): string {
  return [
    teamUserProfile.teamLocationId,
    teamUserProfile.building,
    teamUserProfile.floor,
  ].join(",");
}

/**
 * Returns an unordered list of TeamUserProfiles grouped by common features.
 */
export function groupUserProfiles(
  teamUserProfiles: firebase.firestore.QueryDocumentSnapshot<TeamUserProfile>[],
): TeamUserProfileGroup[] {
  const groupKeyToGroups: Record<string, TeamUserProfileGroup> = {};

  teamUserProfiles.forEach((teamUserProfile) => {
    const key = groupKey(teamUserProfile.data());

    if (!groupKeyToGroups[key]) {
      groupKeyToGroups[key] = {
        building: teamUserProfile.data().building,
        floor: teamUserProfile.data().floor,
        teamLocationId: teamUserProfile.data().teamLocationId,
        teamUserProfiles: [],
      };
    }

    groupKeyToGroups[key]?.teamUserProfiles.push(teamUserProfile);
  });

  return Object.values(groupKeyToGroups);
}
