/* eslint-disable @typescript-eslint/no-unused-vars */
import { InternalStreamChannel } from "@connectedliving/common/lib/stream/InternalStreamChannel";
import { viewUserProfilePageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { UserResponse } from "stream-chat";
import { MessageList } from "stream-chat-react";

export type StreamMessageListProps = {
  teamId: string;
  streamChannel: InternalStreamChannel;
};

const StreamMessageList: React.FC<StreamMessageListProps> = ({ teamId }) => {
  const history = useHistory();

  const onMentionsClick: (
    event: React.BaseSyntheticEvent,
    mentioned_users: UserResponse[],
  ) => void = useCallback(
    (event, mentioned_users) => {
      const eventTarget = event.target as HTMLElement;

      const mentionCssClass = "str-chat__message-mention";
      if (eventTarget.className.search(mentionCssClass) === -1) return;

      const containerElement = eventTarget.closest(".str-chat__message-text");
      if (!containerElement) return;
      const mentionDomElements = Array.from(
        containerElement.querySelectorAll(`.${mentionCssClass}`),
      );
      const mentionIndex = mentionDomElements.indexOf(eventTarget);

      const mentionedUser = mentioned_users[mentionIndex];
      if (!mentionedUser) return;

      history.push(
        viewUserProfilePageUrl({
          teamId,
          userProfileId: mentionedUser.id,
        }),
      );
    },
    [history, teamId],
  );

  return <MessageList {...{ onMentionsClick }} />;
};

export default StreamMessageList;
