/* eslint-disable no-bitwise */
// https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript/7616484#7616484

const stringHash = (str: string | null | undefined): number => {
  let hash = 0;
  let i;
  let chr;
  if (!str) return hash;
  for (i = 0; i < str.length; i += 1) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};
export default stringHash;
