import { IonSpinner } from "@ionic/react";
import commonCss from "./common.module.css";

const FullscreenSpinner: React.FC = () => (
  <div className={`${commonCss.fullscreenContainer} ${commonCss.flexColumn}`}>
    <IonSpinner color="primary" />
  </div>
);

export default FullscreenSpinner;
