import { getString, StrictDocumentData } from "./dataHelpers";

export type TeamChannelMembership = {
  teamId: string;
  teamChannelId: string;
  userId: string;
};

export function teamChannelMembershipToFirestoreData(
  teamChannelMembership: TeamChannelMembership,
): TeamChannelMembership {
  return teamChannelMembership;
}

export function firestoreDataToTeamChannelMembership(
  data: StrictDocumentData,
): TeamChannelMembership {
  return {
    userId: getString(data.userId),
    teamChannelId: getString(data.teamChannelId),
    teamId: getString(data.teamId),
  };
}
