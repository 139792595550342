import firebase from "firebase/compat/app";
import {
  isFirestoreAppTimestamp,
  makeTimestampValueFromFirestoreAppTimestamp,
} from "./app/firestoreAppTimestamp";
import {
  ChannelBackingStorage,
  channelBackingStorageValues,
} from "./ChannelBackingStorage";
import { channelPurposeValues } from "./ChannelPurpose";
import {
  getBoolean,
  getList,
  getOptionalStrictDocumentData,
  getOptionalStringLiteral,
  getString,
  getStringLiteral,
  StrictDocumentData,
} from "./dataHelpers";
import {
  firestoreDataToMessage,
  messageToFirestoreData,
} from "./MessageConverter";
import { channelIconColors, channelIcons, TeamChannel } from "./TeamChannel";

export function teamChannelToFirestoreData(
  teamChannel: Partial<TeamChannel>,
): StrictDocumentData {
  return {
    ...teamChannel,
    ...(teamChannel.latestMessage && {
      latestMessage: messageToFirestoreData(teamChannel.latestMessage),
    }),
  };
}

export function firestoreDataToTeamChannel(
  data: StrictDocumentData,
): TeamChannel {
  const latestMessageData = getOptionalStrictDocumentData(data.latestMessage);
  return {
    teamId: getString(data.teamId),
    name: getString(data.name),
    createdBy: getString(data.createdBy),
    streamChannelId: getString(data.streamChannelId),
    channelIcon: getStringLiteral(data.channelIcon, {
      permitted: channelIcons,
      fallback: "chatbubbles",
    }),
    channelIconColor: getStringLiteral(data.channelIconColor, {
      permitted: channelIconColors,
      fallback: "sky-blue",
    }),
    purpose: getOptionalStringLiteral(data.purpose, {
      permitted: channelPurposeValues,
    }),
    autoAddNewTeamMembers: getBoolean(data.autoAddNewTeamMembers),
    backingStorage: getStringLiteral(data.backingStorage, {
      permitted: channelBackingStorageValues,
      fallback: ChannelBackingStorage.Stream,
    }),
    latestMessage:
      latestMessageData && firestoreDataToMessage(latestMessageData),
    messageTimestamps: getList(data.messageTimestamps, {
      element: isFirestoreAppTimestamp,
    }).map(makeTimestampValueFromFirestoreAppTimestamp),
  };
}

const TeamChannelConverter: firebase.firestore.FirestoreDataConverter<TeamChannel> =
  Object.freeze({
    toFirestore(teamChannel: TeamChannel) {
      return teamChannelToFirestoreData(teamChannel);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TeamChannel {
      return firestoreDataToTeamChannel(snapshot.data(options));
    },
  });

export default TeamChannelConverter;
