import { InternalStreamChannel } from "@connectedliving/common/lib/stream/InternalStreamChannel";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import {
  IonAvatar,
  IonBadge,
  IonIcon,
  IonItem,
  IonLabel,
  RouterDirection,
} from "@ionic/react";
import * as allIcons from "ionicons/icons";
import { first } from "lodash";
import React, { useEffect, useState } from "react";
import ChannelAvatarContent, {
  ChannelAvatarContentProps,
} from "src/common/ChannelAvatarContent";
import I18nContainer from "src/state/i18n/I18nContainer";
import StreamChannelsCacheContainer from "src/state/stream/StreamChannelsCacheContainer";
import StreamChatContainer from "src/state/stream/StreamChatContainer";
import {
  dataAvailable,
  isErrorState,
  LoadingState,
} from "src/utilities/LoadingState";
import { EventTypes } from "stream-chat";
import css from "./StreamChannelPreview.module.css";

type ChannelMessage = InternalStreamChannel["state"]["messages"][number];

type MessagePreview = {
  preview: JSX.Element | undefined | string;
  user?: ChannelMessage["user"];
};

function attachmentMessagePreview(
  attachment: NonNullable<ChannelMessage["attachments"]>[number],
  latestMessage: NonNullable<ChannelMessage>,
) {
  let attachmentIcon: string;

  if (attachment.type === "file") {
    attachmentIcon = allIcons.document;
  } else if (attachment.type === "image") {
    attachmentIcon = allIcons.camera;
  } else if (attachment.type === "video") {
    attachmentIcon = allIcons.videocam;
  } else {
    attachmentIcon = allIcons.attach;
  }

  return (
    <>
      <IonIcon
        className={css.messagePreviewIcon}
        icon={attachmentIcon}
        size="small"
      />{" "}
      {attachment.title || latestMessage.text || attachment.type}
    </>
  );
}

type RenderLabelProps = {
  loadingState: LoadingState;
  latestMessagePreview: MessagePreview;
};

export type RenderLabelFunction = React.FC<RenderLabelProps>;

type StreamChannelPreviewProps = {
  channelType: StreamChannelType;
  channelId: string;
  channelAvatar: ChannelAvatarContentProps["content"];
  userId: string;
  routerLink: string;
  loadingState: LoadingState;
  renderLabel: RenderLabelFunction;
  routerDirection?: RouterDirection;
  active?: boolean;
};
const StreamChannelPreview: React.FC<StreamChannelPreviewProps> = ({
  channelType,
  channelId,
  channelAvatar,
  userId,
  routerLink,
  loadingState,
  renderLabel,
  routerDirection,
  active,
}) => {
  const { streamChat } = StreamChatContainer.useContainer();
  const { getStreamChannel } = StreamChannelsCacheContainer.useContainer();
  const { value: streamChannel, loadingState: streamChannelLoadingState } =
    getStreamChannel(channelType, channelId);
  const [unreadMessageCount, setUnreadMessageCount] = useState(
    dataAvailable(streamChannelLoadingState)
      ? streamChannel.countUnread(streamChannel.lastRead())
      : 0,
  );
  const i18n = I18nContainer.useContainer();

  function getMessagePreview(
    message: ChannelMessage | undefined,
  ): MessagePreview {
    let preview: JSX.Element | string | undefined;
    if (message) {
      if (message.deleted_at) {
        preview = i18n.t.ChannelPreview.messageDeleted;
      } else {
        const attachment = first(message?.attachments);
        if (attachment) {
          preview = attachmentMessagePreview(attachment, message);
        } else {
          preview = message.text;
        }
      }
    }

    return { preview, user: message?.user };
  }

  useEffect(() => {
    if (dataAvailable(streamChannelLoadingState)) {
      setUnreadMessageCount(
        streamChannel.countUnread(streamChannel.lastRead()),
      );
    }
  }, [streamChannel, streamChannelLoadingState]);

  useEffect(() => {
    function handleEvent() {
      setUnreadMessageCount(
        streamChannel.countUnread(streamChannel.lastRead()),
      );
    }

    const subscribedEvents: EventTypes[] = [
      "message.new",
      "message.updated",
      "message.deleted",
      "message.read",
    ];

    subscribedEvents.forEach((event) => streamChannel.on(event, handleEvent));
    streamChat.on("connection.recovered", handleEvent);

    return () => {
      subscribedEvents.forEach((event) =>
        streamChannel.off(event, handleEvent),
      );
      streamChat.off("connection.recovered", handleEvent);
    };
  }, [streamChannel, streamChat]);

  const latestMessagePreview = getMessagePreview(streamChannel.lastMessage());

  const messagePreviewPrefix = () => {
    const { user } = latestMessagePreview;
    if (!user) return "";
    if (user.id === userId) {
      return `${i18n.t.common.you}: `;
    }
    if (channelType === "team") return `${user.name}: `;
    return "";
  };

  latestMessagePreview.preview = (
    <>
      {messagePreviewPrefix()}
      {latestMessagePreview.preview}
    </>
  );
  return (
    <IonItem
      data-cy="ChannelPreview-item"
      {...{ routerLink }}
      routerDirection={routerDirection ?? "forward"}
      detail={false}
      disabled={isErrorState(loadingState)}
      className={`${active ? css.isActive : ""} ${css.root}`}
    >
      <IonAvatar
        slot="start"
        className={
          channelAvatar.type === "icon" ? css.iconAvatar : css.channelAvatar
        }
      >
        <ChannelAvatarContent content={channelAvatar} />
      </IonAvatar>

      <IonLabel>
        {renderLabel({
          loadingState,
          latestMessagePreview,
        })}
      </IonLabel>

      {unreadMessageCount > 0 && (
        <IonBadge color="primary" slot="end">
          {unreadMessageCount}
        </IonBadge>
      )}

      {isErrorState(loadingState) && (
        <IonIcon icon={allIcons.alertCircleOutline} color="danger" />
      )}
    </IonItem>
  );
};

export default StreamChannelPreview;
