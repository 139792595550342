import exhausted from "./lang/exhausted";
import { isErrorState, LoadingState } from "./LoadingState";

function combineTwoLoadingStates(
  loadingStateA: LoadingState,
  loadingStateB: LoadingState,
): LoadingState {
  if (isErrorState(loadingStateA)) return loadingStateA;
  if (isErrorState(loadingStateB)) return loadingStateB;

  if (
    loadingStateA === "networkUnavailable" ||
    loadingStateB === "networkUnavailable"
  )
    return "networkUnavailable";

  if (loadingStateA === "initialLoad" || loadingStateB === "initialLoad")
    return "initialLoad";

  if (loadingStateA === "updating" || loadingStateB === "updating")
    return "updating";

  if (loadingStateA === "ready" || loadingStateB === "ready") return "ready";

  throw exhausted(loadingStateA && loadingStateB);
}

export default function combineLoadingStates(
  loadingState1: LoadingState,
  loadingState2: LoadingState,
  ...loadingStates: LoadingState[]
): LoadingState {
  return [loadingState2, ...loadingStates].reduce(
    combineTwoLoadingStates,
    loadingState1,
  );
}
