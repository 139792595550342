import {
  IonAvatar,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
} from "@ionic/react";

const StreamChannelSkeleton: React.FC = () => (
  <div className="str-chat str-chat-channel">
    <IonList lines="none">
      <IonItem className="ion-text-end">
        <IonAvatar slot="end">
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel>
          <IonSkeletonText
            animated
            style={{ display: "inline-block", width: "80%" }}
          />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start">
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel>
          <IonSkeletonText
            animated
            style={{ display: "inline-block", width: "50%" }}
          />
        </IonLabel>
      </IonItem>
      <IonItem className="ion-text-end">
        <IonAvatar slot="end">
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel>
          <IonSkeletonText
            animated
            style={{ display: "inline-block", width: "70%" }}
          />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start">
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel>
          <IonSkeletonText
            animated
            style={{ display: "inline-block", width: "30%" }}
          />
        </IonLabel>
      </IonItem>
      <IonItem>
        <IonAvatar slot="start">
          <IonSkeletonText animated />
        </IonAvatar>
        <IonLabel>
          <IonSkeletonText
            animated
            style={{ display: "inline-block", width: "60%" }}
          />
        </IonLabel>
      </IonItem>
    </IonList>
  </div>
);

export default StreamChannelSkeleton;
