import firebase from "firebase/compat/app";
import { isString } from "lodash";
import {
  convertTimestampValueToFirebaseAppTimestamp,
  getOptionalFirestoreAppTimestampValue,
} from "./app/firestoreAppTimestamp";
import { ChannelBackingStorage } from "./ChannelBackingStorage";
import {
  getBoolean,
  getList,
  getNumber,
  getOptionalString,
  getString,
  getStringLiteral,
  getValue,
  StrictDocumentData,
} from "./dataHelpers";
import isBuildingPart from "./isBuildingPart";
import isUtmParams from "./isUtmParams";
import { Team } from "./Team";

function firestoreDataToTeam(data: StrictDocumentData): Team {
  return {
    creatorId: getOptionalString(data.creatorId),
    name: getString(data.name),
    inviteCode: getString(data.inviteCode),
    starterKitSentAt: getOptionalFirestoreAppTimestampValue(
      data.starterKitSentAt,
    ),
    numberOfApartments: getNumber(data.numberOfApartments),
    buildingParts: getList(data.buildingParts, { element: isBuildingPart }),
    userIds: getList(data.userIds, { element: isString }),
    utmParams: getValue(data.utmParams, {
      typeCheck: isUtmParams,
      fallback: {
        utm_campaign: null,
        utm_content: null,
        utm_item: null,
        utm_medium: null,
        utm_source: null,
        utm_term: null,
      },
    }),
    isArchived: getBoolean(data.isArchived),
    channelBackingStorage: getStringLiteral(data.channelBackingStorage, {
      permitted: [
        ChannelBackingStorage.Firestore,
        ChannelBackingStorage.Stream,
      ],
      fallback: ChannelBackingStorage.Stream,
    }),
  };
}

function teamToFirestoreData(team: Team): Record<string, unknown> {
  return {
    ...team,
    starterKitSentAt: team.starterKitSentAt
      ? convertTimestampValueToFirebaseAppTimestamp(team.starterKitSentAt)
      : null,
  };
}

const TeamConverter: firebase.firestore.FirestoreDataConverter<Team> =
  Object.freeze({
    toFirestore(team: Team) {
      return teamToFirestoreData(team);
    },

    fromFirestore(snapshot: firebase.firestore.QueryDocumentSnapshot): Team {
      return firestoreDataToTeam(snapshot.data());
    },
  });

export default TeamConverter;
