import { customAlphabet } from "nanoid";

function uniqueGlobalId(options: { length?: number } = {}): string {
  const nanoid = customAlphabet(
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz",
    options.length || 10,
  );
  return nanoid();
}

export default uniqueGlobalId;
