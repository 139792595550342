import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  channelsListPageUrl,
  getStartedPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonCardTitle, IonImg } from "@ionic/react";
import { useEffect } from "react";
import { Redirect } from "react-router";
import InfoCard, { InfoCardText } from "src/common/InfoCard";
import InviteCodeFormCard from "src/common/InviteCodeFormCard";
import useEmailActionSheet from "src/common/useEmailActionSheet";
import OnboardingPageLayout from "src/share/OnboardingPageLayout";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import infoCardCss from "../common/InfoCard.module.css";

const ClosedBetaPage: React.FC = () => {
  const { authUser } = FirebaseAppContainer.useContainer();
  const { userProfile } = LoggedInUserProfileContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const supportEmail = requireEnvVar("REACT_APP_SUPPORT_EMAIL");
  const emailActionSheet = useEmailActionSheet(supportEmail);

  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  const screenName = "ClosedBetaPage";

  const firstTeamId = userProfile.teamIds[0];

  assertPresent(authUser, {
    because: "<App> waits for user to log in",
  });
  useEffect(() => {
    dontAwait(
      track({
        eventName: "Screen Viewed",
        "screen name": screenName,
        "team id": undefined,
      }),
    );
  }, [track]);

  if (firstTeamId) {
    return (
      <Redirect
        to={channelsListPageUrl({
          teamId: firstTeamId,
        })}
      />
    );
  }
  return (
    <OnboardingPageLayout
      backButtonUrl={getStartedPageUrl()}
      title={i18n.t.ClosedBetaPage.title}
      subtitle={i18n.t.ClosedBetaPage.subtitle}
      screenName={screenName}
    >
      <InviteCodeFormCard />
      <InfoCard
        cardTitle={
          <IonCardTitle>{i18n.t.ClosedBetaPage.inviteCard.title}</IonCardTitle>
        }
      >
        <div className={infoCardCss.illustrationContentLayout}>
          <InfoCardText>
            {i18n.t.ClosedBetaPage.inviteCard.body(async () => {
              await emailActionSheet();
            })}
          </InfoCardText>
          <IonImg
            src="/assets/illustrations/invitation.png"
            alt={i18n.t.ClosedBetaPage.illustrationAltText}
            className="ion-margin-start"
          />
        </div>
      </InfoCard>
    </OnboardingPageLayout>
  );
};

export default ClosedBetaPage;
