import axios from "axios";
import { getString } from "./firestore/dataHelpers";

export type DynamicLinksConfig = {
  apiKey: string;
  domainUriPrefix: string;
};

type FirebaseDynamicLinksResponse = {
  data: { shortLink: string; previewLink: string };
};

export default async function createDynamicLink({
  apiKey,
  domainUriPrefix,
  targetUrl,
}: DynamicLinksConfig & {
  targetUrl: string;
}): Promise<string> {
  const response: FirebaseDynamicLinksResponse = await axios.post(
    `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`,
    {
      dynamicLinkInfo: {
        domainUriPrefix,
        link: targetUrl,
      },
      suffix: {
        option: "short",
      },
    },
  );

  const shortLink = getString(response.data.shortLink);
  return shortLink;
}
