import React from "react";

export type SafeAreaSpacerProps = { position: "top" | "bottom" };

const SafeAreaSpacer: React.FC<SafeAreaSpacerProps> = ({ position }) => (
  <div
    style={{
      height: `var(--ion-safe-area-${position})`,
    }}
  />
);

export default SafeAreaSpacer;
