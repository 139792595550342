import { profileImagePath } from "@connectedliving/common/lib/firebase/storagePathBuilders";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import firebase from "firebase/compat/app";
import { MixpanelClient } from "src/state/mixpanel/MixpanelContainer";
import { UserProfileDocumentRef } from "src/utilities/UserProfileDocumentRef";

export default async function uploadProfileImage(
  file: File | undefined,
  firebaseApp: firebase.app.App,
  authUser: firebase.User,
  userProfileDocumentRef: UserProfileDocumentRef,
  track: MixpanelClient["track"],
): Promise<void> {
  if (!file) {
    return;
  }
  const storageRef = firebaseApp.storage().ref();
  const fileName = `${new Date().getTime()}-${file.name}`;
  const path = profileImagePath({ userId: authUser.uid, fileName });

  const ref = storageRef.child(path);

  const snapshot = await ref.put(file);
  const downloadUrl = await snapshot.ref.getDownloadURL();
  await userProfileDocumentRef.update({ imageUrl: downloadUrl });

  dontAwait(
    track({
      eventName: "User Profile Updated",
      "team id": "teamid",
      "user fields updated": ["imageUrl"],
    }),
  );
}
