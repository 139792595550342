import blindCast from "./lang/blindCast";
import { PathBuilder } from "./PathBuilder";

type Params<K extends string> = {
  [key in K]: string;
};
export type ExclusivePathStringParser<K extends string> = (
  pathString: string,
) => Params<K>;

export type PathStringParser<K extends string> = (
  pathString: string,
) => Params<K> | undefined;

export default function makePathStringParser<K extends string>(
  pathBuilder: PathBuilder<K>,
  paramNames: K[],
  options: { throwUnlessMatch: true },
): ExclusivePathStringParser<K>;
export default function makePathStringParser<K extends string>(
  pathBuilder: PathBuilder<K>,
  paramNames: K[],
  options: { throwUnlessMatch: false },
): PathStringParser<K>;
export default function makePathStringParser<K extends string>(
  pathBuilder: PathBuilder<K>,
  paramNames: K[],
): ExclusivePathStringParser<K>;
export default function makePathStringParser<K extends string>(
  pathBuilder: PathBuilder<K>,
  paramNames: K[],
  options: { throwUnlessMatch: boolean } = { throwUnlessMatch: true },
): PathStringParser<K> {
  const params = blindCast<
    Params<K>,
    "type information is lost in reduce() call"
  >(
    paramNames.reduce(
      (result, paramName) => ({
        ...result,
        [paramName]: `(?<${paramName}>[^/]+)`,
      }),
      {},
    ),
  );

  const pathPattern = pathBuilder(params);
  const regexpString =
    pathPattern[0] === "/" ? `/?${pathPattern.slice(1)}` : pathPattern;
  const regexp = new RegExp(regexpString);

  return (pathString) => {
    const match = pathString.match(regexp);

    if (!match) {
      if (options.throwUnlessMatch)
        throw new Error(
          `Path string "${pathString}" failed to match pattern ${regexpString.toString()}`,
        );
      return undefined;
    }

    return blindCast<
      Params<K>,
      "if the regex matches the named capture groups must be present"
    >(match.groups);
  };
}
