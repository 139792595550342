import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import channelTrackingProps, {
  ChannelTrackingContext,
} from "@connectedliving/common/lib/utilities/tracking/channelTrackingProps";
import { IonButton, IonIcon, IonToast } from "@ionic/react";
import { languageOutline } from "ionicons/icons";
import { useCallback, useState } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import { languageName } from "src/utilities/languageName";
import environment from "../../utilities/environment";

type MessageTranslationButtonProps = {
  onShowTranslationChange: (value: boolean) => void;
  showTranslation: boolean;
  channelTrackingContext: ChannelTrackingContext;
  teamId: string;
  userProfile: UserProfile;
};

const MessageTranslationButton: React.FC<MessageTranslationButtonProps> = ({
  onShowTranslationChange,
  showTranslation,
  channelTrackingContext,
  userProfile,
  teamId,
}) => {
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const [translationEnabledToastVisible, setTranslationEnabledToastVisible] =
    useState(false);
  const [translationDisabledToastVisible, setTranslationDisabledToastVisible] =
    useState(false);
  const onToggleInlineTranslationClick = useCallback(() => {
    const newValue = !showTranslation;
    onShowTranslationChange(newValue);
    setTranslationEnabledToastVisible(newValue);
    setTranslationDisabledToastVisible(!newValue);

    dontAwait(
      track({
        eventName: "Translation Button Clicked",
        ...channelTrackingProps({
          teamId,
          channel: channelTrackingContext,
          supportUserId: environment().supportUserId(),
        }),
        "show translation": newValue,
      }),
    );
  }, [
    showTranslation,
    onShowTranslationChange,
    track,
    teamId,
    channelTrackingContext,
  ]);

  return (
    <>
      <IonToast
        isOpen={translationEnabledToastVisible}
        onDidDismiss={() => setTranslationEnabledToastVisible(false)}
        message={i18n.t.ChannelsMessagesHeader.autoTranslate(
          userProfile,
          languageName,
        )}
        duration={2000}
        position="bottom"
        color="dark"
      />
      <IonToast
        isOpen={translationDisabledToastVisible}
        onDidDismiss={() => setTranslationDisabledToastVisible(false)}
        message={i18n.t.ChannelsMessagesHeader.autoTranslateDisabled}
        duration={2000}
        position="bottom"
        color="dark"
      />
      <IonButton
        color={showTranslation ? "primary" : "dark"}
        onClick={onToggleInlineTranslationClick}
      >
        <IonIcon color="inherit" icon={languageOutline} />
      </IonButton>
    </>
  );
};

export default MessageTranslationButton;
