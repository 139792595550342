import * as Sentry from "@sentry/browser";
import firebase from "firebase/compat/app";
import { useDocument } from "react-firebase-hooks/firestore";
import { Loadable } from "./Loadable";

export function checkDocumentExistence<T>(
  doc: firebase.firestore.DocumentSnapshot<T>,
): ExistingDocument<T> | NonexistingDocument<T> {
  if (doc.exists) return doc as ExistingDocument<T>;
  return doc as NonexistingDocument<T>;
}

export type ExistingDocument<T> =
  firebase.firestore.QueryDocumentSnapshot<T> & {
    exists: true;
  };
export type NonexistingDocument<T> = firebase.firestore.DocumentSnapshot<T> & {
  exists: false;
  data(): undefined;
};

export type FirestoreDocument<T> = Loadable<
  ExistingDocument<T> | NonexistingDocument<T>
>;

export default function useFirestoreDocument<T>(
  docRef: firebase.firestore.DocumentReference<T> | null | undefined | false,
): FirestoreDocument<T> {
  const [data, isLoading, error] = useDocument(docRef || null) as [
    data: firebase.firestore.DocumentSnapshot<T> | undefined,
    isLoading: boolean,
    error: Error | undefined,
  ];

  if (!docRef) {
    return { loadingState: "initialLoad", data: undefined };
  }

  if (error !== undefined) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(
        `Error subscribing to Firestore document ${docRef.path}\n`,
        error,
      );
    } else {
      Sentry.captureException(error, { extra: { path: docRef.path } });
    }

    return {
      loadingState: { error },
      data: data && checkDocumentExistence(data),
    };
  }

  if (isLoading) {
    if (data) {
      return {
        loadingState: "updating",
        data: checkDocumentExistence(data),
      };
    }

    if (!data) {
      return { loadingState: "initialLoad", data: undefined };
    }
  }

  if (!isLoading) {
    if (!data) {
      return { loadingState: "initialLoad", data: undefined };
    }

    return {
      loadingState: "ready",
      data: checkDocumentExistence(data),
    };
  }

  throw new Error("Unreachable");
}

export function isNotFound<T>(
  document: ExistingDocument<T> | NonexistingDocument<T>,
): document is NonexistingDocument<T> {
  return document.exists === false;
}

export function isFound<T>(
  document: ExistingDocument<T> | NonexistingDocument<T>,
): document is ExistingDocument<T> {
  return document.exists === true;
}

export function isExistingDocument<T>(
  document: firebase.firestore.DocumentSnapshot<T>,
): document is ExistingDocument<T> {
  return document.exists === true;
}
