import { DirectMessageChannel } from "../../firestore/DirectMessageChannel";
import { FirestoreChannelType } from "../../firestore/FirestoreChannelType";
import { TeamChannel } from "../../firestore/TeamChannel";
import { ChannelTrackingProps } from "../../TrackingEvent";
import assertExhausted from "../lang/assertExhausted";

export type ChannelTrackingContext =
  | {
      type: FirestoreChannelType.Team;
      data: TeamChannel;
      path: string;
      id: string;
    }
  | {
      type: FirestoreChannelType.DirectMessage;
      data: DirectMessageChannel;
      path: string;
      id: string;
      userIds: [string, string];
    };

export default function channelTrackingProps({
  teamId,
  channel,
  supportUserId,
}: {
  channel: ChannelTrackingContext;
  teamId: string;
  supportUserId: string;
}): ChannelTrackingProps {
  let channelProperties: Pick<
    ChannelTrackingProps,
    | "channel name"
    | "channel purpose"
    | "is support dm channel"
    | "channel type"
  >;
  if (channel.type === FirestoreChannelType.Team) {
    channelProperties = {
      "channel type": "team",
      "channel purpose": channel.data.purpose,
      "channel name": channel.data.name,
      "is support dm channel": false,
    };
  } else if (channel.type === FirestoreChannelType.DirectMessage) {
    channelProperties = {
      "channel type": "direct message",
      "channel purpose": null,
      "channel name": null,
      "is support dm channel": channel.userIds.includes(supportUserId),
    };
  } else {
    throw assertExhausted(channel);
  }

  return {
    "team id": teamId,
    "channel id": channel.id,
    "channel backing storage": channel.data.backingStorage,
    "channel path": channel.path,
    ...channelProperties,
  };
}
