import { getOptionalStringLiteral } from "@connectedliving/common/lib/firestore/dataHelpers";
import { ArrayElement } from "@connectedliving/common/lib/utilities/ArrayElement";

export const geoCodeAddressComponentTypes = [
  "country",
  "postal_code",
  "street_number",
  "route",
  "locality",
  "administrative_area_level_1",
] as const;
export type GeocodeAddressComponentType = ArrayElement<
  typeof geoCodeAddressComponentTypes
>;

export type GeocodeAddressComponentDict = {
  [key in GeocodeAddressComponentType]?: google.maps.GeocoderAddressComponent;
};

export default function getGeocodeAddressComponentDict(
  geoCodeResult: google.maps.GeocoderResult,
): GeocodeAddressComponentDict {
  const result: GeocodeAddressComponentDict = {};

  for (const component of geoCodeResult.address_components) {
    for (const type of component.types) {
      const knownType = getOptionalStringLiteral(type, {
        permitted: geoCodeAddressComponentTypes,
      });
      if (knownType === null) continue;
      result[knownType] = component;
    }
  }

  return result;
}
