import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { IonAvatar, IonButton, IonText, useIonToast } from "@ionic/react";
import * as Sentry from "@sentry/react";
import { close } from "ionicons/icons";
import { get } from "lodash";
import React, { useCallback, useEffect } from "react";
import ImagePicker from "src/common/ImagePicker";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import ProfileImage from "src/utilities/ProfileImage";
import { useSwiper, useSwiperSlide } from "swiper/react";
import I18nContainer from "../state/i18n/I18nContainer";
import uploadProfileImage from "../userProfiles/uploadProfileImage";
import SafeAreaSpacer from "../utilities/SafeAreaSpacer";
import css from "./UserOnboardingProfileImageSlide.module.css";
import UserOnboardingSlideLayout from "./UserOnboardingSlideLayout";
import layoutCss from "./UserOnboardingSlideLayout.module.css";

type UserOnboardingProfileImageSlideProps = EmptyObject;

const UserOnboardingProfileImageSlide: React.FC<
  UserOnboardingProfileImageSlideProps
> = () => {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const screenName = "UserOnboardingProfileImageSlide";
  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const i18n = I18nContainer.useContainer();

  const { userProfile, userProfileDocumentRef } =
    LoggedInUserProfileContainer.useContainer();
  const { teamId } = TeamContextContainer.useContainer();
  const [presentToast, dismissToast] = useIonToast();

  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  assertPresent(userProfileDocumentRef, {
    because:
      "userProfile being present means userProfileDocumentRef is present as well.",
  });

  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();

  useEffect(() => {
    if (swiperSlide.isVisible) {
      dontAwait(
        track({
          eventName: "Screen Viewed",
          "team id": teamId,
          "screen name": screenName,
        }),
      );
    }
  }, [swiperSlide.isVisible, teamId, track]);

  const onFilePicked = useCallback(
    async (file) => {
      try {
        await uploadProfileImage(
          file,
          firebaseApp,
          authUser,
          userProfileDocumentRef,
          track,
        );
      } catch (error) {
        const message = `${i18n.t.common.genericError} ${get(
          error,
          "message",
        )}`;
        Sentry.captureException(error);
        dontAwait(
          presentToast({
            message,
            color: "danger",
            duration: 5000,
            position: "bottom",
            buttons: [
              {
                icon: close,
                role: "cancel",
                handler: () => dismissToast(),
              },
            ],
          }),
        );
      }
    },
    [
      firebaseApp,
      authUser,
      userProfileDocumentRef,
      track,
      i18n.t.common.genericError,
      presentToast,
      dismissToast,
    ],
  );

  return (
    <UserOnboardingSlideLayout>
      <SafeAreaSpacer position="top" />
      <div className={`${layoutCss.media} ${css.background}`} />
      <div
        className={layoutCss.content}
        data-cy="UserOnboardingProfileImageSlide"
      >
        <IonAvatar className={css.avatar}>
          <ProfileImage userProfile={userProfile} />
        </IonAvatar>

        <h2>
          <IonText color="aubergine-text">
            <strong>{i18n.t.UserOnboardingProfileImageSlide.headline}</strong>
          </IonText>
        </h2>
        <p>{i18n.t.UserOnboardingProfileImageSlide.explanation}</p>

        <div className="ion-text-center">
          <ImagePicker
            text={
              userProfile.imageUrl
                ? i18n.t.UserOnboardingProfileImageSlide.changePictureButtonText
                : i18n.t.UserOnboardingProfileImageSlide.uploadPictureButtonText
            }
            onFilePicked={onFilePicked}
            screenName={screenName}
            teamId={teamId}
            buttonFill={userProfile.imageUrl ? "clear" : "solid"}
          />
        </div>
      </div>

      <div
        className={`${layoutCss.footer} ${layoutCss.buttons} ${css.footerOverride}`}
      >
        <IonButton
          color="aubergine-text"
          expand="block"
          fill="clear"
          size="large"
          onClick={() => swiper.slidePrev()}
        >
          {i18n.t.common.back}
        </IonButton>

        {userProfile.imageUrl ? (
          <IonButton
            color="aubergine-fill"
            expand="block"
            size="large"
            onClick={() => swiper.slideNext()}
            fill="solid"
          >
            {i18n.t.common.done}
          </IonButton>
        ) : (
          <IonButton
            color="aubergine-text"
            expand="block"
            size="large"
            onClick={() => swiper.slideNext()}
            fill="clear"
            data-cy="UserOnboardingProfileImageSlide-skipButton"
          >
            Skip
          </IonButton>
        )}
      </div>
    </UserOnboardingSlideLayout>
  );
};

export default UserOnboardingProfileImageSlide;
