import { IonCardContent, IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import React from "react";
import CharacterIcons from "src/common/CharacterIcons";
import I18nContainer from "src/state/i18n/I18nContainer";
import css from "./CommunityResultCardContent.module.css";

export type CommunityResultCardContentProps = {
  createdAtMillisecondsTimestamp: number;
  numberOfMembers: number;
  numberOfApartments: number;
};

const CommunityResultCardContent: React.FC<CommunityResultCardContentProps> = ({
  createdAtMillisecondsTimestamp,
  numberOfMembers,
  numberOfApartments,
  children,
}) => {
  const i18n = I18nContainer.useContainer();

  return (
    <IonCardContent>
      <IonGrid className={css.communityResultCardGrid}>
        <IonRow className="ion-align-items-center ion-justify-content-start">
          <IonCol size="auto" className={css.peopleIcons}>
            <CharacterIcons numberOfIcons={numberOfMembers} />
          </IonCol>
          <IonCol>
            {i18n.t.ComunityResultCardContent.numberOfMembersAndApartments({
              numberOfApartments,
              numberOfMembers,
            })}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol>
            <em>
              <IonText color="medium">
                {i18n.t.ComunityResultCardContent.founded(
                  createdAtMillisecondsTimestamp,
                )}
              </IonText>
            </em>
          </IonCol>
        </IonRow>
        {children}
      </IonGrid>
    </IonCardContent>
  );
};

export default CommunityResultCardContent;
