import firebase from "firebase/compat/app";
import { isUndefined, omitBy } from "lodash";

/**
 * Currently, the .update method for firestore documents does not use converters as expected.
 * This means it doesn't remove values that are set to undefined.
 * Hence, this utility function that introduces a safer way to update values for a given firestore document.
 *
 * @param doc - A firestore DocumentSnapshot
 * @param update - An update for the same type of document as passed into doc
 * Example Usage:
 * @example
 * type bestFireStoreObject = {
 *   foo: string;
 *   bar: string;
 * }
 *
 * const greatFirestoreDocument = firebase...
 * const kindaBrokenUpdateObject: bestFireStoreObject = { foo: undefined, bar: "Baz"}
 * await greatFirestoreDocument.ref.update(kindaBrokenUpdateObject) // THIS WILL FAIL, because we can't update a value to undefined in firestore!
 * await updateFirestoreDocument<bestFireStoreObject>(greatFirestoreDocument, kindaBrokenUpdateObject) // THIS WILL WORK, as we filter out the key with value undefined
 */
export default function updateFirestoreDocument<T>(
  doc: firebase.firestore.DocumentSnapshot<T>,
  update: Partial<T>,
): Promise<unknown> {
  return doc.ref.update(omitBy(update, isUndefined));
}
