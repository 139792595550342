import { Clipboard } from "@capacitor/clipboard";
import { Message } from "@connectedliving/common/lib/firestore/Message";
import MessageConverter from "@connectedliving/common/lib/firestore/MessageConverter";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { useIonActionSheet } from "@ionic/react";
import firebase from "firebase/compat/app";
import { useCallback, useMemo } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import FirebaseAppContainer from "../../../state/firebase/FirebaseAppContainer";

export type MessageContextMenuReturn = {
  presentMessageContextMenu: (event: unknown) => void;
};

export default function useMessageContextMenu({
  message,
  displayedText,
}: {
  message: firebase.firestore.QueryDocumentSnapshot<Message>;
  displayedText: string;
}): MessageContextMenuReturn {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();

  const i18n = I18nContainer.useContainer();
  const [present, dismiss] = useIonActionSheet();

  const presentDeleteMessageConfirmation = useCallback(() => {
    dontAwait(
      present({
        header: i18n.t.MessageRenderer.cannotUndo,
        animated: true,
        keyboardClose: true,
        buttons: [
          {
            text: i18n.t.MessageRenderer.deleteMessage,
            role: "destructive",
            handler: async () => {
              await firebaseApp
                .firestore()
                .doc(message.ref.path)
                .withConverter(MessageConverter)
                .update({
                  deleted: true,
                  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                });
            },
          },
          {
            text: i18n.t.common.cancel,
            role: "cancel",
          },
        ],
      }),
    );
  }, [
    present,
    i18n.t.MessageRenderer.cannotUndo,
    i18n.t.MessageRenderer.deleteMessage,
    i18n.t.common.cancel,
    message,
    firebaseApp,
  ]);

  const presentMessageContextMenu = useCallback(() => {
    const buttons = [];
    const copyAction = {
      text: i18n.t.MessageRenderer.copyText,
      handler: async () =>
        Clipboard.write({
          string: displayedText,
        }),
    };

    const deleteAction = {
      text: i18n.t.MessageRenderer.deleteMessage,
      role: "destructive",
      handler: async () => {
        await dismiss();
        presentDeleteMessageConfirmation();
      },
    };

    if (message.data().creatorId === authUser?.uid) buttons.push(deleteAction);
    if (message.data().body) buttons.push(copyAction);

    if (buttons.length) {
      dontAwait(
        present({
          animated: true,
          keyboardClose: true,
          buttons: [
            ...buttons,
            {
              text: i18n.t.common.cancel,
              role: "cancel",
            },
          ],
        }),
      );
    }
  }, [
    authUser?.uid,
    dismiss,
    displayedText,
    i18n.t.MessageRenderer.copyText,
    i18n.t.MessageRenderer.deleteMessage,
    i18n.t.common.cancel,
    message,
    present,
    presentDeleteMessageConfirmation,
  ]);

  return useMemo(
    () => ({
      presentMessageContextMenu,
    }),
    [presentMessageContextMenu],
  );
}
