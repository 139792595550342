import { supportedCountries } from "./firestore/supportedCountries";
import { TeamLocation } from "./firestore/TeamLocation";

export const formatGermanAddress = (teamLocation: TeamLocation): string =>
  `${teamLocation.streetName} ${teamLocation.streetNumber}, ${
    teamLocation.postcode
  } ${teamLocation.city}, ${supportedCountries[teamLocation.country]}`;

export const formatStreetAddress = (teamLocation: TeamLocation): string =>
  `${teamLocation.streetName} ${teamLocation.streetNumber}`;
