import { useState } from "react";
import { matchPath } from "react-router";

/**
 * Use this hook to retrieve the params of the current route from the component
 * rendered by the route.
 *
 * E.g. if your component is rendered on the route "/my-screen/:teamId":
 *
 * ```
 * const { teamId } = useIonicParamsForRoute("/my-screen/:teamId");
 * ```
 *
 * This hook should not be used _unless_ it is under the provided route path,
 * otherwise it will throw an error.
 *
 * This is a workaround for a bug in Ionic router's implementation of "fallback"
 * routes. When a fallback route renders a redirect to another page, it causes
 * `useParams()` to return an empty object instead of the params of the current
 * route. This hook prevents that by returning the params of the most recent,
 * successful attempt to match the given route (and enforces that the first
 * attempt succeeds).
 */
export default function useIonicParamsForRoute<T>(
  expectedRoutePath: string,
): T {
  const { pathname } = window.location;
  const currentRouteMatch = matchPath<T>(pathname, expectedRoutePath);

  const [mostRecentRouteMatch, setMostRecentRouteMatch] = useState(() => {
    const initialRouteMatch = currentRouteMatch;

    if (!initialRouteMatch) {
      throw new Error(
        `Route match failed during initial render: '${pathname}' ~= '${expectedRoutePath}'`,
      );
    }

    return initialRouteMatch;
  });

  const navigatingAway = !currentRouteMatch;
  if (navigatingAway) {
    return mostRecentRouteMatch.params;
  }

  if (currentRouteMatch.url !== mostRecentRouteMatch.url) {
    setMostRecentRouteMatch(currentRouteMatch);
  }

  return currentRouteMatch.params;
}
