import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import {
  IonInput,
  IonItem,
  IonLabel,
  IonNote,
  IonSegment,
  IonSegmentButton,
  SegmentChangeEventDetail,
} from "@ionic/react";
import { find } from "lodash";
import { PropsWithChildren, ReactElement, useCallback } from "react";
import { FieldInputProps } from "src/state/formState/useFieldInputs";
import FormFieldErrors from "./FormFieldErrors";
import { FormSelectOption } from "./FormSingleSelect";

export type FormSegmentSelectProps<ValueType> = Omit<
  React.ComponentProps<typeof IonSegment>,
  "onIonChange" | "onIonBlur" | "onChange" | "onBlur" | "value"
> &
  FieldInputProps<ValueType> & {
    label: string;
    options: readonly (FormSelectOption<ValueType> & { key: string })[];
    helperText: string;
  };

// eslint-disable-next-line react/function-component-definition
function FormSegmentSelect<OptionValues>({
  onChange,
  options,
  errors,
  label: segmentLabel,
  value: valueProp,
  helperText,
  ...restProps
}: PropsWithChildren<
  FormSegmentSelectProps<OptionValues>
>): ReactElement | null {
  const onIonChange: (event: CustomEvent<SegmentChangeEventDetail>) => void =
    useCallback(
      (event) => {
        const selectedOption = find(
          options,
          ({ key }) => key === event.detail.value,
        );
        assertPresent(selectedOption, {
          because:
            "the key must match an option because it was used to generate the button",
        });
        onChange(selectedOption.value);
      },
      [onChange, options],
    );
  return (
    <>
      <IonItem lines="none" detail={false} style={{ "--min-height": "30px" }}>
        <IonLabel position="stacked">{segmentLabel}</IonLabel>
        <IonInput className="ion-hide" />
      </IonItem>
      <IonItem lines="none" detail={false}>
        <IonSegment
          {...restProps}
          {...{ onIonChange }}
          value={options.find(({ value }) => value === valueProp)?.key}
        >
          {options.map(({ label, key }) => (
            <IonSegmentButton key={key} value={key}>
              <IonLabel>{label}</IonLabel>
            </IonSegmentButton>
          ))}
        </IonSegment>
        {helperText && <IonNote slot="helper">{helperText}</IonNote>}
      </IonItem>
      <FormFieldErrors {...{ errors }} />
    </>
  );
}

export default FormSegmentSelect;
