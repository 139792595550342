import { Clipboard } from "@capacitor/clipboard";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { userSettingsPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { code, informationCircleOutline, refreshOutline } from "ionicons/icons";
import { get } from "lodash";
import { useCallback, useState } from "react";
import useScreenTracking from "src/firebase/useScreenTracking";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import commonCss from "../common/common.module.css";
import IonItemIcon from "../common/IonItemIcon";
import PushNotificationsContainer from "../state/PushNotificationsContainer";

type PushNotificationSettingsPageProps = EmptyObject;

const PushNotificationSettingsPage: React.FC<
  PushNotificationSettingsPageProps
> = () => {
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const { teamId } = TeamContextContainer.useContainer();
  const { resetPushToken, pushNotificationDeviceId } =
    PushNotificationsContainer.useContainer();
  const [inProgress, setInProgress] = useState(false);

  const screenName = "PushNotificationSettingsPage";
  useScreenTracking(screenName, teamId);

  const [presentToast] = useIonToast();

  const onResetPushNotificationTokenClick = useCallback(async () => {
    setInProgress(true);
    try {
      await resetPushToken();
    } catch (error) {
      const message = `${i18n.t.common.genericError} ${get(error, "message")}`;
      presentToast({
        message,
        color: "danger",
        duration: 2000,
      });
      throw error;
    } finally {
      setInProgress(false);
    }
    presentToast({
      message: i18n.t.PushNotificationSettingsPage.resetPushToken.success,
      color: "success",
      duration: 2000,
    });
    dontAwait(
      track({
        eventName: "Push Notification System Reset",
      }),
    );
  }, [
    i18n.t.PushNotificationSettingsPage.resetPushToken.success,
    i18n.t.common.genericError,
    presentToast,
    resetPushToken,
    track,
  ]);

  const onCopyPushTokenClick = useCallback(async () => {
    try {
      await Clipboard.write({ string: pushNotificationDeviceId });
      presentToast({
        message: i18n.t.PushNotificationSettingsPage.copyPushToken.success,
        color: "success",
        duration: 2000,
      });
    } catch (error) {
      const message = `${i18n.t.common.genericError} ${get(error, "message")}`;
      presentToast({
        message,
        color: "danger",
        duration: 2000,
      });
      throw error;
    }
  }, [
    i18n.t.PushNotificationSettingsPage.copyPushToken.success,
    i18n.t.common.genericError,
    presentToast,
    pushNotificationDeviceId,
  ]);

  return (
    <IonPage>
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={userSettingsPageUrl({ teamId })}
            />
          </IonButtons>
          <IonTitle>{i18n.t.PushNotificationSettingsPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={commonCss.contentContainer}>
        <IonCard className={commonCss.card}>
          <IonItem onClick={onCopyPushTokenClick}>
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={code}
            />
            {i18n.t.PushNotificationSettingsPage.pushToken.label}
            <IonText slot="end" color="medium">
              {pushNotificationDeviceId?.slice(0, 8)}
            </IonText>
          </IonItem>

          <IonItem
            onClick={onResetPushNotificationTokenClick}
            button
            detail={false}
            disabled={inProgress}
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="none"
              slot="start"
              icon={refreshOutline}
            />
            <IonLabel>
              {i18n.t.PushNotificationSettingsPage.resetPushToken.action}
            </IonLabel>
          </IonItem>
        </IonCard>

        <IonCard color="clear" className={commonCss.card}>
          <div className="ion-margin-horizontal">
            <IonIcon color="none" icon={informationCircleOutline} />{" "}
            {i18n.t.PushNotificationSettingsPage.resetPushToken.description}
          </div>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default PushNotificationSettingsPage;
