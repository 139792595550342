import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  channelsListPageUrl,
  checkMyAddressPageUrl,
  closedBetaPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonCardTitle, IonIcon } from "@ionic/react";
import { helpCircle } from "ionicons/icons";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import InfoCard, { InfoCardButton, InfoCardText } from "src/common/InfoCard";
import MoreInfoModal from "src/common/MoreInfoModal";
import useEmailActionSheet from "src/common/useEmailActionSheet";
import OnboardingPageLayout from "src/share/OnboardingPageLayout";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import css from "./GetStartedPage.module.css";

const GetStartedPage: React.FC = () => {
  const { authUser } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const supportEmail = requireEnvVar("REACT_APP_SUPPORT_EMAIL");
  const emailActionSheet = useEmailActionSheet(supportEmail);
  const { userProfile } = LoggedInUserProfileContainer.useContainer();

  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  const firstTeamId = userProfile.teamIds[0];

  const screenName = "GetStartedPage";

  assertPresent(authUser, {
    because: "<App> waits for user to log in",
  });
  useEffect(() => {
    dontAwait(
      track({
        eventName: "Screen Viewed",
        "screen name": screenName,
        "team id": undefined,
      }),
    );
  }, [track]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const cards = [
    { name: "inviteCodeCard", url: closedBetaPageUrl() },
    { name: "checkMyAddressCard", url: checkMyAddressPageUrl() },
    { name: "noneOfTheAboveCard", url: undefined },
  ] as const;

  if (firstTeamId) {
    return (
      <Redirect
        to={channelsListPageUrl({
          teamId: firstTeamId,
        })}
      />
    );
  }

  return (
    <OnboardingPageLayout
      title={i18n.t.GetStartedPage.title}
      subtitle={i18n.t.GetStartedPage.subtitle}
      screenName={screenName}
    >
      {cards.map((card) => (
        <InfoCard
          key={card.name}
          cardTitle={
            <>
              <IonCardTitle>
                {i18n.t.GetStartedPage[card.name].title}
              </IonCardTitle>
              {card.name === "checkMyAddressCard" && (
                <IonIcon
                  icon={helpCircle}
                  size="large"
                  onClick={() => setIsOpen(true)}
                  className={css.icon}
                />
              )}
            </>
          }
        >
          <InfoCardText>
            {i18n.t.GetStartedPage[card.name].paragraph}
          </InfoCardText>
          <InfoCardButton
            data-cy={`GetStartedPage-${card.name}-button`}
            routerLink={card.url}
            routerDirection="forward"
            onClick={
              card.name === "noneOfTheAboveCard"
                ? async () => emailActionSheet()
                : undefined
            }
          >
            {i18n.t.GetStartedPage[card.name].button}
          </InfoCardButton>
        </InfoCard>
      ))}

      <MoreInfoModal
        isOpen={isOpen}
        breakpoints={[0, 0.85, 1]}
        initialBreakpoint={0.85}
        title={i18n.t.common.startNewCommunityInfoModal.title}
        body={i18n.t.common.startNewCommunityInfoModal.body(emailActionSheet)}
        closeModal={() => setIsOpen(false)}
      />
    </OnboardingPageLayout>
  );
};

export default GetStartedPage;
