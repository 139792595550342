import { Message } from "@connectedliving/common/lib/firestore/Message";
import convertTimestampValueToDate from "@connectedliving/common/lib/utilities/convertTimestampValueToDate";
import firebase from "firebase/compat/app";
import { DateTime } from "luxon";

export type ChannelHistoryMessageItem = {
  id: string;
  type: "message";
  message: firebase.firestore.QueryDocumentSnapshot<Message>;
  showAvatar: boolean;
  showTimestamp: boolean;
};

export type ChannelHistoryDayBoundaryItem = {
  id: string;
  type: "time-boundary";
  date: Date;
};

export type ChannelHistoryItem =
  | ChannelHistoryMessageItem
  | ChannelHistoryDayBoundaryItem;

function dateAtMidnight(date: Date): Date {
  return DateTime.fromJSDate(date)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();
}
function dateAtMinute(date: Date): Date {
  const result = DateTime.fromJSDate(date)
    .set({ second: 0, millisecond: 0 })
    .toJSDate();
  return result;
}

/**
 * This handles optimistically-created messages which don't have a createdAt yet.
 */
function safelyGetMessageCreatedAt(
  message: firebase.firestore.QueryDocumentSnapshot<Message>,
): Date {
  const date = convertTimestampValueToDate(message.data().createdAt);
  if (date.valueOf() === 0) return new Date();
  return date;
}

export function calculateChannelHistory(
  allMessages: firebase.firestore.QueryDocumentSnapshot<Message>[],
): ChannelHistoryItem[] {
  if (allMessages.length === 0) return [];

  const result: ChannelHistoryItem[] = [];

  let currentMessageGroupDate: Date | undefined;
  let previousMessage:
    | firebase.firestore.QueryDocumentSnapshot<Message>
    | undefined;

  for (let i = 0; i < allMessages.length; i += 1) {
    const message = allMessages[i]!;
    const messageCreatedAt = safelyGetMessageCreatedAt(message);
    const messageDateAtMidnight = dateAtMidnight(messageCreatedAt);

    if (
      !currentMessageGroupDate ||
      messageDateAtMidnight.valueOf() !== currentMessageGroupDate.valueOf()
    ) {
      previousMessage = undefined;
      currentMessageGroupDate = messageDateAtMidnight;
      result.push({
        type: "time-boundary",
        date: currentMessageGroupDate,
        id: String(currentMessageGroupDate.valueOf()),
      });
    }

    const showAvatar =
      !previousMessage ||
      previousMessage.data().creatorId !== message.data().creatorId;
    const showTimestamp =
      !previousMessage ||
      previousMessage.data().creatorId !== message.data().creatorId ||
      dateAtMinute(safelyGetMessageCreatedAt(previousMessage)).valueOf() !==
        dateAtMinute(messageCreatedAt).valueOf();
    result.push({
      type: "message",
      message,
      showAvatar,
      id: message.id,
      showTimestamp,
    });

    previousMessage = message;
  }

  return result;
}
