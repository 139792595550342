import { isSet } from "@connectedliving/common/lib/firestore/dataHelpers";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import { buildingName } from "@connectedliving/common/lib/utilities/buildingName";
import { floorName } from "@connectedliving/common/lib/utilities/floorName";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonItem,
  IonItemDivider,
  IonItemGroup,
  IonLabel,
  IonList,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { personAddOutline } from "ionicons/icons";
import { compact } from "lodash";
import { useCallback, useState } from "react";
import InviteNeighborModal from "src/common/InviteNeighborModal";
import IonItemIcon from "src/common/IonItemIcon";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import css from "./GroupedUserProfileList.module.css";
import { groupUserProfiles, TeamUserProfileGroup } from "./groupUserProfiles";
import UserProfileListItem from "./UserProfileListItem";

function calculateTeamUserProfileGroupHeader(
  teamUserProfileGroup: TeamUserProfileGroup,
  teamLocations: firebase.firestore.QueryDocumentSnapshot<TeamLocation>[],
): {
  header: { line1: string | undefined; line2: string };
  teamUserProfileGroup: TeamUserProfileGroup;
} {
  const { building, floor, teamLocationId } = teamUserProfileGroup;

  const teamLocation =
    teamLocationId && teamLocations.find(({ id }) => id === teamLocationId);

  const line1 = teamLocation
    ? `${teamLocation.data().streetName} ${teamLocation.data().streetNumber}`
    : undefined;

  const line2 = compact([
    isSet(building) && buildingName(building),
    isSet(floor) && floorName(floor),
  ]).join(", ");

  return {
    header: { line1, line2 },
    teamUserProfileGroup,
  };
}

type GroupedUserProfileListProps = {
  screenName: string;
  teamId: string;
  teamUserProfiles: firebase.firestore.QueryDocumentSnapshot<TeamUserProfile>[];
  teamLocations: firebase.firestore.QueryDocumentSnapshot<TeamLocation>[];
  userProfiles: firebase.firestore.QueryDocumentSnapshot<UserProfile>[];
};

const GroupedUserProfileList: React.FC<GroupedUserProfileListProps> = ({
  screenName,
  teamId,
  teamUserProfiles,
  teamLocations,
  userProfiles,
}) => {
  const { authUser } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { team } = TeamContextContainer.useContainer();
  const [isOpenInviteNeighborModal, setIsOpenInviteNeighborModal] =
    useState<boolean>(false);

  const onInviteNeighborButtonClick = useCallback(() => {
    setIsOpenInviteNeighborModal(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Invite Neighbor",
      }),
    );
    dontAwait(
      track({
        eventName: "Modal Opened",
        "screen name": screenName,
        "team id": teamId,
        "modal name": "InviteNeighborModal",
      }),
    );
  }, [screenName, teamId, track]);
  const i18n = I18nContainer.useContainer();

  const headingsAlreadyRendered: Record<string, boolean | undefined> = {};

  function renderHeaderOnce(header: string): React.ReactNode {
    const seenBefore = headingsAlreadyRendered[header];
    headingsAlreadyRendered[header] = true;
    if (seenBefore) return null;
    return (
      <IonItemDivider color="light">
        <IonLabel>{header}</IonLabel>
      </IonItemDivider>
    );
  }

  const userProfileGroupsWithHeaders = groupUserProfiles(teamUserProfiles)
    .map((teamUserProfileGroup) =>
      calculateTeamUserProfileGroupHeader(teamUserProfileGroup, teamLocations),
    )
    .sort(
      ({ header: header1 }, { header: header2 }) =>
        (header1.line1 || "").localeCompare(header2.line1 || "") ||
        header1.line2.localeCompare(header2.line2),
    );

  return (
    <IonList>
      <IonItem button detail={false} onClick={onInviteNeighborButtonClick}>
        <IonItemIcon slot="start" icon={personAddOutline} />

        <IonLabel color="primary">{i18n.t.common.inviteNeighbor}</IonLabel>
      </IonItem>

      {userProfileGroupsWithHeaders.map(({ header, teamUserProfileGroup }) => (
        <IonItemGroup key={`${header.line1}-${header.line2}`}>
          {header.line1 && renderHeaderOnce(header.line1)}
          {header.line2 && (
            <IonItemDivider color="light">
              <IonLabel className={css.deemphasizedLabel}>
                {header.line2}
              </IonLabel>
            </IonItemDivider>
          )}

          {teamUserProfileGroup.teamUserProfiles.map((teamUserProfile) => {
            const userProfile = userProfiles.find(
              ({ id }) => id === teamUserProfile.id,
            );
            return (
              <UserProfileListItem
                key={teamUserProfile.id}
                loadingState="ready"
                {...{ userProfile, teamId }}
                displayAsYou={teamUserProfile.id === authUser?.uid}
              />
            );
          })}
        </IonItemGroup>
      ))}
      <InviteNeighborModal
        isOpen={isOpenInviteNeighborModal}
        dismissInviteNeighborModal={() => setIsOpenInviteNeighborModal(false)}
        inviteCode={team.data().inviteCode}
        track={track}
        teamId={teamId}
        screenName={screenName}
        teamName={team.data().name}
      />
    </IonList>
  );
};

export default GroupedUserProfileList;
