import { ArrayElement } from "../utilities/ArrayElement";

export const utmParamKeys = Object.freeze([
  "utm_medium",
  "utm_source",
  "utm_campaign",
  "utm_term",
  "utm_content",
  "utm_item",
] as const);

export type UtmParams = Record<
  ArrayElement<typeof utmParamKeys>,
  string | null
>;

export function makeEmptyUtmParams(): UtmParams {
  return {
    utm_campaign: null,
    utm_content: null,
    utm_item: null,
    utm_medium: null,
    utm_source: null,
    utm_term: null,
  };
}
