import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { IonItem, IonText } from "@ionic/react";
import firebase from "firebase/compat/app";
import { banOutline } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import commonCss from "src/common/common.module.css";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamUserPreferencesContainer from "src/state/team/TeamUserPreferencesContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import { isDataAvailable } from "src/utilities/data/Loadable";
import environment from "../utilities/environment";
import IonItemIcon from "./IonItemIcon";

type UseBlockUserButtonsReturn = React.ReactNode;

export default function useBlockUserButtons({
  screenName,
  teamId,
  targetUserId,
  targetUserName,
}: {
  screenName: string;
  targetUserId: string;
  targetUserName: string;
  teamId: string;
}): UseBlockUserButtonsReturn {
  const { authUser } = TeamContextContainer.useContainer();
  const { teamUserPreferences, isUserBlocked } =
    TeamUserPreferencesContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();

  const [blockInProgress, setBlockInProgress] = useState(false);
  const onBlockUserClick = useCallback(async () => {
    if (!isDataAvailable(teamUserPreferences)) return;

    setBlockInProgress(true);
    try {
      await teamUserPreferences.data.ref.update({
        blockedUserIds: firebase.firestore.FieldValue.arrayUnion(targetUserId),
      });
      dontAwait(
        track({
          eventName: "User Blocked",
          "button name": "Block",
          "screen name": screenName,
          "team id": teamId,
          "target user id": targetUserId,
          "target user name": targetUserName,
        }),
      );
    } finally {
      setBlockInProgress(false);
    }
  }, [
    teamUserPreferences,
    targetUserId,
    track,
    screenName,
    teamId,
    targetUserName,
  ]);
  const i18n = I18nContainer.useContainer();

  const onUnblockUserClick = useCallback(async () => {
    if (!isDataAvailable(teamUserPreferences)) return;

    setBlockInProgress(true);
    try {
      await teamUserPreferences.data.ref.update({
        blockedUserIds: firebase.firestore.FieldValue.arrayRemove(targetUserId),
      });
      dontAwait(
        track({
          eventName: "User Unblocked",
          "button name": "Unblock",
          "screen name": screenName,
          "team id": teamId,
          "target user id": targetUserId,
          "target user name": targetUserName,
        }),
      );
    } finally {
      setBlockInProgress(false);
    }
  }, [
    teamUserPreferences,
    targetUserId,
    track,
    screenName,
    teamId,
    targetUserName,
  ]);

  const targetUserIsSelf = targetUserId === authUser.uid;
  const targetUserIsSupportUser =
    targetUserId === environment().supportUserId();
  const canBlockTargetUser = !targetUserIsSupportUser && !targetUserIsSelf;

  if (!canBlockTargetUser || isUserBlocked(targetUserId) === undefined) {
    return null;
  }

  return isUserBlocked(targetUserId) ? (
    <IonItem
      button
      detail={false}
      data-cy="BlockUserButtons-UnblockButton"
      onClick={onUnblockUserClick}
      disabled={blockInProgress}
    >
      <IonItemIcon
        className={commonCss.itemIconAvatar}
        color="primary"
        slot="start"
        icon={banOutline}
      />
      <IonText color="primary">{i18n.t.UseBlockUserButtons.unblock}</IonText>
    </IonItem>
  ) : (
    <IonItem
      button
      detail={false}
      data-cy="BlockUserButtons-BlockButton"
      onClick={onBlockUserClick}
      disabled={blockInProgress}
    >
      <IonItemIcon
        className={commonCss.itemIconAvatar}
        color="primary"
        slot="start"
        icon={banOutline}
      />
      <IonText color="primary">{i18n.t.UseBlockUserButtons.block}</IonText>
    </IonItem>
  );
}
