import {
  getOptionalString,
  getOptionalStringLiteral,
  getString,
  getStringLiteral,
  StrictDocumentData,
} from "./dataHelpers";
import { LanguageCode } from "./supportedLanguages";
import { SupportedLocale } from "./SupportedLocale";
import { TimestampValue } from "./TimestampValue";

export enum PushDisabledReason {
  logout = "deregistered on logout",
  notYetRegistered = "not yet registered",
}

export enum PushNetwork {
  ios = "apns",
  android = "android",
}
export const pushNetworkValues = Object.freeze(Object.values(PushNetwork));

export enum PushStatus {
  initial = "initial",
  active = "active",
  error = "error",
  disabled = "disabled",
}
export const pushStatusValues = Object.freeze(Object.values(PushStatus));

export type InitialPushConfig = {
  pushStatus: PushStatus.initial;
  fcmToken: null;
  pushNetwork: null;
  pushLatestError: unknown;
  pushDisabledReason: null;
};

export type NonemptyPushConfig = {
  pushStatus: Omit<PushStatus, PushStatus.initial>;
  fcmToken: string;
  pushNetwork: PushNetwork;
  pushLatestError: unknown;
  pushDisabledReason: string | null;
};

export type PushConfig = InitialPushConfig | NonemptyPushConfig;

export function firestoreDataToPushConfig(
  data: StrictDocumentData,
): PushConfig {
  const pushStatus = getStringLiteral(data.pushStatus, {
    permitted: pushStatusValues,
    fallback: PushStatus.disabled,
  });

  if (pushStatus === PushStatus.initial) {
    const result: InitialPushConfig = {
      pushStatus,
      fcmToken: getOptionalString(data.fcmToken) as null,
      pushNetwork: getOptionalStringLiteral(data.pushNetwork, {
        permitted: pushNetworkValues,
        fallback: PushNetwork.ios,
      }) as null,
      pushDisabledReason: getOptionalString(data.pushDisabledReason) as null,
      pushLatestError:
        data.pushLatestError === undefined ? null : data.pushLatestError,
    };
    return result;
  }

  return {
    pushStatus,
    fcmToken: getString(data.fcmToken),
    pushNetwork: getStringLiteral(data.pushNetwork, {
      permitted: pushNetworkValues,
      fallback: PushNetwork.ios,
    }),
    pushDisabledReason: getOptionalString(data.pushDisabledReason),
    pushLatestError:
      data.pushLatestError === undefined ? null : data.pushLatestError,
  };
}

export type ClientInstanceTargetLanguage = LanguageCode;

export type ClientInstanceTranslationConfig =
  | {
      enabled: false;
    }
  | {
      enabled: true;
      targetLanguage: ClientInstanceTargetLanguage;
    };

export type ClientInstance = {
  userId: string;
  locale: SupportedLocale;
  createdAt: TimestampValue;
  updatedAt: TimestampValue;
  pushConfig: PushConfig;
  translationConfig: ClientInstanceTranslationConfig;
};
