import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { IonButton, IonSpinner, IonText, useIonToast } from "@ionic/react";
import * as Sentry from "@sentry/react";
import { close } from "ionicons/icons";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import FormOptionalTextInput from "src/common/form/FormOptionalTextInput";
import { useFieldInputs } from "src/state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "src/state/formState/useFormState";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import updateUserProfileAndTeamProfile from "src/userProfiles/updateUserAndTeamUserProfiles";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import SafeAreaSpacer from "src/utilities/SafeAreaSpacer";
import validate from "src/utilities/validate/validate";
import { useSwiper, useSwiperSlide } from "swiper/react";
import css from "./UserOnboardingNameSlide.module.css";
import UserOnboardingSlideLayout from "./UserOnboardingSlideLayout";
import layoutCss from "./UserOnboardingSlideLayout.module.css";

type UserOnboardingNameSlideParams = EmptyObject;

type UserOnboardingNameSlideFormValues = Pick<
  UserProfile,
  "firstName" | "lastName"
>;
const UserOnboardingNameSlide: React.FC<UserOnboardingNameSlideParams> = () => {
  const { teamId, userProfile } = TeamContextContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();
  useEffect(() => {
    if (swiperSlide.isVisible) {
      dontAwait(
        track({
          eventName: "Screen Viewed",
          "team id": teamId,
          "screen name": "UserOnboardingNameSlide",
        }),
      );
    }
  }, [swiperSlide.isVisible, teamId, track]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [presentToast, dismissToast] = useIonToast();
  const formState = useFormState<UserOnboardingNameSlideFormValues>({
    initial: {
      firstName: userProfile.data().firstName,
      lastName: userProfile.data().lastName,
    },
    validate: {
      fields: {
        firstName: (fieldState) => {
          const { value } = fieldState;
          if (value === null)
            return [
              { message: i18n.t.UserOnboardingNameSlide.firstName.isRequired },
            ];
          if (value.includes("@"))
            return [
              { message: i18n.t.UserOnboardingNameSlide.noAtSignInNames },
            ];
          return validate.string.isPresent(
            i18n.t.UserOnboardingNameSlide.firstName,
          )({
            value,
          });
        },
        lastName: (fieldState) => {
          const { value } = fieldState;
          if (value === null)
            return [
              { message: i18n.t.UserOnboardingNameSlide.lastName.isRequired },
            ];
          if (value.includes("@"))
            return [
              { message: i18n.t.UserOnboardingNameSlide.noAtSignInNames },
            ];
          return validate.string.isPresent(
            i18n.t.UserOnboardingNameSlide.lastName,
          )({
            value,
          });
        },
      },
    },
  });

  const fields = useFieldInputs(formState, useFieldStates(formState));
  const { form } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
          await updateUserProfileAndTeamProfile({
            userProfile: {
              originalUserProfile: userProfile,
              updatedUserProfile: formValues,
            },
            teamId,
            track,
          });
          setIsSubmitting(false);
          swiper.slideNext();
        } catch (error) {
          setIsSubmitting(false);
          const message = `${i18n.t.common.genericError} ${get(
            error,
            "message",
          )}`;
          Sentry.captureException(error);
          dontAwait(
            presentToast({
              message,
              color: "danger",
              duration: 5000,
              position: "bottom",
              buttons: [
                {
                  icon: close,
                  role: "cancel",
                  handler: () => dismissToast(),
                },
              ],
            }),
          );
        }
      },
      [
        dismissToast,
        i18n.t.common.genericError,
        isSubmitting,
        presentToast,
        swiper,
        teamId,
        track,
        userProfile,
      ],
    ),
  });

  return (
    <form
      {...form}
      className={layoutCss.form}
      data-cy="UserOnboardingNameSlide"
      autoComplete="on"
    >
      <UserOnboardingSlideLayout>
        <SafeAreaSpacer position="top" />
        <div className={`ion-padding-horizontal ${layoutCss.media}`}>
          <img
            className={css.illustration}
            src="/assets/illustrations/profile.png"
            alt={i18n.t.UserOnboardingNameSlide.illustrationAlt}
          />
        </div>
        <div className={layoutCss.content}>
          <h2>
            <IonText color="aubergine-text">
              <strong>{i18n.t.UserOnboardingNameSlide.headline}</strong>
            </IonText>
          </h2>
          <p>{i18n.t.UserOnboardingNameSlide.explanation}</p>
          <FormOptionalTextInput
            {...fields.firstName}
            autoCapitalize="words"
            label={i18n.t.UserOnboardingNameSlide.firstName.label}
            data-cy="UserOnboardingNameSlide-input-firstName"
            autocomplete="given-name"
          />
          <FormOptionalTextInput
            {...fields.lastName}
            autoCapitalize="words"
            label={i18n.t.UserOnboardingNameSlide.lastName.label}
            data-cy="UserOnboardingNameSlide-input-lastName"
            autocomplete="family-name"
          />
        </div>
        <div className={`${layoutCss.footer} ${layoutCss.buttons}`}>
          <IonButton
            color="aubergine-text"
            size="large"
            fill="clear"
            data-cy="UserOnboardingNameSlide-goBack"
            onClick={() => swiper.slidePrev()}
          >
            {i18n.t.common.back}
          </IonButton>

          <IonButton
            type="submit"
            color="aubergine-fill"
            fill="solid"
            size="large"
            data-cy="UserOnboardingNameSlide-submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? <IonSpinner /> : i18n.t.common.next}
          </IonButton>
        </div>
      </UserOnboardingSlideLayout>
    </form>
  );
};

export default UserOnboardingNameSlide;
