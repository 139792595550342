import { teamLocationsPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import { channelsListPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import useScreenTracking from "src/firebase/useScreenTracking";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { isDataAvailable } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import SafeAreaSpacer from "src/utilities/SafeAreaSpacer";
import SearchableUserProfileList from "./SearchableUserProfileList";

export type UserProfilesListPageProps = {
  teamId: string;
};

const LoadingSkeleton: React.FC = () => (
  <IonList>
    {[100, 80, 120, 40, 70, 100, 80, 120, 40, 70].map((width, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <IonItem lines="full" key={index}>
        <IonAvatar
          slot="start"
          style={{ backgroundColor: "var(--ion-color-light)" }}
        />
        <IonLabel>
          <IonSkeletonText animated style={{ width }} />
        </IonLabel>
      </IonItem>
    ))}
  </IonList>
);

const UserProfilesListPage: React.FC<UserProfilesListPageProps> = ({
  teamId,
}) => {
  const { authUser, firebaseApp } = FirebaseAppContainer.useContainer();
  const { onboardedUserProfiles, loadingState, idToTeamUserProfile } =
    TeamUserProfilesContainer.useContainer();

  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const { userProfile } = LoggedInUserProfileContainer.useContainer();
  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });
  const i18n = I18nContainer.useContainer();

  const teamLocations = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(teamLocationsPath({ teamId }))
      .withConverter(TeamLocationConverter),
  );

  const screenName = "UserProfilesListPage";
  useScreenTracking(screenName, teamId);

  return (
    <IonPage data-cy="UserProfilesListPage">
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              data-cy="UserProfilesListPage-BackButton"
              text=""
              defaultHref={channelsListPageUrl({ teamId })}
            />
          </IonButtons>
          <IonTitle>{i18n.t.UserProfilesListPage.people}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {loadingState === "initialLoad" || !isDataAvailable(teamLocations) ? (
          <LoadingSkeleton />
        ) : (
          <SearchableUserProfileList
            {...{
              screenName,
              teamId,
              userProfiles: onboardedUserProfiles,
              idToTeamUserProfile,
              teamLocations: teamLocations.data.docs,
            }}
          />
        )}
        <SafeAreaSpacer position="bottom" />
      </IonContent>
    </IonPage>
  );
};

export default UserProfilesListPage;
