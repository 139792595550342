import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import { directMessageChannelPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import {
  channelsListPageUrl,
  directMessageChannelMessagesPageUrl,
  viewUserProfilePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonPage,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { personCircle } from "ionicons/icons";
import React from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { Redirect, useParams } from "react-router";
import commonCss from "src/common/common.module.css";
import IonItemIcon from "src/common/IonItemIcon";
import useBlockUserButtons from "src/common/useBlockUserButtons";
import useReportUserButton from "src/common/useReportUserButton";
import UserIsBlockedBanner from "src/common/UserIsBlockedBanner";
import useScreenTracking from "src/firebase/useScreenTracking";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import TeamUserPreferencesContainer from "src/state/team/TeamUserPreferencesContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import environment from "src/utilities/environment";
import firestoreHooksLoadingState from "src/utilities/firestoreHooksLoadingState";
import { loadFailedOrNotFound } from "src/utilities/LoadingState";
import ProfileImage from "src/utilities/ProfileImage";

export type DirectMessageChannelDetailPageProps = {
  teamId: string;
};
export type DirectMessageChannelDetailPageRouteParams = {
  otherUserId: string;
};

const DirectMessageChannelDetailPage: React.FC<
  DirectMessageChannelDetailPageProps
> = ({ teamId }) => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { authUser } = TeamContextContainer.useContainer();
  const { otherUserId } =
    useParams<DirectMessageChannelDetailPageRouteParams>();
  const i18n = I18nContainer.useContainer();

  const [directMessageChannel, directMessageChannelLoadingState] =
    firestoreHooksLoadingState<
      firebase.firestore.DocumentSnapshot<DirectMessageChannel>
    >(
      useDocument(
        firebaseApp
          .firestore()
          .doc(
            directMessageChannelPath({
              teamId,
              userId: authUser.uid,
              otherUserId,
            }),
          )
          .withConverter(DirectMessageChannelConverter),
      ),
    );

  const { findUserProfileById, loadingState: teamUserProfilesLoadingState } =
    TeamUserProfilesContainer.useContainer();

  const otherUserUserProfile = findUserProfileById(otherUserId);

  const screenName = "DirectMessageChannelDetailPage";
  useScreenTracking(screenName, teamId);

  const backUrl = directMessageChannelMessagesPageUrl({
    teamId,
    authUserId: authUser.uid,
    otherUserId,
  });

  const directMessageChannelNotFound = loadFailedOrNotFound(
    directMessageChannel,
    directMessageChannelLoadingState,
  );
  const otherUserUserProfileNotFound = loadFailedOrNotFound(
    otherUserUserProfile,
    teamUserProfilesLoadingState,
  );
  const otherUserUserProfileData =
    otherUserUserProfile && otherUserUserProfile.data();
  const formattedName = otherUserUserProfileData
    ? formatFullName(otherUserUserProfileData)
    : "";
  const { isUserBlocked } = TeamUserPreferencesContainer.useContainer();

  const otherUserIsSupportUser = otherUserId === environment().supportUserId();

  const blockUserButtons = useBlockUserButtons({
    targetUserId: otherUserId,
    screenName,
    targetUserName: formattedName,
    teamId,
  });

  const reportUserButton = useReportUserButton({
    targetUserId: otherUserId,
    screenName,
    targetUserName: formattedName,
    teamId,
  });

  return (
    <IonPage id="main-content" data-cy="DirectMessageChannelDetailPage">
      {(directMessageChannelNotFound || otherUserUserProfileNotFound) && (
        <Redirect to={channelsListPageUrl({ teamId })} />
      )}
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={backUrl}
              text=""
              data-cy="ChannelDetailPage-BackButton"
            />
          </IonButtons>

          <IonTitle>{i18n.t.DirectMessageChannelDetailPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={commonCss.contentContainer}>
        <div className={`ion-padding-top ${commonCss.centerContent}`}>
          <IonAvatar className={commonCss.large}>
            <ProfileImage userProfile={otherUserUserProfileData} />
          </IonAvatar>
          <h1>
            <strong>
              {otherUserUserProfile ? (
                formattedName
              ) : (
                <IonSkeletonText
                  animated
                  style={{ display: "inline-block", width: 100 }}
                />
              )}
            </strong>
          </h1>
        </div>

        {isUserBlocked(otherUserId) && (
          <UserIsBlockedBanner {...{ teamId }} blockedUserId={otherUserId} />
        )}

        <IonCard className={commonCss.card}>
          {otherUserIsSupportUser ? (
            <IonItem>
              <p>
                <em>
                  {i18n.t.ViewUserProfilePage.messageVisibilityNotification()}
                </em>
              </p>
            </IonItem>
          ) : (
            <IonItem
              button
              data-cy="BlockUserButtons-UnblockButton"
              routerLink={viewUserProfilePageUrl({
                teamId,
                userProfileId: otherUserId,
              })}
              routerDirection="forward"
            >
              <IonItemIcon
                className={commonCss.itemIconAvatar}
                color="none"
                slot="start"
                icon={personCircle}
              />
              {i18n.t.DirectMessageChannelDetailPage.contactDetails}
            </IonItem>
          )}
        </IonCard>

        <IonCard className={commonCss.card}>
          {blockUserButtons}
          {reportUserButton}
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default DirectMessageChannelDetailPage;
