import { App, AppInfo } from "@capacitor/app";
import { isPlatform } from "@ionic/core";
import { isError } from "lodash";

export type NativeAppVersionData = AppInfo & {
  tag: string;
  platform: string;
};

export default async function getNativeAppVersion(): Promise<NativeAppVersionData | null> {
  if (process.env.REACT_APP_RESTRICTED_WEB_BUILD) {
    return null;
  }
  let appInfo;
  try {
    appInfo = await App.getInfo();
  } catch (e) {
    if (isError(e) && e.message.match(/Not implemented on web/)) {
      return null;
    }
    throw e;
  }

  let platform;
  if (isPlatform("ios")) platform = "ios";
  else if (isPlatform("android")) platform = "android";
  else platform = "unknown";

  const tag = `${platform}-${appInfo.version}-${appInfo.build}`;

  return { ...appInfo, tag, platform };
}
