import * as Sentry from "@sentry/browser";
import firebase from "firebase/compat/app";
import { useCollection } from "react-firebase-hooks/firestore";
import { Loadable } from "./Loadable";

export type FirestoreCollection<T> = Loadable<
  firebase.firestore.QuerySnapshot<T>
>;

export default function useFirestoreCollection<T>(
  collectionRef: firebase.firestore.Query<T> | undefined | null | false | "",
): FirestoreCollection<T> {
  const [data, isLoading, error] = useCollection(collectionRef || null) as [
    data: firebase.firestore.QuerySnapshot<T> | undefined,
    isLoading: boolean,
    error: Error | undefined,
  ];

  if (
    collectionRef === undefined ||
    collectionRef === null ||
    collectionRef === "" ||
    collectionRef === false
  ) {
    return { loadingState: "initialLoad", data: undefined };
  }

  if (error !== undefined) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-console
      console.log(
        `Error subscribing to Firestore collection ${collectionRef.toString()}\n`,
        error,
      );
    } else {
      Sentry.captureException(error);
    }

    return {
      loadingState: { error },
      data,
    };
  }

  if (isLoading) {
    if (data) {
      return {
        loadingState: "updating",
        data,
      };
    }

    if (!data) {
      return { loadingState: "initialLoad", data };
    }
  }

  if (!isLoading) {
    if (!data) {
      return { loadingState: "initialLoad", data };
    }

    return {
      loadingState: "ready",
      data,
    };
  }

  throw new Error("Unreachable");
}
