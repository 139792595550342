import { BuildingPart } from "@connectedliving/common/lib/firestore/BuildingPart";
import isFirestoreError from "@connectedliving/common/lib/utilities/firestore/isFirestoreError";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { teamAdminPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFooter,
  IonHeader,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import FormMultipleSelect from "src/common/form/FormMultipleSelect";
import FormOptionalNumberInput from "src/common/form/FormOptionalNumberInput";
import FormTextInput from "src/common/form/FormTextInput";
import { useFieldInputs } from "src/state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "src/state/formState/useFormState";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import mapObjectToFormSelectOptions from "src/utilities/mapObjectToFormSelectOptions";
import validate from "src/utilities/validate/validate";
import commonCss from "../common/common.module.css";
import localCommonCss from "./commonEditTeam.module.css";
import updateTeam from "./updateTeam";

export type EditTeamPageProps = { teamId: string };

const EditTeamPage: React.FC<EditTeamPageProps> = ({ teamId }) => {
  const { team } = TeamContextContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();

  const formState = useFormState<{
    name: string;
    numberOfApartments: number;
    buildingParts: BuildingPart[];
  }>({
    initial: {
      name: team.data().name,
      numberOfApartments: team.data().numberOfApartments,
      buildingParts: team.data().buildingParts,
    },
    validate: {
      fields: {
        name: validate.string.isPresent(i18n.t.common.validations),
        numberOfApartments: validate.number.over(
          { min: 1 },
          i18n.t.common.validations,
        ),
      },
    },
  });
  const fields = useFieldInputs(formState, useFieldStates(formState));
  const history = useHistory();
  const [presentToast, dismissToast] = useIonToast();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { form, submit } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
          await updateTeam({
            update: {
              name: formValues.name,
              numberOfApartments: formValues.numberOfApartments,
              buildingParts: formValues.buildingParts,
            },
            team,
            track,
            teamId,
          });
          dontAwait(
            presentToast({
              color: "success",
              message: i18n.t.EditTeamPage.teamUpdated,
              duration: 3000,
              cssClass: localCommonCss.toast,
            }),
          );
          history.replace(teamAdminPageUrl({ teamId }));
        } catch (e) {
          if (isFirestoreError(e)) {
            let { message } = e;
            if (e.code === "internal") {
              message = i18n.t.common.errorMessage;
            }
            formState.setFormErrors([{ message }]);
            dontAwait(
              presentToast({
                message,
                color: "danger",
                duration: 5000,
                position: "bottom",
                buttons: [
                  {
                    icon: close,
                    role: "cancel",
                    handler: () => dismissToast(),
                  },
                ],
              }),
            );
            setIsSubmitting(false);
            return;
          }
          throw e;
        }
      },
      [
        isSubmitting,
        team,
        track,
        teamId,
        presentToast,
        i18n.t.EditTeamPage.teamUpdated,
        i18n.t.common.errorMessage,
        history,
        formState,
        dismissToast,
      ],
    ),
  });

  const buildingPartSelectOptions = mapObjectToFormSelectOptions(
    i18n.t.common.buildingParts,
  );
  return (
    <IonPage data-cy="EditTeamPage">
      <IonHeader>
        <IonToolbar className={localCommonCss.toolbarContainer}>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={teamAdminPageUrl({ teamId })}
              data-cy="EditTeamPage-BackButton"
            />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              type="submit"
              data-cy="EditTeamPage-SubmitButton"
              disabled={isSubmitting}
              onClick={() => submit()}
            >
              {isSubmitting ? <IonSpinner /> : i18n.t.common.update}
            </IonButton>
          </IonButtons>
          <IonTitle>{i18n.t.EditTeamPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={commonCss.contentContainer} fullscreen>
        <IonCard className={commonCss.card}>
          <form {...form}>
            <FormTextInput
              {...fields.name}
              label={i18n.t.common.name}
              required
              data-cy="EditTeamPage-Name-FormInput"
              labelPosition="stacked"
            />
            <FormOptionalNumberInput
              {...fields.numberOfApartments}
              label={i18n.t.EditTeamPage.numberOfApartments}
              required
              data-cy="EditTeamPage-NumberOfApartments-FormInput"
              labelPosition="stacked"
            />
            <FormMultipleSelect
              {...fields.buildingParts}
              label={i18n.t.EditTeamPage.buildingsWhiteListLabel}
              options={buildingPartSelectOptions}
              buttonLabels={i18n.t.common}
              data-cy="EditTeamLocationPage-State-FormInput"
              labelPosition="stacked"
              selectedText={
                fields.buildingParts.value.length > 3
                  ? i18n.t.EditTeamPage.countBuildingPartsSelected(
                      fields.buildingParts.value.length,
                    )
                  : undefined
              }
            />
          </form>
        </IonCard>
      </IonContent>
      <IonFooter />
    </IonPage>
  );
};

export default EditTeamPage;
