import { ChannelBackingStorage } from "@connectedliving/common/lib/firestore/ChannelBackingStorage";
import {
  teamChannelMembershipPath,
  teamChannelPath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { SupportedChannelIcon } from "@connectedliving/common/lib/firestore/supportedChannelIcons";
import {
  ChannelIconColor,
  TeamChannel,
} from "@connectedliving/common/lib/firestore/TeamChannel";
import TeamChannelConverter from "@connectedliving/common/lib/firestore/TeamChannelConverter";
import firebase from "firebase/compat/app";

export default async function createTeamChannel(
  firebaseApp: firebase.app.App,
  authUser: firebase.User,
  {
    teamId,
    teamChannelId,
    name,
    channelIcon,
    channelIconColor,
    channelBackingStorage,
  }: {
    teamId: string;
    teamChannelId: string;
    name: string;
    channelIcon: SupportedChannelIcon;
    channelIconColor: ChannelIconColor;
    channelBackingStorage: ChannelBackingStorage;
  },
  memberIds: string[],
): Promise<void> {
  const batch = firebaseApp.firestore().batch();

  const teamChannel: TeamChannel = {
    teamId,
    name,
    createdBy: authUser.uid,
    channelIcon,
    channelIconColor,
    streamChannelId: teamChannelId,
    purpose: null,
    autoAddNewTeamMembers: false,
    backingStorage: channelBackingStorage,
    latestMessage: null,
    messageTimestamps: [],
  };
  batch.set<TeamChannel>(
    firebaseApp
      .firestore()
      .doc(
        teamChannelPath({
          teamId,
          teamChannelId,
        }),
      )
      .withConverter(TeamChannelConverter),
    teamChannel,
  );

  batch.set(
    firebaseApp.firestore().doc(
      teamChannelMembershipPath({
        teamId,
        teamChannelId,
        userId: authUser.uid,
      }),
    ),
    { teamChannelId, teamId, userId: authUser.uid },
  );

  memberIds.forEach((memberId) => {
    batch.set(
      firebaseApp.firestore().doc(
        teamChannelMembershipPath({
          teamId,
          teamChannelId,
          userId: memberId,
        }),
      ),
      { teamChannelId, teamId, userId: memberId },
    );
  });

  await batch.commit();
}
