import { Team } from "@connectedliving/common/lib/firestore/Team";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import firebase from "firebase/compat/app";
import React, { useMemo } from "react";
import { ExistingDocument } from "src/utilities/data/useFirestoreDocument";

export type TeamContext = {
  teamId: string;
  team: ExistingDocument<Team>;
  authUser: firebase.User;
  userProfile: ExistingDocument<UserProfile>;
  teamUserProfile: ExistingDocument<TeamUserProfile>;
  teamLocations: ExistingDocument<TeamLocation>[];
};

export const EMPTY: unique symbol = Symbol("empty");

const Context = React.createContext<TeamContext | typeof EMPTY>(EMPTY);

type ProviderProps = {
  team: ExistingDocument<Team>;
  authUser: firebase.User;
  userProfile: ExistingDocument<UserProfile>;
  teamUserProfile: ExistingDocument<TeamUserProfile>;
  teamLocations: ExistingDocument<TeamLocation>[];
};

const TeamContextProvider: React.FC<ProviderProps> = ({
  team,
  authUser,
  userProfile,
  teamUserProfile,
  children,
  teamLocations,
}) => {
  const value = useMemo(
    () => ({
      teamId: team.id,
      team,
      authUser,
      userProfile,
      teamUserProfile,
      teamLocations,
    }),
    [authUser, team, teamLocations, teamUserProfile, userProfile],
  );
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

function useTeamContextContainer(): TeamContext {
  const value = React.useContext(Context);
  if (value === EMPTY) {
    throw new Error("Component must be wrapped with <Container.Provider>");
  }
  return value;
}

const TeamContextContainer = {
  Provider: TeamContextProvider,
  useContainer: useTeamContextContainer,
};

export default TeamContextContainer;
