import { Clipboard } from "@capacitor/clipboard";
import {
  teamLocationPath,
  teamPath,
  teamUserProfilePath,
  userProfilePath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import TeamUserProfileConverter from "@connectedliving/common/lib/firestore/TeamUserProfileConverter";
import { formatGermanAddress } from "@connectedliving/common/lib/formatAddress";
import firestoreConsoleDocumentUrl from "@connectedliving/common/lib/utilities/firestoreConsoleUrlBuilders";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  directMessageChannelMessagesPageUrl,
  teamAdminPageUrl,
  userProfilesListPageUrl,
  viewUserProfilePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonSpinner,
  useIonAlert,
  useIonToast,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { clipboardOutline } from "ionicons/icons";
import { without } from "lodash";
import React, { useState } from "react";
import { ChannelPreviewSkeleton } from "src/channels/list/ChannelsListSkeleton";
import commonCss from "src/common/common.module.css";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import environment from "src/utilities/environment";
import { dataAvailable, LoadingState } from "src/utilities/LoadingState";
import ProfileImage from "src/utilities/ProfileImage";
import { isDataAvailable } from "../../utilities/data/Loadable";
import useFirestoreDocument, {
  isFound,
} from "../../utilities/data/useFirestoreDocument";
import { DirectMessageChannelWithContext } from "./DirectMessageChannelWithContext";
import css from "./SupportUserConversationsPageDetailsMenu.module.css";

type SupportUserConversationsPageDetailsMenuProps = {
  loadingState: LoadingState;
  authUser: firebase.User;
  activeDirectMessageChannelWithContext:
    | DirectMessageChannelWithContext
    | null
    | undefined;
};

type ContentProps = {
  activeDirectMessageChannelWithContext: DirectMessageChannelWithContext;
  authUser: firebase.User;
};

const Content: React.FC<ContentProps> = ({
  activeDirectMessageChannelWithContext,
  authUser,
}) => {
  const supportUserId = environment().supportUserId();
  const [presentToast] = useIonToast();
  const [present, dismiss] = useIonAlert();
  const { cloudFunctions, firebaseApp } = FirebaseAppContainer.useContainer();
  const { findTeamById, userProfile } =
    LoggedInUserProfileContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { teamId, otherUserProfile } = activeDirectMessageChannelWithContext;
  const team = findTeamById(teamId);
  const i18n = I18nContainer.useContainer();
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);

  const otherUserTeamUserProfile = useFirestoreDocument(
    otherUserProfile &&
      firebaseApp
        .firestore()
        .doc(teamUserProfilePath({ teamId, userId: otherUserProfile.id }))
        .withConverter(TeamUserProfileConverter),
  );

  const otherUserTeamLocationId =
    otherUserTeamUserProfile.data?.data()?.teamLocationId;

  const otherUserTeamLocation = useFirestoreDocument(
    !!otherUserTeamLocationId &&
      firebaseApp
        .firestore()
        .doc(
          teamLocationPath({
            teamId,
            teamLocationId: otherUserTeamLocationId,
          }),
        )
        .withConverter(TeamLocationConverter),
  );

  const onDeleteUserClick = async () => {
    setIsDeleting(true);
    if (!otherUserProfile)
      throw new Error("There must be an otherUserProfile to delete it");
    const userId = otherUserProfile.id;
    try {
      await cloudFunctions.deleteUser({
        userId,
      });

      dontAwait(
        track({
          eventName: "User Account Deleted",
          "initiator id": authUser.uid,
          "initiator name": userProfile ? formatFullName(userProfile) : "",
          "team id": teamId,
          "user id": userId,
          $user_id: userId,
        }),
      );

      await presentToast({
        message:
          i18n.t.SupportUserConversationsPage.detailsMenu
            .deleteUserSuccessToast,
        color: "success",
        duration: 500,
      });
    } catch (error) {
      await presentToast({
        message:
          i18n.t.SupportUserConversationsPage.detailsMenu.deleteUserErrorToast(
            error,
          ),
        color: "danger",
      });
      setIsDeleting(false);
    }
  };

  const onRemoveUserClick = async () => {
    setIsRemoving(true);
    if (!otherUserProfile)
      throw new Error(
        "There must be an otherUserProfile to remove it from a team",
      );
    const userId = otherUserProfile.id;
    try {
      await cloudFunctions.removeUserFromTeam({
        userId,
        teamId,
      });

      dontAwait(
        track({
          eventName: "User Removed From Team",
          "initiator id": authUser.uid,
          "initiator name": userProfile ? formatFullName(userProfile) : "",
          "team id": teamId,
          "user id": userId,
          $user_id: userId,
        }),
      );

      await presentToast({
        message:
          i18n.t.SupportUserConversationsPage.detailsMenu
            .removeUserSuccessToast,
        color: "success",
        duration: 500,
      });
    } catch (error) {
      await presentToast({
        message:
          i18n.t.SupportUserConversationsPage.detailsMenu.removeUserErrorToast(
            error,
          ),
        color: "danger",
      });
      setIsRemoving(false);
    }
  };

  const otherUserTeamLocationDisplayString =
    isDataAvailable(otherUserTeamLocation) &&
    isFound(otherUserTeamLocation.data) &&
    formatGermanAddress(otherUserTeamLocation.data.data());

  return (
    <>
      <div className={`ion-padding ${commonCss.centerContent}`}>
        <IonAvatar className={commonCss.large}>
          <ProfileImage userProfile={otherUserProfile?.data()} />
        </IonAvatar>
      </div>

      <IonList>
        <IonItem>
          <h1>{otherUserProfile && formatFullName(otherUserProfile.data())}</h1>
        </IonItem>

        <IonItem>
          <IonLabel>
            <strong>
              {i18n.t.SupportUserConversationsPage.detailsMenu.userName}
            </strong>
          </IonLabel>
          <IonLabel>
            {otherUserProfile && formatFullName(otherUserProfile.data())}
          </IonLabel>
        </IonItem>

        <IonItem
          onClick={async () => {
            await Clipboard.write({
              string: otherUserProfile?.id,
            });

            dontAwait(
              presentToast({
                message: i18n.t.common.copiedToClipboard,
                color: "success",
                duration: 3000,
              }),
            );
          }}
        >
          <IonLabel>
            <strong>
              {i18n.t.SupportUserConversationsPage.detailsMenu.userId}
            </strong>
          </IonLabel>
          <IonLabel className={css.clickable}>
            {otherUserProfile?.id}{" "}
            <IonIcon color="medium" icon={clipboardOutline} />
          </IonLabel>
        </IonItem>

        <IonItem
          disabled={!otherUserTeamLocationDisplayString}
          onClick={async () => {
            await Clipboard.write({
              string: otherUserTeamLocationDisplayString || "",
            });

            dontAwait(
              presentToast({
                message: i18n.t.common.copiedToClipboard,
                color: "success",
                duration: 3000,
              }),
            );
          }}
        >
          <IonLabel>
            <strong>
              {i18n.t.SupportUserConversationsPage.detailsMenu.userLocation}
            </strong>
          </IonLabel>
          <IonLabel className={css.clickable}>
            {otherUserTeamLocationDisplayString || "-"}{" "}
            {otherUserTeamLocationDisplayString && (
              <IonIcon color="medium" icon={clipboardOutline} />
            )}
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonLabel>
            <strong>
              {i18n.t.SupportUserConversationsPage.detailsMenu.teamName}
            </strong>
          </IonLabel>
          <IonLabel>{team?.data().name}</IonLabel>
        </IonItem>

        <IonItem
          onClick={async () => {
            await Clipboard.write({
              string: teamId,
            });

            dontAwait(
              presentToast({
                message: i18n.t.common.copiedToClipboard,
                color: "success",
                duration: 3000,
              }),
            );
          }}
        >
          <IonLabel>
            <strong>
              {i18n.t.SupportUserConversationsPage.detailsMenu.teamId}
            </strong>
          </IonLabel>
          <IonLabel className={css.clickable}>
            {teamId} <IonIcon color="medium" icon={clipboardOutline} />
          </IonLabel>
        </IonItem>

        <IonItem>
          <IonLabel>
            <strong>
              {i18n.t.SupportUserConversationsPage.detailsMenu.teamMemberCount}
            </strong>
          </IonLabel>
          <IonLabel>
            {i18n.format.formatNumber(
              without(team?.data().userIds, supportUserId).length,
            )}
          </IonLabel>
        </IonItem>

        {otherUserProfile && (
          <>
            <IonListHeader>
              <strong>
                {
                  i18n.t.SupportUserConversationsPage.detailsMenu
                    .connectedLiving
                }
              </strong>
            </IonListHeader>

            <IonItem
              href={viewUserProfilePageUrl({
                teamId,
                userProfileId: otherUserProfile.id,
              })}
              target="_blank"
            >
              <IonLabel color="primary">
                {
                  i18n.t.SupportUserConversationsPage.detailsMenu
                    .userProfilePageLink
                }
              </IonLabel>
            </IonItem>

            <IonItem
              href={directMessageChannelMessagesPageUrl({
                teamId,
                authUserId: authUser.uid,
                otherUserId: otherUserProfile.id,
              })}
              target="_blank"
            >
              <IonLabel color="primary">
                {
                  i18n.t.SupportUserConversationsPage.detailsMenu
                    .directMessageConversationPageLink
                }
              </IonLabel>
            </IonItem>

            <IonItem href={teamAdminPageUrl({ teamId })} target="_blank">
              <IonLabel color="primary">
                {
                  i18n.t.SupportUserConversationsPage.detailsMenu
                    .teamAdminPageLink
                }
              </IonLabel>
            </IonItem>

            <IonItem href={userProfilesListPageUrl({ teamId })} target="_blank">
              <IonLabel color="primary">
                {
                  i18n.t.SupportUserConversationsPage.detailsMenu
                    .membersListPageLink
                }
              </IonLabel>
            </IonItem>

            <IonListHeader>
              <strong>
                {i18n.t.SupportUserConversationsPage.detailsMenu.firestore}
              </strong>
            </IonListHeader>

            <IonItem
              href={firestoreConsoleDocumentUrl({
                projectId: environment().requireEnvVar(
                  "REACT_APP_FIREBASE_PROJECT_ID",
                ),
                documentPath: teamPath({ teamId }),
              })}
              target="_blank"
              routerDirection="forward"
            >
              <IonLabel color="primary">
                {
                  i18n.t.SupportUserConversationsPage.detailsMenu
                    .openTeamInFirestore
                }
              </IonLabel>
            </IonItem>

            <IonItem
              href={firestoreConsoleDocumentUrl({
                projectId: environment().requireEnvVar(
                  "REACT_APP_FIREBASE_PROJECT_ID",
                ),
                documentPath: userProfilePath({ userId: otherUserProfile.id }),
              })}
              target="_blank"
              routerDirection="forward"
            >
              <IonLabel color="primary">
                {
                  i18n.t.SupportUserConversationsPage.detailsMenu
                    .openUserProfileInFirestore
                }
              </IonLabel>
            </IonItem>
            <IonButton
              color="danger"
              expand="block"
              className="ion-margin"
              fill="outline"
              disabled={isRemoving}
              onClick={async () => {
                await present({
                  message:
                    i18n.t.SupportUserConversationsPage.detailsMenu.removalAlert
                      .message,
                  header:
                    i18n.t.SupportUserConversationsPage.detailsMenu.removalAlert
                      .title,
                  buttons: [
                    {
                      text: i18n.t.common.cancel,
                      role: "cancel",
                      handler: dismiss,
                    },
                    {
                      text: i18n.t.SupportUserConversationsPage.detailsMenu
                        .removalAlert.confirm,
                      role: "destructive",
                      handler: onRemoveUserClick,
                    },
                  ],
                });
              }}
            >
              {isRemoving ? (
                <IonSpinner color="danger" />
              ) : (
                i18n.t.SupportUserConversationsPage.detailsMenu.removeUser
              )}
            </IonButton>
            <IonButton
              color="danger"
              expand="block"
              className="ion-margin"
              fill="outline"
              disabled={isDeleting}
              onClick={async () => {
                await present({
                  message:
                    i18n.t.SupportUserConversationsPage.detailsMenu
                      .deletionAlert.message,
                  header:
                    i18n.t.SupportUserConversationsPage.detailsMenu
                      .deletionAlert.title,
                  buttons: [
                    {
                      text: i18n.t.common.cancel,
                      role: "cancel",
                      handler: dismiss,
                    },
                    {
                      text: i18n.t.SupportUserConversationsPage.detailsMenu
                        .deletionAlert.confirm,
                      role: "destructive",
                      handler: onDeleteUserClick,
                    },
                  ],
                });
              }}
            >
              {isDeleting ? (
                <IonSpinner color="danger" />
              ) : (
                i18n.t.SupportUserConversationsPage.detailsMenu.deleteUser
              )}
            </IonButton>
          </>
        )}
      </IonList>

      <IonButton
        routerLink="/"
        expand="block"
        className="ion-margin"
        fill="outline"
      >
        {i18n.t.SupportUserConversationsPage.menu.exit}
      </IonButton>
    </>
  );
};

const SupportUserConversationsPageDetailsMenu: React.FC<
  SupportUserConversationsPageDetailsMenuProps
> = ({ activeDirectMessageChannelWithContext, loadingState, authUser }) => {
  const i18n = I18nContainer.useContainer();

  return (
    <IonMenu side="end" contentId="conversations" menuId="details">
      <IonContent className="ion-padding-top">
        {!dataAvailable(loadingState) ? (
          <>
            <IonItem detail={false}>
              <IonLabel color="primary">
                {i18n.t.SupportUserConversationsPage.detailsMenu.loadingMessage}
              </IonLabel>
              <IonSpinner />
            </IonItem>
            <ChannelPreviewSkeleton />
            <ChannelPreviewSkeleton />
            <ChannelPreviewSkeleton />
          </>
        ) : (
          activeDirectMessageChannelWithContext && (
            <Content
              {...{
                activeDirectMessageChannelWithContext,
                authUser,
              }}
            />
          )
        )}
      </IonContent>
    </IonMenu>
  );
};

export default SupportUserConversationsPageDetailsMenu;
