import { includes } from "lodash";
import assertExhausted from "../utilities/lang/assertExhausted";
import {
  getNumber,
  getOptionalString,
  getStrictDocumentData,
  getString,
  getStringLiteral,
  isDocumentData,
  StrictDocumentData,
} from "./dataHelpers";
import { FileUpload } from "./FileUpload";
import { firestoreDataToThumbnail, Thumbnail } from "./Thumbnail";

export enum MessageAttachmentType {
  File = "file",
}
const messageAttachmentTypeValues = Object.values(MessageAttachmentType);

export type FileUploadAttachment = {
  type: MessageAttachmentType.File;
  thumbnail: Thumbnail | null;
} & Omit<FileUpload, "creatorId" | "createdAt" | "updatedAt">;

export type MessageAttachment = FileUploadAttachment;

export function isRecognizedAttachmentType(
  value: unknown,
): value is StrictDocumentData {
  return (
    isDocumentData(value) &&
    includes(messageAttachmentTypeValues, getString(value.type))
  );
}

export function firestoreDataToMessageAttachment(
  data: StrictDocumentData,
): MessageAttachment {
  const type = getStringLiteral(data.type, {
    permitted: messageAttachmentTypeValues,
    fallback: MessageAttachmentType.File,
  });

  if (type === MessageAttachmentType.File) {
    return {
      type,
      thumbnail: firestoreDataToThumbnail(
        getStrictDocumentData(data.thumbnail),
      ),
      teamId: getString(data.teamId),
      channelPath: getString(data.channelPath),
      url: getString(data.url),
      mimeType: getOptionalString(data.mimeType),
      name: getString(data.name),
      description: getOptionalString(data.description),
      size: getNumber(data.size),
    };
  }

  throw assertExhausted(type);
}
