import { ArrayElement } from "../utilities/ArrayElement";
import { SupportedCountryCode } from "./supportedCountries";
import { TimestampValue } from "./TimestampValue";

export const waitingListEntryStatuses = Object.freeze([
  "initial",
  "confirmationSent",
  "teamCreatedNotificationSent",
  "teamJoined",
  "completed",
] as const);

export type WaitingListEntryStatus = ArrayElement<
  typeof waitingListEntryStatuses
>;

export const pendingCompletionStatuses: WaitingListEntryStatus[] = [
  "initial",
  "confirmationSent",
  "teamCreatedNotificationSent",
];
export const pendingNotificationStatuses: WaitingListEntryStatus[] = [
  "confirmationSent",
];

export type WaitingListEntry = {
  status: WaitingListEntryStatus;

  userId: string | null;
  email: string;
  mixpanelId: string;

  streetNumber: string;
  streetName: string;
  city: string;
  state: string;
  postcode: string;
  country: SupportedCountryCode;
  googlePlaceId: string;
  googlePlaceIdRetrievedAt: TimestampValue;
  formattedAddress: string;

  locale: "en" | "de";

  joinedTeamId: string | null;
};
