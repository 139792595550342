import { StreamChannelWithLoadingState } from "../../state/stream/StreamChannelsCacheContainer";

export default function streamChannelLastMessageSentAt(
  channel: StreamChannelWithLoadingState,
): number {
  return (
    channel.value.state.messages[
      channel.value.state.messages.length - 1
    ]?.created_at?.valueOf() || 0
  );
}
