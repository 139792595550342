import firebase from "firebase/compat/app";
import convertFields from "../utilities/firestore/convertFields";
import {
  convertTimestampValueToFirebaseAppTimestamp,
  getFirestoreAppTimestampValue,
} from "./app/firestoreAppTimestamp";
import {
  getOptionalString,
  getString,
  getStringLiteral,
  getValue,
  StrictDocumentData,
} from "./dataHelpers";
import isSupportedCountryCode from "./isSupportedCountryCode";
import { WaitingListEntry, waitingListEntryStatuses } from "./WaitingListEntry";

export function waitingListEntryToFirestoreData(
  waitingListEntry: Partial<WaitingListEntry>,
): Record<string, unknown> {
  const result = convertFields(waitingListEntry, {
    googlePlaceIdRetrievedAt: convertTimestampValueToFirebaseAppTimestamp,
  });
  return result;
}

export function firestoreDataToWaitingListEntry(
  data: StrictDocumentData,
): WaitingListEntry {
  return {
    status: getStringLiteral(data.status, {
      permitted: waitingListEntryStatuses,
      fallback: "initial",
    }),

    userId: getOptionalString(data.userId, { fallback: null }),
    email: getString(data.email),
    mixpanelId: getString(data.mixpanelId),

    streetNumber: getString(data.streetNumber),
    streetName: getString(data.streetName),
    city: getString(data.city),
    state: getString(data.state),
    postcode: getString(data.postcode),
    country: getValue(data.country, {
      typeCheck: isSupportedCountryCode,
      fallback: "de",
    }),
    googlePlaceId: getString(data.googlePlaceId),
    googlePlaceIdRetrievedAt: getFirestoreAppTimestampValue(
      data.googlePlaceIdRetrievedAt,
    ),
    formattedAddress: getString(data.formattedAddress),

    locale: getStringLiteral(data.language, {
      permitted: ["en", "de"],
      fallback: "en",
    }),

    joinedTeamId: getOptionalString(data.userId, { fallback: null }),
  };
}

const WaitingListEntryConverter: firebase.firestore.FirestoreDataConverter<WaitingListEntry> =
  Object.freeze({
    toFirestore(waitingListEntry: WaitingListEntry) {
      return waitingListEntryToFirestoreData(waitingListEntry);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): WaitingListEntry {
      return firestoreDataToWaitingListEntry(snapshot.data(options));
    },
  });
export default WaitingListEntryConverter;
