import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import { viewUserProfilePageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonAvatar, IonItem, IonLabel, IonSkeletonText } from "@ionic/react";
import firebase from "firebase/compat/app";
import I18nContainer from "src/state/i18n/I18nContainer";
import { dataAvailable, LoadingState } from "src/utilities/LoadingState";
import ProfileImage from "src/utilities/ProfileImage";
import css from "./UserProfileListItem.module.css";

const UserProfileListItem: React.FC<{
  teamId: string;
  userProfile?:
    | firebase.firestore.QueryDocumentSnapshot<UserProfile>
    | firebase.firestore.DocumentSnapshot<UserProfile>;
  loadingState: LoadingState;
  displayAsYou?: boolean;
}> = ({ teamId, loadingState, displayAsYou, userProfile }) => {
  const userProfileData = userProfile && userProfile.data();
  const formattedName = userProfileData ? formatFullName(userProfileData) : "";
  const i18n = I18nContainer.useContainer();

  let displayName;

  if (!dataAvailable(loadingState)) {
    displayName = (
      <IonSkeletonText
        animated
        style={{ display: "inline-block", width: 100 }}
      />
    );
  } else if (displayAsYou) {
    displayName = i18n.t.common.you;
  } else displayName = formattedName;
  return (
    <IonItem
      detail={!!userProfile}
      data-cy={`UserProfileListItem-${formattedName}`}
      routerLink={
        userProfile &&
        viewUserProfilePageUrl({
          teamId,
          userProfileId: userProfile.id,
        })
      }
    >
      <IonAvatar slot="start" className={css.container}>
        {dataAvailable(loadingState) && formattedName ? (
          <ProfileImage userProfile={userProfileData} />
        ) : (
          <IonSkeletonText animated />
        )}
      </IonAvatar>

      <IonLabel>{displayName}</IonLabel>
    </IonItem>
  );
};

export default UserProfileListItem;
