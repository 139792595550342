import css from "./UserOnboardingSlideLayout.module.css";

type UserOnboardingSlideLayoutProps = {
  className?: string;
};

const UserOnboardingSlideLayout: React.FC<UserOnboardingSlideLayoutProps> = ({
  children,
  className,
}) => <div className={`${css.slideContainer} ${className}`}>{children}</div>;
export default UserOnboardingSlideLayout;
