import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import {
  checkMyAddressPageUrl,
  checkMyAddressResultsPageUrl,
  teamCreatedSuccessPageUrl,
  teamCreatePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Redirect, Route } from "react-router";
import CheckMyAddressPage from "src/getStarted/CheckMyAddressPage";
import CheckMyAddressResultsPage from "src/getStarted/CheckMyAddressResultsPage";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import TeamCreatedSuccessPage from "src/teams/TeamCreatedSuccessPage";
import TeamCreatePage from "src/teams/TeamCreatePage";

const FallbackRoute: React.FC = () => {
  const { userProfile } = LoggedInUserProfileContainer.useContainer();

  assertPresent(userProfile, {
    because: "<App> waits for `LoggedInUserProfile` to load",
  });

  const firstTeamId = userProfile.teamIds[0];
  const rootRedirectUrl = !firstTeamId
    ? checkMyAddressPageUrl()
    : teamCreatedSuccessPageUrl({ teamId: firstTeamId });

  return <Redirect to={rootRedirectUrl} />;
};

const RestrictedWebRoutes: React.FC = () => (
  <IonRouterOutlet>
    <Route
      path={checkMyAddressResultsPageUrl({ placeId: ":placeId" })}
      exact
      component={CheckMyAddressResultsPage}
    />
    <Route
      path={teamCreatedSuccessPageUrl({ teamId: ":teamId" })}
      exact
      component={TeamCreatedSuccessPage}
    />
    <Route
      path={teamCreatePageUrl({ placeId: ":placeId" })}
      exact
      component={TeamCreatePage}
    />
    <Route
      path={checkMyAddressPageUrl()}
      exact
      component={CheckMyAddressPage}
    />
    <Route component={FallbackRoute} />
  </IonRouterOutlet>
);

export default RestrictedWebRoutes;
