import { ArrayElement } from "../utilities/ArrayElement";
import castAs from "../utilities/lang/castAs";

export const supportedCountryCodes = ["de"] as const;

export type SupportedCountryCode = ArrayElement<typeof supportedCountryCodes>;

export const supportedCountries = castAs<Record<SupportedCountryCode, string>>({
  de: `Germany`,
});
