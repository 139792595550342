import firebase from "firebase/compat/app";
import {
  firestoreDataToVersions,
  Versions,
  versionsToFirestoreData,
} from "./Versions";

const VersionsConverter: firebase.firestore.FirestoreDataConverter<Versions> =
  Object.freeze({
    toFirestore(versions: Versions) {
      return versionsToFirestoreData(versions);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Versions {
      return firestoreDataToVersions(snapshot.data(options));
    },
  });

export default VersionsConverter;
