import { IonButton, IonIcon, useIonActionSheet } from "@ionic/react";
import firebase from "firebase/compat/app";
import {
  globeOutline,
  logOutOutline,
  settingsOutline,
  trashOutline,
} from "ionicons/icons";
import { useCallback } from "react";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import useDeleteAccountActionSheet from "./useDeleteAccountActionSheet";
import useLocaleSwitcherActionSheet from "./useLocaleSwitcherActionSheet";

export type OnboardingActionSheetButtonProps = {
  color?: string;
  authUser: firebase.User;
  screenName: string;
};

const OnboardingActionSheetButton: React.FC<
  OnboardingActionSheetButtonProps
> = ({ authUser, color, screenName }) => {
  const [presentActionSheet] = useIonActionSheet();
  const i18n = I18nContainer.useContainer();
  const { logOut } = FirebaseAppContainer.useContainer();

  const presentDeleteAccountActionSheet = useDeleteAccountActionSheet({
    screenName,
  });

  const presentLocaleSwitcherActionSheet = useLocaleSwitcherActionSheet({
    screenName,
  });

  const presentOnboardingActionSheet = useCallback(async () => {
    await presentActionSheet({
      buttons: [
        {
          text: i18n.t.UserSettingsPage.deleteAccount,
          role: "destructive",
          icon: trashOutline,
          handler: presentDeleteAccountActionSheet,
        },
        {
          text: i18n.t.OnboardingActionSheetButtons.localeSwitch,
          icon: globeOutline,
          handler: presentLocaleSwitcherActionSheet,
        },
        {
          icon: logOutOutline,
          text: i18n.t.UserSettingsPage.logOut,
          handler: logOut,
        },
        {
          text: i18n.t.common.cancel,
          role: "cancel",
        },
      ],
      header: authUser.email ?? "",
    });
  }, [
    authUser.email,
    i18n.t.OnboardingActionSheetButtons.localeSwitch,
    i18n.t.UserSettingsPage.deleteAccount,
    i18n.t.UserSettingsPage.logOut,
    i18n.t.common.cancel,
    logOut,
    presentActionSheet,
    presentDeleteAccountActionSheet,
    presentLocaleSwitcherActionSheet,
  ]);

  return (
    <IonButton onClick={presentOnboardingActionSheet}>
      <IonIcon color={color ?? "light"} icon={settingsOutline} />
    </IonButton>
  );
};

export default OnboardingActionSheetButton;
