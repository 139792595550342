import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import {
  IonItem,
  IonLabel,
  IonListHeader,
  IonRadio,
  IonRadioGroup,
} from "@ionic/react";
import React, { PropsWithChildren, ReactElement, useCallback } from "react";
import { FieldInputProps } from "src/state/formState/useFieldInputs";
import { IonicBlurHandler } from "src/utilities/ionic/IonicBlurHandler";
import { IonicChangeHandler } from "src/utilities/ionic/IonicChangeHandler";
import FormFieldErrors from "./FormFieldErrors";
import css from "./FormRadioGroup.module.css";

type CommonFormRadioGroupProps<ValueType> = Omit<
  React.ComponentProps<typeof IonRadioGroup>,
  "onIonChange" | "onIonBlur" | "onChange" | "onBlur" | "value"
> & {
  label: string;
  disabled: boolean;
  options: readonly { value: ValueType; label: string }[];
};

export type FormOptionalRadioGroupProps<ValueType extends string> =
  CommonFormRadioGroupProps<ValueType> & FieldInputProps<ValueType | null>;

const FormOptionalRadioGroup = <OptionValues extends string>({
  onChange,
  onBlur,
  options,
  disabled,
  label: radioGroupLabel,
  errors,
  ...restProps
}: PropsWithChildren<
  FormOptionalRadioGroupProps<OptionValues>
>): ReactElement | null => {
  const onIonChange: IonicChangeHandler = useCallback(
    (event) => {
      if (disabled) return;
      onChange(
        blindCast<
          OptionValues,
          "we will only render options with values from the `options` prop so we can assume the value received here comes from one of them"
        >(event.detail.value),
      );
    },
    [disabled, onChange],
  );
  const onIonBlur: IonicBlurHandler = useCallback(() => {
    onBlur();
  }, [onBlur]);

  return (
    <>
      <IonRadioGroup {...restProps} {...{ onIonChange, onIonBlur }}>
        <IonListHeader className={css.radioGroupLabel}>
          <IonLabel>{radioGroupLabel}</IonLabel>
        </IonListHeader>

        {options.map(({ label, value }) => (
          <IonItem key={value} lines="none" disabled={disabled}>
            <IonLabel className="ion-text-wrap">{label}</IonLabel>
            <IonRadio slot="start" mode="md" {...{ value }} />
          </IonItem>
        ))}
      </IonRadioGroup>
      <FormFieldErrors {...{ errors }} />
    </>
  );
};

export { FormOptionalRadioGroup };

export type FormRadioGroupProps<ValueType extends string> =
  CommonFormRadioGroupProps<ValueType> & FieldInputProps<ValueType>;

const FormRadioGroup = <OptionValues extends string>(
  props: PropsWithChildren<FormRadioGroupProps<OptionValues>>,
): ReactElement | null => <FormOptionalRadioGroup {...props} />;

export default FormRadioGroup;
