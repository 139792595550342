import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import {
  directMessageChannelPath,
  userProfilePath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import UserProfileConverter from "@connectedliving/common/lib/firestore/UserProfileConverter";
import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import streamDirectMessageChannelId from "@connectedliving/common/lib/stream/streamDirectMessageChannelId";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { ChannelTrackingContext } from "@connectedliving/common/lib/utilities/tracking/channelTrackingProps";
import {
  channelsListPageUrl,
  directMessageChannelDetailPageUrl,
  viewUserProfilePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonContent, IonHeader, useIonViewDidEnter } from "@ionic/react";
import firebase from "firebase/compat/app";
import React, { useCallback, useMemo, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";
import { Redirect } from "react-router";
import ChannelsMessagesHeader from "src/channels/messages/ChannelsMessagesHeader";
import StreamChannelSkeleton from "src/channels/messages/StreamChannelSkeleton";
import DraftMessagesContainer, {
  DraftMessage,
} from "src/state/DraftMessagesContainer";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import StreamChannelsCacheContainer from "src/state/stream/StreamChannelsCacheContainer";
import TeamUserPreferencesContainer from "src/state/team/TeamUserPreferencesContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import TranslationConfigContainer from "src/state/TranslationConfigContainer";
import firestoreHooksLoadingState from "src/utilities/firestoreHooksLoadingState";
import { dataAvailable, isErrorState } from "src/utilities/LoadingState";
import { Channel } from "stream-chat-react";
import UserIsBlockedBanner from "../../../common/UserIsBlockedBanner";
import environment from "../../../utilities/environment";
import ChannelMessagesPageContextProvider from "../ChannelMessagesPageContext";
import { CLAvatar } from "../CLAvatar";
import CLMessageInput from "../CLMessageInput";
import CLMessageInputFlat from "../CLMessageInputFlat";
import CLTriggerProvider from "../CLTriggerProvider";
import MessageRenderer from "../MessageRenderer";
import StreamMessageList from "../StreamMessageList";

export type StreamDirectMessageChannelMessagesPageContentProps = {
  directMessageChannelId: string;
  directMessageChannel: DirectMessageChannel;
  currentDirectMessageChannelPath: string;
  teamId: string;
  otherUserId: string;
};

const StreamDirectMessageChannelMessagesPageContent: React.FC<
  StreamDirectMessageChannelMessagesPageContentProps
> = ({
  directMessageChannelId,
  directMessageChannel,
  currentDirectMessageChannelPath,
  teamId,
  otherUserId,
}) => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { authUser } = TeamContextContainer.useContainer();
  const { findUserProfileById } = TeamUserProfilesContainer.useContainer();

  const [otherUserProfile, otherUserProfileLoadingState] =
    firestoreHooksLoadingState<
      firebase.firestore.DocumentSnapshot<UserProfile>
    >(
      useDocument(
        firebaseApp
          .firestore()
          .doc(userProfilePath({ userId: otherUserId }))
          .withConverter(UserProfileConverter),
      ),
    );

  const streamChannelId =
    directMessageChannel.streamChannelId ||
    streamDirectMessageChannelId(
      teamId,
      authUser.uid,
      directMessageChannel.otherUserId,
    );

  const { getStreamChannel } = StreamChannelsCacheContainer.useContainer();
  const { value: streamChannel, loadingState } = getStreamChannel(
    StreamChannelType.DirectMessage,
    streamChannelId,
  );

  const { showTranslation, setShowTranslation } =
    TranslationConfigContainer.useContainer();

  const { setDraftMessage, getDraftMessage } =
    DraftMessagesContainer.useContainer();
  const draftMessage = getDraftMessage(currentDirectMessageChannelPath);
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);

  const onMessageInputChange = useCallback(
    (value: DraftMessage) => {
      setDraftMessage(currentDirectMessageChannelPath, value);
    },
    [currentDirectMessageChannelPath, setDraftMessage],
  );

  const { markChannelRead } = StreamChannelsCacheContainer.useContainer();

  const markRead = useCallback(() => {
    dontAwait(
      markChannelRead(StreamChannelType.DirectMessage, streamChannelId),
    );
  }, [markChannelRead, streamChannelId]);

  useIonViewDidEnter(() => {
    if (loadingState === "ready") {
      markRead();
    }
  }, [markRead, loadingState]);

  const { teamUserPreferences } = TeamUserPreferencesContainer.useContainer();
  const userIsBlocked = teamUserPreferences.data
    ?.data()
    ?.blockedUserIds.includes(otherUserId);

  const otherUserUserProfileData = otherUserProfile && otherUserProfile.data();

  const imageUrl = otherUserUserProfileData?.imageUrl;

  const formattedName = otherUserUserProfileData
    ? formatFullName(otherUserUserProfileData)
    : "";

  const channelTrackingContext: ChannelTrackingContext = useMemo(
    () => ({
      type: FirestoreChannelType.DirectMessage,
      id: directMessageChannelId,
      path: currentDirectMessageChannelPath,
      data: directMessageChannel,
      userIds: [authUser.uid, otherUserId],
    }),
    [
      authUser.uid,
      currentDirectMessageChannelPath,
      directMessageChannel,
      directMessageChannelId,
      otherUserId,
    ],
  );

  return (
    <ChannelMessagesPageContextProvider {...{ setAttachmentModalOpen }}>
      {!attachmentModalOpen && (
        <IonHeader>
          <ChannelsMessagesHeader
            channelPath={currentDirectMessageChannelPath}
            channelType={StreamChannelType.DirectMessage}
            channelAvatar={
              imageUrl
                ? { type: "image", imageUrl }
                : {
                    type: "initialsAvatar",
                    name: formattedName,
                  }
            }
            title={formattedName}
            onShowTranslationChange={setShowTranslation}
            loadingState={otherUserProfileLoadingState}
            channelDetailPageUrl={directMessageChannelDetailPageUrl({
              teamId,
              authUserId: authUser.uid,
              otherUserId,
            })}
            titleRouterLink={viewUserProfilePageUrl({
              teamId,
              userProfileId: otherUserId,
            })}
            {...{ teamId, showTranslation, channelTrackingContext }}
          />
        </IonHeader>
      )}

      <IonContent forceOverscroll={false}>
        {isErrorState(loadingState) && (
          <Redirect to={channelsListPageUrl({ teamId })} />
        )}

        {dataAvailable(loadingState) && streamChannel ? (
          <Channel<InternalStreamChatGenerics>
            channel={streamChannel}
            EmptyPlaceholder={<StreamChannelSkeleton />}
            doMarkReadRequest={markRead}
            Avatar={CLAvatar}
            // eslint-disable-next-line react/no-unstable-nested-components
            Message={(props) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <MessageRenderer
                {...props}
                authUser={authUser}
                teamId={teamId}
                findUserProfileById={findUserProfileById}
                linkTrackingContext={{
                  "channel type": "direct message",
                  "channel id": directMessageChannelId,
                  "channel purpose": null,
                  "channel name": null,
                  "channel path": directMessageChannelPath({
                    teamId,
                    userId: authUser.uid,
                    otherUserId,
                  }),
                  "is support dm channel":
                    otherUserId === environment().supportUserId(),
                  "is support user":
                    authUser.uid === environment().supportUserId(),
                }}
              />
            )}
            Input={CLMessageInputFlat}
            TriggerProvider={CLTriggerProvider}
          >
            {userIsBlocked ? (
              <UserIsBlockedBanner
                {...{ teamId }}
                blockedUserId={otherUserId}
              />
            ) : (
              <>
                <StreamMessageList {...{ teamId, streamChannel }} />

                <div
                  style={{ display: attachmentModalOpen ? "none" : "block" }}
                >
                  <CLMessageInput
                    onChange={onMessageInputChange}
                    draftMessage={draftMessage}
                  />
                </div>
              </>
            )}
          </Channel>
        ) : (
          <StreamChannelSkeleton />
        )}
      </IonContent>
    </ChannelMessagesPageContextProvider>
  );
};

export default StreamDirectMessageChannelMessagesPageContent;
