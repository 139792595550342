import { BuildingPart } from "./BuildingPart";
import { ChannelBackingStorage } from "./ChannelBackingStorage";
import { TimestampValue } from "./TimestampValue";
import { UtmParams } from "./UtmParams";

export const teamUnsecuredUntilMemberCount = 10;

export type Team = {
  creatorId: string | null;
  name: string;
  inviteCode: string;
  starterKitSentAt: TimestampValue | null;
  numberOfApartments: number;
  userIds: string[];
  utmParams: UtmParams;
  buildingParts: BuildingPart[];
  isArchived: boolean;
  channelBackingStorage: ChannelBackingStorage;
};
