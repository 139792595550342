import { deletedUserName } from "@connectedliving/common/lib/cloudFunctions/DeleteUser";
import { channelIconColors } from "@connectedliving/common/lib/firestore/TeamChannel";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import css from "./FallbackProfileImage.module.css";
import stringHash from "./stringHash";

export type FallBackProfileImageProps = {
  name: string | null | undefined;
};

const FallbackProfileImage: React.FC<FallBackProfileImageProps> = ({
  name,
}) => {
  const isDeleted = name === deletedUserName;
  const hashValue = stringHash(name);

  const colorClasses = channelIconColors.map((color) => `ion-color-${color}`);

  const backgroundColorClass = isDeleted
    ? "ion-color-medium"
    : colorClasses[hashValue % colorClasses.length];

  return (
    <div className={`${css.container} ${backgroundColorClass}`}>
      {isDeleted ? (
        <IonIcon icon={closeOutline} />
      ) : (
        name?.charAt(0).toUpperCase()
      )}
    </div>
  );
};

export default FallbackProfileImage;
