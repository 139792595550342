import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonText,
} from "@ionic/react";
import React from "react";
import css from "./InfoCard.module.css";

export const InfoCardButton: React.FC<
  React.ComponentProps<typeof IonButton>
> = (props) => <IonButton color="light" fill="outline" {...props} />;

export const InfoCardText: React.FC<React.ComponentProps<typeof IonText>> = ({
  className,
  ...props
}) => (
  <IonText
    className={`ion-margin-bottom ${css.content} ${className}`}
    {...props}
  />
);

export type InfoCardProps = React.ComponentProps<typeof IonCard> & {
  cardTitle: React.ReactNode;
};

const InfoCard: React.FC<InfoCardProps> = ({
  cardTitle,
  children,
  ...props
}) => (
  <IonCard color="aubergine-fill" {...props}>
    <IonCardHeader className={css.cardHeader}>{cardTitle}</IonCardHeader>
    <IonCardContent className={css.cardContent}>{children}</IonCardContent>
  </IonCard>
);

export default InfoCard;
