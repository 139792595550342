import { ChannelBackingStorage } from "@connectedliving/common/lib/firestore/ChannelBackingStorage";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import {
  directMessageChannelPath,
  teamLocationsPath,
  userProfilePath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import UserProfileConverter from "@connectedliving/common/lib/firestore/UserProfileConverter";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  directMessageChannelMessagesPageUrl,
  editTeamPageUrl,
  leaveTeamPageUrl,
  userProfilesListPageUrl,
  userSettingsPageUrl,
  viewUserProfilePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { OverlayEventDetail } from "@ionic/core";
import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import {
  addOutline,
  close,
  closeOutline,
  createOutline,
  informationCircleOutline,
  personAddOutline,
} from "ionicons/icons";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import InviteNeighborModal from "src/common/InviteNeighborModal";
import IonItemIcon from "src/common/IonItemIcon";
import SearchAddressModal from "src/common/SearchAddressModal";
import useScreenTracking from "src/firebase/useScreenTracking";
import createDirectMessageChannel from "src/state/firebase/createDirectMessageChannel";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import { isDataAvailable } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import useFirestoreDocument from "src/utilities/data/useFirestoreDocument";
import sortTeamLocations from "src/utilities/sortTeamLocations";
import commonCss from "../common/common.module.css";
import createTeamLocation from "./createTeamLocation";
import css from "./TeamAdminPage.module.css";

export type TeamAdminPageProps = {
  teamId: string;
};

const TeamAdminPage: React.FC<TeamAdminPageProps> = () => {
  const { teamId, authUser, team } = TeamContextContainer.useContainer();
  const { onboardedTeamUserProfiles } =
    TeamUserProfilesContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const screenName = "TeamAdminPage";
  useScreenTracking(screenName, teamId);
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { directMessageChannels } = FirestoreChannelsContainer.useContainer();

  const teamData = team.data();
  const { creatorId, inviteCode, buildingParts, name, numberOfApartments } =
    teamData;

  const userProfile = useFirestoreDocument(
    creatorId
      ? firebaseApp
          .firestore()
          .doc(userProfilePath({ userId: creatorId }))
          .withConverter(UserProfileConverter)
      : undefined,
  );

  const userProfileData = userProfile.data?.data();
  const i18n = I18nContainer.useContainer();
  const { currentLocale } = i18n;
  const isFounder = authUser.uid === creatorId;

  const teamLocations = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(teamLocationsPath({ teamId }))
      .withConverter(TeamLocationConverter),
  );
  const supportUserId = requireEnvVar("REACT_APP_SUPPORT_USER_ID");
  const history = useHistory();
  const [showAddAddressModal, setShowAddAddressModal] =
    useState<boolean>(false);
  const [isOpenInviteNeighborModal, setIsOpenInviteNeighborModal] =
    useState<boolean>(false);

  const onContactUsClick = useCallback(async () => {
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Contact Us",
      }),
    );
    const existingDirectMessageChannel = directMessageChannels.find(
      (channel) => channel.id === supportUserId,
    );
    if (!existingDirectMessageChannel) {
      await createDirectMessageChannel({
        firebaseApp,
        teamId,
        userId: authUser.uid,
        otherUserId: supportUserId,
        backingStorage: ChannelBackingStorage.Firestore,
      });

      await firebaseApp
        .firestore()
        .doc(
          directMessageChannelPath({
            teamId,
            userId: authUser.uid,
            otherUserId: supportUserId,
          }),
        )
        .withConverter(DirectMessageChannelConverter)
        .get();
    }

    history.replace(
      directMessageChannelMessagesPageUrl({
        teamId,
        authUserId: authUser.uid,
        otherUserId: supportUserId,
      }),
    );
  }, [
    track,
    teamId,
    directMessageChannels,
    history,
    authUser.uid,
    supportUserId,
    firebaseApp,
  ]);

  const [presentToast] = useIonToast();

  const handleDismiss: (
    event: CustomEvent<OverlayEventDetail<TeamLocation | null>>,
  ) => void = async (event) => {
    setShowAddAddressModal(false);
    if (event.detail.data) {
      try {
        await createTeamLocation({
          teamLocation: event.detail.data,
          track,
          teamId,
          firebaseApp,
        });
        await presentToast({
          message: i18n.t.TeamAdminPage.teamLocationUpdated(event.detail.data),
          color: "success",
          position: "bottom",
          duration: 1500,
        });
      } catch (error) {
        Sentry.captureException(error);
        await presentToast({
          message: i18n.t.common.errorMessage,
          color: "danger",
          position: "bottom",
          duration: 5000,
          buttons: [
            {
              icon: close,
              role: "cancel",
            },
          ],
        });
      }
    }
  };

  const onInviteNeighborButtonClick = useCallback(() => {
    setIsOpenInviteNeighborModal(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Invite Neighbor",
      }),
    );
    dontAwait(
      track({
        eventName: "Modal Opened",
        "screen name": screenName,
        "team id": teamId,
        "modal name": "InviteNeighborModal",
      }),
    );
  }, [track, teamId]);

  if (!isDataAvailable(teamLocations)) {
    return (
      <IonPage data-cy="TeamAdminPage">
        <div>
          <IonSpinner color="primary" />
        </div>
      </IonPage>
    );
  }

  const sortedTeamLocations = sortTeamLocations(
    teamLocations.data,
    currentLocale,
  );

  const memberCount = onboardedTeamUserProfiles.filter(
    ({ id }) => id !== supportUserId,
  ).length;

  return (
    <IonPage data-cy="TeamAdminPage">
      <SearchAddressModal
        placeholder={i18n.t.TeamAdminPage.addLocationPlaceholder}
        submitText={i18n.t.TeamAdminPage.addLocationButton}
        isOpen={showAddAddressModal}
        onDidDismiss={handleDismiss}
        screenName={screenName}
        teamId={teamId}
      />
      <UserOnboardingModal />
      <InviteNeighborModal
        isOpen={isOpenInviteNeighborModal}
        dismissInviteNeighborModal={() => setIsOpenInviteNeighborModal(false)}
        inviteCode={inviteCode}
        track={track}
        teamId={teamId}
        screenName={screenName}
        teamName={name}
      />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              text=""
              defaultHref={userSettingsPageUrl({ teamId })}
              data-cy="TeamAdminPage-BackButton"
            />
          </IonButtons>
          <IonTitle> {i18n.t.TeamAdminPage.yourCommunity}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className={commonCss.contentContainer}>
        <IonCard className={commonCss.card}>
          <IonItem routerLink={userProfilesListPageUrl({ teamId })}>
            <IonAvatar slot="start" className={css.communityImage}>
              <IonImg src="assets/illustrations/relevantAdvice.png" />
            </IonAvatar>
            <IonLabel>
              <h2>
                <strong>{name}</strong>
              </h2>
              <p>
                {i18n.format.formatNumber(memberCount)}{" "}
                {i18n.t.TeamAdminPage.members} /{" "}
                {i18n.format.formatNumber(numberOfApartments)}{" "}
                {i18n.t.TeamAdminPage.apartments}
              </p>
            </IonLabel>
          </IonItem>
        </IonCard>

        <IonCard className={commonCss.card}>
          <IonItem onClick={onInviteNeighborButtonClick} button detail={false}>
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="primary"
              slot="start"
              icon={personAddOutline}
            />
            <IonLabel color="primary">{i18n.t.common.inviteNeighbor}</IonLabel>
          </IonItem>
        </IonCard>

        {sortedTeamLocations.length > 0 && (
          <IonCard className={commonCss.card}>
            <IonItem lines="none" className={css.locationsLabel}>
              <IonLabel>
                <p>{i18n.t.TeamAdminPage.locationLabel(sortedTeamLocations)}</p>
              </IonLabel>
            </IonItem>
            {sortedTeamLocations.map((teamLocation) => (
              <IonItem key={teamLocation.id}>
                <IonLabel>
                  {i18n.t.TeamAdminPage.location(teamLocation.data())}
                </IonLabel>
              </IonItem>
            ))}
          </IonCard>
        )}

        {buildingParts.length > 0 && (
          <IonCard className={commonCss.card}>
            <IonItem lines="none" className={css.locationsLabel}>
              <IonLabel>
                <p>{i18n.t.TeamAdminPage.buildingsLabel(buildingParts)}</p>
              </IonLabel>
            </IonItem>
            {buildingParts.map((building) => (
              <IonItem key={building}>
                <IonLabel>{i18n.t.common.buildingParts[building]}</IonLabel>
              </IonItem>
            ))}
          </IonCard>
        )}

        <IonCard className={commonCss.card}>
          <IonItem
            data-cy="TeamAdminPage-AdminProfileButton"
            routerLink={
              creatorId
                ? viewUserProfilePageUrl({
                    teamId,
                    userProfileId: creatorId,
                  })
                : undefined
            }
            routerDirection="forward"
          >
            <IonLabel>
              <p>{i18n.t.TeamAdminPage.communityAdmin}</p>
              {userProfileData
                ? formatFullName(userProfileData)
                : i18n.t.TeamAdminPage.unknownAdmin}
            </IonLabel>
          </IonItem>
        </IonCard>

        <IonCard color="clear" className={commonCss.card}>
          <div className="ion-margin-horizontal">
            <IonIcon
              className={css.infoIcon}
              color="none"
              icon={informationCircleOutline}
            />{" "}
            {!isFounder
              ? i18n.t.TeamAdminPage.contactAdmin(
                  userProfileData,
                  onContactUsClick,
                )
              : i18n.t.TeamAdminPage.contactUs(onContactUsClick)}
          </div>
        </IonCard>

        {isFounder && (
          <IonCard className={commonCss.card}>
            <IonItem
              data-cy="TeamAdminPage-EditTeamButton"
              routerLink={editTeamPageUrl({ teamId })}
              routerDirection="forward"
              button
              detail={false}
            >
              <IonItemIcon
                className={css.itemIconAvatar}
                color="primary"
                slot="start"
                icon={createOutline}
              />
              <IonLabel color="primary">
                {i18n.t.TeamAdminPage.editTeamButton}
              </IonLabel>
            </IonItem>
            <IonItem
              data-cy="TeamAdminPage-AddLocationButton"
              onClick={() => {
                setShowAddAddressModal(true);
              }}
              button
              detail={false}
            >
              <IonItemIcon
                className={css.itemIconAvatar}
                color="primary"
                slot="start"
                icon={addOutline}
              />
              <IonLabel color="primary">
                {i18n.t.TeamAdminPage.addLocationButton}
              </IonLabel>
            </IonItem>
          </IonCard>
        )}

        <IonCard className={commonCss.card}>
          <IonItem
            button
            detail={false}
            routerLink={leaveTeamPageUrl({ teamId })}
            routerDirection="forward"
          >
            <IonItemIcon
              className={commonCss.itemIconAvatar}
              color="danger"
              slot="start"
              icon={closeOutline}
            />
            <IonLabel color="danger">
              {i18n.t.UserSettingsPage.leaveCommunity}
            </IonLabel>
          </IonItem>
        </IonCard>
      </IonContent>
      <IonFooter />
    </IonPage>
  );
};

export default TeamAdminPage;
