import { channelIconColors } from "@connectedliving/common/lib/firestore/TeamChannel";
import makeEmptyArray from "@connectedliving/common/lib/utilities/makeEmptyArray";
import { IonIcon } from "@ionic/react";
import { personCircleSharp } from "ionicons/icons";
import css from "./CharacterIcons.module.css";

type CharacterIconsProps = {
  numberOfIcons: number;
};

const CharacterIcons: React.FC<CharacterIconsProps> = ({ numberOfIcons }) => {
  const numberOfIconsToRender = Math.max(numberOfIcons, 1);

  return (
    <>
      {makeEmptyArray(Math.min(numberOfIconsToRender, 4)).map((_, i) => (
        <IonIcon
          key={channelIconColors[i]}
          icon={personCircleSharp}
          color={channelIconColors[i]}
          className={css.icon}
        />
      ))}
    </>
  );
};
export default CharacterIcons;
