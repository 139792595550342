import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  useIonPopover,
} from "@ionic/react";
import { checkmark, globeOutline } from "ionicons/icons";
import { useCallback } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import mapObjectToFormSelectOptions from "src/utilities/mapObjectToFormSelectOptions";
import sortFormSelectOptionsAlphabetically from "src/utilities/sortFormSelectOptionsAlphabetically";

type LocaleSwitcherProps = {
  screenName: string;
  color?: string;
};

const LocaleSwitcher: React.FC<LocaleSwitcherProps> = ({
  screenName,
  color,
}) => {
  const i18n = I18nContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();

  const PopoverList: React.FC<{
    onHide: () => void;
  }> = useCallback(
    ({ onHide }) => (
      <IonList>
        {sortFormSelectOptionsAlphabetically(
          mapObjectToFormSelectOptions(i18n.t.common.locales),
        ).map(({ value, label }) => {
          const locale = blindCast<
            keyof typeof i18n.t.common.locales,
            "must be same type as the key it came from"
          >(value);
          return (
            <IonItem
              key={value}
              button
              detail={false}
              lines="none"
              disabled={i18n.currentLocale === value}
              onClick={() => {
                dontAwait(
                  track({
                    eventName: "Locale Selected",
                    "screen name": screenName,
                    "new locale": locale,
                  }),
                );
                i18n.setCurrentLocale(locale);
                onHide();
              }}
            >
              <IonLabel>{label}</IonLabel>
              {i18n.currentLocale === value && (
                <IonIcon icon={checkmark} color="success" />
              )}
            </IonItem>
          );
        })}
      </IonList>
    ),
    [i18n, screenName, track],
  );

  const [present, dismiss] = useIonPopover(PopoverList, {
    onHide: () => dismiss(),
  });

  return (
    <IonButton
      color={color ?? "light"}
      onClick={(e) =>
        present({
          event: e.nativeEvent,
        })
      }
    >
      <IonIcon slot="start" icon={globeOutline} />
      {i18n.t.common.locales[i18n.currentLocale]}
    </IonButton>
  );
};

export default LocaleSwitcher;
