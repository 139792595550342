import makePathStringParser from "../utilities/makePathStringParser";
import stripTrailingSlash from "../utilities/stripTrailingSlash";

export function inviteCodesPath(): string {
  return `/InviteCodes`;
}

export function inviteCodePath({ inviteCode }: { inviteCode: string }): string {
  return [`/InviteCodes`, inviteCode].join("/");
}

export function userProfilesPath(): string {
  return `/UserProfiles`;
}

export function userProfilePath({ userId }: { userId: string }): string {
  return [userProfilesPath(), userId].join("/");
}
export type UserProfilePathParams = Parameters<typeof userProfilePath>[0];

export function lastReadTimestampsPath({ userId }: { userId: string }): string {
  return ["/LastReadTimestamps", userId].join("/");
}

export function teamsPath(): string {
  return `/Teams`;
}

export function teamPath({ teamId }: { teamId: string }): string {
  return [teamsPath(), teamId].join("/");
}
export type TeamPathParams = Parameters<typeof teamPath>[0];

export function teamUserProfilesPath({ teamId }: { teamId: string }): string {
  return [teamsPath(), teamId, "TeamUserProfiles"].join("/");
}
export type TeamUserProfilesPathParams = Parameters<
  typeof teamUserProfilesPath
>[0];

export function teamUserProfilePath({
  teamId,
  userId,
}: {
  teamId: string;
  userId: string;
}): string {
  return [teamUserProfilesPath({ teamId }), userId].join("/");
}
export type TeamUserProfilePathParams = Parameters<
  typeof teamUserProfilePath
>[0];

export function teamChannelsPath({ teamId }: { teamId: string }): string {
  return [teamPath({ teamId }), "TeamChannels"].join("/");
}

export function teamChannelPath({
  teamId,
  teamChannelId,
}: {
  teamId: string;
  teamChannelId: string;
}): string {
  return [teamChannelsPath({ teamId }), teamChannelId].join("/");
}
export const parseTeamChannelPath = makePathStringParser(teamChannelPath, [
  "teamId",
  "teamChannelId",
]);

export function teamChannelMembershipsPath({
  teamId,
  teamChannelId,
}: {
  teamId: string;
  teamChannelId: string;
}): string {
  return [
    teamChannelPath({ teamId, teamChannelId }),
    "TeamChannelMemberships",
  ].join("/");
}

export function teamChannelMembershipsGroupCollectionPath(): string {
  return "TeamChannelMemberships";
}

export function teamChannelMembershipPath({
  teamId,
  teamChannelId,
  userId,
}: {
  teamId: string;
  teamChannelId: string;
  userId: string;
}): string {
  return [teamChannelMembershipsPath({ teamId, teamChannelId }), userId].join(
    "/",
  );
}

export function directMessageChannelsCollectionGroupPath() {
  return "DirectMessageChannels";
}

export function directMessageChannelsPath({
  teamId,
  userId,
}: {
  teamId: string;
  userId: string;
}): string {
  return [teamPath({ teamId }), "Users", userId, "DirectMessageChannels"].join(
    "/",
  );
}

type DirectMessageChannelPathParams = {
  teamId: string;
  userId: string;
  otherUserId: string;
};

export function directMessageChannelPath({
  teamId,
  userId,
  otherUserId,
}: DirectMessageChannelPathParams): string {
  return [directMessageChannelsPath({ teamId, userId }), otherUserId].join("/");
}
export const parseDirectMessageChannelPath = makePathStringParser(
  directMessageChannelPath,
  ["teamId", "userId", "otherUserId"],
);
export const matchDirectMessageChannelPath = makePathStringParser(
  directMessageChannelPath,
  ["teamId", "userId", "otherUserId"],
  { throwUnlessMatch: false },
);

export function teamLocationsPath({ teamId }: { teamId: string }): string {
  return [teamsPath(), teamId, "TeamLocations"].join("/");
}
export type TeamLocationsPathParams = Parameters<typeof teamLocationsPath>[0];

export function teamLocationPath({
  teamId,
  teamLocationId,
}: {
  teamId: string;
  teamLocationId: string;
}): string {
  return [teamLocationsPath({ teamId }), teamLocationId].join("/");
}
export type TeamLocationPathParams = Parameters<typeof teamLocationPath>[0];

export const parseTeamLocationPath = makePathStringParser(teamLocationPath, [
  "teamId",
  "teamLocationId",
]);

export function teamUserPreferencesCollectionPath({
  teamId,
}: {
  teamId: string;
}): string {
  return [teamsPath(), teamId, "TeamUserPreferences"].join("/");
}
export type TeamUserPreferencesCollectionPathParams = Parameters<
  typeof teamUserPreferencesCollectionPath
>[0];

export function teamUserPreferencesDocPath({
  teamId,
  userId,
}: {
  teamId: string;
  userId: string;
}): string {
  return [teamsPath(), teamId, "TeamUserPreferences", userId].join("/");
}
export type TeamUserPreferencesDocPathParams = Parameters<
  typeof teamUserPreferencesDocPath
>[0];
export const parseTeamUserPreferencesDocPath = makePathStringParser(
  teamUserPreferencesDocPath,
  ["teamId", "userId"],
);

export function userModerationReportsPath(): string {
  return `/UserModerationReports`;
}

export function waitingListEntriesPath(): string {
  return "/WaitingListEntries";
}
export function waitingListEntryPath({ id }: { id: string }): string {
  return [waitingListEntriesPath(), id].join("/");
}

export function migrationRecordsPath(): string {
  return "/MigrationRecords";
}

export function migrationRecordPath({ id }: { id: string }): string {
  return [migrationRecordsPath(), id].join("/");
}

export function trackingEventRecordsPath(): string {
  return "/TrackingEventRecords";
}

export function trackingEventRecordPath({ id }: { id: string }): string {
  return [trackingEventRecordsPath(), id].join("/");
}

export function triggerStateRecordsPath(): string {
  return "/TriggerStateRecords";
}

export function triggerStateRecordPath({ id }: { id: string }): string {
  return [triggerStateRecordsPath(), id].join("/");
}

export function teamChannelMessagesPath({
  teamId,
  teamChannelId,
}: {
  teamId: string;
  teamChannelId: string;
}): string {
  return [teamChannelPath({ teamId, teamChannelId }), "Messages"].join("/");
}

export function teamChannelMessagePath({
  teamId,
  teamChannelId,
  messageId,
}: {
  teamId: string;
  teamChannelId: string;
  messageId: string;
}): string {
  return [teamChannelMessagesPath({ teamId, teamChannelId }), messageId].join(
    "/",
  );
}
export const parseTeamChannelMessagePath = makePathStringParser(
  teamChannelMessagePath,
  ["teamId", "teamChannelId", "messageId"],
);

export function teamChannelFileUploadsPath({
  teamId,
  teamChannelId,
}: {
  teamId: string;
  teamChannelId: string;
}): string {
  return [teamChannelPath({ teamId, teamChannelId }), "FileUploads"].join("/");
}

export function teamChannelFileUploadPath({
  teamId,
  teamChannelId,
  fileUploadId,
}: {
  teamId: string;
  teamChannelId: string;
  fileUploadId: string;
}): string {
  return [
    teamChannelFileUploadsPath({ teamId, teamChannelId }),
    fileUploadId,
  ].join("/");
}

export function primaryDirectMessageChannelPath({
  teamId,
  userId,
  otherUserId,
}: {
  teamId: string;
  userId: string;
  otherUserId: string;
}) {
  const [lowerUserId, higherUserId] = [userId, otherUserId].sort(
    (string1, string2) => string1.localeCompare(string2),
  ) as [string, string];

  return directMessageChannelPath({
    teamId,
    userId: lowerUserId,
    otherUserId: higherUserId,
  });
}

export function bothDirectMessageChannelPaths({
  teamId,
  userId1,
  userId2,
}: {
  teamId: string;
  userId1: string;
  userId2: string;
}): {
  primaryDirectMessageChannelPath: string;
  secondaryDirectMessageChannelPath: string;
} {
  const [lowerUserId, higherUserId] = [userId1, userId2].sort(
    (string1, string2) => string1.localeCompare(string2),
  ) as [string, string];

  return {
    primaryDirectMessageChannelPath: directMessageChannelPath({
      teamId,
      userId: lowerUserId,
      otherUserId: higherUserId,
    }),
    secondaryDirectMessageChannelPath: directMessageChannelPath({
      teamId,
      userId: higherUserId,
      otherUserId: lowerUserId,
    }),
  };
}

export function directMessageChannelMessagesPath({
  teamId,
  userId,
  otherUserId,
}: {
  teamId: string;
  userId: string;
  otherUserId: string;
}): string {
  return [
    primaryDirectMessageChannelPath({
      teamId,
      userId,
      otherUserId,
    }),
    "Messages",
  ].join("/");
}

export function directMessageChannelMessagePath({
  teamId,
  userId,
  otherUserId,
  messageId,
}: {
  teamId: string;
  userId: string;
  otherUserId: string;
  messageId: string;
}): string {
  return [
    directMessageChannelMessagesPath({ teamId, userId, otherUserId }),
    messageId,
  ].join("/");
}

export function versionsMetadataPath(): string {
  return ["/Metadata", "Versions"].join("/");
}

export const parseDirectMessageChannelMessagePath = makePathStringParser(
  directMessageChannelMessagePath,
  ["teamId", "userId", "otherUserId", "messageId"],
);

export function clientInstancesPath() {
  return "/ClientInstances";
}

export function clientInstancePath({
  clientInstanceId,
}: {
  clientInstanceId: string;
}) {
  return [clientInstancesPath(), clientInstanceId].join("/");
}

export function directMessageChannelFileUploadsPath({
  teamId,
  userId,
  otherUserId,
}: {
  teamId: string;
  userId: string;
  otherUserId: string;
}): string {
  return [
    directMessageChannelPath({ teamId, userId, otherUserId }),
    "FileUploads",
  ].join("/");
}

export function directMessageChannelFileUploadPath({
  teamId,
  userId,
  otherUserId,
  fileUploadId,
}: {
  teamId: string;
  userId: string;
  otherUserId: string;
  fileUploadId: string;
}): string {
  return [
    directMessageChannelFileUploadsPath({ teamId, userId, otherUserId }),
    fileUploadId,
  ].join("/");
}

export function channelMessagesPath({
  channelPath,
}: {
  channelPath: string;
}): string {
  return [stripTrailingSlash(channelPath), "Messages"].join("/");
}

export function channelMessagePath({
  channelPath,
  messageId,
}: {
  channelPath: string;
  messageId: string;
}): string {
  return [channelMessagesPath({ channelPath }), messageId].join("/");
}

export function channelFileUploadsPath({
  channelPath,
}: {
  channelPath: string;
}): string {
  return [stripTrailingSlash(channelPath), "FileUploads"].join("/");
}

export function channelFileUploadPath({
  channelPath,
  fileUploadId,
}: {
  channelPath: string;
  fileUploadId: string;
}): string {
  return [channelFileUploadsPath({ channelPath }), fileUploadId].join("/");
}
