export const germanStatesByShortcode = Object.freeze(
  new Map([
    ["bw", "Baden-Württemberg"],
    ["by", "Bayern"],
    ["be", "Berlin"],
    ["bb", "Brandenburg"],
    ["hb", "Bremen"],
    ["hh", "Hamburg"],
    ["he", "Hessen"],
    ["mv", "Mecklenburg-Vorpommern"],
    ["nds", "Niedersachsen"],
    ["nrw", "Nordrhein-Westfalen"],
    ["rp", "Rheinland-Pfalz"],
    ["sl", "Saarland"],
    ["sn", "Sachsen"],
    ["sa", "Sachsen-Anhalt"],
    ["sh", "Schleswig-Holstein"],
    ["th", "Thüringen"],
  ]),
);

export const germanStatesArray = Object.freeze(
  Array.from(germanStatesByShortcode, ([key, value]) => ({
    value: key,
    label: value,
  })),
);

export function germanStateName(id: string): string {
  // TODO: Once we migrate the old state data in TeamLocations we should return nothing or error when we don't find a match
  return germanStatesByShortcode.get(id) || id.toUpperCase();
}
