import castAs from "../utilities/lang/castAs";
import {
  SupportedCountryCode,
  supportedCountryCodes,
} from "./supportedCountries";

export default function isSupportedCountryCode(
  value: unknown,
): value is SupportedCountryCode {
  return (
    typeof value === "string" &&
    castAs<readonly string[]>(supportedCountryCodes).includes(value)
  );
}
