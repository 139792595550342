import {
  FormatValueFunction,
  FormatValueTagFunction,
  makeFormatValueTagFunction,
} from "src/utilities/i18n/makeFormatValueTagFunction";

export type I18nLocale<
  T extends Record<string, unknown>,
  F extends { formatValue: FormatValueFunction },
  Tag extends Intl.UnicodeBCP47LocaleIdentifier,
> = {
  tag: Tag;
  translations: T;
  formatters: F;
};

type TranslationsFactoryFunction<
  Translations extends Record<string, unknown>,
  Formatters extends {
    formatValue: FormatValueFunction;
  },
> = (t: FormatValueTagFunction, formatters: Formatters) => Translations;

export function makeLocale<
  Translations extends Record<string, unknown>,
  Formatters extends { formatValue: FormatValueFunction },
  Tag extends Intl.UnicodeBCP47LocaleIdentifier,
>(options: {
  tag: Tag;
  translations: TranslationsFactoryFunction<Translations, Formatters>;
  formatters: (tag: string) => Formatters;
}): I18nLocale<Translations, Formatters, Tag> {
  const {
    tag,
    translations: translationsFn,
    formatters: formattersFn,
  } = options;

  const formatters = formattersFn(tag);
  const t = makeFormatValueTagFunction(formatters.formatValue);
  const translations = translationsFn(t, formatters);

  return Object.freeze({
    tag,
    translations,
    formatters,
  });
}
