import { Clipboard } from "@capacitor/clipboard";
import {
  FindTeamsWithPlaceIdMatch,
  FindTeamsWithPlaceIdResponseBody,
} from "@connectedliving/common/lib/cloudFunctions/FindTeamsWithPlaceId";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { isHttpsError } from "@connectedliving/common/lib/utilities/firestore/isHttpsError";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import {
  channelsListPageUrl,
  checkMyAddressPageUrl,
  checkMyAddressResultsPageUrl,
  joinTeamPageUrl,
  teamCreatePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCol,
  IonIcon,
  IonRow,
  IonSpinner,
  IonText,
  useIonToast,
  useIonViewWillEnter,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import { close, copyOutline, helpCircle } from "ionicons/icons";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import AppStoreButtons from "src/common/AppStoreButtons";
import commonCss from "src/common/common.module.css";
import CommunityResultCardContent from "src/common/CommunityResultCardContent";
import ErrorCard from "src/common/ErrorCard";
import InfoCard, { InfoCardButton, InfoCardText } from "src/common/InfoCard";
import MoreInfoModal from "src/common/MoreInfoModal";
import SkeletonCard from "src/common/SkeletonCard";
import useEmailActionSheet from "src/common/useEmailActionSheet";
import useScreenTracking from "src/firebase/useScreenTracking";
import OnboardingPageLayout from "src/share/OnboardingPageLayout";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import GoogleMapsApiContainer from "src/state/GoogleMapsApiContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import formatTeamLocation from "src/utilities/formatTeamLocation";
import validateGeocodeResult from "src/utilities/googlePlaces/validateGeocodeResult";
import useIonicParamsForRoute from "src/utilities/ionic/useIonicParamsForRoute";
import { dataAvailable, isErrorState } from "src/utilities/LoadingState";
import { getGeocode } from "use-places-autocomplete";
import MixpanelClientContainer from "../state/mixpanel/MixpanelContainer";
import environment from "../utilities/environment";
import css from "./CheckMyAddressResultsPage.module.css";
import JoinWaitingListCard from "./JoinWaitingListCard";

export type CheckMyAddressResultsPageParams = {
  placeId: string;
};

type CheckMyAddressResultsPageLocationState = {
  teamLocation: TeamLocation;
  results: FindTeamsWithPlaceIdResponseBody;
};

type InviteCodeDisplayProps = {
  inviteCode: string | undefined;
  screenName: string;
  teamId: string;
  numberOfMembers: number;
};

const InviteCodeDisplay: React.FC<InviteCodeDisplayProps> = ({
  inviteCode,
  screenName,
  teamId,
  numberOfMembers: numberOfTeamMembers,
}) => {
  const i18n = I18nContainer.useContainer();
  const [inviteCodeVisible, setInviteCodeVisible] = useState(false);
  const { track } = MixpanelClientContainer.useContainer();
  const [presentToast] = useIonToast();

  const onShowInviteCodeClick = useCallback(async () => {
    setInviteCodeVisible(true);
    track({
      eventName: "Button Clicked",
      "button name": "Join Team Search Result",
      "screen name": screenName,
      "team id": teamId,
      "number of team members": numberOfTeamMembers,
    });
  }, [numberOfTeamMembers, screenName, teamId, track]);

  const onCopyInviteCodeClick = useCallback(async () => {
    await Clipboard.write({
      string: inviteCode,
    });

    track({
      eventName: "Button Clicked",
      "button name": "Copy Invite Code",
      "screen name": screenName,
      "team id": teamId,
    });

    presentToast({
      message: "Invite code copied to clipboard",
      duration: 4000,
      color: "success",
      buttons: [
        {
          icon: close,
          role: "cancel",
        },
      ],
    });
  }, [inviteCode, track, screenName, teamId, presentToast]);

  if (inviteCodeVisible) {
    if (!inviteCode) {
      return (
        <>
          <p>
            {
              i18n.t.CheckMyAddressResultsPage.cards.communityResultCard
                .installMobileApp
            }
          </p>
          <AppStoreButtons {...{ screenName, teamId }} />
        </>
      );
    }

    return (
      <>
        <p>
          {
            i18n.t.CheckMyAddressResultsPage.cards.communityResultCard
              .installAppAndJoinWithInviteCode
          }
        </p>
        <p className={css.inviteCode}>
          <IonText color="magenta" onClick={onCopyInviteCodeClick}>
            <strong>{inviteCode.toLocaleUpperCase()}</strong>{" "}
            <IonIcon icon={copyOutline} color="medium" size="small" />
          </IonText>
        </p>
        <AppStoreButtons {...{ screenName, teamId }} />
      </>
    );
  }

  return (
    <IonRow className="ion-justify-content-end">
      <IonCol size="6">
        <IonButton expand="block" onClick={onShowInviteCodeClick}>
          {i18n.t.common.join}
        </IonButton>
      </IonCol>
    </IonRow>
  );
};

type CommunityResultCardProps = {
  result: FindTeamsWithPlaceIdMatch;
  screenName: string;
  googlePlaceId: string;
};

const CommunityResultCard: React.FC<CommunityResultCardProps> = ({
  result,
  screenName,
  googlePlaceId,
}) => {
  const {
    id: teamId,
    name,
    inviteCode,
    createdAtMillisecondsTimestamp,
    numberOfMembers,
    numberOfApartments,
  } = result;
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const history = useHistory();
  const inviteCodeUpperCase = result.inviteCode?.toLocaleUpperCase();
  const { cloudFunctions } = FirebaseAppContainer.useContainer();
  const [joinInProgress, setJoinInProgress] = useState(false);
  const [joinError, setJoinError] = useState<string | undefined>();

  const onJoinClick: React.MouseEventHandler<HTMLIonButtonElement> =
    useCallback(
      async (e) => {
        e.preventDefault();

        track({
          eventName: "Button Clicked",
          "button name": "Join Team Search Result",
          "screen name": screenName,
          "team id": teamId,
          "number of team members": numberOfMembers,
        });

        if (!inviteCode) {
          history.push(
            joinTeamPageUrl({
              teamId,
              placeId: googlePlaceId,
            }),
            {
              teamDetails: result,
            },
          );
          return;
        }

        setJoinInProgress(true);
        try {
          await cloudFunctions.joinTeam({ inviteCode });
          history.push(channelsListPageUrl({ teamId }));
        } catch (error) {
          setJoinInProgress(false);
          if (isHttpsError(error)) {
            setJoinError(
              `${i18n.t.InviteCodeFormCard.errorOccurred} (${error.code})`,
            );
            throw error;
          } else {
            setJoinError(i18n.t.InviteCodeFormCard.errorOccurred);
            throw error;
          }
        }
      },
      [
        cloudFunctions,
        googlePlaceId,
        history,
        i18n.t.InviteCodeFormCard.errorOccurred,
        result,
        teamId,
        screenName,
        inviteCode,
        numberOfMembers,
        track,
      ],
    );

  return (
    <IonCard data-cy={`CommunityResultCard-${teamId}`}>
      <IonCardHeader>
        <IonCardTitle>{name}</IonCardTitle>
      </IonCardHeader>
      <CommunityResultCardContent
        createdAtMillisecondsTimestamp={createdAtMillisecondsTimestamp}
        numberOfMembers={numberOfMembers}
        numberOfApartments={numberOfApartments}
      >
        {process.env.REACT_APP_RESTRICTED_WEB_BUILD ? (
          <IonRow>
            <IonCol>
              <IonText color="dark">
                <InviteCodeDisplay
                  {...{
                    inviteCode: inviteCodeUpperCase,
                    screenName,
                    teamId,
                    numberOfMembers,
                  }}
                />
              </IonText>
            </IonCol>
          </IonRow>
        ) : (
          <>
            {joinError && (
              <>
                <p className="ion-padding-top">
                  <IonText color="danger">{joinError}</IonText>
                </p>

                <p className="ion-padding-bottom">
                  {i18n.t.InviteCodeFormCard.needHelp}{" "}
                  <a
                    href={`mailto:${
                      environment().marketingSiteConfig().supportEmail
                    }`}
                  >
                    {environment().marketingSiteConfig().supportEmail}
                  </a>
                </p>
              </>
            )}
            <IonRow className="ion-justify-content-end">
              <IonCol size="6">
                <IonButton
                  data-cy={`CommunityResultCard-${teamId}-joinButton`}
                  expand="block"
                  onClick={onJoinClick}
                  disabled={joinInProgress}
                >
                  {joinInProgress ? <IonSpinner /> : i18n.t.common.join}
                </IonButton>
              </IonCol>
            </IonRow>
          </>
        )}
      </CommunityResultCardContent>
    </IonCard>
  );
};

const CheckMyAddressResultsPage: React.FC = () => {
  const { state } = useLocation<
    CheckMyAddressResultsPageLocationState | undefined
  >();
  const screenName = "CheckMyAddressResultsPage";
  const i18n = I18nContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const supportEmail = requireEnvVar("REACT_APP_SUPPORT_EMAIL");
  const emailActionSheet = useEmailActionSheet(supportEmail);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { cloudFunctions } = FirebaseAppContainer.useContainer();

  const history = useHistory();

  const { placeId: googlePlaceId } =
    useIonicParamsForRoute<CheckMyAddressResultsPageParams>(
      checkMyAddressResultsPageUrl({ placeId: ":placeId" }),
    );

  useScreenTracking(screenName, undefined);

  const [results, setResults] =
    useState<FindTeamsWithPlaceIdResponseBody | null>(null);

  const [teamLocation, setTeamLocation] = useState<TeamLocation | null>(null);

  const [firestoreFailed, setFirestoreFailed] = useState(false);

  const { loadGoogleMapsApi, loadingState: googleMapsApiLoadingState } =
    GoogleMapsApiContainer.useContainer();

  const [presentToast] = useIonToast();

  useEffect(() => {
    if (!results || !results.length) return;

    results.forEach((result) => {
      dontAwait(
        track({
          eventName: "Team Search Result Viewed",
          "screen name": screenName,
          "team id": result.id,
          "number of team members": result.numberOfMembers,
        }),
      );
    });
  }, [results, track]);

  useIonViewWillEnter(() => {
    if (
      state?.results[0]?.id !== googlePlaceId ||
      state?.teamLocation?.googlePlaceId !== googlePlaceId
    ) {
      setResults(null);
      setTeamLocation(null);
    } else {
      setResults(state.results);
      setTeamLocation(state.teamLocation);
    }
  }, [googlePlaceId, state]);

  useEffect(() => {
    dontAwait(loadGoogleMapsApi());
  }, [loadGoogleMapsApi]);

  useEffect(() => {
    async function findTeamsWithPlaceId(googlePlaceIdToSearchFor: string) {
      const matchingTeams = await cloudFunctions.findTeamsWithPlaceId({
        googlePlaceId: googlePlaceIdToSearchFor,
      });
      return matchingTeams;
    }

    if (!results) {
      findTeamsWithPlaceId(googlePlaceId)
        .then((matchingTeams) => {
          setResults(matchingTeams);
        })
        .catch((error) => {
          setFirestoreFailed(true);
          throw new Error(error);
        });
    }
  }, [cloudFunctions, googlePlaceId, results]);

  useEffect(() => {
    if (
      !teamLocation &&
      googleMapsApiLoadingState &&
      dataAvailable(googleMapsApiLoadingState) &&
      !results
    ) {
      getGeocode({ placeId: googlePlaceId })
        .then((geocoderResults) => {
          if (geocoderResults.length === 0) {
            dontAwait(
              presentToast({
                message:
                  i18n.t.CheckMyAddressResultsPage.errors.noGeocodeResults,
                color: "danger",
              }),
            );
            history.replace(checkMyAddressPageUrl());
          }
          if (geocoderResults.length > 1) {
            Sentry.captureException(
              "There was more than one geocodeResult for a placeId geocoding request",
            );
          }

          const geocoderResult = assertPresent.andReturn(geocoderResults[0], {
            because:
              "We handled the case that there is no result, hence there must be at least one",
          });
          const validatedGeocodeResult = validateGeocodeResult(geocoderResult);

          if (validatedGeocodeResult.validTeamLocation) {
            setTeamLocation(validatedGeocodeResult.validTeamLocation);
          } else {
            dontAwait(
              presentToast({
                message:
                  i18n.t.CheckMyAddressResultsPage.errors.noGeocodeResults,
                color: "danger",
              }),
            );
            history.replace(checkMyAddressPageUrl());
          }
        })
        .catch((error) => {
          dontAwait(
            presentToast({
              message: i18n.t.CheckMyAddressResultsPage.errors.noGeocodeResults,
              color: "danger",
            }),
          );
          history.replace(checkMyAddressPageUrl());
          Sentry.captureException(
            `Something went wrong trying to fetch geoCode:${error}`,
          );
        });
    }
  });

  const subtitle =
    results && results.length > 0
      ? i18n.t.CheckMyAddressResultsPage.subtitle
      : i18n.t.CheckMyAddressResultsPage.noResultsSubtitle;

  const preTitle =
    results && results.length > 0
      ? i18n.t.CheckMyAddressResultsPage.preTitle
      : false;

  let content;

  if (
    googleMapsApiLoadingState === null ||
    googleMapsApiLoadingState === "initialLoad"
  ) {
    content = (
      <div className={`${commonCss.flexCenter} ${commonCss.flexColumn}`}>
        <IonSpinner color="primary" />
      </div>
    );
  } else if (
    isErrorState(googleMapsApiLoadingState) ||
    googleMapsApiLoadingState === "networkUnavailable"
  ) {
    content = (
      <ErrorCard
        errorMessage={i18n.t.SearchAddressModal.loadingError}
        onRetryClick={async () => {
          await loadGoogleMapsApi();
        }}
      />
    );
  } else if (!results && firestoreFailed) {
    content = (
      <ErrorCard
        errorMessage={
          i18n.t.CheckMyAddressResultsPage.errors.errorFirestoreResults
        }
        onRetryClick={() => {
          window.location.reload();
        }}
      />
    );
  } else if (!results) {
    content = (
      <>
        <SkeletonCard />
        <SkeletonCard />
      </>
    );
  } else {
    content = (
      <>
        {results.length > 0 ? (
          results.map((result) => (
            <CommunityResultCard
              key={result.id}
              result={result}
              screenName={screenName}
              googlePlaceId={googlePlaceId}
            />
          ))
        ) : (
          <>
            <InfoCard
              cardTitle={
                <>
                  <IonCardTitle>
                    {i18n.t.CheckMyAddressResultsPage.cards.noResults.title}
                  </IonCardTitle>
                  <IonIcon
                    icon={helpCircle}
                    size="large"
                    onClick={() => setIsOpen(true)}
                    className={css.icon}
                  />
                </>
              }
            >
              <InfoCardText>
                {i18n.t.CheckMyAddressResultsPage.cards.noResults.paragraph}
              </InfoCardText>
              <InfoCardButton
                data-cy={`${screenName}-createCommunity-button`}
                onClick={(e) => {
                  e.preventDefault();
                  history.push(
                    teamCreatePageUrl({ placeId: googlePlaceId }),
                    state,
                  );
                }}
              >
                {i18n.t.CheckMyAddressResultsPage.cards.noResults.button}
              </InfoCardButton>
            </InfoCard>

            {teamLocation && environment().waitingListFeatureFlag() && (
              <JoinWaitingListCard {...{ teamLocation }} />
            )}
          </>
        )}

        <InfoCard
          cardTitle={
            <IonCardTitle>
              {i18n.t.CheckMyAddressResultsPage.cards.getHelp.title}
            </IonCardTitle>
          }
        >
          <InfoCardText>
            {i18n.t.CheckMyAddressResultsPage.cards.getHelp.text}
          </InfoCardText>
          <InfoCardButton
            data-cy={`${screenName}-getHelp-button`}
            onClick={async () => emailActionSheet()}
          >
            {i18n.t.common.contactSupport}
          </InfoCardButton>
        </InfoCard>
        <MoreInfoModal
          isOpen={isOpen}
          breakpoints={[0, 0.85, 1]}
          initialBreakpoint={0.85}
          title={i18n.t.common.startNewCommunityInfoModal.title}
          body={i18n.t.common.startNewCommunityInfoModal.body(emailActionSheet)}
          closeModal={() => setIsOpen(false)}
        />
      </>
    );
  }

  return (
    <OnboardingPageLayout
      backButtonUrl={checkMyAddressPageUrl()}
      data-cy="CheckMyAddressResultsPage"
      screenName={screenName}
      preTitle={results ? preTitle : ""}
      title={teamLocation ? formatTeamLocation(teamLocation) : ""}
      subtitle={results ? subtitle : ""}
    >
      {content}
    </OnboardingPageLayout>
  );
};

export default CheckMyAddressResultsPage;
