import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import assertExhausted from "@connectedliving/common/lib/utilities/lang/assertExhausted";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import { Reducer, useCallback, useEffect, useReducer } from "react";
import useGooglePlacesAutocompleteAndGeocoding from "src/state/useGooglePlacesAutocompleteAndGeocoding";
import { GeocodeAddressComponentType } from "src/utilities/googlePlaces/getGeocodeAddressComponentDict";
import validateGeocodeResult from "src/utilities/googlePlaces/validateGeocodeResult";
import { getGeocode } from "use-places-autocomplete";

type SearchAddressModalErrorState = {
  state: "failed";
  searchInputValue: string;
  addressComponentErrors?: GeocodeAddressComponentType[];
  requestStatus?:
    | Omit<google.maps.GeocoderStatus, "OK">
    | Omit<google.maps.places.PlacesServiceStatus, "OK">;
  queryResults: google.maps.places.AutocompletePrediction[] | [];
};

type SearchAddressModalResultsAvailableState = {
  state: "results_available";
  searchInputValue: string;
  queryResults: google.maps.places.AutocompletePrediction[];
};

type SearchAddressModalState =
  | SearchAddressModalErrorState
  | {
      state: "initial";
      searchInputValue: string;
      queryResults: [];
    }
  | {
      state: "ready_to_query";
      searchInputValue: string;
      queryResults: [];
    }
  | {
      state: "query_in_progress";
      searchInputValue: string;
      queryResults: [];
    }
  | SearchAddressModalResultsAvailableState
  | {
      state: "geocode_in_progress";
      searchInputValue: string;
      queryResults: google.maps.places.AutocompletePrediction[];
    }
  | {
      state: "valid_address_selected";
      searchInputValue: string;
      verifiedAddress: TeamLocation;
    };

type SearchAddressModalActions =
  | {
      type: "initializedUsePlacesAutocompleteHook";
    }
  | {
      type: "setSearchInputValue";
      value: string;
    }
  | {
      type: "startQuerying";
      searchInputValue: string;
    }
  | {
      type: "receiveResults";
      value: Omit<SearchAddressModalResultsAvailableState, "searchInputValue">;
    }
  | {
      type: "setSelectedSuggestion";
      value: google.maps.places.AutocompletePrediction;
    }
  | {
      type: "setVerifiedAddress";
      geocoderResult: google.maps.GeocoderResult;
    }
  | {
      type: "setError";
      addressComponentErrors?: GeocodeAddressComponentType[];
      requestStatus?:
        | Omit<google.maps.GeocoderStatus, "OK">
        | Omit<google.maps.places.PlacesServiceStatus, "OK">;
    };

type UseSearchAddressModalStateReturn = {
  searchAddressModalState: SearchAddressModalState;
  selectSuggestion: (
    suggestion: google.maps.places.AutocompletePrediction,
  ) => void;
  setSearchInputValue: (value: string) => void;
  initializeUsePlacesAutocompleteHook: () => void;
};

const useSearchAddressModalState = (): UseSearchAddressModalStateReturn => {
  const { setValue, queryStatus, queryResults, queryLoadingState, init } =
    useGooglePlacesAutocompleteAndGeocoding();
  const characterLimitForSearching = 5;

  const [searchAddressModalState, dispatch] = useReducer<
    Reducer<SearchAddressModalState, SearchAddressModalActions>
  >(
    (previousState, action) => {
      switch (action.type) {
        case "initializedUsePlacesAutocompleteHook":
          return {
            state: "ready_to_query",
            searchInputValue: "",
            queryResults: [],
          };
        case "startQuerying":
          return {
            state: "query_in_progress",
            searchInputValue: action.searchInputValue,
            queryResults: [],
          };
        case "setSearchInputValue":
          return {
            state: "ready_to_query",
            searchInputValue: action.value,
            queryResults: [],
          };
        case "receiveResults":
          assertPresent(previousState.searchInputValue, {
            because:
              "We are only calling this state update if we ran a query which requires searchInputValue to be set",
          });
          return {
            searchInputValue: previousState.searchInputValue,
            ...action.value,
          };
        case "setSelectedSuggestion":
          return {
            state: "geocode_in_progress",
            searchInputValue: action.value.description,
            queryResults,
          };
        case "setVerifiedAddress": {
          const formattedAddress = action.geocoderResult.formatted_address;
          const validatedGeocodeResult = validateGeocodeResult(
            action.geocoderResult,
          );

          if (validatedGeocodeResult.validTeamLocation) {
            return {
              state: "valid_address_selected",
              verifiedAddress: validatedGeocodeResult.validTeamLocation,
              searchInputValue: formattedAddress,
            };
          }
          return {
            state: "failed",
            searchInputValue: formattedAddress,
            addressComponentErrors: validatedGeocodeResult.errors,
            queryResults,
          };
        }
        case "setError":
          return {
            addressComponentErrors: action.addressComponentErrors,
            requestStatus: action.requestStatus,
            state: "failed",
            queryResults,
            searchInputValue: previousState.searchInputValue,
          };
        default:
          throw assertExhausted(action);
      }
    },
    {
      state: "initial",
      searchInputValue: "",
      queryResults: [],
    },
  );

  useEffect(() => {
    if (
      (searchAddressModalState.state === "query_in_progress" ||
        searchAddressModalState.state === "results_available") &&
      !queryLoadingState &&
      queryStatus.length > 0
    ) {
      if (queryStatus === "OK") {
        dispatch({
          type: "receiveResults",
          value: {
            state: "results_available",
            queryResults,
          },
        });
      } else {
        dispatch({
          type: "setError",
          requestStatus: queryStatus,
        });
      }
    }
  }, [
    queryLoadingState,
    queryResults,
    queryStatus,
    searchAddressModalState.searchInputValue,
    searchAddressModalState.state,
  ]);

  const selectSuggestion = useCallback(
    (suggestion: google.maps.places.AutocompletePrediction) => {
      if (
        !(
          searchAddressModalState.state === "results_available" ||
          searchAddressModalState.state === "failed" ||
          searchAddressModalState.state === "valid_address_selected"
        )
      )
        return;

      dispatch({
        type: "setSelectedSuggestion",
        value: suggestion,
      });
      setValue(suggestion.description, false);

      getGeocode({ placeId: suggestion.place_id })
        .then((geocoderResults) => {
          const geoCoderResult = assertPresent.andReturn(geocoderResults[0], {
            because:
              "We are fetching a geoCodeResult for a placeId that came from google so it must have a result",
          });
          dispatch({
            type: "setVerifiedAddress",
            geocoderResult: geoCoderResult,
          });
          setValue(geoCoderResult.formatted_address, false);
        })
        .catch((error) => {
          dispatch({
            type: "setError",
            requestStatus: error,
          });
        });
    },
    [searchAddressModalState.state, setValue],
  );

  const setSearchInputValue = useCallback(
    (value: string) => {
      if (value.length < characterLimitForSearching) {
        dispatch({
          type: "setSearchInputValue",
          value,
        });
        setValue(value, false);
      } else {
        dispatch({
          type: "startQuerying",
          searchInputValue: value,
        });
        setValue(value, true);
      }
    },
    [setValue],
  );
  const initializeUsePlacesAutocompleteHook = useCallback(() => {
    init();
    dispatch({
      type: "initializedUsePlacesAutocompleteHook",
    });
  }, [init]);

  return {
    searchAddressModalState,
    selectSuggestion,
    setSearchInputValue,
    initializeUsePlacesAutocompleteHook,
  };
};

export default useSearchAddressModalState;
