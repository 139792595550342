import { isHttpsError } from "@connectedliving/common/lib/utilities/firestore/isHttpsError";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonSpinner,
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import { useFieldInputs } from "src/state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "src/state/formState/useFormState";
import I18nContainer from "src/state/i18n/I18nContainer";
import validate from "src/utilities/validate/validate";
import environment from "../utilities/environment";
import FormTextInput from "./form/FormTextInput";

type InviteCodeFormCardProps = {
  title?: string;
};

type InviteCodeFormCardFormValues = {
  inviteCode: string;
};

const InviteCodeFormCard: React.FC<InviteCodeFormCardProps> = ({ title }) => {
  const i18n = I18nContainer.useContainer();
  const { cloudFunctions } = FirebaseAppContainer.useContainer();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [inviteError, setInviteError] = useState<string | undefined>();

  const formState = useFormState<InviteCodeFormCardFormValues>({
    initial: {
      inviteCode: "",
    },
    validate: {
      fields: {
        inviteCode: validate.string.isPresent(i18n.t.common.validations),
      },
    },
  });

  const fields = useFieldInputs(formState, useFieldStates(formState));

  const { form } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        setIsSubmitting(true);

        try {
          await cloudFunctions.joinTeam({
            inviteCode: formValues.inviteCode.trim().toLocaleLowerCase(),
          });
        } catch (error) {
          if (isHttpsError(error)) {
            if (/permission-denied/.test(error.code)) {
              setInviteError(i18n.t.InviteCodeFormCard.incorrectInviteCode);
            } else {
              setInviteError(
                `${i18n.t.InviteCodeFormCard.errorOccurred} (${error.code})`,
              );
              throw error;
            }
          } else {
            setInviteError(i18n.t.InviteCodeFormCard.errorOccurred);
            throw error;
          }
          setIsSubmitting(false);
        }
      },
      [cloudFunctions, i18n.t.InviteCodeFormCard],
    ),
  });

  return (
    <IonCard>
      {title && (
        <IonCardHeader>
          <IonCardTitle color="aubergine-fill">{title}</IonCardTitle>
        </IonCardHeader>
      )}
      <IonCardContent>
        <form {...form}>
          <FormTextInput
            insetItem={false}
            {...fields.inviteCode}
            value={fields.inviteCode.value.toLocaleUpperCase()}
            onChange={(value) => {
              if (fields.inviteCode.value === value) return;
              setInviteError(undefined);
              fields.inviteCode.onChange(value);
            }}
            label={i18n.t.InviteCodeFormCard.inviteCode}
            labelPosition="floating"
            data-cy="InviteCodeFormCard-invite-input"
          />
          {inviteError && (
            <>
              <p className="ion-padding-top ion-padding-bottom">
                {inviteError}
              </p>

              <p>
                {i18n.t.InviteCodeFormCard.needHelp}{" "}
                <a
                  href={`mailto:${
                    environment().marketingSiteConfig().supportEmail
                  }`}
                >
                  {environment().marketingSiteConfig().supportEmail}
                </a>
              </p>
            </>
          )}
          <IonButton
            type="submit"
            color="aubergine-fill"
            fill="solid"
            expand="block"
            disabled={isSubmitting || !fields.inviteCode.value || !!inviteError}
            data-cy="InviteCodeFormCard-join-button"
            className="ion-margin-top"
          >
            <span>
              {isSubmitting ? (
                <IonSpinner />
              ) : (
                i18n.t.InviteCodeFormCard.joinButton
              )}
            </span>
          </IonButton>
        </form>
      </IonCardContent>
    </IonCard>
  );
};

export default InviteCodeFormCard;
