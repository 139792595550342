import { Clipboard } from "@capacitor/clipboard";
import { useIonActionSheet } from "@ionic/react";
import { useCallback } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";

export default function useEmailActionSheet(
  email: string,
): () => Promise<void> {
  const [presentActionSheet] = useIonActionSheet();
  const i18n = I18nContainer.useContainer();
  return useCallback(async () => {
    await presentActionSheet({
      buttons: [
        {
          text: i18n.t.useEmailActionSheet.copyEmail,
          handler: async () => {
            await Clipboard.write({ string: email });
          },
        },
        {
          text: i18n.t.useEmailActionSheet.openMailApp,
          handler: () => {
            window.open(`mailto:${email}`);
          },
        },
        {
          text: i18n.t.common.cancel,
          role: "cancel",
          handler: async () => {
            await Clipboard.write({ string: email });
          },
        },
      ],
      header: i18n.t.useEmailActionSheet.header(email),
    });
  }, [
    email,
    i18n.t.common.cancel,
    i18n.t.useEmailActionSheet,
    presentActionSheet,
  ]);
}
