import { getStringLiteral } from "@connectedliving/common/lib/firestore/dataHelpers";
import { waitingListEntriesPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import WaitingListEntryConverter from "@connectedliving/common/lib/firestore/WaitingListEntryConverter";
import { formatGermanAddress } from "@connectedliving/common/lib/formatAddress";
import { IonCardTitle, IonIcon, useIonToast } from "@ionic/react";
import * as Sentry from "@sentry/react";
import firebase from "firebase/compat/app";
import { checkmarkDoneOutline, close } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import InfoCard, { InfoCardButton, InfoCardText } from "src/common/InfoCard";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import FormTextInput from "../common/form/FormTextInput";
import { useFieldInputs } from "../state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "../state/formState/useFormState";
import MixpanelClientContainer from "../state/mixpanel/MixpanelContainer";
import validate from "../utilities/validate/validate";
import css from "./CheckMyAddressResultsPage.module.css";

type UnauthenticatedJoinWaitingListCardProps = {
  teamLocation: TeamLocation;
};

const UnauthenticatedJoinWaitingListCard: React.FC<
  UnauthenticatedJoinWaitingListCardProps
> = ({ teamLocation }) => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { mixpanelClient } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const [presentToast] = useIonToast();
  const formState = useFormState<{ email: string }>({
    initial: {
      email: "",
    },
    validate: {
      fields: {
        email: validate.string.isEmail(i18n.t.common.validations),
      },
    },
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [succeeded, setSucceeded] = useState(false);

  const fields = useFieldInputs(formState, useFieldStates(formState));

  const { form } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        setIsSubmitting(true);
        try {
          await await firebaseApp
            .firestore()
            .collection(waitingListEntriesPath())
            .withConverter(WaitingListEntryConverter)
            .add({
              userId: null,
              status: "initial",
              email: formValues.email,
              mixpanelId: mixpanelClient.get_distinct_id(),

              streetNumber: teamLocation.streetNumber,
              streetName: teamLocation.streetName,
              city: teamLocation.city,
              state: teamLocation.state,
              postcode: teamLocation.postcode,
              country: teamLocation.country,
              googlePlaceId: teamLocation.googlePlaceId,
              googlePlaceIdRetrievedAt: teamLocation.googlePlaceIdRetrievedAt,
              locale: getStringLiteral(i18n.currentLocale.slice(0, 2), {
                permitted: ["en", "de"],
                fallback: "en",
              }),
              formattedAddress: formatGermanAddress(teamLocation),

              joinedTeamId: null,
            });
          setSucceeded(true);
          fields.email.onChange("");
        } catch (error) {
          Sentry.captureException(error);

          presentToast({
            message: i18n.t.common.httpsErrorMessage(
              error as { message: string; code: string },
            ),
            duration: 5000,
            buttons: [
              {
                icon: close,
                role: "cancel",
              },
            ],
            color: "danger",
          });
        } finally {
          setIsSubmitting(false);
        }
      },
      [
        fields.email,
        firebaseApp,
        i18n.currentLocale,
        i18n.t.common,
        mixpanelClient,
        presentToast,
        teamLocation,
      ],
    ),
  });

  return (
    <InfoCard
      cardTitle={
        <IonCardTitle>
          {i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList.title}
        </IonCardTitle>
      }
    >
      <InfoCardText style={{ margin: 0 }}>
        {i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList.text}
      </InfoCardText>

      {succeeded ? (
        <div className="ion-margin-top ion-text-center">
          <strong>
            {
              i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList
                .waitingListJoinedSuccessfully
            }
          </strong>
          <br />
          <IonIcon icon={checkmarkDoneOutline} size="large" color="success" />
        </div>
      ) : (
        <>
          <form
            {...form}
            className={`ion-dark-mode ${css.form}`}
            style={
              { "--ion-item-background": "transparent" } as React.CSSProperties
            }
          >
            <FormTextInput
              className={css.formInput}
              disabled={isSubmitting}
              label={
                i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList.email
                  .label
              }
              {...fields.email}
              ionItemProps={{ lines: "full" }}
            />
          </form>

          <InfoCardButton
            onClick={() => form.onSubmit()}
            disabled={isSubmitting}
          >
            {i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList.button}
          </InfoCardButton>
        </>
      )}
    </InfoCard>
  );
};

type AuthenticatedJoinWaitingListCardProps = {
  authUser: firebase.User;
  teamLocation: TeamLocation;
};

const AuthenticatedJoinWaitingListCard: React.FC<
  AuthenticatedJoinWaitingListCardProps
> = ({ authUser, teamLocation }) => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { mixpanelClient } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const [presentToast] = useIonToast();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [succeeded, setSucceeded] = useState(false);

  const email = authUser.email!;

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await firebaseApp
        .firestore()
        .collection(waitingListEntriesPath())
        .withConverter(WaitingListEntryConverter)
        .add({
          userId: authUser?.uid || null,
          status: "initial",
          email,
          mixpanelId: mixpanelClient.get_distinct_id(),

          streetNumber: teamLocation.streetNumber,
          streetName: teamLocation.streetName,
          city: teamLocation.city,
          state: teamLocation.state,
          postcode: teamLocation.postcode,
          country: teamLocation.country,
          googlePlaceId: teamLocation.googlePlaceId,
          googlePlaceIdRetrievedAt: teamLocation.googlePlaceIdRetrievedAt,
          locale: getStringLiteral(i18n.currentLocale.slice(0, 2), {
            permitted: ["en", "de"],
            fallback: "en",
          }),
          formattedAddress: formatGermanAddress(teamLocation),

          joinedTeamId: null,
        });

      setSucceeded(true);
    } catch (error) {
      Sentry.captureException(error);

      presentToast({
        message: i18n.t.common.httpsErrorMessage(
          error as { message: string; code: string },
        ),
        duration: 5000,
        buttons: [
          {
            icon: close,
            role: "cancel",
          },
        ],
        color: "danger",
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [
    authUser?.uid,
    email,
    firebaseApp,
    i18n.currentLocale,
    i18n.t.common,
    mixpanelClient,
    presentToast,
    teamLocation,
  ]);

  return (
    <InfoCard
      cardTitle={
        <IonCardTitle>
          {i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList.title}
        </IonCardTitle>
      }
    >
      <InfoCardText style={{ margin: 0 }}>
        {i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList.text}
      </InfoCardText>

      {succeeded ? (
        <div className="ion-margin-top ion-text-center">
          <strong>
            {
              i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList
                .waitingListJoinedSuccessfully
            }
          </strong>
          <br />
          <IonIcon icon={checkmarkDoneOutline} size="large" color="success" />
        </div>
      ) : (
        <InfoCardButton onClick={onSubmit} disabled={isSubmitting}>
          {i18n.t.CheckMyAddressResultsPage.cards.joinWaitingList.button}
        </InfoCardButton>
      )}
    </InfoCard>
  );
};

type JoinWaitingListCardProps = {
  teamLocation: TeamLocation;
};

const JoinWaitingListCard: React.FC<JoinWaitingListCardProps> = ({
  teamLocation,
}) => {
  const { authUser } = FirebaseAppContainer.useContainer();

  if (authUser) {
    return <AuthenticatedJoinWaitingListCard {...{ teamLocation, authUser }} />;
  }

  return <UnauthenticatedJoinWaitingListCard {...{ teamLocation }} />;
};

export default JoinWaitingListCard;
