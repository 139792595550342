import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react";
import React, { PropsWithChildren } from "react";

export type FirestoreMessageListErrorMessageProps = PropsWithChildren<{}>;

const FirestoreMessageListErrorScreen: React.FC<
  FirestoreMessageListErrorMessageProps
> = ({ children }) => (
  <IonGrid style={{ height: "100%" }}>
    <IonRow className="ion-justify-content-center" style={{ height: "100%" }}>
      <IonCol
        className="ion-justify-content-center"
        style={{ display: "flex" }}
      >
        <IonText className="ion-text-center">{children}</IonText>
      </IonCol>
    </IonRow>
  </IonGrid>
);

export default FirestoreMessageListErrorScreen;
