import { ChannelFilters, ChannelOptions, ChannelSort } from "stream-chat";
import { InternalStreamChannel } from "./InternalStreamChannel";
import { InternalStreamChat } from "./InternalStreamChat";
import { InternalStreamChatGenerics } from "./InternalStreamChatGenerics";
import respectRateLimit from "./respectRateLimit";

async function loadAllChannels(
  streamChat: InternalStreamChat,
  filterConditions: ChannelFilters<InternalStreamChatGenerics>,
  sort: ChannelSort = [],
  options: ChannelOptions = {},
): Promise<InternalStreamChannel[]> {
  let newChannels: InternalStreamChannel[] = [];
  const limit = 30;
  let queryingChannels = true;

  for (let offset = 0; queryingChannels; offset += limit) {
    const response = await respectRateLimit(() =>
      streamChat.queryChannels(filterConditions, sort, {
        ...options,
        limit,
        offset,
      }),
    );

    newChannels = [...newChannels, ...response];

    if (response.length < limit) queryingChannels = false;
  }

  return newChannels;
}
export default loadAllChannels;
