import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import { isPlatform } from "@ionic/react";
import EventEmitter from "eventemitter3";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { useCallback, useEffect, useRef, useState } from "react";
import idbReady from "safari-14-idb-fix";
import cloudFunctions, { CloudFunctions } from "src/firebase/cloudFunctions";
import { createContainer } from "src/utilities/createContainer";
import environment from "../../utilities/environment";
import I18nContainer from "../i18n/I18nContainer";
import MixpanelClientContainer from "../mixpanel/MixpanelContainer";
import usePrevious from "../usePrevious";

const FirebaseConfig = {
  apiKey: environment().firebaseApiKey(),
  authDomain: environment().firebaseAuthDomain(),
  projectId: environment().firebaseProjectId(),
  storageBucket: environment().firebaseStorageBucket(),
  appId: environment().firebaseAppId(),
};

export type FirebaseApp = {
  authUser: firebase.User | null;
  firebaseApp: firebase.app.App;
  cloudFunctions: CloudFunctions;
  onLogOut(listener: LogOutEventListener): () => void;
  logOut(): void;
};

type FirebaseAppInitialValue = {
  firebaseApp: firebase.app.App;
};

// If name is not provided it defaults to '[DEFAULT]'. The "default" app is returned by global
// calls to `firebase.app()`. Providing a name prevents this Firebase app instance being accessed
// without using `FirebaseAppContainer.useContainer()`
const appName = "connected-living";

async function initializeFirebaseApp(): Promise<firebase.app.App> {
  await idbReady();

  if (process.env.NODE_ENV === "development") {
    const existingApp = firebase.apps.find((app) => app.name === appName);
    if (existingApp) {
      return existingApp;
    }
  }

  const firebaseApp = firebase.initializeApp(FirebaseConfig, appName);
  const initialAuthTokenLoadOperation = new Promise<void>((resolve) => {
    const unsubscribe = firebaseApp.auth().onAuthStateChanged(() => {
      resolve();
      unsubscribe();
    });
  });

  const region = requireEnvVar("REACT_APP_FIREBASE_CLOUD_FUNCTIONS_REGION");
  if (process.env.REACT_APP_USE_EMULATOR_FUNCTIONS) {
    const host = requireEnvVar("REACT_APP_FIREBASE_EMULATOR_HOST");
    const port = requireEnvVar("REACT_APP_FUNCTIONS_EMULATOR_PORT");
    // eslint-disable-next-line no-console
    console.info(
      `Using functions from emulator: ${host}:${port}, region=${region}`,
    );
    firebaseApp.functions(region).useEmulator(host, parseInt(port, 10));
  }

  if (process.env.REACT_APP_USE_EMULATORS) {
    // eslint-disable-next-line no-console
    console.info("Using Firebase emulators");

    firebaseApp
      .firestore()
      .useEmulator(
        requireEnvVar("REACT_APP_FIREBASE_EMULATOR_HOST"),
        parseInt(requireEnvVar("REACT_APP_FIRESTORE_EMULATOR_PORT"), 10),
      );
    firebaseApp
      .functions(region)
      .useEmulator(
        requireEnvVar("REACT_APP_FIREBASE_EMULATOR_HOST"),
        parseInt(requireEnvVar("REACT_APP_FUNCTIONS_EMULATOR_PORT"), 10),
      );

    firebaseApp
      .auth()
      .useEmulator(
        `http://${requireEnvVar("REACT_APP_FIREBASE_EMULATOR_HOST")}:${parseInt(
          requireEnvVar("REACT_APP_AUTH_EMULATOR_PORT"),
          10,
        )}/`,
      );
  }

  async function waitForPersistence() {
    // Disabled by default on desktop web where it may be a shared device, e.g. a library computer:
    // https://firebase.google.com/docs/firestore/manage-data/enable-offline#configure_offline_persistence
    if (isPlatform("capacitor") && process.env.NODE_ENV !== "test") {
      await firebaseApp
        .firestore()
        .enablePersistence({ synchronizeTabs: true })
        .catch((error) => {
          if (process.env.NODE_ENV === "development") {
            // eslint-disable-next-line no-console
            console.log(error);
            return;
          }

          throw error;
        });
    }
  }

  await Promise.all([initialAuthTokenLoadOperation, waitForPersistence()]);

  return firebaseApp;
}

export function useInitializedFirebaseApp(): firebase.app.App | undefined {
  const [firebaseApp, setFirebaseApp] = useState<
    firebase.app.App | undefined
  >();

  useEffect(() => {
    dontAwait(initializeFirebaseApp().then(setFirebaseApp));
  }, []);

  return firebaseApp;
}

const logOutEvent = "logOut";
type LogOutEventListener = () => Promise<void> | void;

export function useFirebaseApp(
  initial: FirebaseAppInitialValue | undefined,
): FirebaseApp {
  const [firebaseApp] = useState(() => {
    if (!initial?.firebaseApp) throw new Error("Must provide firebaseApp");
    return initial.firebaseApp;
  });

  const [eventEmitter] = useState(() => new EventEmitter());
  const [authUser, setAuthUser] = useState<firebase.User | null>(
    firebaseApp.auth().currentUser,
  );
  const mixpanel = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const logOutInProgress = useRef(false);

  const previousAuthUser = usePrevious(authUser);

  const onLogOut = useCallback(
    (listener: LogOutEventListener) => {
      eventEmitter.on(logOutEvent, listener);

      const deregisterLogoutEventHandler = () =>
        eventEmitter.off(logOutEvent, listener);

      return deregisterLogoutEventHandler;
    },
    [eventEmitter],
  );

  const logOut = useCallback(async () => {
    if (logOutInProgress.current) return;
    logOutInProgress.current = true;

    const logOutListeners = eventEmitter.listeners(logOutEvent);

    await Promise.all([
      ...logOutListeners.map((listener) => listener()),
      mixpanel.performLogOut(),
      i18n.performLogOut(),
      firebaseApp.firestore().waitForPendingWrites(),
    ]);

    await Promise.all([
      firebaseApp.firestore().terminate(),
      firebaseApp.auth().signOut(),
    ]);

    window.location.pathname = "/";
  }, [eventEmitter, firebaseApp, i18n, mixpanel]);

  useEffect(() => {
    const unsubscribe = firebaseApp
      .auth()
      .onAuthStateChanged((newUserValue: firebase.User | null): void => {
        setAuthUser(newUserValue);

        if (!previousAuthUser && newUserValue) {
          dontAwait(mixpanel.track({ eventName: "User Logged In" }));
        }

        const userLoggedOut = previousAuthUser && !newUserValue;

        if (userLoggedOut && !logOutInProgress.current) {
          dontAwait(logOut());
        }
      });

    return () => {
      unsubscribe();
    };
  }, [
    authUser,
    eventEmitter,
    firebaseApp,
    i18n,
    logOut,
    mixpanel,
    previousAuthUser,
  ]);

  useEffect(() => {
    if (authUser?.uid) {
      mixpanel.mixpanelClient.identify(authUser.uid);
    }
  }, [authUser?.uid, mixpanel.mixpanelClient]);

  return {
    authUser,
    firebaseApp,
    cloudFunctions: cloudFunctions(firebaseApp),
    onLogOut,
    logOut,
  };
}

export default createContainer(useFirebaseApp);
