export default function updatedObjectKeys<T extends Record<string, unknown>>(
  before: T,
  after: Partial<T>,
): (keyof T)[] {
  const result: (keyof T)[] = [];
  for (const [key, value] of Object.entries(after)) {
    if (before[key] !== value) result.push(key);
  }
  return result;
}
