import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import { SpecificTimestampValue } from "@connectedliving/common/lib/firestore/TimestampValue";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import streamDirectMessageChannelId from "@connectedliving/common/lib/stream/streamDirectMessageChannelId";
import convertTimestampValueToDate from "@connectedliving/common/lib/utilities/convertTimestampValueToDate";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  directMessageChannelMessagesPageUrl,
  discoverChannelsPageUrl,
  newTeamChannelPageUrl,
  teamAdminPageUrl,
  teamChannelMessagesPageUrl,
  userProfilesListPageUrl,
  userSettingsPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonPage,
  IonRouterLink,
  IonSkeletonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import {
  add,
  closeOutline,
  ellipsisHorizontal,
  personAddOutline,
  search,
} from "ionicons/icons";
import { compact, difference } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import InfoCard, { InfoCardText } from "src/common/InfoCard";
import InviteNeighborModal from "src/common/InviteNeighborModal";
import IonItemIcon from "src/common/IonItemIcon";
import useScreenTracking from "src/firebase/useScreenTracking";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import StreamChannelsCacheContainer, {
  StreamChannelWithLoadingState,
} from "src/state/stream/StreamChannelsCacheContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TeamUserPreferencesContainer from "src/state/team/TeamUserPreferencesContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import dismissBanner from "src/teams/teamUserProfile/dismissBanner";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import combineLoadingStates from "src/utilities/combineLoadingStates";
import { isDataAvailable } from "src/utilities/data/Loadable";
import environment from "src/utilities/environment";
import { dataAvailable } from "src/utilities/LoadingState";
import ProfileImage from "src/utilities/ProfileImage";
import useStreamEventsThatAffectSortOrder from "src/utilities/stream/useStreamEventsThatAffectSortOrder";
import I18nContainer from "../../state/i18n/I18nContainer";
import streamChannelLastMessageSentAt from "../../utilities/stream/streamChannelLastMessageSentAt";
import css from "./ChannelsListPage.module.css";
import ChannelsListSkeleton from "./ChannelsListSkeleton";
import FirestoreChannelPreview from "./firestore/FirestoreChannelPreview";
import StreamChannelPreview, {
  RenderLabelFunction,
} from "./stream/StreamChannelPreview";

type ChannelsListPageProps = {
  teamId: string;
};

function getChannelUpdatedAt(
  channel:
    | firebase.firestore.QueryDocumentSnapshot<TeamChannel>
    | firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>,
  streamChannel: StreamChannelWithLoadingState | null,
): number {
  if (streamChannel) return streamChannelLastMessageSentAt(streamChannel);

  const { latestMessage } = channel.data();
  if (!latestMessage) return 0;

  const timestampValue = latestMessage.updatedAt as SpecificTimestampValue;
  return convertTimestampValueToDate(timestampValue).valueOf();
}

const ChannelsListPage: React.FC<ChannelsListPageProps> = ({ teamId }) => {
  const { track } = MixpanelClientContainer.useContainer();
  const { authUser, userProfile, team, teamUserProfile } =
    TeamContextContainer.useContainer();
  const {
    directMessageChannels,
    joinedTeamChannels,
    teamChannels,
    loadingState: firestoreChannelsContainerLoadingState,
  } = FirestoreChannelsContainer.useContainer();
  const { getStreamChannel } = StreamChannelsCacheContainer.useContainer();
  const { findUserProfileById } = TeamUserProfilesContainer.useContainer();
  const { teamUserPreferences } = TeamUserPreferencesContainer.useContainer();
  const loadingState = combineLoadingStates(
    firestoreChannelsContainerLoadingState,
    teamUserPreferences.loadingState,
  );

  const isGrowYourCommunityBannerVisible =
    teamUserProfile.data().visibleSuggestionBanners["Grow your community"];
  const [isOpenInviteNeighborModal, setIsOpenInviteNeighborModal] =
    useState<boolean>(false);
  const { inviteCode, numberOfApartments, name } = team.data();

  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const teamChannelsWithStreamChannel: {
    teamChannel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>;
    streamChannel: StreamChannelWithLoadingState | null;
  }[] = useMemo(() => {
    if (!dataAvailable(firestoreChannelsContainerLoadingState)) return [];
    return joinedTeamChannels.map((teamChannel) => ({
      teamChannel,
      streamChannel:
        teamChannel.data().backingStorage === "stream"
          ? getStreamChannel(StreamChannelType.Team, teamChannel.id)
          : null,
    }));
  }, [
    getStreamChannel,
    joinedTeamChannels,
    firestoreChannelsContainerLoadingState,
  ]);

  const sortedTeamChannels = teamChannelsWithStreamChannel.sort(
    (channel1, channel2) => {
      const channel1UpdatedAt = getChannelUpdatedAt(
        channel1.teamChannel,
        channel1.streamChannel,
      );
      const channel2UpdatedAt = getChannelUpdatedAt(
        channel2.teamChannel,
        channel2.streamChannel,
      );

      return (
        channel2UpdatedAt - channel1UpdatedAt ||
        channel1.teamChannel
          .data()
          .name.localeCompare(channel2.teamChannel.data().name)
      );
    },
  );

  const directMessageChannelsWithStreamChannel: {
    directMessageChannel: firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>;
    otherUserProfile:
      | firebase.firestore.QueryDocumentSnapshot<UserProfile>
      | undefined;
    streamChannel: StreamChannelWithLoadingState | null;
  }[] = useMemo(() => {
    if (!dataAvailable(firestoreChannelsContainerLoadingState)) return [];
    if (!isDataAvailable(teamUserPreferences)) return [];

    const nonBlockedDirectMessageChannels = directMessageChannels.filter(
      (directMessageChannel) => {
        const blockedUserIds =
          teamUserPreferences.data.data()?.blockedUserIds || [];
        const otherUserIsBlocked = blockedUserIds.includes(
          directMessageChannel.data().otherUserId,
        );
        return !otherUserIsBlocked;
      },
    );

    return nonBlockedDirectMessageChannels.map((directMessageChannel) => {
      const otherUserProfile = findUserProfileById(
        directMessageChannel.data().otherUserId,
      );

      if (directMessageChannel.data().backingStorage === "firestore") {
        return { directMessageChannel, otherUserProfile, streamChannel: null };
      }

      const streamChannelId =
        directMessageChannel.data().streamChannelId ||
        streamDirectMessageChannelId(
          teamId,
          authUser.uid,
          directMessageChannel.data().otherUserId,
        );
      return {
        directMessageChannel,
        otherUserProfile,
        streamChannel: getStreamChannel(
          StreamChannelType.DirectMessage,
          streamChannelId,
        ),
      };
    });
  }, [
    firestoreChannelsContainerLoadingState,
    teamUserPreferences,
    directMessageChannels,
    teamId,
    authUser.uid,
    findUserProfileById,
    getStreamChannel,
  ]);

  const sortedDirectMessageChannels =
    directMessageChannelsWithStreamChannel.sort((channel1, channel2) => {
      const channel1UpdatedAt = getChannelUpdatedAt(
        channel1.directMessageChannel,
        channel1.streamChannel,
      );
      const channel2UpdatedAt = getChannelUpdatedAt(
        channel2.directMessageChannel,
        channel2.streamChannel,
      );

      return (
        channel2UpdatedAt - channel1UpdatedAt ||
        formatFullName(
          channel1.otherUserProfile?.data() || { firstName: "", lastName: "" },
        ).localeCompare(
          formatFullName(
            channel2.otherUserProfile?.data() || {
              firstName: "",
              lastName: "",
            },
          ),
        )
      );
    });

  useStreamEventsThatAffectSortOrder(
    compact(
      teamChannelsWithStreamChannel.map(({ streamChannel }) => streamChannel),
    ),
  );
  useStreamEventsThatAffectSortOrder(
    compact(
      directMessageChannelsWithStreamChannel.map(
        ({ streamChannel }) => streamChannel,
      ),
    ),
  );

  const screenName = "ChannelsListPage";
  useScreenTracking(screenName, teamId);

  const onInviteNeighborButtonClick = useCallback(() => {
    setIsOpenInviteNeighborModal(true);
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Invite Neighbor",
      }),
    );
    dontAwait(
      track({
        eventName: "Modal Opened",
        "screen name": screenName,
        "team id": teamId,
        "modal name": "InviteNeighborModal",
      }),
    );
  }, [teamId, track]);
  const onSuggestionBannerClick = useCallback(() => {
    setIsOpenInviteNeighborModal(true);
    dontAwait(
      track({
        eventName: "Suggestion Card Clicked",
        "screen name": screenName,
        "team id": teamId,
        "card name": "Grow your community",
      }),
    );
    dontAwait(
      track({
        eventName: "Modal Opened",
        "screen name": screenName,
        "team id": teamId,
        "modal name": "InviteNeighborModal",
      }),
    );
  }, [teamId, track]);

  const i18n = I18nContainer.useContainer();
  const onSuggestionBannerDismiss = useCallback(
    (event) => {
      const bannerName = "Grow your community";
      event.preventDefault();
      event.stopPropagation();
      dontAwait(
        dismissBanner({
          bannerName,
          teamUserProfile,
          track,
          teamId,
          screenName,
        }),
      );
    },
    [teamId, teamUserProfile, track],
  );
  const dismissInviteNeighborModal = () => {
    setIsOpenInviteNeighborModal(false);
  };

  const otherAvailableChannels = difference(teamChannels, joinedTeamChannels);

  const supportUserId = environment().supportUserId();
  const numberOfNeighbors = team
    .data()
    .userIds.filter((id) => id !== supportUserId).length;

  const minimumNumberOfNeighborsToAllowDismissingOfN2NBanner = 5;
  return (
    <IonPage
      id="main-content"
      className="ion-justify-content-center ion-align-items-center"
      data-cy="ChannelsListPage"
    >
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonRouterLink routerLink={teamAdminPageUrl({ teamId })} color="dark">
            <IonTitle className={css.title}>{name}</IonTitle>
          </IonRouterLink>

          <IonButtons slot="end">
            <IonButton
              data-cy="ChannelsListPage-UserSettingsButton"
              routerLink={userSettingsPageUrl({
                teamId,
              })}
            >
              <IonAvatar className={css.profileAvatar}>
                <ProfileImage userProfile={userProfile.data()} />
              </IonAvatar>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding-bottom">
        {!dataAvailable(loadingState) ? (
          <ChannelsListSkeleton />
        ) : (
          <>
            {isGrowYourCommunityBannerVisible && (
              <div className={`${css.bannerContainer} ion-padding-top`}>
                <InfoCard
                  className="ion-no-margin ion-margin-horizontal"
                  color="aubergine-fill"
                  cardTitle={
                    <>
                      <div>
                        <IonCardSubtitle className={css.superTitle}>
                          {i18n.t.ChannelsListPage.growCommunityCard.superTitle}
                        </IonCardSubtitle>
                        <IonCardTitle>
                          {i18n.t.ChannelsListPage.growCommunityCard.title(
                            numberOfNeighbors,
                          )}
                        </IonCardTitle>
                      </div>
                      {numberOfNeighbors >
                        minimumNumberOfNeighborsToAllowDismissingOfN2NBanner && (
                        <IonIcon
                          icon={closeOutline}
                          size="large"
                          onClick={onSuggestionBannerDismiss}
                          className={css.icon}
                        />
                      )}
                    </>
                  }
                  onClick={onSuggestionBannerClick}
                >
                  <InfoCardText className={css.cardText}>
                    {i18n.t.ChannelsListPage.growCommunityCard.body(
                      numberOfNeighbors,
                      numberOfApartments,
                    )}
                  </InfoCardText>
                </InfoCard>
              </div>
            )}
            <IonList>
              <IonListHeader>
                <IonLabel>{i18n.t.ChannelsListPage.channels}</IonLabel>

                <IonButton
                  routerLink={newTeamChannelPageUrl({ teamId })}
                  data-cy="ChannelsListPage-NewChannelButtton-team"
                  routerDirection="forward"
                >
                  <IonIcon icon={add} />
                </IonButton>
              </IonListHeader>

              {sortedTeamChannels.map(({ teamChannel, streamChannel }) => {
                const renderTeamChannelLabel: RenderLabelFunction = ({
                  loadingState: teamChannelLoadingState,
                  latestMessagePreview,
                }) => (
                  <>
                    <h2>
                      <strong>{teamChannel.data().name}</strong>
                    </h2>
                    {teamChannelLoadingState === "initialLoad" ? (
                      <p>
                        <IonSkeletonText animated />
                      </p>
                    ) : (
                      latestMessagePreview && (
                        <p>{latestMessagePreview.preview}</p>
                      )
                    )}
                  </>
                );

                if (teamChannel.data().backingStorage === "stream") {
                  return (
                    <StreamChannelPreview
                      key={teamChannel.id}
                      userId={authUser.uid}
                      channelType={StreamChannelType.Team}
                      channelId={teamChannel.id || ""}
                      channelAvatar={{
                        type: "icon",
                        icon: teamChannel.data().channelIcon,
                        iconColor: teamChannel.data().channelIconColor,
                      }}
                      routerLink={teamChannelMessagesPageUrl({
                        teamId,
                        teamChannelId: teamChannel.id,
                      })}
                      loadingState={streamChannel!.loadingState}
                      renderLabel={renderTeamChannelLabel}
                    />
                  );
                }

                return (
                  <FirestoreChannelPreview
                    key={teamChannel.id}
                    channelAvatar={{
                      type: "icon",
                      icon: teamChannel.data().channelIcon,
                      iconColor: teamChannel.data().channelIconColor,
                    }}
                    routerLink={teamChannelMessagesPageUrl({
                      teamId,
                      teamChannelId: teamChannel.id,
                    })}
                    channel={{
                      type: FirestoreChannelType.Team,
                      channel: teamChannel,
                    }}
                    {...{ findUserProfileById }}
                  />
                );
              })}

              {otherAvailableChannels.length > 0 && (
                <IonItem
                  detail={false}
                  routerLink={discoverChannelsPageUrl({ teamId })}
                  data-cy="ChannelsListPage-DiscoverChannelsPageButton"
                >
                  <IonItemIcon slot="start" icon={ellipsisHorizontal} />
                  <IonLabel color="primary">
                    {i18n.t.ChannelsListPage.viewMoreChannels}
                  </IonLabel>
                </IonItem>
              )}
            </IonList>

            <IonList>
              <IonListHeader>
                <IonLabel>{i18n.t.ChannelsListPage.directMessages}</IonLabel>

                <IonButton
                  routerLink={userProfilesListPageUrl({ teamId })}
                  data-cy="ChannelsListPage-NewChannelButtton-messaging"
                >
                  <IonIcon icon={search} />
                </IonButton>
              </IonListHeader>

              <IonItem
                button
                detail={false}
                onClick={onInviteNeighborButtonClick}
              >
                <IonItemIcon slot="start" icon={personAddOutline} />
                <IonLabel color="primary">
                  {i18n.t.common.inviteNeighbor}
                </IonLabel>
              </IonItem>

              {directMessageChannels.length === 0 ? (
                <IonItem
                  detail={false}
                  routerLink={userProfilesListPageUrl({ teamId })}
                >
                  <IonItemIcon slot="start" icon={add} />
                  <IonLabel color="primary">
                    {i18n.t.ChannelsListPage.newDirectMessage}
                  </IonLabel>
                </IonItem>
              ) : (
                sortedDirectMessageChannels.map(
                  ({
                    directMessageChannel,
                    otherUserProfile,
                    streamChannel,
                  }) => {
                    const otherUserUserProfileData =
                      otherUserProfile && otherUserProfile.data();
                    const imageUrl = otherUserUserProfileData?.imageUrl;
                    const formattedName = otherUserUserProfileData
                      ? formatFullName(otherUserUserProfileData)
                      : "";

                    const renderDirectMessageChannelLabel: RenderLabelFunction =
                      ({
                        loadingState: directMessageChannelLoadingState,
                        latestMessagePreview,
                      }) => (
                        <>
                          <h2>
                            <strong>{formattedName}</strong>
                          </h2>
                          {directMessageChannelLoadingState ===
                          "initialLoad" ? (
                            <p>
                              <IonSkeletonText animated />
                            </p>
                          ) : (
                            latestMessagePreview && (
                              <p>{latestMessagePreview.preview}</p>
                            )
                          )}
                        </>
                      );

                    if (
                      directMessageChannel.data().backingStorage === "stream"
                    ) {
                      return (
                        <StreamChannelPreview
                          key={directMessageChannel.ref.path}
                          channelId={streamChannel!.value.id || ""}
                          channelType={StreamChannelType.DirectMessage}
                          channelAvatar={
                            imageUrl
                              ? { type: "image", imageUrl }
                              : {
                                  type: "initialsAvatar",
                                  name: formattedName,
                                }
                          }
                          userId={authUser.uid}
                          routerLink={directMessageChannelMessagesPageUrl({
                            teamId,
                            authUserId: authUser.uid,
                            otherUserId:
                              directMessageChannel.data().otherUserId,
                          })}
                          loadingState={streamChannel!.loadingState}
                          renderLabel={renderDirectMessageChannelLabel}
                        />
                      );
                    }

                    return (
                      <FirestoreChannelPreview
                        key={directMessageChannel.ref.path}
                        channelAvatar={
                          imageUrl
                            ? { type: "image", imageUrl }
                            : {
                                type: "initialsAvatar",
                                name: formattedName,
                              }
                        }
                        routerLink={directMessageChannelMessagesPageUrl({
                          teamId,
                          authUserId: authUser.uid,
                          otherUserId: directMessageChannel.data().otherUserId,
                        })}
                        channel={{
                          type: FirestoreChannelType.DirectMessage,
                          channel: directMessageChannel,
                        }}
                        {...{ findUserProfileById }}
                      />
                    );
                  },
                )
              )}
            </IonList>
            <InviteNeighborModal
              isOpen={isOpenInviteNeighborModal}
              dismissInviteNeighborModal={dismissInviteNeighborModal}
              inviteCode={inviteCode}
              track={track}
              teamId={teamId}
              screenName={screenName}
              teamName={name}
            />
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ChannelsListPage;
