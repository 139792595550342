import { makeLocale } from "src/utilities/i18n/makeLocale";
import {
  defaultFormatters,
  LocaleFormatters,
  LocaleTranslations,
} from "../i18nConfig";
import { translations } from "./en-us";

export default makeLocale<LocaleTranslations, LocaleFormatters, "en-de">({
  tag: "en-de",
  formatters: defaultFormatters,
  translations,
});
