import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { germanStateName } from "@connectedliving/common/lib/utilities/countryStates";

export default function formatTeamLocation({
  streetName,
  streetNumber,
  state,
  city,
  postcode,
}: TeamLocation): string {
  const trimmedState = state.trim();
  const stateName = germanStateName(trimmedState);

  const trimmedStreetName = streetName.trim();
  const trimmedStreetNumber = streetNumber.trim();
  const trimmedCity = city.trim();
  const trimmedPostcode = postcode.trim();

  const stateAndCity =
    stateName === trimmedCity ? stateName : `${stateName}, ${trimmedCity}`;
  return `${trimmedStreetName} ${trimmedStreetNumber}, ${trimmedPostcode} ${stateAndCity}`;
}
