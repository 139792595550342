import { PluginListenerHandle } from "@capacitor/core";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { isPlatform } from "@ionic/react";
import { useEffect } from "react";

/**
 * This custom hook can be used to register individual listeners where you require the return value to remove the listener upon unmounting the containing component.
 * This is exclusively for listeners from capacitor plugins, hence we return if the platform is not capacitor!
 *
 * @param register Must be a function that returns a Promise containing a PluginListenerHandle
 */

export default function usePluginListener(
  register: () => Promise<PluginListenerHandle>,
): void {
  useEffect(() => {
    if (!isPlatform("capacitor")) return undefined;
    const pluginListenerHandlePromise = register();
    return () => {
      dontAwait(
        pluginListenerHandlePromise.then((pluginListenerHandle) =>
          pluginListenerHandle.remove(),
        ),
      );
    };
  }, [register]);
}
