import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import { Loader } from "@googlemaps/js-api-loader";
import { isError } from "lodash";
import { useCallback, useState } from "react";
import { createContainer } from "src/utilities/createContainer";
import { dataAvailable, LoadingState } from "src/utilities/LoadingState";

export type GoogleMapsApi = {
  loadGoogleMapsApi(): Promise<void>;
  loadingState: LoadingState | null;
};

export function useGoogleMapsApi(): GoogleMapsApi {
  const [loadingState, setLoadingState] = useState<LoadingState | null>(null);
  const [loader] = useState(
    () =>
      new Loader({
        apiKey: requireEnvVar("REACT_APP_FIREBASE_API_KEY"),
        libraries: ["places"],
      }),
  );

  const loadGoogleMapsApi = useCallback(async () => {
    if (loadingState && dataAvailable(loadingState)) return;

    try {
      await loader.load();
      setLoadingState("ready");
    } catch (e) {
      if (isError(e)) {
        setLoadingState({ error: e });
      } else {
        setLoadingState({ error: new Error(String(e)) });
      }
    }
  }, [loader, loadingState]);

  return {
    loadGoogleMapsApi,
    loadingState,
  };
}

const GoogleMapsApiContainer = createContainer(useGoogleMapsApi);
export default GoogleMapsApiContainer;
