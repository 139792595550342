import {
  userProfilePath,
  userProfilesPath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import UserProfileConverter from "@connectedliving/common/lib/firestore/UserProfileConverter";
import { MAX_CLAUSES_PER_QUERY } from "@connectedliving/common/lib/utilities/firebaseConstants";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import firebase from "firebase/compat/app";
import { chunk, difference } from "lodash";
import { useCallback, useMemo, useRef, useState } from "react";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import { createContainer } from "src/utilities/createContainer";
import { checkDocumentExistence } from "src/utilities/data/useFirestoreDocument";
import { FindUserProfileById } from "../state/FindUserProfileById";

type CacheEntry = {
  userProfile:
    | firebase.firestore.QueryDocumentSnapshot<UserProfile>
    | undefined;
  promise: Promise<unknown>;
};

export type SupportUserProfiles = {
  findUserProfileById: FindUserProfileById;
  prefetchUserProfiles: (userIds: string[]) => Promise<void>;
};

export function useSupportUserProfiles(): SupportUserProfiles {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const [counter, forceUpdate] = useState(0);
  const cache = useRef<Record<string, CacheEntry | undefined>>({});

  const findUserProfileById: FindUserProfileById = useCallback(
    (userId) => {
      const entry = cache.current[userId];
      if (entry) return entry.userProfile;

      async function fetchUserProfile() {
        const promise = firebaseApp
          .firestore()
          .doc(userProfilePath({ userId }))
          .withConverter(UserProfileConverter)
          .get();

        const newEntry: CacheEntry = {
          userProfile: undefined,
          promise,
        };
        cache.current[userId] = newEntry;

        const userProfile = checkDocumentExistence(await promise);

        if (userProfile.exists) {
          newEntry.userProfile = userProfile;
          forceUpdate((current) => current + 1);
        }
      }
      fetchUserProfile();

      return undefined;
    },
    [cache, firebaseApp],
  );

  const prefetchUserProfiles: (userIds: string[]) => Promise<void> =
    useCallback(
      async (userIds) => {
        const userIdsToLoad = difference(userIds, Object.keys(cache.current));
        if (userIdsToLoad.length === 0) return undefined;

        async function fetchUserProfiles() {
          const userIdChunks = chunk(userIdsToLoad, MAX_CLAUSES_PER_QUERY);
          for (const userIdChunk of userIdChunks) {
            const loadedUserProfiles = await firebaseApp
              .firestore()
              .collection(userProfilesPath())
              .withConverter(UserProfileConverter)
              .where(
                firebase.firestore.FieldPath.documentId(),
                "in",
                userIdChunk,
              )
              .get();

            for (const userProfile of loadedUserProfiles.docs) {
              const cacheEntry = cache.current[userProfile.id];
              assertPresent(cacheEntry, { because: "it's created below" });
              cacheEntry.userProfile = userProfile;
            }
          }

          forceUpdate((current) => current + 1);
        }

        const promise = fetchUserProfiles();

        for (const userId of userIdsToLoad) {
          cache.current[userId] = { promise, userProfile: undefined };
        }

        return promise;
      },
      [cache, firebaseApp],
    );

  return useMemo(
    () => ({ findUserProfileById, prefetchUserProfiles }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [findUserProfileById, counter],
  );
}

const SupportUserProfilesContainer = createContainer(useSupportUserProfiles);
export default SupportUserProfilesContainer;
