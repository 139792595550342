import { teamChannelMembershipPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamChannelMembershipConverter from "@connectedliving/common/lib/firestore/TeamChannelMembershipConverter";
import firebase from "firebase/compat/app";

export default function leaveChannel(
  firebaseApp: firebase.app.App,
  teamId: string,
  firestoreChannelId: string,
  userId: string,
): Promise<void> {
  return firebaseApp
    .firestore()
    .doc(
      teamChannelMembershipPath({
        teamId,
        teamChannelId: firestoreChannelId,
        userId,
      }),
    )
    .withConverter(TeamChannelMembershipConverter)
    .delete();
}
