export default function semverCompare(
  version1: string,
  version2: string,
): number {
  const version1Segments = version1.split(".");
  const version2Segments = version2.split(".");

  for (let i = 0; i < version1Segments.length; i += 1) {
    const version1Segment = version1Segments[i];
    const version2Segment = version2Segments[i];

    const version1SegmentNumeric = version1Segment
      ? parseInt(version1Segment, 10)
      : 0;
    const version2SegmentNumeric = version2Segment
      ? parseInt(version2Segment, 10)
      : 0;

    if (version1SegmentNumeric !== version2SegmentNumeric)
      return version1SegmentNumeric - version2SegmentNumeric;
  }

  return 0;
}
