import { Channel, PushNotifications } from "@capacitor/push-notifications";
import { isPlatform } from "@ionic/react";
import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { createContainer } from "src/utilities/createContainer";
import FirestoreChannelsContainer from "../team/FirestoreChannelsContainer";

function notificationChannelUpToDate(
  teamChannel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>,
  notificationChannel: Channel,
): boolean {
  return teamChannel.data().name === notificationChannel.name;
}

function updateNotificationChannel(
  channel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>,
) {
  return PushNotifications.createChannel({
    id: `team:${channel.id}`,
    importance: 5,
    name: channel.data().name,
    vibration: true,
    visibility: 1,
    sound: "default",
  });
}

function useAndroidNotificationChannels(): void {
  const { joinedTeamChannels, loadingState: firestoreChannelsLoadingState } =
    FirestoreChannelsContainer.useContainer();
  const [androidPushNotificationChannels, setAndroidPushNotificationChannels] =
    useState<Channel[] | undefined>(undefined);

  useEffect(() => {
    async function loadChannels() {
      const result = await PushNotifications.listChannels();
      setAndroidPushNotificationChannels(result.channels);
    }

    if (!isPlatform("android")) return;
    dontAwait(loadChannels());
  }, []);

  useEffect(() => {
    if (
      !androidPushNotificationChannels ||
      firestoreChannelsLoadingState !== "ready"
    )
      return;

    const cidToTeamChannel: Record<
      string,
      firebase.firestore.QueryDocumentSnapshot<TeamChannel>
    > = {};
    joinedTeamChannels.forEach((teamChannel) => {
      const cid = `team:${teamChannel.id}`;
      cidToTeamChannel[cid] = teamChannel;
    });

    const cidToPushNotificationChannel: Record<string, Channel> = {};
    androidPushNotificationChannels.forEach((notificationChannel) => {
      cidToPushNotificationChannel[notificationChannel.id] =
        notificationChannel;
    });

    for (const [cid, value] of Object.entries(cidToTeamChannel)) {
      const teamChannel = assertPresent.andReturn(value, {
        because: "channelCid came from object keys to begin with",
      });
      const notificationChannel = cidToPushNotificationChannel[cid];

      if (
        notificationChannel &&
        notificationChannelUpToDate(teamChannel, notificationChannel)
      ) {
        continue;
      }

      dontAwait(updateNotificationChannel(teamChannel));
    }

    for (const [cid, notificationChannel] of Object.entries(
      cidToPushNotificationChannel,
    )) {
      const protectedOsChannel = cid === "default";
      if (
        notificationChannel &&
        !cidToTeamChannel[cid] &&
        !protectedOsChannel
      ) {
        dontAwait(PushNotifications.deleteChannel(notificationChannel));
      }
    }
  }, [
    androidPushNotificationChannels,
    firestoreChannelsLoadingState,
    joinedTeamChannels,
  ]);
}

export default createContainer(useAndroidNotificationChannels);
