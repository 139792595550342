import {
  teamChannelMembershipsPath,
  teamChannelPath,
  teamUserPreferencesDocPath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamChannelConverter from "@connectedliving/common/lib/firestore/TeamChannelConverter";
import { TeamChannelMembership } from "@connectedliving/common/lib/firestore/TeamChannelMembership";
import TeamChannelMembershipConverter from "@connectedliving/common/lib/firestore/TeamChannelMembershipConverter";
import { TeamUserPreferences } from "@connectedliving/common/lib/firestore/TeamUserPreferences";
import TeamUserPreferencesConverter from "@connectedliving/common/lib/firestore/TeamUserPreferencesConverter";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  channelsListPageUrl,
  teamChannelMessagesPageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonAvatar,
  IonBackButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSkeletonText,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import {
  enterOutline,
  exitOutline,
  notificationsOffOutline,
  notificationsOutline,
} from "ionicons/icons";
import { compact, sortBy } from "lodash";
import React, { useCallback, useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { Redirect, useHistory, useParams } from "react-router";
import ChannelAvatarContent from "src/common/ChannelAvatarContent";
import commonCss from "src/common/common.module.css";
import IonItemIcon from "src/common/IonItemIcon";
import useScreenTracking from "src/firebase/useScreenTracking";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import UserOnboardingModal from "src/userOnboarding/UserOnboardingModal";
import UserProfileListItem from "src/userProfiles/UserProfileListItem";
import firestoreHooksLoadingState from "src/utilities/firestoreHooksLoadingState";
import {
  dataAvailable,
  isErrorState,
  loadFailedOrNotFound,
  LoadingState,
} from "src/utilities/LoadingState";
import joinChannel from "../../state/firebase/joinChannel";
import leaveChannel from "../../state/firebase/leaveChannel";
import muteChannel from "../../state/firebase/muteChannel";
import unmuteChannel from "../../state/firebase/unmuteChannel";
import MixpanelClientContainer from "../../state/mixpanel/MixpanelContainer";
import LastReadTimestampsContainer from "../../state/team/LastReadTimestampsContainer";
import { isDataAvailable } from "../../utilities/data/Loadable";
import useFirestoreDocument, {
  FirestoreDocument,
  isExistingDocument,
} from "../../utilities/data/useFirestoreDocument";

const screenName = "TeamChannelDetailPage";

type ChannelOperationButtonsProps = {
  loadingState: LoadingState;
  teamChannelId: string;
  teamId: string;
  teamUserPreferencesDoc: FirestoreDocument<TeamUserPreferences>;
};

const ChannelOperationButtons: React.FC<ChannelOperationButtonsProps> = ({
  loadingState,
  teamChannelId,
  teamId,
  teamUserPreferencesDoc,
}) => {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const history = useHistory();

  const [leaveOperationInProgress, setLeaveOperationInProgress] =
    useState(false);
  const [muteOperationInProgress, setMuteOperationInProgress] = useState(false);
  const { isUserMember } = FirestoreChannelsContainer.useContainer();
  const { setChannelLastReadTimestamp } =
    LastReadTimestampsContainer.useContainer();

  const onLeaveChannelClick = useCallback(async () => {
    if (!authUser) return;
    setLeaveOperationInProgress(true);
    try {
      dontAwait(
        track({
          eventName: "Button Clicked",
          "button name": "Leave Channel",
          "screen name": screenName,
          "team id": teamId,
        }),
      );
      await leaveChannel(firebaseApp, teamId, teamChannelId, authUser.uid);
    } finally {
      setLeaveOperationInProgress(false);
    }
    history.replace(channelsListPageUrl({ teamId }));
  }, [authUser, history, teamId, track, firebaseApp, teamChannelId]);

  const onJoinChannelClick = useCallback(async () => {
    if (!authUser) return;
    setLeaveOperationInProgress(true);
    try {
      dontAwait(
        track({
          eventName: "Button Clicked",
          "button name": "Join Channel",
          "screen name": screenName,
          "team id": teamId,
        }),
      );
      await Promise.all([
        joinChannel(firebaseApp, teamId, teamChannelId, authUser.uid),
        setChannelLastReadTimestamp(
          teamChannelPath({ teamId, teamChannelId }),
          new Date(Date.now()),
        ),
      ]);
    } finally {
      setLeaveOperationInProgress(false);
    }
  }, [
    authUser,
    track,
    teamId,
    firebaseApp,
    teamChannelId,
    setChannelLastReadTimestamp,
  ]);

  const onMuteChannelClick = useCallback(async () => {
    if (!authUser || teamUserPreferencesDoc.loadingState !== "ready") return;

    const teamUserPreferences = teamUserPreferencesDoc.data;
    if (!isExistingDocument(teamUserPreferences)) return;

    setMuteOperationInProgress(true);
    try {
      dontAwait(
        track({
          eventName: "Button Clicked",
          "button name": "Mute Channel",
          "screen name": screenName,
          "team id": teamId,
        }),
      );
      await muteChannel({
        teamChannelId,
        teamUserPreferences,
      });
    } finally {
      setMuteOperationInProgress(false);
    }
  }, [
    authUser,
    teamUserPreferencesDoc.loadingState,
    teamUserPreferencesDoc.data,
    track,
    teamId,
    teamChannelId,
  ]);

  const onUnmuteChannelClick = useCallback(async () => {
    if (!authUser || teamUserPreferencesDoc.loadingState !== "ready") return;

    const teamUserPreferences = teamUserPreferencesDoc.data;
    if (!isExistingDocument(teamUserPreferences)) return;

    setMuteOperationInProgress(true);
    try {
      dontAwait(
        track({
          eventName: "Button Clicked",
          "button name": "Unmute Channel",
          "screen name": screenName,
          "team id": teamId,
        }),
      );
      await unmuteChannel({
        teamChannelId,
        teamUserPreferences,
      });
    } finally {
      setMuteOperationInProgress(false);
    }
  }, [
    authUser,
    teamUserPreferencesDoc.loadingState,
    teamUserPreferencesDoc.data,
    track,
    teamId,
    teamChannelId,
  ]);

  const isChannelMuted = teamUserPreferencesDoc.data
    ?.data()
    ?.mutedTeamChannelIds.includes(teamChannelId);

  if (!dataAvailable(loadingState) || !isDataAvailable(teamUserPreferencesDoc))
    return (
      <IonCard className={commonCss.card}>
        <IonItem>
          <IonSkeletonText animated />
        </IonItem>
      </IonCard>
    );

  if (isUserMember(teamChannelId)) {
    return (
      <IonCard className={commonCss.card}>
        <IonItem
          button
          detail={false}
          disabled={muteOperationInProgress || loadingState !== "ready"}
          onClick={() =>
            isChannelMuted ? onUnmuteChannelClick() : onMuteChannelClick()
          }
        >
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="primary"
            slot="start"
            icon={
              isChannelMuted ? notificationsOffOutline : notificationsOutline
            }
          />
          <IonText color="primary">
            {isChannelMuted
              ? i18n.t.TeamChannelDetailPage.unmuteChannel
              : i18n.t.TeamChannelDetailPage.muteChannel}
          </IonText>
          <IonText slot="end" color="medium">
            {isChannelMuted
              ? i18n.t.TeamChannelDetailPage.yes
              : i18n.t.TeamChannelDetailPage.no}
          </IonText>
        </IonItem>

        <IonItem
          button
          detail={false}
          onClick={onLeaveChannelClick}
          disabled={leaveOperationInProgress || loadingState !== "ready"}
          data-cy="TeamChannelDetailPage-leaveChannelButton"
        >
          <IonItemIcon
            className={commonCss.itemIconAvatar}
            color="primary"
            slot="start"
            icon={exitOutline}
          />
          <IonText color="primary">
            {i18n.t.TeamChannelDetailPage.leaveChannel}
          </IonText>
        </IonItem>
      </IonCard>
    );
  }

  return (
    <IonCard className={commonCss.card}>
      <IonItem
        onClick={() => onJoinChannelClick()}
        disabled={leaveOperationInProgress || loadingState !== "ready"}
      >
        <IonItemIcon
          className={commonCss.itemIconAvatar}
          color="none"
          slot="start"
          icon={enterOutline}
        />
        {i18n.t.TeamChannelDetailPage.joinChannel}
      </IonItem>
    </IonCard>
  );
};

export type TeamChannelDetailPageProps = {
  teamId: string;
};
export type TeamChannelDetailPageRouteParams = {
  teamChannelId: string;
};

const TeamChannelDetailPage: React.FC<TeamChannelDetailPageProps> = ({
  teamId,
}) => {
  const { userProfile } = TeamContextContainer.useContainer();
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  const { teamChannelId } = useParams<TeamChannelDetailPageRouteParams>();
  const i18n = I18nContainer.useContainer();

  const teamChannelDoc = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(teamChannelPath({ teamId, teamChannelId }))
      .withConverter(TeamChannelConverter),
  );
  const teamChannelLoadingState = teamChannelDoc.loadingState;
  const teamChannel = teamChannelDoc.data;

  const teamChannelNotFound = loadFailedOrNotFound(
    teamChannel,
    teamChannelLoadingState,
  );

  const { isUserMember } = FirestoreChannelsContainer.useContainer();
  const { onboardedUserProfiles } = TeamUserProfilesContainer.useContainer();

  const userId = authUser?.uid;
  const teamUserPreferencesDoc = useFirestoreDocument(
    !!userId &&
      firebaseApp
        .firestore()
        .doc(teamUserPreferencesDocPath({ teamId, userId }))
        .withConverter(TeamUserPreferencesConverter),
  );

  const [teamChannelMemberships, teamChannelMembershipsLoadingState] =
    firestoreHooksLoadingState<
      firebase.firestore.QuerySnapshot<TeamChannelMembership>
    >(
      useCollection(
        firebaseApp
          .firestore()
          .collection(teamChannelMembershipsPath({ teamId, teamChannelId }))
          .withConverter(TeamChannelMembershipConverter),
      ),
    );

  const filteredMembers = compact(
    onboardedUserProfiles.filter(
      ({ id: userProfileId }) =>
        !!teamChannelMemberships?.docs.find(({ id }) => id === userProfileId),
    ),
  );

  const userProfiles = sortBy(filteredMembers, (userProfileToSort) =>
    formatFullName(userProfileToSort.data()).toLocaleLowerCase(),
  );

  const loadingState = teamChannelMembershipsLoadingState;

  assertPresent(authUser, {
    because: "<App> waits for user to log in",
  });

  useScreenTracking(screenName, teamId);

  return (
    <IonPage data-cy="TeamChannelDetailPage">
      {(teamChannelNotFound || isErrorState(loadingState)) && (
        <Redirect to={channelsListPageUrl({ teamId })} />
      )}
      <UserOnboardingModal />
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton
              defaultHref={teamChannelMessagesPageUrl({
                teamId,
                teamChannelId,
              })}
              text=""
              data-cy="ChannelDetailPage-BackButton"
            />
          </IonButtons>

          <IonTitle>{i18n.t.TeamChannelDetailPage.title}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className={commonCss.contentContainer}>
        <div className={`ion-padding-top ${commonCss.centerContent}`}>
          <IonAvatar className={commonCss.large}>
            <ChannelAvatarContent
              content={{
                type: "icon",
                icon: teamChannel?.data()?.channelIcon || null,
                iconColor: teamChannel?.data()?.channelIconColor || "sky-blue",
              }}
            />
          </IonAvatar>
          <h1>
            <strong>
              {isDataAvailable(teamChannelDoc) ? (
                teamChannel?.data()?.name
              ) : (
                <IonSkeletonText
                  animated
                  style={{ display: "inline-block", width: 100 }}
                />
              )}
            </strong>
          </h1>
        </div>

        <ChannelOperationButtons
          {...{ loadingState, teamChannelId, teamId, teamUserPreferencesDoc }}
        />

        <IonCard className={commonCss.card}>
          <IonItem lines="none">
            <IonLabel>
              <p>{i18n.t.common.members}</p>
            </IonLabel>
          </IonItem>
          <IonList>
            {isUserMember(teamChannelId) && (
              <UserProfileListItem
                {...{ teamId }}
                userProfile={userProfile}
                loadingState="ready"
                displayAsYou
              />
            )}
            {userProfiles.map(
              (otherUserProfile) =>
                otherUserProfile.id !== userProfile.id && (
                  <UserProfileListItem
                    {...{ teamId, userProfile: otherUserProfile }}
                    key={otherUserProfile.id}
                    loadingState="ready"
                  />
                ),
            )}
          </IonList>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default TeamChannelDetailPage;
