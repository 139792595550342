import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  marketingSitePrivacyPolicyUrl,
  marketingSiteTermsAndConditionsUrl,
} from "@connectedliving/common/lib/utilities/marketingSiteUrlBuilders";
import { IonCard } from "@ionic/react";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import { FirebaseAuth } from "react-firebaseui";
import OnboardingPageLayout from "src/share/OnboardingPageLayout";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import environment from "../utilities/environment";
import css from "./AuthPage.module.css";

const uiConfig: firebaseui.auth.Config = {
  signInFlow: "redirect",
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
    },
  ],
  privacyPolicyUrl: marketingSitePrivacyPolicyUrl(
    environment().marketingSiteConfig(),
    {},
  ),
  tosUrl: marketingSiteTermsAndConditionsUrl(
    environment().marketingSiteConfig(),
    {},
  ),

  callbacks: {
    signInSuccessWithAuthResult: () => {
      const shouldRedirectAfterAuth = false;
      return shouldRedirectAfterAuth;
    },
  },
};

const AuthPage: React.FC = () => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();

  useEffect(() => {
    dontAwait(
      track({
        eventName: "Screen Viewed",
        "screen name": "AuthPage",
        "team id": undefined,
      }),
    );
  }, [track]);

  return (
    <OnboardingPageLayout
      title={i18n.t.AuthPage.title}
      subtitle={i18n.t.AuthPage.subtitle}
      screenName="AuthPage"
    >
      <IonCard>
        <FirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebaseApp.auth()}
          className={css.firebaseUi}
        />
      </IonCard>
    </OnboardingPageLayout>
  );
};

export default AuthPage;
