import { LanguageCode } from "@connectedliving/common/lib/firestore/supportedLanguages";

export const languageNamesByCode: Record<LanguageCode, string | undefined> = {
  de: "German",
  en: "English",
};

export function languageName(languageCode: LanguageCode): string {
  return languageNamesByCode[languageCode] || languageCode;
}
