import { CustomStyles } from "stream-chat-react";

const streamLightModeTheme: CustomStyles = {
  "--bg-gradient-end": "#f7f7f7",
  "--bg-gradient-start": "#fcfcfc",
  "--black": "#000000",
  "--blue-alice": "#e9f2ff",
  "--border": "#00000014",
  "--button-background": "#ffffff",
  "--button-text": "#005fff",
  "--grey": "#7a7a7a",
  "--grey-gainsboro": "#dbdbdb",
  "--grey-whisper": "#ecebeb",
  "--modal-shadow": "#00000099",
  "--overlay": "#00000033",
  "--overlay-dark": "#00000099",
  "--shadow-icon": "#00000040",
  "--targetedMessageBackground": "#fbf4dd",
  "--transparent": "transparent",
  "--white": "#ffffff",
  "--white-smoke": "#f2f2f2",
  "--white-snow": "#fcfcfc",
};
export default streamLightModeTheme;
