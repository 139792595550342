import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";

export default function parseDataURIToBlob(dataUri: string): Blob {
  const dataUriRegexMatch = dataUri.match(/data:([^;,]+)?(;base64)?,(.+)/);
  if (!dataUriRegexMatch) throw new Error("Not a data URI");
  const mimeType: string | undefined = dataUriRegexMatch[1];
  const base64Flag: string | undefined = dataUriRegexMatch[2];
  const data: string = assertPresent.andReturn(dataUriRegexMatch[3], {
    because:
      "As we check if this is a dataUri string, this will definitely be defined",
  });
  if (base64Flag) {
    const bstr = atob(data);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    for (n; n >= 0; n -= 1) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mimeType });
  }
  throw new Error("Not a base64 string as part of the datauri");
}
