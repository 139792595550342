import createDynamicLink from "@connectedliving/common/lib/createDynamicLink";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  marketingSiteGetStartedUrl,
  marketingSiteJoinUrl,
} from "@connectedliving/common/lib/utilities/marketingSiteUrlBuilders";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import { useEffect, useMemo, useState } from "react";
import { createContainer } from "src/utilities/createContainer";
import environment from "../utilities/environment";

export type InviteUrls = {
  n2n: { copyLinkUrl?: string; osShareUrl?: string; qrCodeUrl?: string };
  n2c: { copyLinkUrl?: string; osShareUrl?: string; qrCodeUrl?: string };
};

export function useInviteUrls(initialState?: {
  inviteCode: string;
  teamId: string;
  teamName: string;
  userId: string;
}): InviteUrls {
  const [n2cLinks, setN2cLinks] = useState({});
  const [n2nLinks, setN2nLinks] = useState({});

  const { inviteCode, teamId, teamName, userId } = initialState || {};

  useEffect(() => {
    const apiKey = requireEnvVar("REACT_APP_FIREBASE_API_KEY");
    const domainUriPrefix = requireEnvVar("REACT_APP_DYNAMIC_LINKS_URI_PREFIX");

    const sharedUtmParams = {
      utm_medium: "referral",
      utm_source: "app",
    };
    const pendingOperations = async (): Promise<void> => {
      const [n2nQrUrl, n2nOsUrl, n2nCopyUrl, n2cQrUrl, n2cOsUrl, n2cCopyUrl] =
        await Promise.all([
          ...["qr", "copyLink", "osShare"].map(
            (utmContentCode) =>
              inviteCode &&
              teamId &&
              teamName &&
              createDynamicLink({
                apiKey,
                domainUriPrefix,
                targetUrl: marketingSiteJoinUrl(
                  environment().marketingSiteConfig(),
                  {
                    utm_content: utmContentCode,
                    utm_campaign: "n2n",
                    ...sharedUtmParams,
                    inviteCode,
                    teamId,
                    teamName,
                    referrerTeamId: teamId,
                    referrerUserId: userId,
                  },
                ),
              }),
          ),
          ...["qr", "copyLink", "osShare"].map((utmContentCode) =>
            createDynamicLink({
              apiKey,
              domainUriPrefix,
              targetUrl: marketingSiteGetStartedUrl(
                environment().marketingSiteConfig(),
                {
                  utm_content: utmContentCode,
                  utm_campaign: "n2c",
                  referrerTeamId: teamId,
                  referrerUserId: userId,
                  ...sharedUtmParams,
                },
              ),
            }),
          ),
        ]);

      setN2nLinks({
        copyLinkUrl: n2nCopyUrl,
        qrCodeUrl: n2nQrUrl,
        osShareUrl: n2nOsUrl,
      });
      setN2cLinks({
        copyLinkUrl: n2cCopyUrl,
        qrCodeUrl: n2cQrUrl,
        osShareUrl: n2cOsUrl,
      });
    };
    dontAwait(pendingOperations());
  }, [inviteCode, teamId, teamName, userId]);

  return useMemo(
    () => ({ n2n: n2nLinks, n2c: n2cLinks }),
    [n2nLinks, n2cLinks],
  );
}

const InviteUrlsContainer = createContainer(useInviteUrls);
export default InviteUrlsContainer;
