import { ChannelBackingStorage } from "@connectedliving/common/lib/firestore/ChannelBackingStorage";
import DirectMessageChannelConverter from "@connectedliving/common/lib/firestore/DirectMessageChannelConverter";
import { directMessageChannelPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import streamDirectMessageChannelId from "@connectedliving/common/lib/stream/streamDirectMessageChannelId";
import type firebase from "firebase/compat/app";

export default async function createDirectMessageChannel({
  firebaseApp,
  teamId,
  userId,
  otherUserId,
  backingStorage,
}: {
  firebaseApp: firebase.app.App;
  teamId: string;
  userId: string;
  otherUserId: string;
  backingStorage: ChannelBackingStorage;
}): Promise<void> {
  const batch = firebaseApp.firestore().batch();
  const streamChannelId = streamDirectMessageChannelId(
    teamId,
    userId,
    otherUserId,
  );

  batch.set(
    firebaseApp
      .firestore()
      .doc(directMessageChannelPath({ teamId, userId, otherUserId }))
      .withConverter(DirectMessageChannelConverter),
    {
      teamId,
      createdBy: userId,
      otherUserId,
      streamChannelId,
      userId,
      importedFromStream: false,
      backingStorage,
      latestMessage: null,
      messageTimestamps: [],
    },
  );

  batch.set(
    firebaseApp
      .firestore()
      .doc(
        directMessageChannelPath({
          teamId,
          userId: otherUserId,
          otherUserId: userId,
        }),
      )
      .withConverter(DirectMessageChannelConverter),
    {
      teamId,
      createdBy: userId,
      otherUserId: userId,
      userId: otherUserId,
      streamChannelId,
      importedFromStream: false,
      backingStorage,
      latestMessage: null,
      messageTimestamps: [],
    },
  );

  await batch.commit();
}
