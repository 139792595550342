import {
  teamLocationsPath,
  teamPath,
  teamUserProfilePath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamConverter from "@connectedliving/common/lib/firestore/TeamConverter";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import TeamUserProfileConverter from "@connectedliving/common/lib/firestore/TeamUserProfileConverter";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import {
  channelsListPageUrl,
  directMessageChannelDetailPageUrl,
  directMessageChannelMessagesPageUrl,
  discoverChannelsPageUrl,
  editMyUserProfilePageUrl,
  editTeamPageUrl,
  languageSettingsPageUrl,
  newTeamChannelPageUrl,
  pushNotificationSettingsPageUrl,
  teamAdminPageUrl,
  teamChannelDetailPageUrl,
  teamChannelMessagesPageUrl,
  teamUrl,
  TeamUrlParams,
  userProfilesListPageUrl,
  userSettingsPageUrl,
  viewUserProfilePageUrl,
} from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonRouterOutlet, IonSpinner } from "@ionic/react";
import { Redirect, Route, useParams } from "react-router-dom";
import DirectMessageChannelDetailPage from "src/channels/detail/DirectMessageChannelDetailPage";
import TeamChannelDetailPage from "src/channels/detail/TeamChannelDetailPage";
import DiscoverChannelsPage from "src/channels/discover/DiscoverChannelsPage";
import ChannelsListPage from "src/channels/list/ChannelsListPage";
import DirectMessageChannelMessagesPage from "src/channels/messages/DirectMessageChannelMessagesPage";
import TeamChannelMessagesPage from "src/channels/messages/TeamChannelMessagesPage";
import NewTeamChannelPage from "src/channels/new/NewTeamChannelPage";
import commonCss from "src/common/common.module.css";
import AndroidNotificationChannelsContainer from "src/state/android/AndroidNotificationChannelsContainer";
import DraftMessagesContainer from "src/state/DraftMessagesContainer";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import TeamUserProfilesContainer from "src/state/firebase/TeamUserProfilesContainer";
import InviteUrlsContainer from "src/state/InviteUrlsContainer";
import StreamChannelsCacheContainer from "src/state/stream/StreamChannelsCacheContainer";
import StreamChatContainer from "src/state/stream/StreamChatContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import LastReadTimestampsContainer from "src/state/team/LastReadTimestampsContainer";
import TeamUserPreferencesContainer from "src/state/team/TeamUserPreferencesContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import streamLightModeTheme from "src/theme/streamThemeVariables";
import EditMyUserProfilePage from "src/userProfiles/edit/EditMyUserProfilePage";
import LanguageSettingsPage from "src/userProfiles/edit/LanguageSettingsPage";
import UserProfilesListPage from "src/userProfiles/UserProfilesListPage";
import UserSettingsPage from "src/userProfiles/userSettings/UserSettingsPage";
import ViewUserProfilePage from "src/userProfiles/ViewUserProfilePage";
import { isDataAvailable, isErrored } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import useFirestoreDocument, {
  checkDocumentExistence,
  ExistingDocument,
  isNotFound,
} from "src/utilities/data/useFirestoreDocument";
import useMediaQuery from "src/utilities/react/useMediaQuery";
import { Chat, darkModeTheme } from "stream-chat-react";
import PushNotificationSettingsPage from "../userSettings/PushNotificationsSettingsPage";
import EditTeamPage from "./EditTeamPage";
import TeamAdminPage from "./TeamAdminPage";

const TeamPage: React.FC = () => {
  const { authUser, firebaseApp } = FirebaseAppContainer.useContainer();
  const { userProfileSnapshot } = LoggedInUserProfileContainer.useContainer();
  const { streamChat, streamReady, streamI18n } =
    StreamChatContainer.useContainer();

  const darkMode = useMediaQuery("(prefers-color-scheme: dark)");

  assertPresent(authUser, {
    because: "App waits for user to log in",
  });
  assertPresent(userProfileSnapshot, {
    because: "App waits for user to log in",
  });
  const userProfile = checkDocumentExistence(userProfileSnapshot);
  if (!userProfile.exists)
    throw new Error(
      "It should be impossible to not have a userProfile document at this point",
    );

  const { teamId } = useParams<TeamUrlParams>();
  const team = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(teamPath({ teamId }))
      .withConverter(TeamConverter),
  );
  const teamUserProfile = useFirestoreDocument(
    firebaseApp
      .firestore()
      .doc(
        teamUserProfilePath({
          teamId,
          userId: authUser.uid,
        }),
      )
      .withConverter(TeamUserProfileConverter),
  );

  const teamLocations = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(teamLocationsPath({ teamId }))
      .withConverter(TeamLocationConverter),
  );

  if (isErrored(team)) {
    return <Redirect to="/" />;
  }

  if (
    !isDataAvailable(team) ||
    !isDataAvailable(teamUserProfile) ||
    !isDataAvailable(teamLocations) ||
    !streamReady
  ) {
    return (
      <div className={commonCss.fullscreenContainer}>
        <IonSpinner color="primary" />
      </div>
    );
  }

  const existingTeamLocations = blindCast<
    ExistingDocument<TeamLocation>[],
    "documents in a query result must exist to be returned by the query"
  >(teamLocations.data.docs);

  if (isNotFound(teamUserProfile.data)) {
    throw new Error(
      `Can't find teamUserProfile for user: ${authUser.uid} in team: ${teamId}`,
    );
  }
  if (isNotFound(team.data)) {
    const defaultTeamId = userProfile.data().teamIds[0];
    if (defaultTeamId === teamId) {
      throw new Error(
        `Can't find team with ID ${teamId} despite its presence in UserProfile.teamIds`,
      );
    }

    if (!defaultTeamId) {
      return <Redirect to="/" />;
    }

    return (
      <Redirect
        to={channelsListPageUrl({
          teamId: defaultTeamId,
        })}
      />
    );
  }

  return (
    <Chat
      client={streamChat}
      customStyles={darkMode ? darkModeTheme : streamLightModeTheme}
      i18nInstance={streamI18n}
    >
      <LastReadTimestampsContainer.Provider>
        <StreamChannelsCacheContainer.Provider>
          <TeamContextContainer.Provider
            team={team.data}
            {...{
              authUser,
              userProfile,
              teamUserProfile: teamUserProfile.data,
              teamLocations: existingTeamLocations,
            }}
          >
            <FirestoreChannelsContainer.Provider>
              <TeamUserPreferencesContainer.Provider initialState={{ teamId }}>
                <DraftMessagesContainer.Provider>
                  <TeamUserProfilesContainer.Provider initialState={{ teamId }}>
                    <InviteUrlsContainer.Provider
                      initialState={{
                        inviteCode: team.data.data().inviteCode,
                        teamId,
                        teamName: team.data.data().name,
                        userId: authUser.uid,
                      }}
                    >
                      <AndroidNotificationChannelsContainer.Provider />
                      <IonRouterOutlet>
                        <Route
                          path={editMyUserProfilePageUrl({ teamId })}
                          exact
                        >
                          <EditMyUserProfilePage {...{ teamId }} />
                        </Route>
                        <Route
                          path={viewUserProfilePageUrl({
                            teamId,
                            userProfileId: ":userProfileId",
                          })}
                          exact
                          component={ViewUserProfilePage}
                        />

                        <Route path={userProfilesListPageUrl({ teamId })} exact>
                          <UserProfilesListPage {...{ teamId }} />
                        </Route>

                        <Route path={newTeamChannelPageUrl({ teamId })} exact>
                          <NewTeamChannelPage {...{ teamId }} />
                        </Route>

                        <Route
                          path={teamChannelMessagesPageUrl({
                            teamId,
                            teamChannelId: ":teamChannelId",
                          })}
                          exact
                        >
                          <TeamChannelMessagesPage {...{ teamId }} />
                        </Route>

                        <Route
                          path={teamChannelDetailPageUrl({
                            teamId,
                            teamChannelId: ":teamChannelId",
                          })}
                          exact
                        >
                          <TeamChannelDetailPage {...{ teamId }} />
                        </Route>

                        <Route
                          path={directMessageChannelDetailPageUrl({
                            teamId,
                            authUserId: authUser.uid,
                            otherUserId: ":otherUserId",
                          })}
                          exact
                        >
                          <DirectMessageChannelDetailPage {...{ teamId }} />
                        </Route>

                        <Route
                          path={directMessageChannelMessagesPageUrl({
                            teamId,
                            authUserId: authUser.uid,
                            otherUserId: ":otherUserId",
                          })}
                          exact
                        >
                          <DirectMessageChannelMessagesPage {...{ teamId }} />
                        </Route>

                        <Route path={channelsListPageUrl({ teamId })} exact>
                          <ChannelsListPage {...{ teamId }} />
                        </Route>

                        <Route path={editTeamPageUrl({ teamId })} exact>
                          <EditTeamPage {...{ teamId }} />
                        </Route>

                        <Route path={teamAdminPageUrl({ teamId })} exact>
                          <TeamAdminPage {...{ teamId }} />
                        </Route>

                        <Route path={discoverChannelsPageUrl({ teamId })} exact>
                          <DiscoverChannelsPage {...{ teamId }} />
                        </Route>

                        <Route path={userSettingsPageUrl({ teamId })} exact>
                          <UserSettingsPage {...{ teamId }} />
                        </Route>

                        <Route
                          path={languageSettingsPageUrl({ teamId })}
                          exact
                          component={LanguageSettingsPage}
                        />

                        <Route
                          path={pushNotificationSettingsPageUrl({ teamId })}
                          exact
                          component={PushNotificationSettingsPage}
                        />

                        <Route
                          path={teamUrl({ teamId })}
                          exact
                          render={() => (
                            <Redirect
                              to={channelsListPageUrl({
                                teamId,
                              })}
                            />
                          )}
                        />
                      </IonRouterOutlet>
                    </InviteUrlsContainer.Provider>
                  </TeamUserProfilesContainer.Provider>
                </DraftMessagesContainer.Provider>
              </TeamUserPreferencesContainer.Provider>
            </FirestoreChannelsContainer.Provider>
          </TeamContextContainer.Provider>
        </StreamChannelsCacheContainer.Provider>
      </LastReadTimestampsContainer.Provider>
    </Chat>
  );
};

export default TeamPage;
