import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { directMessageChannelMessagesPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import MessageConverter from "@connectedliving/common/lib/firestore/MessageConverter";
import {
  ActiveUserTrackingProps,
  ChannelTrackingProps,
} from "@connectedliving/common/lib/TrackingEvent";
import { IonContent, IonFooter, IonProgressBar } from "@ionic/react";
import React, { useMemo } from "react";
import FirestoreMessageComposer from "src/channels/messages/firestore/FirestoreMessageComposer";
import FirestoreMessageList from "src/channels/messages/firestore/FirestoreMessageList";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import useChannelScreenBehavior from "../../channels/messages/firestore/useChannelScreenBehavior";
import { FindUserProfileById } from "../../state/FindUserProfileById";
import { isDataAvailable } from "../../utilities/data/Loadable";
import useFirestoreCollection from "../../utilities/data/useFirestoreCollection";
import environment from "../../utilities/environment";
import SafeAreaSpacer from "../../utilities/SafeAreaSpacer";
import { DirectMessageChannelWithContext } from "./DirectMessageChannelWithContext";

type SupportUserConversationMessageListProps = {
  directMessageChannelWithContext: DirectMessageChannelWithContext;
  findUserProfileById: FindUserProfileById;
};

const SupportUserConversationMessageList: React.FC<
  SupportUserConversationMessageListProps
> = ({ directMessageChannelWithContext, findUserProfileById }) => {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  const { teamId, otherUserId } = directMessageChannelWithContext;

  useChannelScreenBehavior({
    channelPath: directMessageChannelWithContext.directMessageChannel.ref.path,
    latestMessage:
      directMessageChannelWithContext.directMessageChannel.data().latestMessage,
  });

  const messagesPath = directMessageChannelMessagesPath({
    teamId,
    userId: authUser?.uid || "",
    otherUserId,
  });
  const messagesCollectionRef = firebaseApp
    .firestore()
    .collection(messagesPath)
    .withConverter(MessageConverter);
  const messagesCollection = useFirestoreCollection(
    messagesCollectionRef.orderBy("createdAt", "asc"),
  );

  const directMessageChannelData =
    directMessageChannelWithContext.directMessageChannel.data();

  const messageListTrackingContext = useMemo(
    (): ChannelTrackingProps & ActiveUserTrackingProps => ({
      "team id": directMessageChannelData.teamId,
      "channel backing storage": directMessageChannelData.backingStorage,
      "channel type": "direct message",
      "channel id": directMessageChannelWithContext.directMessageChannel.id,
      "channel path":
        directMessageChannelWithContext.directMessageChannel.ref.path,
      "channel name": null,
      "channel purpose": null,
      "is support dm channel": [
        directMessageChannelData.userId,
        directMessageChannelData.otherUserId,
      ].includes(environment().supportUserId()),
      "is support user": authUser?.uid === environment().supportUserId(),
    }),
    [
      authUser?.uid,
      directMessageChannelData.backingStorage,
      directMessageChannelData.otherUserId,
      directMessageChannelData.teamId,
      directMessageChannelData.userId,
      directMessageChannelWithContext.directMessageChannel.id,
      directMessageChannelWithContext.directMessageChannel.ref.path,
    ],
  );

  return (
    <>
      <IonContent>
        {messagesCollection.data ? (
          <FirestoreMessageList
            teamId={directMessageChannelWithContext.teamId}
            channelType={FirestoreChannelType.DirectMessage}
            trackingContext={messageListTrackingContext}
            {...{
              messagesCollection: messagesCollection.data,
              findUserProfileById,
            }}
          />
        ) : (
          <IonProgressBar type="indeterminate" />
        )}
      </IonContent>
      <IonFooter>
        <FirestoreMessageComposer
          disabled={!isDataAvailable(messagesCollection)}
          channelPath={
            directMessageChannelWithContext.directMessageChannel.ref.path
          }
        />
        <SafeAreaSpacer position="bottom" />
      </IonFooter>
    </>
  );
};
export default SupportUserConversationMessageList;
