import { compact } from "lodash";

export default function formatFullName({
  firstName,
  lastName,
}: {
  firstName: string | null;
  lastName: string | null;
}): string {
  return compact([firstName, lastName])
    .map((s) => s.trim())
    .join(" ");
}
