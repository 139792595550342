import { Team } from "@connectedliving/common/lib/firestore/Team";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { channelsListPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonCard, IonItem, IonSearchbar } from "@ionic/react";
import firebase from "firebase/compat";
import Fuse from "fuse.js";
import { useCallback, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import commonCss from "../../common/common.module.css";
import css from "./TeamSwitcher.module.css";

export type TeamSwitcherProps = {
  screenName: string;
};

type TeamSearchEntry = {
  queryString: string;
  value: firebase.firestore.QueryDocumentSnapshot<Team>;
};

function makeTeamSearchEntries(
  teams: firebase.firestore.QueryDocumentSnapshot<Team>[],
): TeamSearchEntry[] {
  return teams.map((team) => ({
    queryString: `${team.data().name}`,
    value: team,
  }));
}

export type TeamListItemProps = {
  team: firebase.firestore.QueryDocumentSnapshot<Team>;
  currentTeam: firebase.firestore.QueryDocumentSnapshot<Team>;
  screenName: string;
};

const TeamListItem: React.FC<TeamListItemProps> = ({
  team,
  currentTeam,
  screenName,
}) => {
  const { track } = MixpanelClientContainer.useContainer();
  const isCurrentTeam = team.id === currentTeam.id;

  const routerLink = isCurrentTeam
    ? undefined
    : channelsListPageUrl({ teamId: team.id });

  return (
    <IonItem
      routerLink={routerLink}
      routerDirection="forward"
      onClick={() => {
        if (isCurrentTeam) return;
        dontAwait(
          track({
            eventName: "Team Switched",
            "team id": team.id,
            "team name": team.data().name,
            "previous team id": currentTeam.id,
            "previous team name": currentTeam.data().name,
            "screen name": screenName,
          }),
        );
      }}
    >
      {team.data().name}
    </IonItem>
  );
};

const TeamSwitcher: React.FC<TeamSwitcherProps> = ({ screenName }) => {
  const { teams } = LoggedInUserProfileContainer.useContainer();
  const { teamId, team: currentTeam } = TeamContextContainer.useContainer();
  const i18n = I18nContainer.useContainer();

  const [queryString, setQueryString] = useState<string>("");

  const [fuse] = useState(
    () =>
      new Fuse(makeTeamSearchEntries(teams), {
        keys: ["queryString"],
        useExtendedSearch: true,
        threshold: 0.2,
      }),
  );

  useEffect(() => {
    fuse.setCollection(makeTeamSearchEntries(teams));
  }, [fuse, teamId, teams]);

  const filteredTeams =
    queryString === ""
      ? teams
      : fuse.search(queryString).map(({ item }) => item.value);

  const sortedTeams = filteredTeams.sort((teamA, teamB) =>
    teamA.data().name.localeCompare(teamB.data().name),
  );

  const VirtualizedItem = useCallback(
    (_index: number, team: firebase.firestore.QueryDocumentSnapshot<Team>) => (
      <TeamListItem
        currentTeam={currentTeam}
        screenName={screenName}
        team={team}
      />
    ),
    [currentTeam, screenName],
  );

  const maxVisibleTeams = 3;
  let teamsList;
  if (sortedTeams.length > maxVisibleTeams) {
    teamsList = (
      <Virtuoso
        style={{ height: "150px" }}
        data={sortedTeams}
        itemContent={VirtualizedItem}
      />
    );
  } else {
    teamsList = sortedTeams.map((team) => (
      <TeamListItem
        key={team.id}
        currentTeam={currentTeam}
        screenName={screenName}
        team={team}
      />
    ));
  }

  return (
    <IonCard className={`${commonCss.card} ${css.cardOverride}`}>
      {teams.length > maxVisibleTeams && (
        <IonSearchbar
          placeholder={i18n.t.TeamSwitcher.searchbarPlaceholder}
          animated
          value={queryString}
          onIonChange={(e) => {
            setQueryString(e.detail.value || "");
          }}
        />
      )}

      {teamsList}
    </IonCard>
  );
};

export default TeamSwitcher;
