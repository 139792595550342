import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import renderSvgQrCode from "@connectedliving/common/lib/utilities/renderSvgQrCode";
import * as Sentry from "@sentry/react";
import qrcodeLib from "qrcode";
import React, { useEffect, useState } from "react";

export type QrCodeProps = { url: string };

const QrCode: React.FC<QrCodeProps> = ({ url }) => {
  const [qrCodeData, setQrCodeData] = useState<qrcodeLib.QRCode | null>(null);

  useEffect(() => {
    async function run() {
      try {
        const result = await qrcodeLib.create(url, {
          errorCorrectionLevel: "M",
        });
        setQrCodeData(result);
      } catch (err) {
        Sentry.captureException(err);
      }
    }

    dontAwait(run());
  }, [url]);

  if (!qrCodeData) return null;

  const magenta = "#d06cb4";
  const base64Data = btoa(`${renderSvgQrCode(qrCodeData, { color: magenta })}`);

  return (
    <img
      className="ion-padding"
      alt="QR code"
      src={`data:image/svg+xml;base64,${base64Data}`}
    />
  );
};

export default QrCode;
