import { TeamUserProfile } from "@connectedliving/common/lib/firestore/TeamUserProfile";
import { VisibleSuggestionBanners } from "@connectedliving/common/lib/firestore/VisibleSuggestionBanners";
import updateFirestoreDocument from "@connectedliving/common/lib/utilities/firestore/updateFirestoreDocument";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { MixpanelClient } from "src/state/mixpanel/MixpanelContainer";
import { ExistingDocument } from "src/utilities/data/useFirestoreDocument";

export default async function dismissBanner({
  bannerName,
  teamUserProfile,
  track,
  teamId,
  screenName,
}: {
  bannerName: keyof VisibleSuggestionBanners;
  teamUserProfile: ExistingDocument<TeamUserProfile>;
  track: MixpanelClient["track"];
  teamId: string;
  screenName: string;
}): Promise<unknown> {
  const teamUserProfileData = teamUserProfile.data();
  const { visibleSuggestionBanners } = teamUserProfileData;
  visibleSuggestionBanners[bannerName] = false;

  dontAwait(
    track({
      eventName: "Suggestion Card Dismissed",
      "screen name": screenName,
      "team id": teamId,
      "card name": bannerName,
    }),
  );

  return updateFirestoreDocument(teamUserProfile, {
    visibleSuggestionBanners,
  });
}
