import { useCallback, useMemo, useState } from "react";
import { createContainer } from "src/utilities/createContainer";

export type InitialUrlSearchParams = {
  initialUrlSearchParams: URLSearchParams;
  resetInitialUrlSearchParams(): void;
};

export function useInitialUrlSearchParams(): InitialUrlSearchParams {
  const [initialUrlSearchParams, setInitialUrlSearchParams] = useState(() => {
    const url = new URL(window.location.toString());
    const searchParams = new URLSearchParams(url.searchParams);

    return searchParams;
  });

  const resetInitialUrlSearchParams = useCallback(() => {
    setInitialUrlSearchParams(new URLSearchParams());
  }, []);

  return useMemo(
    () => ({
      initialUrlSearchParams,
      resetInitialUrlSearchParams,
    }),
    [initialUrlSearchParams, resetInitialUrlSearchParams],
  );
}

const InitialUrlSearchParamsContainer = createContainer(
  useInitialUrlSearchParams,
);
export default InitialUrlSearchParamsContainer;
