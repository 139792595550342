import { getList, isString, StrictDocumentData } from "./dataHelpers";

export type TeamUserPreferences = Readonly<{
  blockedUserIds: string[];
  mutedTeamChannelIds: string[];
  remoteMutedTeamChannelIds: string[];
}>;

export function teamUserPreferencesToFirestoreData(
  teamUserPreferences: TeamUserPreferences,
): TeamUserPreferences {
  return teamUserPreferences;
}

export function firestoreDataToTeamUserPreferences(
  data: StrictDocumentData,
): TeamUserPreferences {
  return {
    blockedUserIds: getList(data.blockedUserIds, { element: isString }),
    mutedTeamChannelIds: getList(data.mutedTeamChannelIds, {
      element: isString,
    }),
    remoteMutedTeamChannelIds: getList(data.remoteMutedTeamChannelIds, {
      element: isString,
    }),
  };
}
