import { isString } from "lodash";
import { getDictionary, getString, StrictDocumentData } from "./dataHelpers";

export type Versions = {
  apiVersion: string;
  minimumSupportedClientVersions: Record<string, string | undefined>;
};

export function versionsToFirestoreData(versions: Versions): Versions {
  return versions;
}

export function firestoreDataToVersions(data: StrictDocumentData): Versions {
  return {
    apiVersion: getString(data.apiVersion),
    minimumSupportedClientVersions: getDictionary(
      data.minimumSupportedClientVersions,
      { isKey: isString, isValue: isString, fallback: {} },
    ),
  };
}
