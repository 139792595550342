import { LoadingState } from "src/utilities/LoadingState";

function calculateLoadingState(
  data: unknown,
  isLoading: boolean,
  error: Error | undefined,
): LoadingState {
  if (isLoading) {
    return !data ? "initialLoad" : "updating";
  }
  if (error) {
    return { error };
  }

  if (!data) {
    return "initialLoad";
  }
  return "ready";
}

export default function firestoreHooksLoadingState<T>([
  data,
  isLoading,
  error,
]: [data: T | undefined, isLoading: boolean, error: Error | undefined]): [
  T | undefined,
  LoadingState,
] {
  return [data, calculateLoadingState(data, isLoading, error)];
}
