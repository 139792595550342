/* eslint-disable @typescript-eslint/naming-convention */
import { SupportedCountryCode } from "@connectedliving/common/lib/firestore/supportedCountries";
import { TeamLocation } from "@connectedliving/common/lib/firestore/TeamLocation";
import { makeTimestampValueFromDate } from "@connectedliving/common/lib/firestore/TimestampValue";
import blindCast from "@connectedliving/common/lib/utilities/lang/blindCast";
import { difference } from "lodash";
import getGeocodeAddressComponentDict, {
  GeocodeAddressComponentType,
  geoCodeAddressComponentTypes,
} from "./getGeocodeAddressComponentDict";

export default function validateGeocodeResult(
  geocodeResult: google.maps.GeocoderResult,
): {
  validTeamLocation: TeamLocation | null;
  errors: GeocodeAddressComponentType[];
} {
  const geocodeAddressComponentDict =
    getGeocodeAddressComponentDict(geocodeResult);

  const differentKeys = difference<GeocodeAddressComponentType>(
    geoCodeAddressComponentTypes,
    blindCast<
      GeocodeAddressComponentType[],
      "geocodeComponentDict only has GeocodeAddressComponentType as keys"
    >(Object.keys(geocodeAddressComponentDict)),
  );

  if (differentKeys.length > 0) {
    return {
      validTeamLocation: null,
      errors: differentKeys,
    };
  }

  const {
    country,
    postal_code,
    street_number,
    route,
    locality,
    administrative_area_level_1,
  } = blindCast<
    Record<GeocodeAddressComponentType, google.maps.GeocoderAddressComponent>,
    "the difference in keys is null, hence all keys must be present"
  >(geocodeAddressComponentDict);

  const teamLocation: TeamLocation = {
    city: locality.long_name,
    country: blindCast<
      SupportedCountryCode,
      "we get a two letter shortcode and limit our search results to germany"
    >(country.short_name.toLowerCase()),
    postcode: postal_code.short_name,
    state: administrative_area_level_1.short_name.toLowerCase(),
    streetNumber: street_number.short_name,
    streetName: route.long_name,
    googlePlaceId: geocodeResult.place_id,
    googlePlaceIdRetrievedAt: makeTimestampValueFromDate(new Date(Date.now())),
  };

  return {
    validTeamLocation: teamLocation,
    errors: [],
  };
}
