import { TrackingEvent } from "@connectedliving/common/lib/TrackingEvent";
import { prepareMixpanelData } from "@connectedliving/common/lib/utilities/mixpanel/prepareMixpanelData";
import requireEnvVar from "@connectedliving/common/lib/utilities/requireEnvVar";
import * as Sentry from "@sentry/react";
import { isObject } from "lodash";
import Mixpanel, {
  Callback,
  Mixpanel as MixpanelType,
  Response as MixpanelCallbackResponse,
} from "mixpanel-browser";
import { useCallback, useMemo, useState } from "react";
import { createContainer } from "src/utilities/createContainer";
import getNativeAppVersion from "../../utilities/capacitor/getNativeAppVersion";
import environment from "../../utilities/environment";
import I18nContainer from "../i18n/I18nContainer";
import InitialUrlSearchParamsContainer from "../InitialUrlSearchParamsContainer";
import makeMixpanelClientNullObject from "./makeMixpanelClientNullObject";

export type MixpanelClient = {
  mixpanelClient: MixpanelType;
  track(event: TrackingEvent): Promise<void>;
  performLogOut(): Promise<void>;
};

type MixpanelClientConfig =
  | {
      enabled: true;
      apiHost: string;
      projectToken: string;
    }
  | { enabled: false };

function getConfig(): MixpanelClientConfig {
  const apiHost = process.env.REACT_APP_MIXPANEL_PROXY_DOMAIN;
  const projectToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

  if (projectToken && apiHost) {
    if (process.env.REACT_APP_USE_EMULATOR_FUNCTIONS) {
      const emulatorHost = requireEnvVar("REACT_APP_FIREBASE_EMULATOR_HOST");
      const emulatorPort = requireEnvVar("REACT_APP_FUNCTIONS_EMULATOR_PORT");
      const region = requireEnvVar("REACT_APP_FIREBASE_CLOUD_FUNCTIONS_REGION");
      const projectId = requireEnvVar("REACT_APP_FIREBASE_PROJECT_ID");
      const mpProxyPath = new URL(apiHost).pathname;
      const emulatorMixpanelApiHost = `http://${emulatorHost}:${emulatorPort}/${projectId}/${region}${mpProxyPath}`;

      return { enabled: true, apiHost: emulatorMixpanelApiHost, projectToken };
    }

    return { enabled: true, apiHost, projectToken };
  }

  return { enabled: false };
}

let appVersionProperties: Record<string, string> = {
  "deploy target": environment().deployTarget(),
  "release version": environment().releaseVersion()?.slice(0, 7),
};

getNativeAppVersion().then((appVersion) => {
  if (!appVersion) return;
  appVersionProperties = {
    ...appVersionProperties,
    "native app version": appVersion.version,
    "native app build": appVersion.build,
    "native app name": appVersion.name,
    "native app identifier": appVersion.id,
    "native app platform": appVersion.platform,
    "native app tag": appVersion.tag,
  };
});

export function useMixpanelClientContainer(): MixpanelClient {
  const { initialUrlSearchParams } =
    InitialUrlSearchParamsContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const [config] = useState(getConfig());
  const [mixpanelClient] = useState<MixpanelType>(() => {
    if (
      config.enabled === false &&
      process.env.CONNECTED_LIVING_ENVIRONMENT === "production"
    ) {
      throw new Error("Mixpanel Client configuration missing or invalid");
    }

    if (config.enabled === false) {
      return makeMixpanelClientNullObject();
    }

    Mixpanel.init(config.projectToken, {
      api_host: config.apiHost,
      persistence: "localStorage",
      verbose: true,
      ignore_dnt: true,
    });

    const initialDistinctId = initialUrlSearchParams.get("distinctId");
    if (initialDistinctId) {
      // Overwrite Mixpanel's initial random-generated distinct ID
      Mixpanel.register({ distinct_id: initialDistinctId });
    }

    return Mixpanel;
  });

  const track = useCallback(
    async (event: TrackingEvent): Promise<void> => {
      const { eventName, ...eventProperties } = event;
      const distinctId = mixpanelClient.get_distinct_id();

      return new Promise((resolve, reject) => {
        const callback: Callback = (response: MixpanelCallbackResponse) => {
          if (isObject(response) && response.status === 0) {
            reject(response.error);
          } else if (response === 0) {
            reject(new Error("Backend error for Mixpanel tracking"));
          } else {
            resolve();
          }
        };

        mixpanelClient.track(
          eventName,
          {
            "user id": distinctId,
            "browser languages": navigator.languages,
            "current locale": i18n.currentLocale,
            $user_id: distinctId,
            ...appVersionProperties,
            ...prepareMixpanelData({ ...eventProperties }),
          },
          callback,
        );
      });
    },
    [i18n.currentLocale, mixpanelClient],
  );

  const performLogOut = useCallback(async () => {
    try {
      await track({
        eventName: "User Logged Out",
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    mixpanelClient.reset();
  }, [mixpanelClient, track]);

  return useMemo(
    () => ({
      mixpanelClient,
      track,
      performLogOut,
    }),
    [mixpanelClient, performLogOut, track],
  );
}

const MixpanelClientContainer = createContainer(useMixpanelClientContainer);
export default MixpanelClientContainer;
