import { getNumber, StrictDocumentData } from "./dataHelpers";

export type Dimensions = {
  width: number;
  height: number;
};

export function firestoreDataToDimensions(
  data: StrictDocumentData,
): Dimensions {
  return {
    width: getNumber(data.width),
    height: getNumber(data.height),
  };
}
