import {
  IonButton,
  IonButtons,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonContent,
  IonIcon,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import React from "react";
import commonCss from "src/common/common.module.css";
import LocaleSwitcher from "src/common/LocaleSwitcher";
import OnboardingActionSheetButton from "src/common/OnboardingActionSheetButton";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";
import SafeAreaSpacer from "src/utilities/SafeAreaSpacer";
import css from "./OnboardingPageLayout.module.css";

type OnboardingPageLayoutProps = {
  backButtonUrl?: string;
  title?: React.ReactNode;
  preTitle?: string | false;
  subtitle?: string;
  screenName: string;
  iconProps?: { iconName: string; iconOnClickFn: () => void };
};

const OnboardingPageLayout: React.FC<OnboardingPageLayoutProps> = ({
  backButtonUrl,
  title,
  subtitle,
  preTitle,
  children,
  screenName,
  iconProps,
}) => {
  const i18n = I18nContainer.useContainer();
  const { authUser } = FirebaseAppContainer.useContainer();
  return (
    <IonPage id="main-content" className="ion-light-mode" data-cy={screenName}>
      <IonContent className={css.background}>
        <SafeAreaSpacer position="top" />

        <IonToolbar className={css.toolbar}>
          {backButtonUrl && (
            <IonButtons slot="start">
              <IonButton
                routerLink={backButtonUrl}
                routerDirection="back"
                color="light"
                data-cy={`OnboardingPageLayout-${screenName}-backButton`}
              >
                <IonIcon slot="start" icon={chevronBack} />
                <span className={css.button}>{i18n.t.common.back}</span>
              </IonButton>
            </IonButtons>
          )}
          <IonButtons slot="primary">
            {authUser ? (
              <OnboardingActionSheetButton
                authUser={authUser}
                screenName={screenName}
              />
            ) : (
              <LocaleSwitcher screenName={screenName} />
            )}
          </IonButtons>
        </IonToolbar>

        <div className={commonCss.onboardingContentContainer}>
          <div className={`${commonCss.limitMaxWidth}`}>
            <IonCardHeader color="white">
              {preTitle && (
                <IonCardSubtitle className="ion-text-uppercase">
                  {preTitle}
                </IonCardSubtitle>
              )}
              {iconProps ? (
                <div className={css.header}>
                  <IonCardTitle
                    className={`${css.title}`}
                    data-cy={`OnboardingPageLayout-${screenName}-title`}
                  >
                    {title}
                  </IonCardTitle>
                  <IonIcon
                    className={css.icon}
                    icon={iconProps.iconName}
                    onClick={iconProps.iconOnClickFn}
                  />
                </div>
              ) : (
                title && (
                  <IonCardTitle
                    className={`${css.title}`}
                    data-cy={`OnboardingPageLayout-${screenName}-title`}
                  >
                    {title}
                  </IonCardTitle>
                )
              )}
            </IonCardHeader>
            {subtitle && (
              <p className="ion-padding-horizontal ion-no-margin">{subtitle}</p>
            )}
            {children}
          </div>
        </div>
        <SafeAreaSpacer position="bottom" />
      </IonContent>
    </IonPage>
  );
};

export default OnboardingPageLayout;
