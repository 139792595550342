import countUnreadMessages from "@connectedliving/common/lib/domain/countUnreadMessages";
import isFirestoreBackedChannel from "@connectedliving/common/lib/domain/isFirestoreBackedChannel";
import isStreamBackedChannel from "@connectedliving/common/lib/domain/isStreamBackedChannel";
import { DirectMessageChannel } from "@connectedliving/common/lib/firestore/DirectMessageChannel";
import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import {
  parseDirectMessageChannelPath,
  parseTeamChannelPath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import streamDirectMessageChannelId from "@connectedliving/common/lib/stream/streamDirectMessageChannelId";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import { dataAvailable, LoadingState } from "src/utilities/LoadingState";
import useForceUpdate from "src/utilities/react/useForceUpdate";
import { EventTypes } from "stream-chat";
import FirestoreChannelsContainer from "../team/FirestoreChannelsContainer";
import LastReadTimestampsContainer, {
  LastReadTimestampsValue,
} from "../team/LastReadTimestampsContainer";
import TeamContextContainer from "../TeamContextContainer";
import StreamChannelsCacheContainer, {
  StreamChannelWithLoadingState,
} from "./StreamChannelsCacheContainer";
import StreamChatContainer from "./StreamChatContainer";

export type UnreadCountInOtherChannels = {
  loadingState: LoadingState;
  unreadCountInOtherChannels: number;
};

export function countUnreadMessagesInOtherStreamChannels(
  channels: StreamChannelWithLoadingState[],
  currentChannelCid: string,
): number {
  const otherChannels = channels.filter(
    (streamChannel) => streamChannel.value.cid !== currentChannelCid,
  );
  let unreadCount = 0;
  otherChannels.forEach((streamChannel) => {
    if (dataAvailable(streamChannel.loadingState)) {
      unreadCount += streamChannel.value.countUnread(
        streamChannel.value.lastRead(),
      );
    }
  });
  return unreadCount;
}

export function countUnreadMessagesInFirestoreChannels({
  channels,
  getChannelLastReadTimestamp,
}: {
  channels: (
    | firebase.firestore.QueryDocumentSnapshot<TeamChannel>
    | firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>
  )[];
  getChannelLastReadTimestamp: LastReadTimestampsValue["getChannelLastReadTimestamp"];
}): number {
  let unreadCount = 0;
  channels.forEach((channel) => {
    const channelLastReadTimestamp = getChannelLastReadTimestamp(
      channel.ref.path,
    );

    const channelType = channel.ref.path.match("DirectMessageChannels")
      ? FirestoreChannelType.DirectMessage
      : FirestoreChannelType.Team;

    unreadCount += countUnreadMessages({
      messageTimestamps: channel.data().messageTimestamps,
      channelLastReadTimestamp,
      channelType,
    });
  });
  return unreadCount;
}

export default function useUnreadCountInOtherChannels(
  currentChannelType: StreamChannelType,
  currentChannelPath: string,
): UnreadCountInOtherChannels {
  const { joinedTeamChannels, directMessageChannels, loadingState } =
    FirestoreChannelsContainer.useContainer();
  const { streamChat } = StreamChatContainer.useContainer();
  const { authUser, teamId } = TeamContextContainer.useContainer();
  const { getStreamChannel } = StreamChannelsCacheContainer.useContainer();
  const { getChannelLastReadTimestamp } =
    LastReadTimestampsContainer.useContainer();
  const forceUpdate = useForceUpdate();

  let streamChannelId: string;
  if (currentChannelType === StreamChannelType.Team) {
    streamChannelId = parseTeamChannelPath(currentChannelPath).teamChannelId;
  } else {
    const { userId, otherUserId } =
      parseDirectMessageChannelPath(currentChannelPath);
    streamChannelId = streamDirectMessageChannelId(teamId, userId, otherUserId);
  }

  function isNotCurrentChannel(
    channel:
      | firebase.firestore.QueryDocumentSnapshot<TeamChannel>
      | firebase.firestore.QueryDocumentSnapshot<DirectMessageChannel>,
  ): boolean {
    return channel.ref.path !== currentChannelPath;
  }

  const streamTeamChannels = joinedTeamChannels
    .filter(isNotCurrentChannel)
    .filter(isStreamBackedChannel)
    .map((teamChannel) =>
      getStreamChannel(StreamChannelType.Team, teamChannel.id),
    );
  const streamDirectMessageChannels = directMessageChannels
    .filter(isNotCurrentChannel)
    .filter(isStreamBackedChannel)
    .map((directMessageChannel) => {
      if (directMessageChannel.data().streamChannelId) {
        return getStreamChannel(
          StreamChannelType.DirectMessage,
          directMessageChannel.data().streamChannelId,
        );
      }

      return getStreamChannel(
        StreamChannelType.DirectMessage,
        streamDirectMessageChannelId(
          teamId,
          authUser.uid,
          directMessageChannel.data().otherUserId,
        ),
      );
    });

  const currentChannelCid = `${currentChannelType}:${streamChannelId}`;

  useEffect(() => {
    const subscribedEvents: EventTypes[] = [
      "message.new",
      "message.deleted",
      "message.read",
      "channel.truncated",
      "notification.channel_deleted",
      "notification.removed_from_channel",
      "channel.unmuted",
    ];
    subscribedEvents.forEach((event) => {
      streamChat.on(event, forceUpdate);
    });

    return () => {
      subscribedEvents.forEach((event) => {
        streamChat.off(event, forceUpdate);
      });
    };
  });

  const unreadCountInStreamChannels = countUnreadMessagesInOtherStreamChannels(
    [...streamTeamChannels, ...streamDirectMessageChannels],
    currentChannelCid,
  );
  const unreadCountInFirestoreChannels = countUnreadMessagesInFirestoreChannels(
    {
      channels: [...joinedTeamChannels, ...directMessageChannels]
        .filter(isNotCurrentChannel)
        .filter(isFirestoreBackedChannel),
      getChannelLastReadTimestamp,
    },
  );

  return {
    loadingState,
    unreadCountInOtherChannels:
      unreadCountInStreamChannels + unreadCountInFirestoreChannels,
  };
}
