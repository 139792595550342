import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import formatFullName from "@connectedliving/common/lib/utilities/formatFullName";
import { ChannelTrackingContext } from "@connectedliving/common/lib/utilities/tracking/channelTrackingProps";
import {
  IonBadge,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonMenuButton,
  IonPage,
  IonProgressBar,
  IonText,
  IonToolbar,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { menuOutline, personCircleOutline } from "ionicons/icons";
import React, { useMemo } from "react";
import LoggedInUserProfileContainer from "src/state/firebase/LoggedInUserProfileContainer";
import TranslationConfigContainer from "src/state/TranslationConfigContainer";
import { ExistingDocument } from "src/utilities/data/useFirestoreDocument";
import { LoadingState } from "src/utilities/LoadingState";
import MessageTranslationButton from "../../channels/messages/MessageTranslationButton";
import { DirectMessageChannelWithContext } from "./DirectMessageChannelWithContext";
import SupportUserConversationMessageList from "./SupportUserConversationMessageList";
import css from "./SupportUserConversationsPage.module.css";

type SupportUserConversationsPageProps = {
  activeDirectMessageChannelWithContext:
    | DirectMessageChannelWithContext
    | undefined;
  userProfile: ExistingDocument<UserProfile>;
  authUser: firebase.User;
  otherChannelsUnreadCount: number;
  findUserProfileById: (
    userProfileId: string,
  ) => firebase.firestore.QueryDocumentSnapshot<UserProfile> | undefined;
  channelLoadingState: LoadingState;
};

const SupportUserConversationsPage: React.FC<
  SupportUserConversationsPageProps
> = ({
  activeDirectMessageChannelWithContext,
  userProfile,
  authUser,
  otherChannelsUnreadCount,
  findUserProfileById,
  channelLoadingState,
}) => {
  const { findTeamById } = LoggedInUserProfileContainer.useContainer();
  const { showTranslation, setShowTranslation } =
    TranslationConfigContainer.useContainer();
  const teamId = activeDirectMessageChannelWithContext?.teamId ?? null;
  const otherUserProfileData =
    activeDirectMessageChannelWithContext?.otherUserProfile?.data();
  const otherUserProfileFormattedName = otherUserProfileData
    ? formatFullName(otherUserProfileData)
    : undefined;
  const team = teamId ? findTeamById(teamId) : null;
  const teamName = team ? team.data().name : null;

  const channelTrackingContext: ChannelTrackingContext | undefined = useMemo(
    () =>
      activeDirectMessageChannelWithContext && {
        type: FirestoreChannelType.DirectMessage,
        id: activeDirectMessageChannelWithContext.directMessageChannel.ref.id,
        path: activeDirectMessageChannelWithContext.directMessageChannel.ref
          .path,
        data: activeDirectMessageChannelWithContext.directMessageChannel.data(),
        userIds: [
          authUser.uid,
          activeDirectMessageChannelWithContext.directMessageChannel.data()
            .otherUserId,
        ],
      },
    [authUser.uid, activeDirectMessageChannelWithContext],
  );

  return (
    <IonPage
      className="ion-justify-content-center ion-align-items-center"
      data-cy="SupportUserConversationsPage"
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton slot="start" menu="conversationsList">
              <IonIcon icon={menuOutline} size="large" />
              {otherChannelsUnreadCount > 0 && (
                <IonBadge className={css.menuUnreadCount} color="primary">
                  {otherChannelsUnreadCount}
                </IonBadge>
              )}
            </IonMenuButton>
          </IonButtons>
          <IonButtons className="ion-margin-horizontal">
            {teamId &&
              otherUserProfileFormattedName &&
              activeDirectMessageChannelWithContext?.otherUserProfile && (
                <IonLabel>{otherUserProfileFormattedName}</IonLabel>
              )}
            {teamId && teamName && (
              <>
                <span className="ion-hide-md-down">&nbsp;—&nbsp;</span>
                <IonLabel className="ion-hide-md-down">{teamName}</IonLabel>
              </>
            )}
          </IonButtons>
          <IonButtons slot="end">
            {teamId &&
              userProfile.data().language &&
              activeDirectMessageChannelWithContext &&
              channelTrackingContext && (
                <MessageTranslationButton
                  onShowTranslationChange={setShowTranslation}
                  showTranslation={showTranslation}
                  teamId={teamId}
                  userProfile={userProfile.data()}
                  {...{ channelTrackingContext }}
                />
              )}
            {teamId && (
              <IonMenuButton menu="details">
                <IonIcon icon={personCircleOutline} size="large" />
              </IonMenuButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {/* eslint-disable-next-line no-nested-ternary */}
      {activeDirectMessageChannelWithContext ? (
        <SupportUserConversationMessageList
          directMessageChannelWithContext={
            activeDirectMessageChannelWithContext
          }
          {...{ findUserProfileById }}
        />
      ) : channelLoadingState === "ready" ? (
        <IonContent>
          <p className="ion-text-center">
            <IonText color="medium">Conversation not found</IonText>
          </p>
        </IonContent>
      ) : (
        <IonContent>
          <IonProgressBar type="indeterminate" />
        </IonContent>
      )}
    </IonPage>
  );
};
export default SupportUserConversationsPage;
