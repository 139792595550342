import { difference } from "lodash";
import { utmParamKeys, UtmParams } from "./UtmParams";

export default function isUtmParams(value: unknown): value is UtmParams {
  return (
    typeof value === "object" &&
    value !== null &&
    difference(Object.keys(value), utmParamKeys).length === 0
  );
}
