import { FirestoreMessageListItemSkeleton } from "./FirestoreMessageListItem";

export type LoadingSkeletonProps = {};

const FirestoreMessageListLoadingScreen: React.FC<
  LoadingSkeletonProps
> = () => (
  <>
    <hr />
    <FirestoreMessageListItemSkeleton />
    <FirestoreMessageListItemSkeleton hideAvatar textWidth={280} />
    <FirestoreMessageListItemSkeleton hideAvatar textWidth={170} />
    <hr />
    <FirestoreMessageListItemSkeleton fromSelf />
    <FirestoreMessageListItemSkeleton fromSelf hideAvatar textWidth={100} />
  </>
);

export default FirestoreMessageListLoadingScreen;
