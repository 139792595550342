import firebase from "firebase/compat/app";
import isObjectInstance from "../lang/isObjectInstance";

// https://firebase.google.com/docs/reference/node/firebase.firestore#firestoreerrorcode
const firestoreErrorCodes: firebase.firestore.FirestoreErrorCode[] = [
  "cancelled",
  "unknown",
  "invalid-argument",
  "deadline-exceeded",
  "not-found",
  "already-exists",
  "permission-denied",
  "resource-exhausted",
  "failed-precondition",
  "aborted",
  "out-of-range",
  "unimplemented",
  "internal",
  "unavailable",
  "data-loss",
  "unauthenticated",
];

export default function isFirestoreError(
  value: unknown,
): value is firebase.firestore.FirestoreError {
  return (
    isObjectInstance(value) &&
    "code" in value &&
    (firestoreErrorCodes as unknown[]).includes(value.code)
  );
}
