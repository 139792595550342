import {
  Camera,
  CameraResultType,
  CameraSource,
  Photo,
} from "@capacitor/camera";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { JSX as IonicJSX } from "@ionic/core/components";
import {
  IonActionSheet,
  IonButton,
  IonSpinner,
  isPlatform,
} from "@ionic/react";
import { camera, close, imagesOutline } from "ionicons/icons";
import { useRef, useState } from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import parseDataURIToBlob from "../utilities/parseDataUriToBlob";

type ImagePickerProps = {
  text: string;
  onFilePicked: (file: File | Blob | undefined) => Promise<void>;
  screenName: string;
  teamId: string;
  buttonFill?: IonicJSX.IonButton["fill"];
};

type DataUrlCameraResultType = Required<
  Pick<Photo, "dataUrl" | "format" | "saved">
> &
  Pick<Photo, "exif">;
function isDataUrlCameraResultType(
  value: Photo,
): value is DataUrlCameraResultType {
  return !!value.dataUrl;
}

async function getImage(
  type: keyof typeof CameraSource,
): Promise<DataUrlCameraResultType> {
  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: false,
    resultType: CameraResultType.DataUrl,
    source: CameraSource[type],
  });
  if (isDataUrlCameraResultType(image)) {
    return image;
  }
  throw new Error(
    "It should not be possible to have no dataUrl when choosing CameraResultType.DataUrl",
  );
}

const ImagePicker: React.FC<ImagePickerProps> = ({
  text,
  onFilePicked,
  screenName,
  teamId,
  buttonFill,
}) => {
  const { track } = MixpanelClientContainer.useContainer();
  const fileInput = useRef<HTMLInputElement>(null);
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const i18n = I18nContainer.useContainer();

  async function onInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUploadInProgress(true);
    let platform: "ios" | "android" | "other";
    if (isPlatform("ios")) platform = "ios";
    else if (isPlatform("android")) platform = "android";
    else platform = "other";

    if (e.target.files) {
      await onFilePicked(e.target.files[0]);
      dontAwait(
        track({
          eventName: "Image Uploaded",
          "screen name": screenName,
          "team id": teamId,
          platform,
          source: "unknown",
        }),
      );
    } else {
      await onFilePicked(undefined);
    }
    setUploadInProgress(false);
  }

  const onButtonClicked = async () => {
    if (isPlatform("android")) {
      setShowActionSheet(true);
    } else if (fileInput && fileInput.current && !isPlatform("android")) {
      fileInput.current.click();
    }
    dontAwait(
      track({
        eventName: "Button Clicked",
        "screen name": screenName,
        "team id": teamId,
        "button name": "Intent to upload image",
      }),
    );
  };

  return (
    <>
      {isPlatform("android") && (
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[
            {
              text: i18n.t.ImagePicker.takeAPicture,
              icon: camera,
              handler: async () => {
                dontAwait(
                  track({
                    eventName: "Button Clicked",
                    "screen name": screenName,
                    "team id": teamId,
                    "button name": "Android: Take a Picture",
                  }),
                );
                const image = await getImage("Camera");
                setUploadInProgress(true);
                const blob = parseDataURIToBlob(image.dataUrl);
                await onFilePicked(blob);
                dontAwait(
                  track({
                    eventName: "Image Uploaded",
                    "screen name": screenName,
                    "team id": teamId,
                    platform: "android",
                    source: "Camera",
                  }),
                );
                setUploadInProgress(false);
              },
            },
            {
              text: i18n.t.ImagePicker.pickFromGallery,
              icon: imagesOutline,
              handler: async () => {
                dontAwait(
                  track({
                    eventName: "Button Clicked",
                    "screen name": screenName,
                    "team id": teamId,
                    "button name": "Android: Pick from Gallery",
                  }),
                );
                const image = await getImage("Photos");
                setUploadInProgress(true);
                const blob = parseDataURIToBlob(image.dataUrl);
                dontAwait(
                  track({
                    eventName: "Image Uploaded",
                    "screen name": screenName,
                    "team id": teamId,
                    platform: "android",
                    source: "Gallery",
                  }),
                );
                await onFilePicked(blob);

                setUploadInProgress(false);
              },
            },
            {
              text: i18n.t.common.cancel,
              icon: close,
              role: "cancel",
              handler: async () => {
                setShowActionSheet(false);
              },
            },
          ]}
        />
      )}
      <input
        ref={fileInput}
        hidden
        type="file"
        accept="image/*"
        onChange={onInputChange}
      />
      <IonButton
        color="primary"
        onClick={onButtonClicked}
        fill={buttonFill ?? "clear"}
      >
        {uploadInProgress ? <IonSpinner /> : text}
      </IonButton>
    </>
  );
};

export default ImagePicker;
