import { viewUserProfilePageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import { IonIcon, IonItem } from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import React from "react";
import I18nContainer from "src/state/i18n/I18nContainer";

export type UserIsBlockedBannerProps = {
  teamId: string;
  blockedUserId: string;
};

const UserIsBlockedBanner: React.FC<UserIsBlockedBannerProps> = ({
  teamId,
  blockedUserId,
}) => {
  const i18n = I18nContainer.useContainer();
  return (
    <IonItem
      color="danger"
      routerLink={viewUserProfilePageUrl({
        teamId,
        userProfileId: blockedUserId,
      })}
      data-cy="UserIsBlockedBanner"
    >
      <p>
        <em>
          <IonIcon icon={warningOutline} />
          {i18n.t.UserIsBlockedBanner.notification}
        </em>
      </p>
    </IonItem>
  );
};

export default UserIsBlockedBanner;
