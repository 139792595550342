import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonSkeletonText,
} from "@ionic/react";
import React from "react";

const SkeletonCard: React.FC = () => (
  <IonCard>
    <IonCardHeader>
      <IonCardTitle>
        <IonSkeletonText animated />
      </IonCardTitle>
    </IonCardHeader>
    <IonCardContent>
      <IonSkeletonText animated />
      <IonSkeletonText animated />
      <IonSkeletonText animated />
    </IonCardContent>
  </IonCard>
);

export default SkeletonCard;
