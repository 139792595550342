import {
  primaryDirectMessageChannelPath,
  teamChannelPath,
} from "../firestore/firestorePathBuilders";

export function storageUrl({
  bucket,
  path,
}: {
  bucket: string;
  path: string;
}): string {
  return `gs://${[bucket, path].join("/").replace(/\/\//g, "/")}`;
}

export function teamChannelFileUploadPath({
  teamId,
  teamChannelId,
  fileUploadId,
}: {
  teamId: string;
  teamChannelId: string;
  fileUploadId: string;
}): string {
  return [
    teamChannelPath({ teamId, teamChannelId }),
    "FileUploads",
    fileUploadId,
  ]
    .join("/")
    .replace(/^\//, "");
}

export function directMessageChannelFileUploadPath({
  teamId,
  userId,
  otherUserId,
  fileUploadId,
}: {
  teamId: string;
  userId: string;
  otherUserId: string;
  fileUploadId: string;
}): string {
  return [
    primaryDirectMessageChannelPath({ teamId, userId, otherUserId }),
    "FileUploads",
    fileUploadId,
  ]
    .join("/")
    .replace(/^\//, "");
}

export function profileImagePath({
  userId,
  fileName,
}: {
  userId: string;
  fileName: string;
}): string {
  return `public/profileImages/${userId}/${fileName}`;
}
