import { teamLocationsPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import TeamLocationConverter from "@connectedliving/common/lib/firestore/TeamLocationConverter";
import { IonContent, IonModal, IonSpinner } from "@ionic/react";
import React, { useEffect, useState } from "react";
import commonCss from "src/common/common.module.css";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import { isDataAvailable } from "src/utilities/data/Loadable";
import useFirestoreCollection from "src/utilities/data/useFirestoreCollection";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import UserOnboardingDataPrivacySlide from "./UserOnboardingDataPrivacySlide";
import UserOnboardingFinalSlide from "./UserOnboardingFinalSlide";
import UserOnboardingInitialSlide from "./UserOnboardingInitialSlide";
import UserOnboardingLocationSlide from "./UserOnboardingLocationSlide";
import css from "./UserOnboardingModal.module.css";
import UserOnboardingNameSlide from "./UserOnboardingNameSlide";
import UserOnboardingProfileImageSlide from "./UserOnboardingProfileImageSlide";
import UserOnboardingTranslationLanguageSlide from "./UserOnboardingTranslationLanguageSlide";

type UserOnboardingModalProps = EmptyObject;

const UserOnboardingModal: React.FC<UserOnboardingModalProps> = () => {
  const { firebaseApp } = FirebaseAppContainer.useContainer();
  const { teamId, teamUserProfile, team } = TeamContextContainer.useContainer();

  const teamLocations = useFirestoreCollection(
    firebaseApp
      .firestore()
      .collection(teamLocationsPath({ teamId }))
      .withConverter(TeamLocationConverter),
  );

  const { onboardingVersionCompleted } = teamUserProfile.data();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (onboardingVersionCompleted === 0) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [onboardingVersionCompleted]);

  if (!isDataAvailable(teamLocations)) {
    return (
      <div className={commonCss.fullscreenContainer}>
        <IonSpinner color="primary" />
      </div>
    );
  }

  return (
    <IonModal isOpen={isOpen} backdropDismiss={false}>
      <IonContent>
        <Swiper
          modules={[Virtual]}
          virtual
          allowSlideNext
          allowSlidePrev
          allowTouchMove={false}
          className={css.swiper}
        >
          <SwiperSlide>
            <UserOnboardingInitialSlide teamLocations={teamLocations.data} />
          </SwiperSlide>
          <SwiperSlide>
            <UserOnboardingDataPrivacySlide />
          </SwiperSlide>
          <SwiperSlide>
            <UserOnboardingLocationSlide
              teamLocations={teamLocations.data}
              teamBuildingParts={team.data().buildingParts}
            />
          </SwiperSlide>
          <SwiperSlide>
            <UserOnboardingTranslationLanguageSlide />
          </SwiperSlide>
          <SwiperSlide>
            <UserOnboardingNameSlide />
          </SwiperSlide>
          <SwiperSlide>
            <UserOnboardingProfileImageSlide />
          </SwiperSlide>
          <SwiperSlide>
            <UserOnboardingFinalSlide />
          </SwiperSlide>
        </Swiper>
      </IonContent>
    </IonModal>
  );
};

export default UserOnboardingModal;
