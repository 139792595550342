import { InternalStreamChatGenerics } from "@connectedliving/common/lib/stream/InternalStreamChatGenerics";
import React, { useCallback, useMemo, useState } from "react";
import type { Attachment } from "stream-chat";
import {
  ModalComponent as ModalWrapper,
  useTranslationContext,
} from "stream-chat-react";
import { useChannelMessagesPageContext } from "./ChannelMessagesPageContext";

export type GalleryProps<
  StreamChatGenerics extends InternalStreamChatGenerics,
> = {
  images:
    | {
        image_url?: string | undefined;
        thumb_url?: string | undefined;
      }[]
    | Attachment<StreamChatGenerics>[];
};

const UnMemoizedCLGallery = <
  StreamChatGenerics extends InternalStreamChatGenerics,
>(
  props: GalleryProps<StreamChatGenerics>,
) => {
  const { images } = props;

  const [index, setIndex] = useState(0);
  //----------------------------------------------------------------------------
  // CONNECTED LIVING MODIFICATIONS
  //----------------------------------------------------------------------------
  const [modalOpen, internalSetModalOpen] = useState(false);
  const { setAttachmentModalOpen } = useChannelMessagesPageContext();

  const setModalOpen = useCallback(
    (value: boolean) => {
      internalSetModalOpen(value);
      setAttachmentModalOpen(value);
    },
    [setAttachmentModalOpen],
  );
  // END MODIFICATIONS ---------------------------------------------------------

  const { t } = useTranslationContext("Gallery");

  const toggleModal = (selectedIndex: number) => {
    if (modalOpen) {
      setModalOpen(false);
    } else {
      setIndex(selectedIndex);
      setModalOpen(true);
    }
  };

  const formattedArray = useMemo(
    () =>
      images.map((image) => ({
        original: image.image_url || image.thumb_url || "",
        originalAlt: "User uploaded content",
        source: image.image_url || image.thumb_url || "",
      })),
    [images],
  );

  const renderImages = images.slice(0, 3).map((image, i) => (
    <button
      type="button"
      className="str-chat__gallery-image"
      data-testid="gallery-image"
      key={image.image_url}
      onClick={() => toggleModal(i)}
    >
      <img
        alt="User uploaded content"
        src={image.image_url || image.thumb_url}
      />
    </button>
  ));

  return (
    <div
      className={`str-chat__gallery ${
        images.length > 3 ? "str-chat__gallery--square" : ""
      }`}
    >
      {renderImages}
      {images.length > 3 && (
        <button
          type="button"
          className="str-chat__gallery-placeholder"
          onClick={() => toggleModal(3)}
          style={{
            backgroundImage: `url(${images[3]?.image_url})`,
          }}
        >
          <p>
            {t("{{ imageCount }} more", {
              imageCount: images.length - 3,
            })}
          </p>
        </button>
      )}
      <ModalWrapper
        images={formattedArray}
        index={index}
        modalIsOpen={modalOpen}
        toggleModal={() => setModalOpen(!modalOpen)}
      />
    </div>
  );
};

/**
 * Displays images in a simple responsive grid with a light box to view the images.
 */
export const CLGallery = React.memo(
  UnMemoizedCLGallery,
) as typeof UnMemoizedCLGallery;
