import {
  selectableChannelIcons,
  SupportedChannelIcon,
} from "@connectedliving/common/lib/firestore/supportedChannelIcons";
import {
  ChannelIconColor,
  channelIconColors,
} from "@connectedliving/common/lib/firestore/TeamChannel";
import {
  IonAvatar,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonPage,
  IonRow,
  IonToolbar,
} from "@ionic/react";
import classNames from "classnames";
import React, { useState } from "react";
import ChannelAvatarContent from "src/common/ChannelAvatarContent";
import I18nContainer from "src/state/i18n/I18nContainer";
import css from "./SelectColoredIconModal.module.css";

export const SelectColoredIconModalName = "SelectColoredIconModal";

const SelectColoredIconModal = ({
  icon,
  onSelect,
}: {
  icon: { icon: SupportedChannelIcon; color: ChannelIconColor };
  onSelect: (value: {
    icon: SupportedChannelIcon;
    color: ChannelIconColor;
  }) => void;
}): React.ReactElement => {
  const i18n = I18nContainer.useContainer();
  const [selectedIconColor, setSelectedIconColor] = useState<ChannelIconColor>(
    icon.color,
  );
  const [selectedIcon, setSelectedIcon] = useState<SupportedChannelIcon>(
    icon.icon,
  );
  return (
    <IonPage>
      <IonHeader className={classNames("ion-no-border", css.modalHeader)}>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton
              onClick={() =>
                onSelect({ icon: selectedIcon, color: selectedIconColor })
              }
            >
              {i18n.t.common.done}
            </IonButton>
          </IonButtons>
        </IonToolbar>

        <div className={classNames("ion-padding", css.iconPreviewWrapper)}>
          <IonAvatar className={css.iconPreview}>
            <ChannelAvatarContent
              content={{
                type: "icon",
                icon: selectedIcon,
                iconColor: selectedIconColor,
              }}
            />
          </IonAvatar>
        </div>

        <div className="ion-padding-horizontal">
          <IonGrid fixed>
            <IonRow>
              {channelIconColors.map((color) => (
                <IonCol key={color} className={css.colorColumn}>
                  <IonAvatar
                    className={css.iconColor}
                    onClick={() => setSelectedIconColor(color)}
                  >
                    <ChannelAvatarContent
                      content={{
                        type: "icon",
                        icon:
                          color === selectedIconColor
                            ? "checkmarkCircle"
                            : null,
                        iconColor: color,
                      }}
                    />
                  </IonAvatar>
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </div>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonGrid fixed className="ion-no-padding">
          <IonRow className="ion-justify-content-center">
            {selectableChannelIcons.map((iconOption) => (
              <IonCol
                key={iconOption}
                className={`ion-no-padding ${css.iconContainer}`}
              >
                <IonAvatar
                  className={css.iconAvatar}
                  onClick={() => setSelectedIcon(iconOption)}
                >
                  <ChannelAvatarContent
                    content={{
                      type: "icon",
                      icon: iconOption,
                      iconColor: selectedIconColor,
                    }}
                  />
                </IonAvatar>
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default SelectColoredIconModal;
