import { FirestoreChannelType } from "@connectedliving/common/lib/firestore/FirestoreChannelType";
import { teamChannelMessagesPath } from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import MessageConverter from "@connectedliving/common/lib/firestore/MessageConverter";
import { TeamChannel } from "@connectedliving/common/lib/firestore/TeamChannel";
import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import {
  ActiveUserTrackingProps,
  ChannelTrackingProps,
} from "@connectedliving/common/lib/TrackingEvent";
import assertExhausted from "@connectedliving/common/lib/utilities/lang/assertExhausted";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import { ChannelTrackingContext } from "@connectedliving/common/lib/utilities/tracking/channelTrackingProps";
import { teamChannelDetailPageUrl } from "@connectedliving/common/lib/utilities/urlBuilders";
import {
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonText,
} from "@ionic/react";
import firebase from "firebase/compat/app";
import { warningOutline } from "ionicons/icons";
import React, { useMemo } from "react";
import ChannelsMessagesHeader from "src/channels/messages/ChannelsMessagesHeader";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import FirestoreChannelsContainer from "src/state/team/FirestoreChannelsContainer";
import TranslationConfigContainer from "src/state/TranslationConfigContainer";
import TeamUserProfilesContainer from "../../state/firebase/TeamUserProfilesContainer";
import I18nContainer from "../../state/i18n/I18nContainer";
import { isDataAvailable } from "../../utilities/data/Loadable";
import useFirestoreCollection from "../../utilities/data/useFirestoreCollection";
import environment from "../../utilities/environment";
import { isErrorState } from "../../utilities/LoadingState";
import SafeAreaSpacer from "../../utilities/SafeAreaSpacer";
import FirestoreMessageComposer from "./firestore/FirestoreMessageComposer";
import FirestoreMessageList from "./firestore/FirestoreMessageList";
import FirestoreMessageListErrorScreen from "./firestore/FirestoreMessageListErrorScreen";
import FirestoreMessageListLoadingScreen from "./firestore/FirestoreMessageListLoadingScreen";
import useChannelScreenBehavior from "./firestore/useChannelScreenBehavior";

export type FirestoreTeamChannelMessagesPageContentProps = {
  teamId: string;
  teamChannel: firebase.firestore.QueryDocumentSnapshot<TeamChannel>;
};

const FirestoreTeamChannelMessagesPageContent: React.FC<
  FirestoreTeamChannelMessagesPageContentProps
> = ({ teamId, teamChannel }) => {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();
  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const i18n = I18nContainer.useContainer();

  const { showTranslation, setShowTranslation } =
    TranslationConfigContainer.useContainer();
  const { findUserProfileById } = TeamUserProfilesContainer.useContainer();

  const { loadingState: firestoreChannelsLoadingState } =
    FirestoreChannelsContainer.useContainer();

  const channelDetailPageUrl = teamChannelDetailPageUrl({
    teamId,
    teamChannelId: teamChannel.id,
  });

  const messagesPath = teamChannelMessagesPath({
    teamId,
    teamChannelId: teamChannel.id,
  });

  const channelPath = teamChannel.ref.path;
  const teamChannelData = teamChannel.data();
  const { latestMessage } = teamChannelData;

  useChannelScreenBehavior({ channelPath, latestMessage });

  const messagesCollectionRef = firebaseApp
    .firestore()
    .collection(messagesPath)
    .withConverter(MessageConverter);
  const messagesCollection = useFirestoreCollection(
    messagesCollectionRef.orderBy("createdAt", "asc"),
  );

  const channelTrackingContext: ChannelTrackingContext = useMemo(
    () => ({
      type: FirestoreChannelType.Team,
      id: teamChannel.ref.id,
      path: teamChannel.ref.path,
      data: teamChannelData,
    }),
    [teamChannel.ref.id, teamChannel.ref.path, teamChannelData],
  );

  const messageListTrackingContext = useMemo(
    (): ChannelTrackingProps & ActiveUserTrackingProps => ({
      "team id": teamChannelData.teamId,
      "channel backing storage": teamChannelData.backingStorage,
      "channel type": "team",
      "channel id": teamChannel.id,
      "channel path": teamChannel.ref.path,
      "channel name": teamChannelData.name,
      "channel purpose": teamChannelData.purpose,
      "is support dm channel": false,
      "is support user": authUser.uid === environment().supportUserId(),
    }),
    [
      authUser.uid,
      teamChannel.id,
      teamChannel.ref.path,
      teamChannelData.backingStorage,
      teamChannelData.name,
      teamChannelData.purpose,
      teamChannelData.teamId,
    ],
  );

  function content() {
    if (messagesCollection.loadingState === "initialLoad") {
      return <FirestoreMessageListLoadingScreen />;
    }

    if (messagesCollection.loadingState === "networkUnavailable") {
      return (
        <FirestoreMessageListErrorScreen>
          <IonText className="ion-text-center">
            <IonIcon icon={warningOutline} size="large" />
            <br />
            {i18n.t.common.cantReachNetwork}
          </IonText>
        </FirestoreMessageListErrorScreen>
      );
    }

    if (isErrorState(messagesCollection.loadingState)) {
      return (
        <FirestoreMessageListErrorScreen>
          <IonText className="ion-text-center">
            <IonIcon icon={warningOutline} size="large" />
            <br />
            {String(messagesCollection.loadingState.error)}
          </IonText>
        </FirestoreMessageListErrorScreen>
      );
    }

    if (
      messagesCollection.loadingState === "updating" ||
      messagesCollection.loadingState === "ready"
    ) {
      return (
        <FirestoreMessageList
          channelType={FirestoreChannelType.Team}
          trackingContext={messageListTrackingContext}
          {...{
            teamId,
            messagesCollection: messagesCollection.data,
            findUserProfileById,
          }}
        />
      );
    }

    throw assertExhausted(messagesCollection.loadingState);
  }

  return (
    <>
      <IonHeader>
        <ChannelsMessagesHeader
          {...{
            teamId,
            showTranslation,
            channelDetailPageUrl,
            channelTrackingContext,
          }}
          channelPath={teamChannel.ref.path}
          onShowTranslationChange={setShowTranslation}
          channelType={StreamChannelType.Team}
          channelAvatar={{
            type: "icon",
            icon: teamChannel.data().channelIcon,
            iconColor: teamChannel.data().channelIconColor,
          }}
          loadingState={firestoreChannelsLoadingState}
          title={teamChannel.data().name || ""}
          titleRouterLink={channelDetailPageUrl}
        />
      </IonHeader>
      <IonContent forceOverscroll={false} scrollY={false}>
        {content()}
      </IonContent>
      <IonFooter>
        <FirestoreMessageComposer
          disabled={!isDataAvailable(messagesCollection)}
          {...{ channelPath }}
        />
        <SafeAreaSpacer position="bottom" />
      </IonFooter>
    </>
  );
};

export default FirestoreTeamChannelMessagesPageContent;
