import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonButton,
  IonItem,
  IonLabel,
  IonSpinner,
  IonText,
  IonToggle,
  useIonToast,
} from "@ionic/react";
import * as Sentry from "@sentry/react";
import { close } from "ionicons/icons";
import { get } from "lodash";
import { useCallback, useEffect, useState } from "react";
import FormSingleSelect from "src/common/form/FormSingleSelect";
import { useFieldInputs } from "src/state/formState/useFieldInputs";
import useFormState, {
  useFieldStates,
  useForm,
} from "src/state/formState/useFormState";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import TeamContextContainer from "src/state/TeamContextContainer";
import updateUserProfileAndTeamProfile from "src/userProfiles/updateUserAndTeamUserProfiles";
import localeToTranslationLanguage from "src/utilities/i18n/localeToTranslationLanguage";
import { EmptyObject } from "src/utilities/lang/EmptyObject";
import mapObjectToFormSelectOptions from "src/utilities/mapObjectToFormSelectOptions";
import SafeAreaSpacer from "src/utilities/SafeAreaSpacer";
import { useSwiper, useSwiperSlide } from "swiper/react";
import UserOnboardingSlideLayout from "./UserOnboardingSlideLayout";
import layoutCss from "./UserOnboardingSlideLayout.module.css";
import css from "./UserOnboardingTranslationLanguageSlide.module.css";

type UserOnboardingTranslationLanguageSlideParams = EmptyObject;

export type UserOnboardingTranslationLanguageSlideFormValues = Pick<
  UserProfile,
  "language"
>;
const UserOnboardingTranslationLanguageSlide: React.FC<
  UserOnboardingTranslationLanguageSlideParams
> = () => {
  const { teamId, userProfile } = TeamContextContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();

  useEffect(() => {
    if (swiperSlide.isVisible) {
      dontAwait(
        track({
          eventName: "Screen Viewed",
          "team id": teamId,
          "screen name": "UserOnboardingTranslationLanguageSlide",
        }),
      );
    }
  }, [swiperSlide.isVisible, teamId, track]);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [presentToast, dismissToast] = useIonToast();
  const formState =
    useFormState<UserOnboardingTranslationLanguageSlideFormValues>({
      initial: {
        language: userProfile.data().language,
      },
    });

  const fields = useFieldInputs(formState, useFieldStates(formState));

  const showSkip = () => fields.language.value === userProfile.data().language;

  const { form } = useForm(formState, {
    onSubmit: useCallback(
      async (formValues) => {
        if (isSubmitting) return;
        setIsSubmitting(true);
        try {
          await updateUserProfileAndTeamProfile({
            userProfile: {
              updatedUserProfile: {
                language: formValues.language,
              },
              originalUserProfile: userProfile,
            },
            teamId,
            track,
          });
          setIsSubmitting(false);
          swiper.slideNext();
        } catch (error) {
          setIsSubmitting(false);
          const message = `${i18n.t.common.genericError} ${get(
            error,
            "message",
          )}`;
          Sentry.captureException(error);
          dontAwait(
            presentToast({
              message,
              color: "danger",
              duration: 5000,
              position: "bottom",
              buttons: [
                {
                  icon: close,
                  role: "cancel",
                  handler: () => dismissToast(),
                },
              ],
            }),
          );
        }
      },
      [
        dismissToast,
        i18n.t.common.genericError,
        isSubmitting,
        presentToast,
        swiper,
        teamId,
        track,
        userProfile,
      ],
    ),
  });

  let buttonDisplay;
  if (isSubmitting) {
    buttonDisplay = <IonSpinner />;
  } else if (showSkip()) {
    buttonDisplay = i18n.t.common.skip;
  } else buttonDisplay = i18n.t.common.next;

  return (
    <form {...form} className={layoutCss.form}>
      <UserOnboardingSlideLayout>
        <SafeAreaSpacer position="top" />
        <div
          data-cy="UserOnboardingTranslationLanguageSlide"
          className={`ion-padding-horizontal ${layoutCss.media} ${css.translationWrapper}`}
        >
          <img
            className={`${css.illustration}`}
            src="/assets/illustrations/translate_2.png"
            alt={i18n.t.UserOnboardingTranslationLanguageSlide.images.altOne}
          />
          <img
            className={`${css.translationIllustration} ${css.illustration}`}
            src="/assets/illustrations/translated_2.png"
            alt={i18n.t.UserOnboardingTranslationLanguageSlide.images.altTwo}
          />
        </div>
        <div className={layoutCss.content}>
          <h2>
            <IonText color="aubergine-text">
              <strong>
                {i18n.t.UserOnboardingTranslationLanguageSlide.headline}
              </strong>
            </IonText>
          </h2>
          <p>{i18n.t.UserOnboardingTranslationLanguageSlide.explanation}</p>
          <IonItem lines="none" className="ion-no-padding">
            <IonToggle
              data-cy="UserOnboardingTranslationLanguageSlide-toggle"
              checked={!!fields.language.value}
              onIonChange={(e) => {
                if (e.detail.checked) {
                  fields.language.onChange(
                    userProfile.data().language ??
                      localeToTranslationLanguage[i18n.currentLocale],
                  );
                } else fields.language.onChange(null);
              }}
            />
            <IonLabel className="ion-margin">
              {i18n.t.UserOnboardingTranslationLanguageSlide.toggleLabel}
            </IonLabel>
          </IonItem>
          {fields.language.value && (
            <FormSingleSelect
              data-cy="UserOnboardingTranslationLanguageSlide-select"
              {...fields.language}
              label={i18n.t.UserOnboardingTranslationLanguageSlide.selectLabel}
              options={mapObjectToFormSelectOptions(i18n.t.common.language)}
              buttonLabels={i18n.t.common}
            />
          )}
        </div>
        <div className={`${layoutCss.footer} ${layoutCss.buttons}`}>
          <IonButton
            color="aubergine-text"
            size="large"
            fill="clear"
            data-cy="UserOnboardingTranslationLanguageSlide-goBack"
            onClick={() => swiper.slidePrev()}
          >
            {i18n.t.common.back}
          </IonButton>
          <IonButton
            type="submit"
            color={showSkip() ? "aubergine-text" : "aubergine-fill"}
            fill={showSkip() ? "clear" : "solid"}
            size="large"
            data-cy="UserOnboardingTranslationLanguageSlide-submit"
            disabled={isSubmitting}
          >
            {buttonDisplay}
          </IonButton>
        </div>
      </UserOnboardingSlideLayout>
    </form>
  );
};

export default UserOnboardingTranslationLanguageSlide;
