import { Team } from "@connectedliving/common/lib/firestore/Team";
import updateFirestoreDocument from "@connectedliving/common/lib/utilities/firestore/updateFirestoreDocument";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { MixpanelClient } from "src/state/mixpanel/MixpanelContainer";
import updatedObjectKeys from "src/utilities/data/updatedObjectKeys";
import { ExistingDocument } from "src/utilities/data/useFirestoreDocument";

export default function updateTeam({
  update: updatedTeam,
  team: originalTeam,
  track,
  teamId,
}: {
  update: Partial<Pick<Team, "name" | "numberOfApartments" | "buildingParts">>;
  team: ExistingDocument<Team>;
  track: MixpanelClient["track"];
  teamId: string;
}): Promise<unknown> {
  const pendingOperations: Promise<unknown>[] = [];

  const originalTeamData = originalTeam.data();
  const teamFieldsUpdated = updatedObjectKeys<Team>(
    originalTeamData,
    updatedTeam,
  );
  if (teamFieldsUpdated.length > 0) {
    pendingOperations.push(updateFirestoreDocument(originalTeam, updatedTeam));
  }

  dontAwait(
    track({
      eventName: "Team Updated",
      "team id": teamId,
      "team fields updated": teamFieldsUpdated,
    }),
  );
  return Promise.all(pendingOperations);
}
