import {
  teamsPath,
  userProfilePath,
} from "@connectedliving/common/lib/firestore/firestorePathBuilders";
import { Team } from "@connectedliving/common/lib/firestore/Team";
import TeamConverter from "@connectedliving/common/lib/firestore/TeamConverter";
import { UserProfile } from "@connectedliving/common/lib/firestore/UserProfile";
import UserProfileConverter from "@connectedliving/common/lib/firestore/UserProfileConverter";
import { MAX_CLAUSES_PER_QUERY } from "@connectedliving/common/lib/utilities/firebaseConstants";
import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import firebase from "firebase/compat/app";
import { chunk, flatMap } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import combineLoadingStates from "src/utilities/combineLoadingStates";
import { createContainer } from "src/utilities/createContainer";
import { isDataAvailable, Loadable } from "src/utilities/data/Loadable";
import useFirestoreDocument from "src/utilities/data/useFirestoreDocument";
import { LoadingState } from "src/utilities/LoadingState";
import { UserProfileDocumentRef } from "src/utilities/UserProfileDocumentRef";
import InitialUrlSearchParamsContainer from "../InitialUrlSearchParamsContainer";
import FirebaseAppContainer from "./FirebaseAppContainer";

export type LoggedInUserProfile = {
  loadingState: LoadingState;
  userProfile: UserProfile | undefined;
  userProfileDocumentRef: UserProfileDocumentRef | undefined;
  userProfileLoadingState: LoadingState;
  userProfileSnapshot:
    | firebase.firestore.DocumentSnapshot<UserProfile>
    | undefined;
  teams: firebase.firestore.QueryDocumentSnapshot<Team>[];
  findTeamById: (
    teamId: string,
  ) => firebase.firestore.QueryDocumentSnapshot<Team> | undefined;
};

export function useLoggedInUserProfile(): LoggedInUserProfile {
  const { firebaseApp, authUser } = FirebaseAppContainer.useContainer();

  const userProfile = useFirestoreDocument(
    authUser &&
      firebaseApp
        .firestore()
        .doc(userProfilePath({ userId: authUser.uid }))
        .withConverter(UserProfileConverter),
  );

  const [teams, setTeams] = useState<
    Loadable<firebase.firestore.QueryDocumentSnapshot<Team>[]>
  >({ loadingState: "initialLoad", data: undefined });

  const teamIdsHash = userProfile.data?.data()?.teamIds?.join("");
  useEffect(() => {
    async function loadData() {
      const teamIds = userProfile.data?.data()?.teamIds;
      const teamIdsInSlices = chunk(teamIds, MAX_CLAUSES_PER_QUERY);

      const sliceQuerySnapshots = await Promise.all(
        flatMap(teamIdsInSlices, (teamIdsSlice) =>
          firebaseApp
            .firestore()
            .collection(teamsPath())
            .where(
              firebase.firestore.FieldPath.documentId(),
              "in",
              teamIdsSlice,
            )
            .withConverter(TeamConverter)
            .get(),
        ),
      );

      const docs = sliceQuerySnapshots.flatMap(
        (querySnapshot) => querySnapshot.docs,
      );
      setTeams({ data: docs, loadingState: "ready" });
    }

    if (!isDataAvailable(userProfile)) return;
    dontAwait(loadData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firebaseApp, userProfile.loadingState, teamIdsHash]);

  const { initialUrlSearchParams, resetInitialUrlSearchParams } =
    InitialUrlSearchParamsContainer.useContainer();

  useEffect(() => {
    if (userProfile.loadingState !== "ready" || !userProfile.data.exists)
      return;

    const existingUtmParams = userProfile.data.data().utmParams;
    if (existingUtmParams) return;

    dontAwait(
      (async () => {
        await userProfile.data.ref.update({
          utmParams: {
            utm_medium: initialUrlSearchParams.get("utm_medium"),
            utm_source: initialUrlSearchParams.get("utm_source"),
            utm_campaign: initialUrlSearchParams.get("utm_campaign"),
            utm_term: initialUrlSearchParams.get("utm_term"),
            utm_content: initialUrlSearchParams.get("utm_content"),
            utm_item: initialUrlSearchParams.get("utm_item"),
          },
          referrerUserId: initialUrlSearchParams.get("referrerUserId"),
          referrerTeamId: initialUrlSearchParams.get("referrerTeamId"),
        });
        resetInitialUrlSearchParams();
      })(),
    );
  }, [
    initialUrlSearchParams,
    resetInitialUrlSearchParams,
    userProfile.data,
    userProfile.loadingState,
  ]);

  const findTeamById = useCallback(
    (teamId: string) => teams.data?.find(({ id }) => id === teamId),
    [teams.data],
  );

  return {
    loadingState: combineLoadingStates(
      userProfile.loadingState,
      teams.loadingState,
    ),
    userProfileLoadingState: userProfile.loadingState,
    userProfile: userProfile.data?.data(),
    userProfileSnapshot: userProfile.data,
    userProfileDocumentRef: userProfile.data?.ref,
    teams: useMemo(() => teams.data || [], [teams.data]),
    findTeamById,
  };
}

const LoggedInUserProfileContainer = createContainer(useLoggedInUserProfile);
export default LoggedInUserProfileContainer;
