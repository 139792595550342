import { StreamChannelType } from "@connectedliving/common/lib/stream/StreamChannelType";
import assertPresent from "@connectedliving/common/lib/utilities/lang/assertPresent";
import { IonItem, IonList, IonListHeader } from "@ionic/react";
import FirebaseAppContainer from "src/state/firebase/FirebaseAppContainer";
import I18nContainer from "src/state/i18n/I18nContainer";

type WhoCanSeeThisProps = {
  channelType: StreamChannelType;
  className?: string;
  withSupportUser?: boolean;
};

const WhoCanSeeThis: React.FC<WhoCanSeeThisProps> = ({
  channelType,
  className,
  withSupportUser,
}) => {
  const { authUser } = FirebaseAppContainer.useContainer();
  assertPresent(authUser, { because: "<App> waits for user to log in" });

  const otherPeopleCanJoin: boolean = channelType === "team";
  const i18n = I18nContainer.useContainer();

  return (
    <IonList className={className}>
      <IonListHeader>{i18n.t.WhoCanSeeThis.header}</IonListHeader>

      {otherPeopleCanJoin ? (
        <>
          <IonItem>{i18n.t.WhoCanSeeThis.whoCanSee.anyone}</IonItem>
          <IonItem>{i18n.t.WhoCanSeeThis.whoCanJoin.anyone}</IonItem>
        </>
      ) : (
        <>
          {withSupportUser ? (
            <IonItem>{i18n.t.WhoCanSeeThis.whoCanSee.userSupport}</IonItem>
          ) : (
            <IonItem>{i18n.t.WhoCanSeeThis.whoCanSee.private}</IonItem>
          )}
          <IonItem>{i18n.t.WhoCanSeeThis.whoCanJoin.noOne}</IonItem>
        </>
      )}
    </IonList>
  );
};

export default WhoCanSeeThis;
