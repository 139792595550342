import firebase from "firebase/compat/app";
import {
  firestoreDataToTeamChannelMembership,
  TeamChannelMembership,
  teamChannelMembershipToFirestoreData,
} from "./TeamChannelMembership";

const TeamChannelMembershipConverter: firebase.firestore.FirestoreDataConverter<TeamChannelMembership> =
  Object.freeze({
    toFirestore(teamChannelMembership: TeamChannelMembership) {
      return teamChannelMembershipToFirestoreData(teamChannelMembership);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): TeamChannelMembership {
      return firestoreDataToTeamChannelMembership(snapshot.data(options));
    },
  });
export default TeamChannelMembershipConverter;
