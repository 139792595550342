import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSearchbar,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { chevronBack } from "ionicons/icons";
import { useEffect, useRef } from "react";
import commonCss from "src/common/common.module.css";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import formatTeamLocation from "src/utilities/formatTeamLocation";
import GoogleMapsApiContainer from "../state/GoogleMapsApiContainer";
import { isErrorState } from "../utilities/LoadingState";
import ErrorCard from "./ErrorCard";
import css from "./SearchAddressModal.module.css";
import useSearchAddressModalState from "./useSearchAddressModalState";

type SearchAddressModalProps = React.ComponentProps<typeof IonModal> & {
  placeholder: string;
  submitText: string;
  screenName: string;
  teamId: string | undefined;
};

const SearchAddressModal: React.FC<SearchAddressModalProps> = ({
  placeholder,
  submitText,
  isOpen,
  screenName,
  teamId,
  ...restProps
}) => {
  const modalRef = useRef<HTMLIonModalElement>(null);

  const i18n = I18nContainer.useContainer();
  const { track } = MixpanelClientContainer.useContainer();
  const { loadGoogleMapsApi, loadingState: googleMapsApiLoadingState } =
    GoogleMapsApiContainer.useContainer();
  const {
    searchAddressModalState,
    selectSuggestion,
    setSearchInputValue,
    initializeUsePlacesAutocompleteHook,
  } = useSearchAddressModalState();

  useEffect(() => {
    dontAwait(
      loadGoogleMapsApi().then(() => {
        initializeUsePlacesAutocompleteHook();
      }),
    );
  }, [initializeUsePlacesAutocompleteHook, loadGoogleMapsApi]);

  const modalName = "SearchAddressModal";

  const renderErrorMessage = () => {
    if (searchAddressModalState.state !== "failed") {
      return undefined;
    }

    if (searchAddressModalState.addressComponentErrors) {
      return (
        <IonItem className="ion-text-center" lines="none">
          <IonLabel className="ion-text-wrap" color="danger">
            {i18n.t.SearchAddressModal.missingAddressComponentsError(
              searchAddressModalState.addressComponentErrors,
            )}
          </IonLabel>
        </IonItem>
      );
    }
    if (searchAddressModalState.requestStatus) {
      if (searchAddressModalState.requestStatus === "ZERO_RESULTS") {
        return (
          <IonItem className="ion-text-center" lines="none">
            <IonLabel className="ion-text-wrap">
              {i18n.t.SearchAddressModal.noResults}
            </IonLabel>
          </IonItem>
        );
      }
      return (
        <IonItem className="ion-text-center" lines="none">
          <IonLabel className="ion-text-wrap" color="danger">
            {i18n.t.SearchAddressModal.apiError}
          </IonLabel>
        </IonItem>
      );
    }
    return undefined;
  };

  const submitModal = () => {
    if (
      modalRef.current &&
      searchAddressModalState.state === "valid_address_selected"
    ) {
      dontAwait(
        track({
          eventName: "Modal Completed",
          "screen name": screenName,
          "team id": teamId,
          "modal name": modalName,
        }),
      );
      dontAwait(
        modalRef.current.dismiss(searchAddressModalState.verifiedAddress),
      );
    }
  };

  let content;
  if (
    googleMapsApiLoadingState === "initialLoad" ||
    googleMapsApiLoadingState === null
  ) {
    content = (
      <div
        className={`${commonCss.fullscreenContainer} ${commonCss.flexColumn}`}
      >
        <IonSpinner color="primary" />
      </div>
    );
  } else if (isErrorState(googleMapsApiLoadingState)) {
    content = (
      <ErrorCard
        errorMessage={i18n.t.SearchAddressModal.loadingError}
        onRetryClick={async () => {
          await loadGoogleMapsApi();
        }}
      />
    );
  } else {
    content = (
      <>
        {renderErrorMessage()}
        {searchAddressModalState.state === "ready_to_query" && (
          <IonItem className="ion-text-center" lines="none">
            <IonLabel className="ion-text-wrap" color="medium">
              {i18n.t.SearchAddressModal.initialMessage}
            </IonLabel>
          </IonItem>
        )}
        {searchAddressModalState.state === "query_in_progress" && (
          <div
            className={`${commonCss.fullscreenContainer} ${commonCss.flexColumn}`}
          >
            <IonSpinner color="primary" />
          </div>
        )}
        {searchAddressModalState.state === "valid_address_selected" ? (
          <IonItem
            data-cy={`${modalName}-verified-address`}
            color="primary"
            onClick={submitModal}
          >
            <IonLabel>
              {formatTeamLocation(searchAddressModalState.verifiedAddress)}
            </IonLabel>
          </IonItem>
        ) : (
          searchAddressModalState.queryResults.length > 0 && (
            <IonList data-cy={`${modalName}-resultList`}>
              {searchAddressModalState.queryResults.map((suggestion, i) => (
                <IonItem
                  disabled={
                    searchAddressModalState.state === "geocode_in_progress"
                  }
                  key={suggestion.place_id}
                  onClick={() => selectSuggestion(suggestion)}
                  data-cy={`${modalName}-result-${i + 1}`}
                >
                  <IonLabel>{suggestion.description}</IonLabel>
                </IonItem>
              ))}
              <IonItem lines="none">
                <div slot="end" className={css.poweredByGoogle} />
              </IonItem>
            </IonList>
          )
        )}
      </>
    );
  }

  return (
    <IonModal
      isOpen={isOpen}
      ref={modalRef}
      {...restProps}
      data-cy={`${modalName}`}
      onIonModalDidPresent={() => {
        dontAwait(
          track({
            eventName: "Modal Opened",
            "screen name": screenName,
            "team id": teamId,
            "modal name": modalName,
          }),
        );
      }}
    >
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton
              onClick={() => {
                if (modalRef.current) {
                  dontAwait(
                    track({
                      eventName: "Modal Aborted",
                      "screen name": screenName,
                      "team id": teamId,
                      "modal name": modalName,
                    }),
                  );
                  dontAwait(modalRef.current.dismiss());
                }
              }}
            >
              <IonIcon slot="start" icon={chevronBack} />
              {i18n.t.common.cancel}
            </IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton
              type="submit"
              onClick={submitModal}
              disabled={
                searchAddressModalState.state !== "valid_address_selected"
              }
              data-cy={`${modalName}-submit`}
            >
              {submitText}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        {searchAddressModalState.state !== "initial" && (
          <IonSearchbar
            placeholder={placeholder}
            value={searchAddressModalState.searchInputValue}
            onIonChange={(e) => {
              if (e.detail.value === searchAddressModalState.searchInputValue)
                return;
              setSearchInputValue(e.detail.value || "");
            }}
            onIonClear={() => {
              setSearchInputValue("");
            }}
            disabled={searchAddressModalState.state === "geocode_in_progress"}
            debounce={0}
            autocomplete="street-address"
            data-cy={`${modalName}-searchbar`}
          />
        )}
      </IonHeader>
      <IonContent>{content}</IonContent>
    </IonModal>
  );
};

export default SearchAddressModal;
