import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonMenuButton,
  IonPage,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { menuOutline } from "ionicons/icons";
import React from "react";

export type NotFoundPageProps = {};

const NotFoundPage: React.FC<NotFoundPageProps> = () => (
  <IonPage>
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonMenuButton slot="start" menu="conversationsList">
            <IonIcon icon={menuOutline} size="large" />
          </IonMenuButton>
        </IonButtons>
        <IonTitle> </IonTitle>
      </IonToolbar>
    </IonHeader>
    <IonContent>
      <p className="ion-text-center">
        <IonText color="medium">
          Choose a conversation from the menu on the left
        </IonText>
      </p>
    </IonContent>
  </IonPage>
);

export default NotFoundPage;
