export type StarterKitSize = "small" | "medium" | "large";

type StarterKit = {
  size: StarterKitSize;
  paypalUrl: string;
  numberOfInvitations: number;
  numberOfPosters: number;
  cost: number;
  shipping: number;
};

type StarterKitOptions = Record<StarterKitSize, StarterKit>;

export const starterKitOptions: StarterKitOptions = {
  small: {
    size: "small",
    paypalUrl: "https://www.paypal.com/instantcommerce/checkout/53M64MTTW26FW",
    numberOfInvitations: 20,
    numberOfPosters: 4,
    cost: 1.66,
    shipping: 1.6,
  },
  medium: {
    size: "medium",
    paypalUrl: "https://www.paypal.com/instantcommerce/checkout/CFLL7GV4MSFU8",
    numberOfInvitations: 40,
    numberOfPosters: 6,
    cost: 3.13,
    shipping: 1.6,
  },
  large: {
    size: "large",
    paypalUrl: "https://www.paypal.com/instantcommerce/checkout/76ZYFVMUMLBA8",
    numberOfInvitations: 100,
    numberOfPosters: 8,
    cost: 6.9,
    shipping: 1.6,
  },
};
