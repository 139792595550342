import { Message } from "@connectedliving/common/lib/firestore/Message";
import { assertSpecificTimestampValue } from "@connectedliving/common/lib/firestore/TimestampValue";
import convertTimestampValueToDate from "@connectedliving/common/lib/utilities/convertTimestampValueToDate";
import { useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useEffect, useState } from "react";
import LastReadTimestampsContainer from "src/state/team/LastReadTimestampsContainer";

export default function useChannelScreenBehavior({
  channelPath,
  latestMessage,
}: {
  channelPath: string;
  latestMessage: Message | null;
}): void {
  const { getChannelLastReadTimestamp, setChannelLastReadTimestamp } =
    LastReadTimestampsContainer.useContainer();
  const channelLastReadTimestamp = getChannelLastReadTimestamp(channelPath);
  const latestMessageCreatedAt = latestMessage?.createdAt;
  const [viewIsActive, setViewIsActive] = useState(false);

  useIonViewDidEnter(() => {
    setViewIsActive(true);
  });
  useIonViewDidLeave(() => {
    setViewIsActive(false);
  });

  useEffect(() => {
    if (!latestMessageCreatedAt || !viewIsActive) return;
    assertSpecificTimestampValue(latestMessageCreatedAt);
    const latestMessageCreatedAtDate = convertTimestampValueToDate(
      latestMessageCreatedAt,
    );

    if (
      !channelLastReadTimestamp ||
      latestMessageCreatedAtDate.valueOf() > channelLastReadTimestamp.valueOf()
    ) {
      setChannelLastReadTimestamp(channelPath, latestMessageCreatedAtDate);
    }
  }, [
    channelLastReadTimestamp,
    channelPath,
    latestMessageCreatedAt,
    setChannelLastReadTimestamp,
    viewIsActive,
  ]);
}
