import dontAwait from "@connectedliving/common/lib/utilities/lang/dontAwait";
import { IonCol, IonGrid, IonImg, IonItem, IonRow } from "@ionic/react";
import React from "react";
import I18nContainer from "src/state/i18n/I18nContainer";
import MixpanelClientContainer from "src/state/mixpanel/MixpanelContainer";
import css from "./AppStoreButtons.module.css";

export type AppStoreButtonsProps = {
  className?: string;
  screenName: string;
  teamId: string | undefined;
  color?: string;
};

const AppStoreButtons: React.FC<AppStoreButtonsProps> = ({
  className,
  screenName,
  color,
  teamId,
}) => {
  const { track } = MixpanelClientContainer.useContainer();
  const i18n = I18nContainer.useContainer();
  return (
    <IonGrid
      className={`ion-margin-top ${className} ion-no-padding`}
      color={color}
    >
      <IonRow>
        <IonCol size="6">
          <IonItem
            color={color}
            href={process.env.REACT_APP_GOOGLE_PLAY_STORE_URL}
            className={`ion-no-padding ${css.storeButton}`}
            target="_blank"
            rel="noreferrer"
            lines="none"
            detail={false}
            onClick={() => {
              dontAwait(
                track({
                  eventName: "Button Clicked",
                  "screen name": screenName,
                  "team id": teamId,
                  "button name": "Google Play Store",
                }),
              );
            }}
          >
            <IonImg
              src="https://app.connectedliving.chat/assets/download-links/google-play-button.png"
              alt={i18n.t.TeamCreatedSuccessPage.playStoreImgAlt}
            />
          </IonItem>
        </IonCol>
        <IonCol size="6">
          <IonItem
            color={color}
            href={process.env.REACT_APP_APPLE_APP_STORE_URL}
            className={`ion-no-padding ${css.storeButton} ${css.appleButton}`}
            target="_blank"
            rel="noreferrer"
            lines="none"
            detail={false}
            onClick={() => {
              dontAwait(
                track({
                  eventName: "Button Clicked",
                  "screen name": screenName,
                  "team id": teamId,
                  "button name": "Apple App Store",
                }),
              );
            }}
          >
            <IonImg
              src="https://app.connectedliving.chat/assets/download-links/app-store-button.png"
              alt={i18n.t.TeamCreatedSuccessPage.appleStoreImgAlt}
            />
          </IonItem>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default AppStoreButtons;
