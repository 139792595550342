import firebase from "firebase/compat/app";
import { omit } from "lodash";
import convertFields from "../utilities/firestore/convertFields";
import {
  convertTimestampValueToFirebaseAppTimestamp,
  getFirestoreAppTimestampValue,
  getOptionalFirestoreAppTimestampValue,
} from "./app/firestoreAppTimestamp";
import {
  getBoolean,
  getDictionary,
  getList,
  getOptionalStrictDocumentData,
  getOptionalString,
  getOptionalStringLiteral,
  getString,
  isString,
  StrictDocumentData,
} from "./dataHelpers";
import { Message } from "./Message";
import {
  firestoreDataToMessageAttachment,
  isRecognizedAttachmentType,
} from "./MessageAttachment";
import { messagePurposeValues } from "./MessagePurpose";

export function firestoreDataToMessage(data: StrictDocumentData): Message {
  const translationsData = getOptionalStrictDocumentData(data.translations);
  const offerData = getOptionalStrictDocumentData(data.offer);
  const processingMetadataData = getOptionalStrictDocumentData(
    data.processingMetadata,
  );

  return {
    creatorId: getString(data.creatorId),
    createdAt: getFirestoreAppTimestampValue(data.createdAt),
    updatedAt: getFirestoreAppTimestampValue(data.updatedAt),
    updatedBy: getOptionalString(data.updatedBy),
    body: getString(data.body),
    deleted: getBoolean(data.deleted),
    detectedSourceLanguage: getOptionalString(data.detectedSourceLanguage),
    translations:
      translationsData &&
      getDictionary(translationsData, {
        isKey: isString,
        isValue: isString,
        fallback: {},
      }),
    attachments: getList(data.attachments, {
      element: isRecognizedAttachmentType,
    }).map((value) => firestoreDataToMessageAttachment(value)),
    purpose: getOptionalStringLiteral(data.purpose, {
      permitted: messagePurposeValues,
    }),
    offer: offerData && {
      id: getString(offerData.id),
      partnerId: getString(offerData.partnerId),
    },
    processingMetadata: processingMetadataData && {
      processedAt: getOptionalFirestoreAppTimestampValue(
        processingMetadataData.processedAt,
      ),
      translatedAt: getOptionalFirestoreAppTimestampValue(
        processingMetadataData.translatedAt,
      ),
      translatedText: getOptionalString(processingMetadataData.translatedText),
      messageCreatedTrackedAt: getOptionalFirestoreAppTimestampValue(
        processingMetadataData.messageCreateTrackedAt,
      ),
      messageUpdatedTrackedAt: getOptionalFirestoreAppTimestampValue(
        processingMetadataData.messageUpdatedTrackedAt,
      ),
      messageDeletedTrackedAt: getOptionalFirestoreAppTimestampValue(
        processingMetadataData.messageDeletedTrackedAt,
      ),
      newMessageNotificationEnabled: getBoolean(
        processingMetadataData.newMessageNotificationEnabled,
        { fallback: true },
      ),
      newMessageNotificationSentAt: getOptionalFirestoreAppTimestampValue(
        processingMetadataData.newMessageNotificationSentAt,
      ),
    },
  };
}

export function messageToFirestoreData(message: Message): StrictDocumentData {
  const result = convertFields(message, {
    createdAt: convertTimestampValueToFirebaseAppTimestamp,
    updatedAt: convertTimestampValueToFirebaseAppTimestamp,
  });
  return omit(result, [
    "translations",
    "detectedSourceLanguage",
    "purpose",
    "offer",
    "processingMetadata",
  ]);
}

const MessageConverter: firebase.firestore.FirestoreDataConverter<Message> =
  Object.freeze({
    toFirestore(message: Message) {
      return messageToFirestoreData(message);
    },

    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Message {
      return firestoreDataToMessage(snapshot.data(options));
    },
  });

export default MessageConverter;
